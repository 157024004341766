import React, { useEffect, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { styled } from "@mui/material/styles";
import * as data from '../data/regions-geojson-uk';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import WaterIcon from '@mui/icons-material/Water';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import TollIcon from '@mui/icons-material/Toll';
import ProjectService from '../services/project-service.service';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    whiteSpace: "pre-line"
  }
}));

export default function ProjectMap(props) {

  const projectService = new ProjectService();
  const [regions, setRegions] = useState()

  const colorArray = ['#D2E7D6', '#355438', '#81A984', '#4C7450', '#b8d8be', '#9aba9c', '#c8e1cc', '#416044', '#68946c'];

  const handleRegionClick = (geo) => {
    let region;
    const geoRegion = geo.properties.RGN20NM;
    if (geoRegion === 'London' || geoRegion === 'South East') {
      region = 'South East and London'
    } else if (geoRegion === 'Yorkshire and The Humber') {
      region = 'Yorkshire and the Humber'
    } else {
      region = geoRegion;
    }
    props.handleMapRegionClick(region);
  }

  const getProjects = async () => {

    projectService.getRegionalPrices(["North East", "North West", "Yorkshire and the Humber", "East Midlands", "West Midlands", "East of England", "South East and London", "South West"])
      .then(response => {
        setRegions(response)
      })
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (<>
    {regions && <ComposableMap
      projection="geoAlbers"
      projectionConfig={{
        center: [2, 52.5],
        rotate: [4.4, 0],
        parallels: [50, 60],
        scale: 5000
      }}
      width={700}
      height={600}
    >
      <Geographies geography={data} >
        {({ geographies }) =>
          geographies.map((geo, i) => {

            let regionName;
            const geoRegion = geo.properties.RGN20NM;
            if (geoRegion === 'London' || geoRegion === 'South East') {
              regionName = 'South East and London'
            } else if (geoRegion === 'Yorkshire and The Humber') {
              regionName = 'Yorkshire and the Humber'
            } else {
              regionName = geoRegion;
            }
            return (
              <StyledTooltip
                title={
                  <Card sx={{ minWidth: 350 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {geo.properties.RGN20NM}
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '10px' }}>
                        <TollIcon color="primary" />
                        <Typography variant="p" color="text.secondary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units available: </b><NumericFormat value={regions.filter((region) => region.region === regionName)[0].units_available} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '10px', mb: '20px' }}>
                        <SellOutlinedIcon color="primary" />
                        <Typography variant="p" color="text.secondary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Average unit price: </b><NumericFormat value={regions.filter((region) => region.region === regionName)[0].average_price_per_unit} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '30px' }}>
                        <Chip
                          label={'Area'}
                          color="primary"
                          variant="outlined"
                          icon={<SpaOutlinedIcon />}
                          key={'Area'}
                        />
                        <Chip
                          label={'Hedgerow'}
                          color="primary"
                          variant="outlined"
                          icon={<GrassOutlinedIcon />}
                          key={'Hedgerow'}
                        />
                        <Chip
                          label={'Watercourse'}
                          color="info"
                          variant="outlined"
                          icon={<WaterIcon />}
                          key={'Watercourse'}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                }
                className='mapTooltip'
                placement="right"
                arrow
                sx={{
                  backgroundColor: 'transparent'
                }}
                key={i}
              >
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleRegionClick(geo)}
                  style={{
                    default: { fill: colorArray[i], outline: "none" },
                    hover: { fill: "#213201", outline: "none", cursor: "pointer" },
                    pressed: { fill: "#213201", outline: "none" },
                    focus: { outline: "none" }
                  }}
                />
              </StyledTooltip>
            )
          })
        }
      </Geographies>
    </ComposableMap>}
  </>

  )
}
