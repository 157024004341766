import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import AdminUserService from '../../services/admin-user-service';
import httpClient from '../../../shared/http/http-client';
import { Alert } from '@mui/material';

export const DialogAddAdminUser = ({ active, onUpdate, onCancel }) => {

  const adminUserService = new AdminUserService();

  const [searchAddress, setSearchAddress] = useState();
  const [foundUser, setFoundUser] = useState();
  const [error, setError] = useState()

  const searchForUser = () => {
    adminUserService.getSellerByEmail(searchAddress)
    .then(response => {
      setFoundUser(response)
      
      if (!response) {
        setError('This user does not exist. Please check the email address and try again.')
      } else {
        setError(undefined)
      }
    })
  }

  const updateSearch = (event) => {
   setSearchAddress(event)
  }

  const closeDialog = () => {
    setFoundUser(undefined);
    setSearchAddress(undefined)
    setError(undefined)
    onCancel()
  }

  function addAdminUser(userId) {
    httpClient.post('admin/hub/add-admin-user', { user_id: foundUser.id })
      .then(response => {
        setFoundUser(undefined)
        setSearchAddress(undefined)
        setError(undefined)
       onUpdate()
      })
      .catch(err => {
        console.log(err)
        setError(err?.response?.data?.message)
        setFoundUser(undefined);
        setSearchAddress(undefined)
      })
  }

  return (<>
    <Dialog open={active} onClose={onUpdate} >
      <DialogTitle sx={{ pb: '5px', mb:1 }}>Add a new admin</DialogTitle>
      <DialogContent>
        {error && <>
        <Alert sx={{mb:2}} severity='error'>{error}</Alert>
        </>}
        {!foundUser && <DialogContentText sx={{ pb: '10px', minWidth: '540px'}}>
          Search for the user by their email address. Please note this user must have signed up for an account prior to being added as an administrator.
        </DialogContentText>}

        {foundUser && <DialogContentText sx={{ pb: '10px', minWidth: '540px' }}>
          User found:
        </DialogContentText>}
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

         
            <GaiaTextField
              label="Email"
              disabled={foundUser}
              value={searchAddress || ''}
              placeholder="Email address"
              emitValueOnly={true}
              onChange={event => updateSearch(event)}>
            </GaiaTextField>

          {!foundUser && <><Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={!searchAddress}
              onClick={searchForUser}
              className="removeUppercase"
              sx={{ mb: 3 }}
            >
              Search
            </Button>
          </>}

         {foundUser && <>
            <DialogContentText sx={{ pb: '10px' }}>
              {foundUser.name}
              <br></br>
              {foundUser.email}
            </DialogContentText>
         </>} 
      

        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={closeDialog} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={!foundUser}
          onClick={addAdminUser}
          className="removeUppercase"
        >
          Add user as administrator
        </Button>
      </DialogActions>
    </Dialog>
  </>
  );
};
