import { createTheme } from '@mui/material/styles';
const gaiaTheme = createTheme({
  typography: {
    fontFamily: [
      'EffraMd',
      'Effra2',
      'Effra',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h6: {
      fontWeight: '600'
    },
    subtitle1: {
      fontSize: '1rem'
    },
    subtitle2: {
      fontSize: '1rem'
    },
    button: {
      fontSize: '1rem'
    },
    p: {
      fontSize: '1rem'
    },
  },
  palette: {
    primary: {
      main: "#70942c",
      contrastText: "#fff"
    },
    secondary: {
      main: "#213201",
      contrastText: "#fff"
    },
    text: {
      primary: "#111E21"
    }
  },
  shape: {
    borderRadius: 5
  }
});


export default gaiaTheme;