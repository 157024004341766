import { useNavigate } from 'react-router-dom';
import AdminStaticPageService from '../../../services/admin-static-page-service';
import { GaiaTextField } from '../../../../shared/components/form-elements/text-field';
import { useContext, useState } from 'react';
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { UserInformationProviderContext } from '../../../../shared/context/user-information-provider';

export const CreateStaticPage = () => {

  const adminStaticPageService = new AdminStaticPageService();
  const navigate = useNavigate();
  const userInformationContext = useContext(UserInformationProviderContext);

  const [errors, setErrors] = useState([])
  const [pageInformation, setPageInformation] = useState({
    name: '',
    slug: '',
    banner_image_url: '',
    page_title: '',
    page_subtitle: '',
    hasBannerImage: false
  })

  const createPage = () => {
    if (pageInformation.name && pageInformation.slug) {
      setErrors([])
      adminStaticPageService.createNewPage(pageInformation)
        .then(response => {
          navigate(`/admin/configuration/static-pages/${response.slug}`)
        })
        .catch(err => {
          userInformationContext.openDialog({
            active: true,
            title: 'Error',
            message: err.response.data.message,
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
        })
    } else {
      setErrors(['You must provide a page name and a URL.'])
    }

  }

  const handlePageInformationUpdate = (formControl, value) => {
    if (formControl === 'slug') {
      value = value.toLowerCase();
    }

    if (formControl === 'hasBannerImage' && !value) {
      setPageInformation({
        ...pageInformation,
        banner_image_url: '',
        page_title: '',
        page_subtitle: '',
        [formControl]: value
      })
    } else {
      setPageInformation({ ...pageInformation, [formControl]: value })
    }
  }

  return (<Box sx={{ padding: 2 }}>

    <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px', mb: 2 }}>
      Create a new static page
    </Typography>

    <Typography variant="p" color="text.primary" >
      Create a new static page using the form below. You will be able to add content on the next page.
    </Typography>

    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />

    {errors.length > 0 && <Alert severity="error" sx={{ p: 2, mb: 4 }}>{errors[0]}</Alert>}

    <GaiaTextField
      label="Page name"
      value={pageInformation.name}
      charchterCount={50}
      minRows={1}
      helperText={'This name is not shown to the user.'}
      placeholder="Page name"
      formControlName='name'
      onChange={handlePageInformationUpdate}>
    </GaiaTextField>

    <GaiaTextField
      label="Page route"
      value={pageInformation.slug}
      charchterCount={250}
      minRows={1}
      placeholder="Page route"
      formControlName='slug'
      helperText={'This value must be unique.'}
      onChange={handlePageInformationUpdate}>
    </GaiaTextField>

    <FormControlLabel
      control={
        <Checkbox
          variant="outlined"
          size="small"
          onChange={e => handlePageInformationUpdate('hasBannerImage', e.target.checked)}
          checked={pageInformation.hasBannerImage || false}
          sx={{ justifySelf: 'flex-start' }} />
      }
      label="Check this box to add an image overlay at the top of the page"
      sx={{ width: '100%', mb: '16px', pl: 0 }} />

    {pageInformation.hasBannerImage && <Box sx={{ mb: 2 }}>

      <GaiaTextField
        label="Banner image URL"
        value={pageInformation.banner_image_url}
        charchterCount={2048}
        minRows={1}
        placeholder="Banner image URL"
        formControlName='banner_image_url'
        onChange={handlePageInformationUpdate}>
      </GaiaTextField>

      <GaiaTextField
        label="Overlay title"
        value={pageInformation.page_title}
        charchterCount={250}
        minRows={1}
        placeholder="Overlay title"
        formControlName='page_title'
        onChange={handlePageInformationUpdate}>
      </GaiaTextField>

      <GaiaTextField
        label="Overlay sub-title"
        value={pageInformation.page_subtitle}
        charchterCount={250}
        minRows={1}

        placeholder="Overlay sub-title"
        formControlName='page_subtitle'
        onChange={handlePageInformationUpdate}>
      </GaiaTextField>
    </Box>}

    <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
      <Button
        variant="outlined"
        size="medium"
        disableElevation
        className="removeUppercase"
        color='info'
        onClick={() => createPage()}
      >
        Save
      </Button>
    </Stack>
  </Box>)

}