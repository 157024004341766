import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { Button, IconButton, Paper, Popover, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { LocationOn, MailOutline, TerrainRounded, Verified, OpenInNewRounded, InfoOutlined, MailOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { UserDetailsContext } from '../../shared/context/user-details';
import { BuyerEnquiryButton } from '../../shared/components/enquiry-button';
import { SignUpDialog } from '../../shared/components/dialogs/sign-up';
import { CustomTooltip } from '../../shared/components/presentation/tooltip';

const MetricProjectCard = ({ result }) => {
  const user = useContext(UserDetailsContext);
  const hub = useContext(HubDetailsContext);
  const [routeToProject, setRouteToProject] = useState('projects');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [enquirySent, setEnquirySent] = useState(0);
  const [signUpFormActive, setSignUpFormActive] = useState(false);
  const [enquiryMessage, setEnquiryMessage] = useState('');

  const project = result.project[0];

  const enquirySuccess = () => {
    setEnquirySent(true);
  }
  const toggleSignUpFormActive = () => {
    setSignUpFormActive(!signUpFormActive);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (user.userDetails) {
      if (user.userDetails.name) {
        setAuthenticated(true);
      }
    }
    const route = 'hub/projects'
    setRouteToProject(route)
  }, [hub, user])

  const [imageLoaded, setImageLoaded] = useState(false);
  let imagePath;
  if (result.project[0]?.project_images[0]?.key) {
    imagePath = result.project[0]?.project_images[0]?.location
  } else {
    imagePath = 'https://ychef.files.bbci.co.uk/976x549/p0b6bt6f.jpg'
  }

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: '10px', height: '100%' }}
      key={uuidv4()}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3} key={uuidv4()}>
          <CardMedia
            component="img"
            image={imagePath}
            alt="project"
            height="100%"
            key={project.p_id}
            onLoad={() => setImageLoaded(true)}
            sx={{ visibility: imageLoaded ? 'visible' : 'hidden', height: '260px', maxHeight: '260px' }}
          />
          <Chip
            label={project.shallow_status?.status_name || 'Available'}
            variant="outlined"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: '#fff',
              color: project.shallow_status?.color || '#70942c',
              borderColor: project.shallow_status?.color || '#70942c'
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={5} key={uuidv4()}>
          <CardContent sx={{ pl: 2, pt: 3, pb: '16px !important', pr: 0 }}>
            <Stack direction={'column'}>
              <Stack direction={'row'} alignItems='center'>
                <Typography variant="h6" color="text.primary" sx={{
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: 1
                }}>
                  {result.broadHabitat}
                </Typography>
                {project.bng_gov_registered && <Tooltip title="On the biodiversity gain sites register" placement="right" arrow>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Typography variant="body" color={'#1DA1F2'} sx={{ mr: 1 }}>
                      BNG registered
                    </Typography>
                    <Verified
                      sx={{
                        color: '#1DA1F2',
                      }} />
                  </Stack>
                </Tooltip>}
              </Stack>
              <Typography variant="subtitle" color="text.secondary" sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{result.habitat}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems='center' spacing={1} sx={{ mt: '16px', mb: '16px' }}>
              <LocationOn color="primary" />
              <Typography variant="p" color="text.secondary">
                <b>Local Planning Authority:</b> {project.p_local_planning_authority}
              </Typography>
              {(result.type === 'localLpa' || result.type === 'neighbouringLpa') && (
                <Chip label={result.type === 'localLpa' ? 'Local' : 'Neighbouring'} />
              )}
            </Stack>
            <Stack direction="row" alignItems='center' spacing={1} sx={{ mt: '16px', mb: '16px' }}>
              <TerrainRounded color="primary" />
              <Typography variant="p" color="text.secondary">
                <b>National Character Area:</b> {project.p_national_character_area}
              </Typography>
              {(result.type === 'localNca' || result.type === 'neighbouringNca') && (
                <Chip label={result.type === 'localNca' ? 'Local' : 'Neighbouring'} />
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                startIcon={<OpenInNewRounded />}
                className='removeUppercase'
                component={Link}
                to={`/${routeToProject}/${project.p_id}`}
                target="_blank"
              >
                View Listing
              </Button>
              {authenticated &&
                <BuyerEnquiryButton
                  projectId={project.p_id}
                  projectType={'BNG_PROJECT'}
                  freeTier={false}
                  onSuccess={enquirySuccess}
                  userRole={'buyer'}
                  defaultMessage={result?.enquiryContent}
                />
              }
              {!authenticated && !project?.enquiryId && <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className='removeUppercase'
                startIcon={<MailOutlined />}
                onClick={() => toggleSignUpFormActive()}
                disabled={authenticated}
              >
                Contact Seller
              </Button>}

              {signUpFormActive && <SignUpDialog open={signUpFormActive} closeDialog={toggleSignUpFormActive} role='BUYER' />}

            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} lg={4} key={uuidv4()}>
          <div style={{ padding: '24px 24px', height: '100%', width: '100%' }}>
            <Paper elevation={0} sx={{ backgroundColor: '#F3F3F3', width: '100%', height: '100%', borderRadius: '10px', p: 2 }}>
              <Stack direction='column' spacing={1}>
                <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600 }}>
                  Trading Summary
                </Typography>
                <Stack direction='row'>
                  <Typography variant="p" color="text.secondary">
                    <b>Unit Price:</b>
                  </Typography>
                  <Typography variant="p" color="text.secondary" sx={{ flex: 1, textAlign: 'right' }}>
                    <NumericFormat value={result?.unitPrice?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='center'>
                  <Typography variant="p" color="text.secondary">
                    <b>Spatial Multiplier:</b>
                  </Typography>
                  <Stack direction='row' spacing={1} alignItems='center' justifyContent='right' sx={{ flex: 1 }}>
                    <Typography variant="p" color='text.secondary'>
                      <NumericFormat value={result.spatialMultiplier} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction='row' alignItems='center'>
                  <Typography variant="p" color="text.secondary">
                    <b>Units Required:</b>
                  </Typography>
                  <Stack direction='row' spacing={1} alignItems='center' justifyContent='right' sx={{ flex: 1 }}>
                    <CustomTooltip content={result.tooltipContent} placement='left'>
                      <InfoOutlined sx={{ color: "#00000099" }} fontSize='small' />
                    </CustomTooltip>
                    <Typography variant="p" color='text.secondary'>
                      <NumericFormat value={result.unitsRequired.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction='row'>
                  <Typography variant="p" color="text.secondary">
                    <b>Total Price:</b>
                  </Typography>
                  <Typography variant="p" color="text.secondary" sx={{ flex: 1, textAlign: 'right' }}>
                    <NumericFormat value={result?.totalPrice?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Card >
  )
};

export default MetricProjectCard