import httpClient from "../http/http-client";

export default class InvestmentProjectService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getOneInvestmentProject(id) {
    return this.http.get(`hub/investment-project/${id}`)
      .then(response => {
        return response.data;
      })
  }

  async getAllInvestmentProjects() {
    return this.http.get('hub/investment-project')
      .then(response => {
        return response.data;
      })
  }
}