import { useEffect, useState } from 'react';
import {
  PaletteOutlined,
  PeopleOutline,
  ViewModuleOutlined,
  HomeOutlined,
  ForestOutlined,
  WaterDropOutlined,
  ArticleOutlined,
  PictureAsPdfOutlined,
  Segment,
  Category,
  List,
  HorizontalRule,
  ArrowRight,
  CalendarViewDay,
  FolderOpen,
  Add,
  AssignmentOutlined,
  PauseCircleOutlined,
  Wysiwyg,
  Article,
  ViewList,
  SmartButton,
  InsertLink,
  GavelRounded,
  SpaRounded,
  Sell,
  CheckCircle,
  CheckCircleOutline,
  CircleOutlined,
  PlayCircleFilledWhiteOutlined,
  TonalityOutlined,
  ForkRight,
  HelpOutline,
  OpenInNew,
  AddBoxOutlined,
  ForestRounded,
  Water,
  Grass,
  SpaOutlined,
  Newspaper,
  Flag
} from '@mui/icons-material';
import Spa from '@mui/icons-material/Spa';

export default function IconPicker({ icon, color, size }) {
  const [iconName, setIconName] = useState();
  const [colorName, setColorName] = useState();
  const [sizeName, setSizeName] = useState();

  useEffect(() => {
    setIconName(icon);
    setColorName(color);
    setSizeName(size);
  }, [icon, color, size]);

  return (<>
    {iconName === 'palette' && <PaletteOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'users' && <PeopleOutline color={colorName} fontSize={sizeName} />}
    {iconName === 'modules' && <ViewModuleOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'home' && <HomeOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'forest' && <ForestOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'trees' && <ForestRounded color={colorName} fontSize={sizeName} />}
    {iconName === 'droplet' && <WaterDropOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'water' && <Water color={colorName} fontSize={sizeName} />}
    {iconName === 'grass' && <Grass color={colorName} fontSize={sizeName} />}
    {iconName === 'spa' && <Spa color={colorName} fontSize={sizeName} />}
    {iconName === 'spaOutlined' && <SpaOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'article' && <ArticleOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'pdf' && <PictureAsPdfOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'segment' && <Segment color={colorName} fontSize={sizeName} />}
    {iconName === 'category' && <Category color={colorName} fontSize={sizeName} />}
    {iconName === 'list' && <List color={colorName} fontSize={sizeName} />}
    {iconName === 'horizontalLine' && <HorizontalRule color={colorName} fontSize={sizeName} />}
    {iconName === 'arrowRight' && <ArrowRight color={colorName} fontSize={sizeName} />}
    {iconName === 'horizontalBars' && <CalendarViewDay color={colorName} fontSize={sizeName} />}
    {iconName === 'folderOpen' && <FolderOpen color={colorName} fontSize={sizeName} />}
    {iconName === 'add' && <Add color={colorName} fontSize={sizeName} />}
    {iconName === 'form' && <AssignmentOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'pause' && <PauseCircleOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'marketplace' && <ViewList color={colorName} fontSize={sizeName} />}
    {iconName === 'investment' && <Wysiwyg color={colorName} fontSize={sizeName} />}
    {iconName === 'learning' && <Article color={colorName} fontSize={sizeName} />}
    {iconName === 'button' && <SmartButton color={colorName} fontSize={sizeName} />}
    {iconName === 'link' && <InsertLink color={colorName} fontSize={sizeName} />}
    {iconName === 'legal' && <GavelRounded color={colorName} fontSize={sizeName} />}
    {iconName === 'leaf' && <SpaRounded color={colorName} fontSize={sizeName} />}
    {iconName === 'sell' && <Sell color={colorName} fontSize={sizeName} />}
    {iconName === 'checkCircleFilled' && <CheckCircle color={colorName} fontSize={sizeName} />}
    {iconName === 'checkCircleOutlined' && <CheckCircleOutline color={colorName} fontSize={sizeName} />}
    {iconName === 'circleOutlined' && <CircleOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'play' && <PlayCircleFilledWhiteOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'inProgress' && <TonalityOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'flow' && <ForkRight color={colorName} fontSize={sizeName} />}
    {iconName === 'helpCircle' && <HelpOutline color={colorName} fontSize={sizeName} />}
    {iconName === 'openInNew' && <OpenInNew color={colorName} fontSize={sizeName} />}
    {iconName === 'addBoxOutlined' && <AddBoxOutlined color={colorName} fontSize={sizeName} />}
    {iconName === 'news' && <Newspaper color={colorName} fontSize={sizeName} />}
    {iconName === 'flag' && <Flag color={colorName} fontSize={sizeName} />}
  </>
  );
}