import { useEffect, useState } from 'react';
import CustomFilterService from '../services/custom-filter-service'
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

export const CustomFilter = ({ pageCode, setControlledAnswerIds, haveTitle = false }) => {

  const [filters, setFilters] = useState()
  const [userSelection, setUserSelection] = useState();
  const customFilterService = new CustomFilterService();

  useEffect(() => {
    customFilterService.getFilters(pageCode)
      .then(response => {
        setFilters(response)
        const formatted = response.map(filter => {
          return {
            key: filter.id,
            value: []
          }
        })
        setUserSelection(formatted)
      })
  }, [pageCode])

  useEffect(() => {
    if (setControlledAnswerIds && userSelection) {
      const ids = []
      setControlledAnswerIds(userSelection.forEach(selection => {
        selection.value.forEach(value => {
          if (!ids.includes(value)) {
            ids.push(value.controlled_answer_ids)
          }
        })
      }))
      setControlledAnswerIds(ids)
    }
  }, [userSelection])

  const handleUserSelection = (filterId, selectedOptions) => {
    const updated = userSelection.map(selection => {
      if (selection.key === filterId) {
        return {
          key: selection.key,
          value: selectedOptions
        }
      } else {
        return selection
      }
    })

    console.log(updated)
    setUserSelection(updated)
  }


  return (<>
    {filters && filters?.length > 0 && filters.map((filter, index) => {
      return <Box key={index} sx={{ mb: 4 }}>
        {haveTitle && <Typography
          sx={{ mb: 1 }}
          variant="body2"
          color="text.primary"
        >
          {filter.filter_title}
        </Typography>}

        <Autocomplete
          disablePortal
          id={filter.filter_title}
          options={filter.filter_options}
          renderInput={(params) => <TextField {...params} label={filter.filter_title} />}
          multiple
          // value={}
          getOptionLabel={(option) => (option ? option.display_label : "")}
          onChange={(e, option, reason) => {
            handleUserSelection(filter.id, option)
          }}
        />
      </Box>
    })
    }
  </>
  )
}