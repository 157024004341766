import { Paper } from '@mui/material';
import EcologistGaiaPlusService from '../services/ecologist-gaia-plus-service'
import { UserInfoTopDisplay } from './user-info-top-display';
import { useEffect, useState } from 'react';
import { Grid } from '@aws-amplify/ui-react';
import { GaiaPlusProjectList } from '../../shared/components/gaia-plus-project-list';
import Title from '../../shared/components/title';

export const EcologistDashboard = () => {

  const ecologistGaiaPlusService = new EcologistGaiaPlusService();

  const [gaiaPlusProjects, setGaiaPlusProjects] = useState();

  useEffect(() => {
    ecologistGaiaPlusService.getAllProjects()
      .then(response => {
        setGaiaPlusProjects(response)
      })
  }, [])

  return (<>
    {/* <UserInfoTopDisplay> */}
    {(gaiaPlusProjects && !!gaiaPlusProjects?.length) && <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8} >
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', mb: 3, flexGrow: 1, height: '100%' }} elevation={0}>
          <Title>Gaia plus projects</Title>
          <GaiaPlusProjectList projects={gaiaPlusProjects} isEcologist={true}></GaiaPlusProjectList>
        </Paper>
      </Grid>
    </Grid>}

    {/* </UserInfoTopDisplay> */}
  </>
  )
}