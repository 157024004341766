import httpClient from '../../shared/http/http-client';

export default class AdminHubOrganisationSettingsService {
  http;

  constructor() {
    this.http = httpClient
  }

  async updateSettings(settings) {
    return this.http.post(`admin/organistaion-settings/update-settings`, settings)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }
}
