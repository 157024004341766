import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Grid, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import AdminProjectService from '../services/admin-project-service';
import BngProjectDetailsBody from '../components/approvals/bng-project-detail-body';
import { ProjectApprovalMessageContainer } from '../../shared/components/project-approval-message-container';
import { AdminActionsBox } from '../components/approvals/admin-actions';
import { ProjectHistory } from '../../shared/components/project-history';
import { Autorenew, CloseOutlined, Delete, EditNoteRounded, TrackChangesOutlined } from '@mui/icons-material';
import { DialogChangeShallowStatus } from '../../shared/components/dialogs/dialog-change-shallow-status';
import { UserInformationProviderContext } from '../../shared/context/user-information-provider';

const BngProjectPage = () => {

  const adminProjectService = new AdminProjectService();
  const userInformationProviderContext = useContext(UserInformationProviderContext);

  const [projectDetails, setProjectDetails] = useState();
  const [approval, setApproval] = useState({ outcome: '', comments: '' });
  const [actionSuccess, setActionSuccess] = useState(0)
  const [showShallowStatusDialog, setShowShallowStatusDialog] = useState(false);


  let navigate = useNavigate();

  const getProject = async () => {
    const path = window.location.pathname;
    const projectId = path.substring(path.lastIndexOf('/') + 1);

    adminProjectService.getBngProject(projectId)
      .then(response => {
        const dateAddedISO = new Date(response.created_at.replace(' ', 'T'));
        const dateAdded = dateAddedISO.toLocaleDateString('en-GB');
        const initials = getInitials(response?.owner?.name);
        const project = { ...response, dateAdded: dateAdded, owner: { ...response.owner, initials: initials } };
        setProjectDetails(project);
      })
  }

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const handleChangeStatus = (selectedStatus) => {
    adminProjectService.changeStatus(projectDetails.p_id, selectedStatus, false)
      .then(() => {
        getProject()
      })
  }

  const handleRemoveDecline = (selectedStatus) => {
    adminProjectService.removeDecline(projectDetails.p_id, selectedStatus)
      .then(() => {
        getProject()
      })
  }

  const approveProject = (sendEmail) => {
    adminProjectService.approveBngProject(projectDetails.p_id, sendEmail, approval.comments)
      .then(() => {
        getProject();
        setApproval({ outcome: '', comments: '' })
        setActionSuccess(actionSuccess + 1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const rejectProject = (sendEmail) => {
    adminProjectService.rejectBngProject(projectDetails.p_id, sendEmail, approval.comments)
      .then(() => {
        getProject();
        setApproval({ outcome: '', comments: '' })
        setActionSuccess(actionSuccess + 1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const deleteProject = () => {
    userInformationProviderContext.openDialog({
      active: true,
      title: 'Delete this project?',
      message: 'Are you sure you would like to permanently delete this project? This action cannot be undone.'
    })
      .then(response => {
        if (response) {
          adminProjectService.deleteProject(projectDetails.p_id)
            .then(() => {
              navigate('/admin/dashboard')
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }



  const handleStatusUpdate = () => {
    setShowShallowStatusDialog(false);
    getProject();
  }

  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.analytics.page();
  }, [])

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 3, mb: 4 }}>
        <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
          <Box sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(-1)}
              underline='hover'
              style={{ ml: '-7px', color: '#70942c' }}

            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back
                </Typography>
              </Grid>
            </Link>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <BngProjectDetailsBody projectDetails={projectDetails} getProject={getProject} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >

              {projectDetails?.project_history?.find(history => history.ph_event === 'Project approved') && projectDetails?.admin_status.status_code !== 'PUBLISHED' && <>
                <Typography variant="p" color="text.primary" sx={{ fontWeight: 300, mb: 2 }}>
                  This project has been previously published
                </Typography>
              </>}
              {projectDetails && <AdminActionsBox
                adminStatus={projectDetails.admin_status}
                approval={approval}
                setApproval={setApproval}
                approve={approveProject}
                reject={rejectProject}
                actionSuccess={actionSuccess}
              ></AdminActionsBox>}

              {projectDetails && projectDetails.admin_status.is_published_status && <Stack direction={'column'}>
                <Stack direction={'row'}>
                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => handleChangeStatus('DRAFT')}
                    className='removeUppercase'
                    startIcon={<EditNoteRounded />}
                    sx={{ flex: 1, mr: 1 }}>
                    Set to draft
                  </Button>
                  <Button
                    color={'error'}
                    variant={'outlined'}
                    onClick={() => handleChangeStatus('CLOSED')}
                    className='removeUppercase'
                    startIcon={<CloseOutlined />}
                    sx={{ flex: 1, ml: 1 }}>
                    Close project
                  </Button>
                </Stack>
              </Stack>}

              {projectDetails && projectDetails.admin_status.status_code === 'AUTO_DECLINE' && <Stack direction={'column'} sx={{ mt: 2 }}>
                <Stack direction={'row'}>
                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => handleRemoveDecline('DRAFT')}
                    className='removeUppercase'
                    startIcon={<EditNoteRounded />}
                    sx={{ flex: 1 }}>
                    Remove auto decline
                  </Button>
                </Stack>
              </Stack>
              }
              <Box sx={{ mt: 2, width: '100%' }}>
                {projectDetails && <Stack direction={'column'}>
                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => setShowShallowStatusDialog(true)}
                    className='removeUppercase'
                    startIcon={<Autorenew />}
                    sx={{ flex: 1 }}>
                    Change status
                  </Button>
                </Stack>}
              </Box>
              <Box sx={{ mt: 2, width: '100%' }}>
                {projectDetails && <Stack direction={'column'}>
                  <Button
                    color={'error'}
                    variant={'outlined'}
                    onClick={() => deleteProject()}
                    className='removeUppercase'
                    startIcon={<Delete />}
                    sx={{ flex: 1 }}>
                    Delete project
                  </Button>
                </Stack>}
              </Box>
            </Paper>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                User Details
              </Typography>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {projectDetails?.owner?.initials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={projectDetails?.owner?.name} secondary={projectDetails?.owner?.email} />
              </ListItem>
            </Paper>

            {projectDetails && <ProjectApprovalMessageContainer
              sectionTitle='Approval comment history'
              projectComments={projectDetails?.project_comments}
              adminStatusCode={projectDetails?.admin_status?.status_code}>
            </ProjectApprovalMessageContainer>}
            <Paper
              sx={{
                p: 4,
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                mb: 2
              }}
            >
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                History
              </Typography>
              {projectDetails && (
                <ProjectHistory projectHistoryRecord={projectDetails?.project_history} />
              )}

            </Paper>
          </Grid>
        </Grid >
      </Container >

      {projectDetails && <DialogChangeShallowStatus
        projectId={projectDetails.p_id}
        statusCode={projectDetails.shallow_status?.status_code}
        active={showShallowStatusDialog}
        onCancel={() => setShowShallowStatusDialog(false)}
        onUpdate={() => handleStatusUpdate()}
        projectType={'BNG_PROJECT'}
        isAdmin={true}
      />}
    </>
  );
};

export default BngProjectPage