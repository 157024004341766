/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, Chip, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import AdminFormService from '../../services/admin-form-service';
import { Delete, DoNotDisturbAltOutlined, Edit, EditRounded } from '@mui/icons-material';
import { DialogCreateDeclineRule } from '../../components/dialogs/dialog-create-decline-rule';
import AdminAutomaticDeclineRuleService from '../../services/admin-automatic-decline-rule-service';
import { DialogEditInformationQuestion } from '../../components/dialogs/dialog-edit-information-question';

const ViewCustomForm = (props) => {

  const params = useParams();
  const adminFormService = AdminFormService.instance;
  const adminAutomaticDeclineRuleService = new AdminAutomaticDeclineRuleService();

  const [form, setForm] = useState();
  const [questionTypes, setQuestionTypes] = useState();
  const [showDeclineDialog, setShowDeclineDialog] = useState();
  const [showEditQuestionDialog, setShowEditQuestionDialog] = useState(false);
  const [questionIndex, setQuestionIndex] = useState();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getForm();
    getQuestionTypes();
  }, []);

  /**
   * Submit the completed form the the BE.
   */
  const getForm = () => {
    adminFormService.getFormWithQuestions(params.formId)
      .then(response => {
        setForm(response);
      })
  }

  const getQuestionTypes = () => {
    adminFormService.getAllQuestionTypes()
      .then(response => {
        setQuestionTypes(response)
      })
  }

  const handleAddDeclineRule = (index) => {
    setQuestionIndex(index)
    setShowDeclineDialog(true)
  }

  const handleEditQuestion = (index) => {
    setQuestionIndex(index)
    setShowEditQuestionDialog(true)
  }

  const handleSuccessfulUpdate = () => {
    setShowEditQuestionDialog(false)
    setShowDeclineDialog(false);
    getForm();
  }

  const handleDeleteDeclineRule = (ruleId) => {
    adminAutomaticDeclineRuleService.deleteDeclineRule(form.id, ruleId)
      .then(() => {
        getForm()
      })
  }

  return (<Box sx={{ p: 2 }}>

    {form && <Stack direction='row' sx={{ alignItems: 'center', marginBottom: 3 }}>
      <Stack direction='row' spacing={2}>
        <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>{form.form_name}</Typography>
      </Stack>

      <Stack direction='row' spacing={2} sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
        <Button
          component={Link}
          to={`/admin/custom-forms/${params.formId}/edit`}
          color='primary'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<EditRounded />}
          sx={{ textTransform: 'capitalize' }}
        >
          Edit
        </Button>
      </Stack>
    </Stack>
    }
    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
    {form && <>
      {questionTypes && form && form.informationQuestions?.sort((a, b) => a.info.display_index - b.info.display_index).map((question, index) => {
        return <Paper variant='outlined' key={index} sx={{ mb: 2, p: 2, position: 'relative' }}>

          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography color="text.primary" variant="subtitle1" sx={{ fontWeight: 600 }}>{index + 1}:</Typography>
            <Typography color="text.primary" variant="subtitle1" sx={{ fontWeight: 600, ml: 2 }}>{question.question}</Typography>
            <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
              <Chip label={questionTypes.find(type => type.id === question.question_type_id).question_name} />
              <Chip label={question.answer_required ? 'Required' : 'Optional'} />
              {question.controlled_answers?.length > 0 && <IconButton aria-label="add" onClick={() => handleAddDeclineRule(index)}>
                <DoNotDisturbAltOutlined />
              </IconButton>}
              <IconButton aria-label="add" onClick={() => handleEditQuestion(index)}>
                <Edit />
              </IconButton>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>Additional information:</Typography>
            <Typography color="text.primary" variant="p" sx={{ ml: 2 }}>{question.additional_information}</Typography>
          </Box>

          {questionTypes.find(type => type.id === question.question_type_id).has_controlled_answers && <>
            <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>Answer options:</Typography>

            {question.controlled_answers.map((answer, index) => {
              return <Box key={index} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>{index + 1}:</Typography>
                <Typography color="text.primary" variant="p" sx={{ ml: 2, mr: 2 }}>{answer.answer_text}</Typography>

                {
                  question.decline_rules.filter(rule => rule.controlled_answer_ids.includes(answer.id))?.map((rule, index) => {
                    return <Chip
                      key={index}
                      label={`Decline IF ${rule.controlled_answer_evaluation}`}
                      variant="outlined"
                      color='error'
                      sx={{
                        ml: 2,
                        justifyContent: "center",

                      }}
                    />
                  })
                }
              </Box>
            })}
          </>
          }

          {
            question.decline_rules?.length > 0 && question.decline_rules?.map((rule, index) => {
              return <Alert severity='info' sx={{ mb: 1 }} key={index}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction={'column'}>
                    <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>This question has a decline rule applied to it</Typography>
                    <br></br>
                    <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>Decline message: {rule.decline_message}</Typography>
                  </Stack>

                  {/* <IconButton aria-label="add" onClick={() => handleAddDeclineRule()}>
                    <Edit />
                  </IconButton> */}

                  <IconButton aria-label="add" onClick={() => handleDeleteDeclineRule(rule.id)}>
                    <Delete />
                  </IconButton>

                </Stack>
              </Alert>
            })
          }
        </Paper>
      })}
    </>}

    {form && <DialogCreateDeclineRule
      active={showDeclineDialog}
      question={form.informationQuestions[questionIndex]}
      formId={form.id}
      onCancel={() => setShowDeclineDialog(false)}
      onUpdate={() => handleSuccessfulUpdate()} />}

    {form && <DialogEditInformationQuestion
      active={showEditQuestionDialog}
      currentQuestion={form.informationQuestions[questionIndex]}
      onCancel={() => setShowDeclineDialog(false)}
      onUpdate={() => handleSuccessfulUpdate()} />}
  </Box >
  );
};

export default ViewCustomForm 