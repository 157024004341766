import { useEffect, useState } from 'react';
import AdminGaiaPlusService from '../../services/admin-gaia-plus-service'
import { Box, Button, Checkbox, FormControlLabel, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const GaiaPlusTaskList = () => {

  const adminGaiaPlusService = new AdminGaiaPlusService();
  const [stages, setStages] = useState();

  const getTasks = () => {
    adminGaiaPlusService.getAllTasks()
      .then(response => {
        setStages(response)
      })
  }

  useEffect(() => {
    getTasks()
  }, [])

  const handleToggleActive = (event) => {
    adminGaiaPlusService.toggleTaskActive(event.target.name)
      .then(() => {
        getTasks();
      })
  }

  return (<>
    <Toolbar />
    <Box sx={{ margin: 2, mt: 2 }}>
      <Paper sx={{ padding: 4 }} >
        <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{ mt: 4, mb: 2 }}>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>All tasks</Typography>
          <Button
            component={Link}
            to='/admin/gaia-plus/add-task'
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            sx={{ textTransform: 'none' }}
          >
            Add a new tasks
          </Button>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} maxWidth={'700px'} sx={{ mr: 3, mb: 2 }}>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>Stage / Task</Typography>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>Active</Typography>

        </Stack>

        {stages && stages.map((stage, index) => {
          return <Box key={index}>
            <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
              <Typography color="text.primary" variant="p">Stage: {stage.name}</Typography>
            </Stack>

            {stage.tasks.map((task, index) => {
              return <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} maxWidth={'700px'} sx={{ mr: 3 }}>
                <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>{task.label}</Typography>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox checked={task.active}
                      name={task.id}
                      onChange={handleToggleActive}
                    />}
                // label={task.label}
                // sx={{ width: '100%' }}
                />
              </Stack>
            })}
          </Box>
        })}
      </Paper>
    </Box>
  </>)
}