import React, { Fragment, useEffect, useState } from "react";
import { Grid, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Alert, Stack, Select, MenuItem } from '@mui/material';
import IconPicker from "../../../../../shared/components/icon-picker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import AdminArticleGroupService from '../../../../services/admin-article-service';

const LearningHubArticleGroupForm = () => {

  const adminArticleGroupService = new AdminArticleGroupService();

  const [articleGroups, setArticleGroups] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [addGroupDialogueActive, setAddGroupDialogueActive] = useState(false);
  const [newGroupTitle, setNewGroupTitle] = useState('');
  const [groupTitleError, setGroupTitleError] = useState(false);
  const [updated, setUpdated] = useState(0)

  useEffect(() => {
    getArticleGroups();
  }, [updated]);

  const getArticleGroups = () => {
    adminArticleGroupService.getAllArticleGroups()
      .then(response => {
        setArticleGroups(response);
      })
  }

  const showAddGroupDialogue = () => {
    if (addGroupDialogueActive) {
      setGroupTitleError(false)
    }
    setAddGroupDialogueActive(!addGroupDialogueActive);
  };

  const handleAddGroup = () => {
    setGroupTitleError(false);
    if (newGroupTitle) {
      adminArticleGroupService.createNewArticleGroup({ title_copy: newGroupTitle })
        .then(() => {
          getArticleGroups();
          setAddGroupDialogueActive(false);
          setNewGroupTitle(undefined);
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setGroupTitleError(true);
    }

  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleUpdateDisplayOrder = (id, index) => {
    adminArticleGroupService.updateDisplayOrder(id, index)
      .then(() => {
        setUpdated(updated + 1)
      })
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Accordion
          key={1}
          expanded={expanded === `panel-${1}`}
          onChange={handleChange(`panel-${1}`)}
          elevation={0}
          square
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`,
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&::before': {
              display: 'none',
            },
            borderRadius: '5px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="segment" />
                </ListItemIcon>
                <ListItemText primary="Article Groups" secondary="Configure the groups of articles in the learning hub." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px' }}>
              <List
                sx={{
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                {articleGroups && articleGroups.map((group, index) => {
                  return (
                    <Fragment key={index}>
                      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ padding: 1 }}>

                        <ListItem>
                          <ListItemIcon >
                            <IconPicker icon="folderOpen" size="small" sx={{ color: "black" }} />
                          </ListItemIcon>
                          <ListItemText primary={group.title_copy} />
                        </ListItem>

                        <FormControl size="small" sx={{ width: '150px' }}>
                          <InputLabel id="user-type-label">Display order</InputLabel>
                          <Select
                            labelId="user-type-label"
                            id="user-type"
                            value={index + 1}
                            label="Display order"
                            onChange={(e) => handleUpdateDisplayOrder(group.id, e.target.value - 1)}
                          >
                            {articleGroups.map((article, rowIndex) => {
                              return <MenuItem value={rowIndex + 1}>{rowIndex + 1}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </Stack>
                      {articleGroups.length !== index + 1 && <Divider sx={{ p: 0 }} />}
                    </Fragment>
                  )
                })
                }
                <ListItem>
                  <ListItemText
                    primary={
                      <Button
                        variant="text"
                        startIcon={<AddIcon />}
                        className="removeUppercase"
                        onClick={() => showAddGroupDialogue()}
                      >
                        Add a new group
                      </Button>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Dialog open={addGroupDialogueActive} onClose={showAddGroupDialogue} maxWidth='sm' fullWidth>
          <DialogTitle sx={{ pb: '5px' }}>Add a new article group</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth margin='normal'>
                <InputLabel htmlFor="new-group-title">New group name</InputLabel>

                <OutlinedInput
                  id="new-group-title"
                  fullWidth
                  className="formField"
                  label="New group name"
                  type="text"
                  value={newGroupTitle}
                  onChange={e => setNewGroupTitle(e.target.value)}
                />
              </FormControl>

              {groupTitleError && <Alert severity='error' sx={{ mt: 2, mb: 1 }}>Please enter a valid group name.</Alert>}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
            <Button
              onClick={showAddGroupDialogue}
              className="removeUppercase"
              sx={{ color: "text.primary" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleAddGroup()}
              //startIcon={<BlockIcon />}
              className="removeUppercase"
            >
              Add group
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default LearningHubArticleGroupForm 