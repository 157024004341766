import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import gaiaTheme from '../../../theme';
import { HubDetailsContext } from '../../context/hub-details';

const PageLayout = ({ children }) => {

  const [newTheme, setNewTheme] = useState(gaiaTheme)
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    setNewTheme(hub.hubDetails.theme)

  }, [hub])

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <div>{children}</div>
    </ThemeProvider>
  );
};

export default PageLayout