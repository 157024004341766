import React, { useContext, useEffect, useState } from "react";
import { Button } from '@mui/material';
import { HubDetailsContext } from '../../context/hub-details';
import { EmailOutlined } from '@mui/icons-material';

export const ContactButton = () => {
  const hubContext = useContext(HubDetailsContext);

  const [supportEmailAddress, setSupportEmailAddress] = useState();

  useEffect(() => {
    if (hubContext.hubDetails) {
      setSupportEmailAddress(hubContext.hubDetails.organisation_settings.support_email_address);
    }
  }, [hubContext.hubDetails])

  return (<>
    {supportEmailAddress && <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      className='removeUppercase'
      startIcon={<EmailOutlined />}
      href={`mailto:${supportEmailAddress}`}
      rel="noopener noreferrer"
    >
      Contact Us
    </Button>
    }
  </>
  );
};