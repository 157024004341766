import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, InputLabel, InputAdornment, OutlinedInput, CircularProgress, FormControlLabel, Checkbox, Autocomplete, TextField } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb';
import SellerInvestmentProjectService from '../../services/seller-investment-project-service';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import AdminInvestmentProjectService from '../../../admin/services/admin-investment-project';
import HubCustomListService from '../../../shared/services/hub-custom-list-service';

const CreateInvestmentProjectForm = (props) => {

  const sellerInvestmentProjectService = new SellerInvestmentProjectService();
  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const hubCustomListService = new HubCustomListService();
  const navigate = useNavigate();

  const [createdInvestmentProject, setCreatedInvestmentProject] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [locale, setLocale] = useState('en-GB');
  const [existingTargetDate, setExistingTargetDate] = useState(null);
  const [existingStartDate, setExistingStartDate] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [customRegionList, setCustomRegionList] = useState();

  useEffect(() => {
    if (props.projectDetails?.investment_target_date) {
      const existing_target_date = new Date(props.projectDetails?.investment_target_date);
      setExistingTargetDate(existing_target_date);
    }

    if (props.projectDetails?.investment_start_date) {
      const existing_start_date = new Date(props.projectDetails?.investment_start_date);
      setExistingStartDate(existing_start_date);
    }
  }, [])

  useEffect(() => {
    setIsUploading(false);
    if (props.projectDetails) {
      setIsEdit(true);

      const investmentProject = {
        title_text: props.projectDetails.title_text,
        subtitle_text: props.projectDetails.subtitle_text,
        description: props.projectDetails.description,
        investment_target: props.projectDetails.investment_target,
        investment_start_date: props.projectDetails.investment_start_date,
        investment_target_date: props.projectDetails.investment_target_date,
        external_project_url: props.projectDetails.external_project_url,
        is_external_project: props.projectDetails.external_project_url,
        un_goals: props.projectDetails.un_goals,
        hide_description: props.projectDetails.hide_description,
        custom_region_tag: props.projectDetails.custom_region_tag,
        video_url: props.projectDetails.video_url
      };

      setCreatedInvestmentProject(investmentProject);
    } else {
      createInvestmentProject();
    }

    if (props.isAdmin) {
      hubCustomListService.getCustomRegionList(true)
        .then(response => {
          setCustomRegionList(response.data)
        })
    }

  }, [])

  // Create the initial investment project object and add arrays for the specific areas.
  function createInvestmentProject(title_text = '', subtitle_text = '', description = '', investment_target = null, investment_target_date = '', investment_start_date = '', external_project_url = '', un_goals = '') {
    const investmentProject = {
      title_text: title_text,
      subtitle_text: subtitle_text,
      description: description,
      investment_target: investment_target,
      investment_target_date: investment_target_date,
      investment_start_date: investment_start_date,
      un_goals: un_goals,
      external_project_url: external_project_url,
      is_external_project: false,
      hide_description: false,
      custom_region_tag: '',
      video_url: null
    };

    setCreatedInvestmentProject(investmentProject);
  };

  // Set the required investment project data.
  function setInvestmentProjectData(formControl, value) {
    const investmentProject = createdInvestmentProject;
    investmentProject[formControl] = value;

    setCreatedInvestmentProject({ ...investmentProject });
  }

  // Submit the investment project
  function submitInvestmentProject(event) {
    event.preventDefault();

    setIsUploading(true);

    if (!isEdit) {
      const form = new FormData();

      for (let i = 0; i < fileData.length; i++) {
        form.append("files", fileData[i]);
      }

      let goals;
      if (createdInvestmentProject.un_goals) {
        goals = createdInvestmentProject.un_goals.map(goal => goal.id).toString();
      }

      form.append('investment_project', JSON.stringify({ ...createdInvestmentProject, shallow_status_code: 'PENDING_LEGAL', un_goals: goals }))

      sellerInvestmentProjectService.createInvestmentProject(form)
        .then((response) => {
          if (response.investmentProject.answer_forms?.length > 0) {
            props.setInvestmentProject(response.investmentProject);
            props.formCreated(response.investmentProject.answer_forms.find(form => !form.privious_answer_form_id).id, response.investmentProject.id);
          } else {
            navigate(`/investment-project/${response.investmentProject.id}`);
          }
        })
        .catch(err => {
          setIsUploading(false);
          console.log(err)
        })

    } else {
      if (props.isAdmin) {
        adminInvestmentProjectService.editInvestmentProject(props.projectDetails.id, createdInvestmentProject)
          .then(() => {
            setIsUploading(false);
            props.getInvestmentProject();
            props.closeDialog()
          })
      } else {
        sellerInvestmentProjectService.updateInvestmentProject(props.projectDetails.id, createdInvestmentProject)
          .then(() => {
            setIsUploading(false);
            props.getInvestmentProject();
            props.closeDialog()
          })
      }
    }
  }

  return (
    <Box component="form" onSubmit={submitInvestmentProject}>
      <GaiaTextField
        required={true}
        label="Title"
        value={createdInvestmentProject?.title_text || ''}
        charchterCount={250}
        maxRows={1}
        placeholder="Provide a title for the investment project"
        formControlName='title_text'
        onChange={setInvestmentProjectData}>
      </GaiaTextField>

      <GaiaTextField
        required={true}
        label="Subheading"
        value={createdInvestmentProject?.subtitle_text || ''}
        charchterCount={250}
        minRows={1}
        maxRows={3}
        placeholder="Describe your project in a sentence. This will be used on your project listing."
        formControlName='subtitle_text'
        onChange={setInvestmentProjectData}>
      </GaiaTextField>

      {customRegionList && <Autocomplete
        disablePortal
        id="region"
        blurOnSelect={true}
        sx={{ mt: 2 }}
        options={customRegionList}
        renderInput={(params) => <TextField {...params} label="Add region" />}
        value={createdInvestmentProject?.custom_region_tag || ''}
        getOptionLabel={(option) => (option?.value ? option.value : option)}
        onChange={(e, option, reason) => {
          console.log(option)
          setInvestmentProjectData('custom_region_tag', option?.value)
        }}
      />}

      {props.isAdmin && <FormControl margin="normal" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              name='hide_description'
              checked={createdInvestmentProject?.hide_description || false}
              onClick={e => setInvestmentProjectData('hide_description', e.target.checked)}
            />}
          label='Hide the user entered description from public view'
        />
      </FormControl>}

      <GaiaTextField
        label="Project Summary"
        value={createdInvestmentProject?.description}
        charchterCount={4000}
        rows={4}
        placeholder="Provide a summary of the project"
        formControlName='description'
        onChange={setInvestmentProjectData}>
      </GaiaTextField>

      {props.isAdmin && <FormControl margin="normal" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              name='is_external_project'
              checked={createdInvestmentProject?.is_external_project || false}
              onClick={e => setInvestmentProjectData('is_external_project', e.target.checked)}
            />}
          label='This is an externally hosted project. (Eg. Crowdfunder)'
        />
      </FormControl>}

      {createdInvestmentProject?.is_external_project && <GaiaTextField
        label="External URL"
        value={createdInvestmentProject?.external_project_url}
        charchterCount={2000}
        maxRows={10}
        placeholder="Provide the link to this project"
        formControlName='external_project_url'
        onChange={setInvestmentProjectData}>
      </GaiaTextField>}

      <FormControl fullWidth margin='normal'>
        <InputLabel htmlFor="investmentTarget">Funding Requirement</InputLabel>
        <OutlinedInput
          required={true}
          inputProps={{ min: 0 }}
          id="investmentTarget"
          startAdornment={<InputAdornment position="start">£</InputAdornment>}
          fullWidth
          className="formField"
          value={createdInvestmentProject?.investment_target || ''}
          label="Funding Requirement"
          placeholder="Provide a funding requirement for the project."
          type="number"
          onChange={e => setInvestmentProjectData('investment_target', e.target.value)}
        />
      </FormControl>

      <FormControl fullWidth margin='normal'>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          {existingStartDate && <DatePicker
            minDate={moment(new Date())}
            label="Target Project Start Date"
            emptyLabel="DD/MM/YYYY"
            value={moment(existingStartDate)}
            onChange={value => setInvestmentProjectData('investment_start_date', value)}
          />}

          {!existingStartDate && <DatePicker
            minDate={moment(new Date())}
            label="Target Project Start Date"
            emptyLabel="DD/MM/YYYY"
            onChange={value => setInvestmentProjectData('investment_start_date', value)}
          />}
        </LocalizationProvider>
      </FormControl>

      <FormControl fullWidth margin='normal'>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          {existingTargetDate && <DatePicker
            minDate={moment(createdInvestmentProject?.investment_start_date) || moment(existingStartDate)}
            label="Target Project End Date"
            emptyLabel="DD/MM/YYYY"
            value={moment(existingTargetDate)}
            onChange={value => setInvestmentProjectData('investment_target_date', value)}
          />}

          {!existingTargetDate && <DatePicker
            disabled={!createdInvestmentProject?.investment_start_date}
            minDate={moment(createdInvestmentProject?.investment_start_date) || moment(existingStartDate)}
            label="Target Project End Date"
            emptyLabel="DD/MM/YYYY"
            onChange={value => setInvestmentProjectData('investment_target_date', value)}
          />}
        </LocalizationProvider>
      </FormControl>

      {props.isAdmin && <GaiaTextField
        required={false}
        label="Video URL"
        value={createdInvestmentProject?.video_url || ''}
        charchterCount={250}
        minRows={1}
        maxRows={3}
        placeholder="Add the URL for the iFrame component."
        formControlName='video_url'
        onChange={setInvestmentProjectData}>
      </GaiaTextField>}

      {isEdit && <Button onClick={props.closeDialog} className="removeUppercase" sx={{ mt: 2 }}>Cancel</Button>}

      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        disabled={isUploading}
        startIcon={isUploading ? undefined : <CheckCircleOutlineIcon />}
        className='removeUppercase'
        sx={{ justifyContent: "center", ml: "auto", mt: 2, float: "right", minWidth: '214px', minHeight: '40px' }}
      >
        {isUploading ? <CircularProgress size={14} /> : isEdit ? 'Update project details' : 'Submit project as draft'}
      </Button>
    </Box>
  )
};

export default CreateInvestmentProjectForm