import React, { useEffect, useState } from "react";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Counter from '../../shared/components/dashboard/counter';
import httpClient from '../../shared/http/http-client';
import ProjectService from '../../shared/services/project-service.service';
import ProjectsTable from '../components/projects-table';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://gaiacompany.io" style={{ color: '#70942c' }}>
        Gaia
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const AdminDashboard = (props) => {
  const [projects, setProjects] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [totalSold, setTotalSold] = useState(0);
  const [totalForSale, setTotalForSale] = useState(0);

  const projectService = new ProjectService();

  // get projects

  const getProjects = async () => {
    projectService.getProjects('seller')
      .then(response => {
        setProjects(response.projects);
        let newTotalSold = 0;
        let newTotalForSale = 0;

        for (const project of response.projects) {
          newTotalSold += parseInt(project.totalSoldValue) || 0;
          newTotalForSale += parseInt(project.totalForSaleValue) || 0;
        }

        setTotalSold(newTotalSold);
        setTotalForSale(newTotalForSale)
      })
  };

  // get enquiries

  const getEnquiries = async () => {
    httpClient.get('seller/enquiry')
      .then(response => {
        setEnquiries(response.data);
      })
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getEnquiries();
  }, []);

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={4} lg={4}>
            <Counter
              title='Open Enquiries'
              value={enquiries.length}
              viewLabel={'open enquiries'}
              type={'number'}
              linkTo={'/deals'}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Counter
              title='Units For Sale'
              value={totalForSale}
              viewLabel={'available units'}
              type={'money'}
              linkTo={'/sales'}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Counter
              title='Units Sold'
              value={totalSold}
              viewLabel={'sold units'}
              type={'money'}
              linkTo={'/sales'}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: '10px' }} variant='outlined'>
              <ProjectsTable />
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </>
  );
}

export default AdminDashboard