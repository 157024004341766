import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { UserDetailsContext } from '../../context/user-details';
import { HubDetailsContext } from '../../context/hub-details';
import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';


export const LogoutButton = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserDetailsContext);
  const hubDetailsContext = useContext(HubDetailsContext);
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);

  const onButtonClick = async () => {

    cognitoUserAuthenticationService.logout(userContext.userDetails.username);
    userContext.clearUserDetails();
    navigate("/login");

  };
  return (
    <Button
      variant="outlined"
      disableElevation
      onClick={onButtonClick}
      className="removeUppercase"
      sx={{ color: '#111E21', borderColor: '#111E21', borderRadius: '30px' }}
    >
      Sign out
    </Button>
  )
};