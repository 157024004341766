import httpClient from "../http/http-client";

export default class HubButtonService {
  http;
  hubButtons = {};

  static instance = new HubButtonService();

  static getInstance() {
    if (HubButtonService.instance === null) HubButtonService.instance = new HubButtonService();
    return this.instance;
  }


  constructor() {
    this.http = httpClient;
    this.getAvailableButtons(true);
    this.getAvailableButtons(false);
    this.getAvailableButtons(false, 'BUYER');
    this.getAvailableButtons(false, 'SELLER');
  }

  /**
   * Get all available buttons for the current Hub.
  **/
  async getAvailableButtons(isNavbar, specificUserRole) {
    let queryString = '';

    if (isNavbar) {
      queryString += '?navbar=true';
    } else {
      queryString += '?navbar=false';
    }

    if (specificUserRole) {
      queryString += `&user_role=${specificUserRole}`;
    }

    if (this.hubButtons[queryString]) {
      return this.hubButtons[queryString];
    }

    return httpClient.get(`hub/available-buttons${queryString}`)
      .then(response => {
        this.hubButtons[queryString] = response.data;
        return response.data;
      })
  }
}