import httpClient from '../../shared/http/http-client';

export default class AdminHubButtonService {
  http;

  constructor() {
    this.http = httpClient
  }

  /**
   * Get all hub buttons.
   * @returns - All hub buttons as an array.
   */
  async getAllHubButtons() {
    return this.http.get('admin/hub-button')
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
   * Create a new button.
   * REQUIRED => [title_copy, body_copy, route_is_internal, url].
   * OPTIONAL => [icon_code] defaultValue='trees'.
   * @returns - The created button.
   */
  async createNewButton(button) {
    return this.http.post('admin/hub-button', button)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Update a button.
  * OPTIONAL => [title_copy, body_copy, route_is_internal, url, icon_code]
  * @returns - The updated button.
  */
  async updateButton(buttonId, updatedParams) {
    return this.http.post(`admin/hub-button/${buttonId}/update`, updatedParams)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Activate a button.
  * @returns - The updated button.
  */
  async activateButton(buttonId) {
    return this.http.post(`admin/hub-button/${buttonId}/activate`, {})
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Disable a button.
  * @returns - The updated button.
  */
  async disableButton(buttonId) {
    return this.http.post(`admin/hub-button/${buttonId}/disable`, {})
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Disable a button.
  * @returns - The updated button.
  */
  async deleteButton(buttonId) {
    return this.http.post(`admin/hub-button/${buttonId}/delete`, {})
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Update the display index of a button.
  * @param {Integer} newDisplayIndex - The new display index of the button. Calculated as the total amount of buttons above this one, starting at 1.
  * @returns - The updated button.
  */
  async updateDisplayIndex(buttonId, newDisplayIndex) {
    return this.http.post(`admin/hub-button/${buttonId}/display-index`, { display_index: newDisplayIndex })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
  * Get the generic functions for the button. These can be used to pre-fill the form inputs with the selected data.
  * @returns - All the generic functions.
  */
  async getGenericFunctions() {
    return this.http.get(`admin/hub-button/generic-functions`)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }
}
