import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from "@mui/material";
import CreateArticleForm from "../../components/articles/create-article-form";
import { useParams } from 'react-router-dom';


const CreateArticle = (props) => {
  const params = useParams();
  const [isEditPage, setIsEditPage] = useState();
  const [articleId, setArticleId] = useState();

  useEffect(() => {
    if (params.id) {
      setArticleId(params.id);
      setIsEditPage(true);
    }
    window.analytics.page();
  }, [])

  return (
    <>
      <Toolbar />
      <Container maxWidth="mdxl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '100%',
            borderRadius: '10px'
          }}
          variant='outlined'
        >
          <Box sx={{ width: '100%', p: '24px' }}>
            <Grid container spacing={2} style={{ paddingTop: 0 }}>
              <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 2 }}>
                <List sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', pt: 0, pb: 3 }}>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      primary={
                        <Typography variant="h5">{isEditPage ? 'Edit article' : 'New Article'}</Typography>
                      }
                      secondary={
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          {isEditPage ? 'Edit an existing article' : 'Create a new article to be displayed in the learning hub'}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CreateArticleForm articleId={articleId} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default CreateArticle