import httpClient from '../../shared/http/http-client';

export default class ProjectHabitatService {
  http;

  constructor() {
    this.http = httpClient
  }

  async updateOneProjectHabitat(id, type, habitatDetails) {
    return this.http.put(`project/habitat/${type}/${id}`, habitatDetails)
      .then(response => {
        return response.data;
      })
  }

}