import { NavBarBrand } from "./nav-bar-brand";

import React, { useContext, useEffect, useState } from 'react';
import { styled, ThemeProvider, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { HubDetailsContext } from '../../../context/hub-details';
import gaiaTheme from '../../../../theme';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LogoutButton } from "../../../components/buttons/logout-button";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import EastIcon from '@mui/icons-material/East';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CalculateIcon from '@mui/icons-material/Calculate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { UserDetailsContext } from '../../../context/user-details';
import { Stack } from '@mui/material';
import HubButtonService from '../../../services/hub-buttons-service';
import { HomeOutlined } from '@mui/icons-material';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: '100%',
  backgroundColor: '#ffffff',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '13px',
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function NavBar(props) {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInitials, setUserInitials] = useState('');
  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('md'));
  const isTablet = useMediaQuery('(max-width:1250px)');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [customButtons, setCustomButtons] = useState([]);
  const [isMainHub, setIsMainHub] = useState(false);
  const [homeUrl, setHomeUrl] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElAbout, setAnchorElAbout] = React.useState(null);
  const open = Boolean(anchorEl);
  const openAbout = Boolean(anchorElAbout);
  const [mobileLayoutAtTablet, setMobileLayoutAtTablet] = useState(false)
  const [hubId, setHubId] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAboutClick = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAboutClose = () => {
    setAnchorElAbout(null);
  };

  const navigate = useNavigate();
  const user = useContext(UserDetailsContext);
  const hubContext = useContext(HubDetailsContext);
  const hubButtonService = HubButtonService.getInstance();

  useEffect(() => {
    if (hubContext.hubDetails) {
      setIsMainHub(hubContext.hubDetails.main_hub);
      setHomeUrl(hubContext.hubDetails.home_page_url);
      setHubId(hubContext.hubDetails?.id)
    }
  }, [hubContext.hubDetails])

  useEffect(() => {
    if (user.userDetails) {
      setIsAuthenticated(true);
      setIsSubscribed(user.userDetails.isSubscribed);
      const initials = getInitials(user.userDetails.name);
      setUserInitials(initials);
    } else {
      setIsAuthenticated(false);
    }
  }, [user.change, user])

  useEffect(() => {
    hubButtonService.getAvailableButtons(true)
      .then(response => {
        setCustomButtons(response);
        setMobileLayoutAtTablet(response.length > 4);
      })
  }, [user.change])

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const handleSignUpClick = () => {
    segmentTrack();
    navigate("/signup");
    setMobileMenuOpen(false);
  }

  const handleLoginClick = () => {
    segmentTrack();
    navigate("/login");
    setMobileMenuOpen(false);
  }

  const handleCustomButtonClick = (button) => {
    if (button.route_is_internal) {
      navigate(button.url);
      setMobileMenuOpen(false);
    } else {
      window.open(button.url, '_self')
    }
  }

  const segmentTrack = () => {
    window.analytics.track('Clicked Action', {
      category: 'Sign Up',
      label: 'Get started for free'
    })
  }

  const [newTheme, setNewTheme] = useState(gaiaTheme)
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    setNewTheme(hub.hubDetails.theme);
  }, [hub])

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <NavBarBrand />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
            </Typography>

            {hubId === 4 && <>
              <IconButton aria-label="home" sx={{ mr: 1, color: '#000' }} to={homeUrl} component={Link}>
                <HomeOutlined />
              </IconButton>
              <Button
                variant='text'
                className="removeUppercase"
                disableElevation
                onClick={handleAboutClick}
                endIcon={<KeyboardArrowDownIcon />}
                style={{ textTransform: 'none', color: '#000', marginRight: '16px' }}
              >
                About
              </Button>

              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElAbout}
                open={openAbout}
                onClose={handleAboutClose}
              >
                <MenuItem
                  onClick={handleAboutClose}
                  disableRipple
                  component={Link}
                  to="/hub/what-is-linc"
                >
                  What is LINC?
                </MenuItem>
                <MenuItem
                  onClick={handleAboutClose}
                  disableRipple
                  component={Link}
                  to="/hub/why-invest"
                >
                  Why is investing in nature important?
                </MenuItem>
                <MenuItem
                  onClick={handleAboutClose}
                  disableRipple
                  component={Link}
                  to="/hub/who-are-we"
                >
                  Who are we? Meet the team
                </MenuItem>
                <MenuItem
                  onClick={handleAboutClose}
                  disableRipple
                  component={Link}
                  to="/hub/governance"
                >
                  Governance and integrity
                </MenuItem>
                <MenuItem
                  onClick={handleAboutClose}
                  disableRipple
                  component={Link}
                  to="/hub/background"
                >
                  Background to LINC
                </MenuItem>
              </StyledMenu>
            </>
            }

            {/* CUSTOM BUTTONS */}
            {!isMobileMode && (!isTablet || !mobileLayoutAtTablet) && customButtons?.map((button, index) => {
              return <Button
                key={index}
                sx={{ mr: '20px' }}
                variant='text'
                className="removeUppercase"
                onClick={() => handleCustomButtonClick(button)}
                style={{ textTransform: 'none', color: '#000' }}
              >
                {button.title_copy}
              </Button>
            })}

            {(!isMobileMode && (!isTablet || !mobileLayoutAtTablet) && !isAuthenticated) && (
              <>
                <Stack direction='row' spacing={2}>
                  {!hub?.hubDetails?.hub_styles.hide_tools_menu && <Button
                    variant='text'
                    className="removeUppercase"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    style={{ textTransform: 'none', color: '#000' }}
                  >
                    Tools
                  </Button>}
                  <Button
                    variant="link"
                    color="primary"
                    disableElevation
                    onClick={() => handleLoginClick()}
                    className="removeUppercase"
                    startIcon={<PersonOutlineOutlinedIcon />}
                    sx={{ borderRadius: '30px', fontSize: '15px', color: 'text.primary' }}
                  >
                    Login
                  </Button>
                  {isMainHub &&
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => handleSignUpClick()}
                      className="removeUppercase"
                      endIcon={<EastIcon />}
                      sx={{ borderRadius: '30px', fontSize: '15px', mr: 1 }}
                    >
                      Sign up for free
                    </Button>
                  }
                </Stack>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleClose}
                    disableRipple
                    component={Link}
                    to="/calculator"
                  >
                    <CalculateIcon />
                    Unit calculator
                  </MenuItem>
                </StyledMenu>
              </>
            )}

            {/* AUTENTICATED BUTTONS */}
            {(!isMobileMode && (!isTablet || !mobileLayoutAtTablet) && isAuthenticated) && isSubscribed && (
              <>
                <Button
                  sx={{ mr: '20px' }}
                  variant='text'
                  className="removeUppercase"
                  component={Link}
                  to="/enquiries"
                  style={{ textTransform: 'none', color: '#000' }}
                >
                  My enquiries
                </Button>
                <Button
                  sx={{ mr: '20px' }}
                  variant='text'
                  className="removeUppercase"
                  component={Link}
                  to="/saved"
                  style={{ textTransform: 'none', color: '#000' }}
                >
                  Saved listings
                </Button>
              </>
            )}

            {(!isMobileMode && (!isTablet || !mobileLayoutAtTablet) && isAuthenticated) && (
              <IconButton
                onClick={() => props.setRightNavOpen(true)}
                sx={{ p: 0 }}
              >
                <Avatar sx={{ bgcolor: 'primary.main' }}>{userInitials}</Avatar>
              </IconButton>
            )}

            {(isMobileMode || (isTablet && mobileLayoutAtTablet)) && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="text.primary"
                  aria-label="open drawer"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <Drawer
                  variant="persistent"
                  anchor="right"
                  open={mobileMenuOpen}
                  id='website-mobile-menu'
                >
                  {/* CUSTOM BUTTONS */}

                  <Divider />
                  <List>
                    {customButtons?.map((button, index) => {
                      return <ListItem key={index} disablePadding onClick={() => handleCustomButtonClick(button)} sx={{ justifyContent: 'center' }}>
                        <ListItemButton>
                          <ListItemText primary={button.title_copy} sx={{ color: '#111e21', textAlign: 'center' }} />
                        </ListItemButton>
                      </ListItem>
                    })}
                    {isAuthenticated && <>
                      <Link to="/enquiries">
                        <ListItem key='enquiries' disablePadding sx={{ justifyContent: 'center' }}>
                          <ListItemButton>
                            <ListItemText primary='My enquiries' sx={{ color: '#111e21', textAlign: 'center' }} />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                      <Link to="/saved">
                        <ListItem key='saved-listings' disablePadding sx={{ justifyContent: 'center' }}>
                          <ListItemButton>
                            <ListItemText primary='Saved listings' sx={{ color: '#111e21', textAlign: 'center' }} />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                      <Divider sx={{ mt: 2 }} />

                      <ListItem key='sign-out' sx={{ justifyContent: "center", pt: 4 }}>
                        <LogoutButton
                          className="removeUppercase"
                        />
                      </ListItem>
                    </>
                    }
                    {!isAuthenticated && <>
                      <Divider sx={{ mt: 2 }} />
                      <ListItem key='saved-listings' disablePadding onClick={() => handleLoginClick()}>
                        <ListItemButton>
                          <Button
                            variant="link"
                            color="primary"
                            disableElevation
                            className="removeUppercase"
                            startIcon={<PersonOutlineOutlinedIcon />}
                            sx={{ borderRadius: '30px', fontSize: '15px', color: 'text.primary' }}
                          >
                            Login
                          </Button>
                        </ListItemButton>
                      </ListItem>
                      {isMainHub &&
                        <ListItem key='sign-out' sx={{ justifyContent: "center" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => handleSignUpClick()}
                            className="removeUppercase"
                            endIcon={<EastIcon />}
                            sx={{ borderRadius: '30px', fontSize: '15px', mr: 1 }}
                          >
                            Sign up for free
                          </Button>
                        </ListItem>
                      }
                    </>
                    }
                  </List>
                </Drawer>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default NavBar;