import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { Box, Card, CardActionArea, Link, Stack } from '@mui/material';

import IconPicker from '../icon-picker';

export default function DashboardButton({ linkTo, size, title, subtitle, icon, alignTop = false }) {

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        flexGrow: 1,
        alignItems: 'center',
        height: '100%',
        borderRadius: '10px',
      }}
      elevation={0}
    >
      <CardActionArea
        component={RouterLink}
        to={linkTo}
        sx={{
          p: 2,
          display: 'flex',
          flexGrow: 1,
          alignItems: alignTop ? 'flex-start' : 'center',
          pt: size === 'large' ? 6 : 2,
          pb: size === 'large' ? 6 : 2,
        }}
      >
        <Stack direction={size === 'large' ? 'column' : 'row'} spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <IconPicker icon={icon} color='primary' size={size === 'large' ? 'large' : 'medium'} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={size === "large" ? "h6" : "subtitle1"}>
              {title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" >
              {subtitle}
            </Typography>
            <Link>{ }</Link>
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
