import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Chip } from "@mui/material";
import ProjectService from '../../services/project-service';
import Title from '../../../shared/components/title';

export default function DashboardProjectsTable() {

  const projectService = new ProjectService();

  const [projectsLoading, setProjectsLoading] = useState(0);
  const [projects, setProjects] = useState([]);
  const [noProjects, setNoProjects] = useState(false);

  const navigate = useNavigate();

  // const setSelectedRow = (project) => {
  //   navigate(`/project/${project.p_id}`, {
  //     state: { project }
  //   })
  // }

  const getProjects = async () => {
    setProjectsLoading(true);
    let params = new URLSearchParams();
    params.append('page', 0);
    params.append('size', 5);
    projectService.getAllProjects(params)
      .then(response => {
        if (response.projects.length === 0) {
          setNoProjects(true);
        } else {
          setProjects(response.projects);
        }
        setProjectsLoading(false);
      })
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      {noProjects && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No listings have been created yet.</Alert>
      )}
      {!noProjects && (<>
        <Title>BNG Listings</Title>
        <Box sx={{
          maxWidth: '100%',
          overflow: 'auto'
        }}>
          <Table >
            <TableHead>
              <TableRow sx={{ fontWeight: 600 }}>
                <TableCell sx={{ fontWeight: 600, pl: 0 }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>LPA</TableCell>
                <TableCell align='right' sx={{ fontWeight: 600 }}>Status</TableCell>
                <TableCell align='right' sx={{ fontWeight: 600 }}>Units</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(projectsLoading ? Array.from(new Array(5)) : projects)?.slice(0, 3).map((project, index) => (
                project ? (
                  <TableRow
                    key={project.p_id}
                    //onClick={() => setSelectedRow(project)}
                    component={RouterLink}
                    to={`/project/${project.p_id}`}
                    hover={true}
                  >
                    <TableCell sx={{ pl: 0 }}>{project.p_summary}</TableCell>
                    <TableCell>{project.p_local_planning_authority}</TableCell>
                    <TableCell align='right'>
                      <Chip
                        label={project?.admin_status?.status_name}
                        variant="outlined"
                        sx={{
                          justifyContent: "center",
                          color: project?.admin_status?.color || "primary",
                          borderColor: project?.admin_status?.color || "primary",
                          minWidth: '100px',
                        }}
                      />
                    </TableCell>
                    <TableCell align='right'><NumericFormat value={project.totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                  </TableRow>
                )))
              }
            </TableBody>
          </Table>
        </Box>
        <Link color="primary" to="/projects" sx={{ mt: 3 }} component={RouterLink} >
          See all listings
        </Link>
      </>
      )}
    </>
  );
}
