
import { Avatar, Box, Button, Chip, InputAdornment, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminUserService from '../services/admin-user-service';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Download, SearchOutlined, Star } from '@mui/icons-material'
import * as React from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'approved_for_sale',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'created_at',
    numeric: true,
    disablePadding: false,
    label: 'Date Registered',
  },
  {
    id: 'enquiries_received',
    numeric: true,
    disablePadding: false,
    label: 'Enquiries Received',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: 600 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const UsersList = ({ userRoleFilter, onlyCommission }) => {
  const adminUserService = new AdminUserService();

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('created_at');

  const colors = ['#71bbff', '#83edda', '#abddf9', '#eba3d3', '#ff9a99', '#ffaa7a', '#fed703'];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (searchValue) {
      setFilterUsers(users.filter(user => user.roles.includes(userRoleFilter) && (user.email.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || user.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || user.id.toString().indexOf(searchValue) !== -1)))
    } else {
      if (onlyCommission) {
        setFilterUsers(users.filter(user => user.commission_contract));
      } else {
        setFilterUsers(users.filter(user => user.roles.includes(userRoleFilter)));
      }
    }
  }, [searchValue, userRoleFilter, onlyCommission, order, orderBy])

  const getUsers = () => {
    adminUserService.getUsers()
      .then((response) => {
        let usersArray = [];
        for (let user of response) {
          user.initials = getInitials(user.name);
          usersArray.push(user);
        }
        setUsers(usersArray);
        setFilterUsers(usersArray);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToCsv = e => {
    e.preventDefault();
    let headers = ['id,name,email,organisation,roles,approved_for_sale,commission_contract,created_at, enquiries_received'];
    let searchesCsv = filterUsers.reduce((acc, user) => {
      const { id, name, email, organisation, roles, approved_for_sale, commission_contract, created_at, enquiries_received } = user
      acc.push([id, `"${name}"`, email, `"${organisation}"`, roles[0], approved_for_sale, commission_contract, created_at, enquiries_received?.length].join(','))
      return acc
    }, [])
    downloadFile({
      data: [...headers, ...searchesCsv].join('\n'),
      fileName: 'users.csv',
      fileType: 'text/csv',
    })
  }

  useEffect(() => {
    getUsers();
  }, [])

  useEffect(() => {
    setUsers(stableSort(users, getComparator(order, orderBy)))
  }, [order, orderBy])

  useEffect(() => {
    setUsers(stableSort(users, getComparator(order, orderBy)))
    setFilterUsers(users.filter(user => user.roles.includes(userRoleFilter)))
  }, [])

  return (
    <Box>
      <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center', pt: 1, pb: 2 }}>
        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Search by email, name or id'
          onChange={e => setSearchValue(e.target.value)}
          value={searchValue || ''}
          sx={{ width: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>)
          }}
        />
        <Button variant='outlined' startIcon={<Download />} onClick={exportToCsv} disableElevation className='removeUppercase' sx={{ width: '200px', mt: 2 }}>
          Export to CSV
        </Button>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filterUsers && filterUsers.map((user, index) => {
              return <TableRow key={user.id} component={Link} to={`/admin/users/${user.id}`} hover={true}>
                <TableCell>
                  <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: colors[index % colors.length] }}>
                      {user.initials}
                    </Avatar>
                    <span>
                      {user.name}
                    </span>
                    {user.commission_contract && <Star sx={{ color: '#fbbd05' }} />}
                  </Stack>
                </TableCell>
                <TableCell>
                  {user.email}
                </TableCell>
                <TableCell>
                  {userRoleFilter !== 'BUYER' && <Chip
                    edge="end"
                    label={user.approved_for_sale ? 'Approved' : 'Pending'}
                    variant='outlined'
                    color={!user.approved_for_sale ? 'info' : 'primary'}
                  />}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {moment(user.created_at).format('DD/MM/yyyy')}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {user?.enquiries_received.length}
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersList
