import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, Select } from '@mui/material';
import ShallowStatusService from '../../services/shallow-status-service';

export const DialogChangeShallowStatus = ({ projectId, statusCode, active, onUpdate, onCancel, projectType, isAdmin }) => {

  const shallowStatusService = ShallowStatusService.getInstance();
  const [shallowStatuses, setShallowStatuses] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState();

  useEffect(() => {
    shallowStatusService.getAllStatuses(projectType)
      .then((response) => {
        setShallowStatuses(response);
      })
  }, [])

  const handleUpdateShallowStatus = () => {
    if (projectType === 'BNG_PROJECT') {
      shallowStatusService.updateBngProjectShallowStatus(projectId, updatedStatus, isAdmin ? 'admin' : 'seller')
        .then(() => {
          onUpdate()
        })
    } else if (projectType === 'INVESTMENT_PROJECT') {
      shallowStatusService.updateInvestmentProjectShallowStatus(projectId, updatedStatus, isAdmin ? 'admin' : 'seller')
        .then(() => {
          onUpdate()
        })
    }

  }

  return (<>
    {shallowStatuses && <Dialog open={active} onClose={onUpdate}>
      <DialogTitle sx={{ pb: '5px' }}>Change status</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Select a new status below.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl size="small" >
            <InputLabel id="project-status-label">Status</InputLabel>
            <Select
              labelId="project-status-label"
              id="project-status"
              value={updatedStatus || ''}
              label="Status"
              onChange={e => setUpdatedStatus(e.target.value)}
              sx={{ minWidth: '400px' }}
            >
              {shallowStatuses && shallowStatuses.filter(status => status.status_code !== statusCode && !isAdmin ? status.status_code !== 'COMING_SOON' : true).map((status, index) => {
                return <MenuItem key={index} value={status.status_code}>{status.status_name}</MenuItem>
              })}

            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleUpdateShallowStatus}
          className="removeUppercase"
        >
          Change status
        </Button>
      </DialogActions>
    </Dialog>}
  </>

  )
}