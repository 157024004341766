import httpClient from "../http/http-client";

export default class CalculatorService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getLpaData(lpa) {
    return this.http.get(`hub/project/lpa-prices?lpa=${lpa}`)
      .then(response => {
        return response.data
      });
  }
}