import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Title from '../../shared/components/title';
import ApprovalsInvestmentProjectsList from '../components/approvals/investment-projects-list';
import ApprovalsBngProjectsList from '../components/approvals/bng-project-list';
import UserApprovalList from '../components/approvals/user-approval-list';
import { HubDetailsContext } from '../../shared/context/hub-details';

const Approvals = () => {
  const [approvalType, setApprovalType] = useState();
  const [moduleCodes, setModuleCodes] = useState([]);

  const hubContext = useContext(HubDetailsContext);

  useEffect(() => {
    if (hubContext.hubDetails) {
      setModuleCodes([]);
      hubContext.hubDetails.hubTiles?.forEach(tile => {
        const codes = moduleCodes;
        codes.push(tile?.tile_code);
        setModuleCodes(codes);
      })
    }
  }, [hubContext.hubDetails])

  useEffect(() => {
    if (moduleCodes.length > 0) {
      if (moduleCodes.includes('INVESTMENT_PROJECT')) {
        setApprovalType('INVESTMENT_PROJECT')
      } else if (moduleCodes.includes('BNG_MARKETPLACE')) {
        setApprovalType('BNG_MARKETPLACE')
      } else {
        setApprovalType('SELLER_USERS')
      }
    }

  }, [moduleCodes])

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      {approvalType && <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: 'calc(100vh - 130px)',
                pt: 3,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container spacing={2} sx={{ mb: 4, alignItems: 'center' }}>
                <Grid item xs={12} md={9} lg={9}>
                  <Title>Approvals</Title>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl size="small" sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}>
                    <InputLabel id="approval-type-label">Approval Type</InputLabel>
                    <Select
                      labelId="approval-type-label"
                      id="approval-type"
                      value={approvalType}
                      label="Approval Type"
                      onChange={(e) => setApprovalType(e.target.value)}
                    >
                      {moduleCodes.includes('BNG_MARKETPLACE') && <MenuItem value={'BNG_MARKETPLACE'}>Marketplace Listings</MenuItem>}
                      {moduleCodes.includes('INVESTMENT_PROJECT') && <MenuItem value={'INVESTMENT_PROJECT'}>Investment Projects</MenuItem>}
                      <MenuItem value={'SELLER_USERS'}>Users</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {approvalType === 'INVESTMENT_PROJECT' && <ApprovalsInvestmentProjectsList />}
              {approvalType === 'BNG_MARKETPLACE' && <ApprovalsBngProjectsList />}
              {approvalType === 'SELLER_USERS' && <UserApprovalList />}
            </Paper>
          </Grid >
        </Grid >
      </Container >}
    </>
  );
};

export default Approvals 