import React, { useEffect } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Verification = () => {
  const [verificationCode, setVerificationCode] = React.useState(null);

  const submitVerification = async () => {
    //authenticateUser
  }

  // const poolData = {
  //   UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  //   ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  // }

  //const userPool = new CognitoUserPool(poolData)

  // const authenticateUser = (email, password) => {
  //   var authenticationData = {
  //     Username: email,
  //     Password: password,
  //   };

  //   var authenticationDetails = new AuthenticationDetails(
  //     authenticationData
  //   );

  //   var userData = {
  //     Username: email,
  //     Pool: userPool,
  //   };
  //   var cognitoUser = new CognitoUser(userData);
  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     onSuccess: function (result) {
  //       var accessToken = result.getAccessToken().getJwtToken();

  //       //POTENTIAL: Region needs to be set if not already set previously elsewhere.
  //       AWS.config.region = 'eu-west-2';

  //       AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //         IdentityPoolId: '...', // your identity pool id here
  //         Logins: {
  //           // Change the key below according to the specific region your user pool is in.
  //           'cognito-idp.eu-west-2.amazonaws.com/ZNYbSTV6m': result
  //             .getIdToken()
  //             .getJwtToken(),
  //         },
  //       });

  //       //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
  //       AWS.config.credentials.refresh(error => {
  //         if (error) {
  //           console.error(error);
  //         } else {
  //           // Instantiate aws sdk service objects now that the credentials have been updated.
  //           // example: var s3 = new AWS.S3();
  //         }
  //       });
  //     },

  //     mfaRequired: function (codeDeliveryDetails) {
  //       // MFA is required to complete user authentication.
  //       // Get the code from user and call
  //       cognitoUser.sendMFACode(verificationCode, this);
  //     },
  //   });
  // }

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <Box style={{ backgroundColor: '#F0F1F3', minHeight: '100vh' }}>
      <Toolbar />
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <img
              src="/gaia_logo.png"
              alt="GAIA logo"
              //width="122"
              height="100"
              style={{ verticalAlign: 'middle', paddingLeft: '45px', paddingBottom: '15px' }}
            />
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
              }}
            >
              <Box sx={{ width: '100%', p: '24px' }}>
                <TabPanel value={0} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5">
                        Verify email address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="subtitle2" color="text.secondary" >
                        Enter the 6 digit code to verify your email and access Gaia.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <TextField
                        fullWidth
                        className="formField"
                        label="Verification Code"
                        id="verificationCode"
                        placeholder="Verification Code"
                        onChange={e => setVerificationCode(e.target.value)}
                        value={verificationCode}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={submitVerification}
                        className='removeUppercase'
                        endIcon={<ChevronRightIcon />}
                        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Verification