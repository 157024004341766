import httpClient from '../../shared/http/http-client';

export default class AdminEventService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getAllEvents() {
    return this.http.get(`admin/event`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async createEvent(event) {
    return this.http.post(`admin/event`, event)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async deleteEvent(eventId) {
    return this.http.delete(`admin/event/${eventId}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async editEvent(eventId, event) {
    return this.http.patch(`admin/event/${eventId}`, event)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }
}
