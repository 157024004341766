import React, { useContext, useEffect, useState } from "react";
import Table from '@mui/material/Table';
import { useNavigate } from "react-router-dom";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Alert from '@mui/material/Alert';
import httpClient from '../../shared/http/http-client';
import moment from 'moment';
import { UserDetailsContext } from '../../shared/context/user-details';
import { DoNotDisturb } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export default function EnquiriesTable() {

  const [enquiries, setEnquiries] = useState([]);
  const [noEnquiries, setNoEnquiries] = useState(false);

  const navigate = useNavigate();
  const userContext = useContext(UserDetailsContext);

  const setSelectedRow = (enquiry) => {
    if (enquiry.investment_project?.admin_status?.status_code === 'PUBLISHED' || enquiry.project?.admin_status?.status_code === 'PUBLISHED') {
      navigate(`/buyer/enquiry/${enquiry.e_id}`, {
        state: { enquiry }
      })
    }
  }

  // get enquiries

  const getEnquiries = async () => {

    httpClient.get(`buyer/enquiry`)
      .then(enquiriesRes => {
        if (enquiriesRes.data.length === 0) {
          setNoEnquiries(true);
        } else {
          setEnquiries(enquiriesRes.data);
        }
      })
      .catch(err => {
        console.error(err.message);
      })

  };

  useEffect(() => {
    if (userContext.userDetails) {
      getEnquiries();
    }
  }, [userContext]);

  return (
    <>
      {noEnquiries && (
        <Alert severity="info" sx={{ p: 2 }}>You have not sent any enquiries yet.</Alert>
      )}
      <Table>
        <TableBody>
          {enquiries?.map((enquiry, index) => (
            <TableRow key={index} onClick={() => setSelectedRow(enquiry)} hover={enquiry.investment_project?.admin_status?.status_code === 'PUBLISHED' || enquiry.project?.admin_status?.status_code === 'PUBLISHED'}>
              <TableCell>{moment(enquiry.created_at).format('DD/MM/yyyy')}</TableCell>
              <TableCell>{enquiry.investment_project ? 'Investment project' : 'BNG Project'}</TableCell>
              <TableCell>{enquiry.investment_project ? enquiry.investment_project.title_text : enquiry.project?.p_summary}</TableCell>
              <TableCell>{enquiry.investment_project ? '' : enquiry.project?.p_local_planning_authority}</TableCell>
              {(enquiry.investment_project?.admin_status?.status_code === 'PUBLISHED' || enquiry.project?.admin_status?.status_code === 'PUBLISHED') && <TableCell align="right"><TaskAltIcon color='primary' /></TableCell>}
              {(enquiry.investment_project?.admin_status?.status_code !== 'PUBLISHED' && enquiry.project?.admin_status?.status_code !== 'PUBLISHED') && <>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography variant="p" color="text.primary" sx={{ mr: '16px' }}>
                      Project closed
                    </Typography>
                    <DoNotDisturb color='error' />
                  </Box>
                </TableCell>
              </>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
