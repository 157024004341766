import httpClient from '../../shared/http/http-client';

export default class SellerDashboardService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getDashboardSummary() {
    return this.http.get('seller/dashboard/dashboard-information')
      .then(response => {
        return response.data;
      })
  }

}