import React, { Fragment, useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Alert, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const LearningMenu = ({ routeToArticle, articleGroups, activeGroup, setActiveGroup, noGroupsFound }) => {

  const navigate = useNavigate();

  const handleGroupClick = (groupId) => {
    setActiveGroup(groupId);
  };

  const handleArticleClick = (articleId, externalUrl) => {
    if (externalUrl) {
      window.open(externalUrl, '_blank');
    } else {
      navigate(`${routeToArticle}${articleId}`)
    }
  };

  return (<>
    {noGroupsFound && <Alert severity="info" sx={{ mb: 4, p: 2 }}>
      There are not topics that match this query. Please try again.
    </Alert>}

    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
    >
      {articleGroups && articleGroups.filter(obj => obj.hide_from_learning_hub === false).map((group, index) => (
        <Fragment key={index}>
          <ListItemButton
            onClick={() => handleGroupClick(group.id)}
            disableRipple
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontWeight: group.id === activeGroup && '600'
                  }}
                >
                  {group.title_copy}
                </Typography>
              }
            />
            {activeGroup === group.id ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {group.articles && group.articles.map((article, index) => (
            <Collapse in={activeGroup === group.id} timeout="auto" unmountOnExit key={index}>
              <List
                component="div"
                disablePadding
              >
                <ListItemButton onClick={() => handleArticleClick(article.id, article.external_url)} sx={{ pl: 4 }} disableRipple>
                  <ListItemText primary={article.title_copy} />
                </ListItemButton>
              </List>
            </Collapse>
          ))}
        </Fragment>
      ))}
    </List>
  </>

  );
}

export default LearningMenu