import { useEffect, useState } from 'react';
import { ImageFileUpload } from '../../shared/components/image-file-upload';
import { LinearProgress, Stack, Typography } from '@mui/material';
import BngMetricService from '../services/bng-metric-service';

const BNGMetricUpload = (props) => {

  const bngMetricService = new BngMetricService();

  const [isUploading, setIsUploading] = useState(false);
  const [fileData, setFileData] = useState([]);

  // This needs to be change to listen for errors. For the moment it will stop double clicking if the image takes a few seconds to upload.
  const setUploadSpinner = () => {
    setIsUploading(true);

    setTimeout(() => {
      setIsUploading(false);
    }, 4000);
  }

  const completeUpload = () => {
    const form = new FormData();

    for (let i = 0; i < fileData.length; i++) {
      form.append("files", fileData[i]);
    }

    // form.append('resource', JSON.stringify({ type: projectType, project_id: projectId }));

    bngMetricService.createResource(form)
      .then((response) => {
        props.setMetricRequiredUnits(response);
        setUploadSpinner();
      })
      .catch(() => {
        props.setUploadError(true);
        setFileData([]);
        setIsUploading(false);
      });
  }

  useEffect(() => {
    if (fileData.length > 0 && !isUploading) {
      props.setUploadError(false);
      completeUpload();
      setIsUploading(true);
    }
  }, [fileData])

  return (<>
    <ImageFileUpload
      fileData={fileData}
      setFileData={setFileData}
      imageCountLimit={1}
      bngMetric={true}
      maxSize={20}
    />
    {isUploading && <Stack direction='column' spacing={1} sx={{ mt: -3 }}>
      <Typography variant='body2' color='text.secondary'>Processing BNG Metric...</Typography>
      <LinearProgress />
    </Stack>}
    {/* <Button
      variant="contained"
      disabled={fileData.length === 0 || isUploading}
      color="primary"
      disableElevation
      onClick={() => [completeUpload(), setUploadSpinner()]}
      className="removeUppercase"
      sx={{ minWidth: '100px' }}
    >
      {isUploading ? <CircularProgress size={14} /> : 'Search units'}
    </Button> */}
  </>
  )
}

export default BNGMetricUpload;