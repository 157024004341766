import httpClient from '../../shared/http/http-client';

export default class AdminArticleGroupService {
  http;

  constructor() {
    this.http = httpClient
  }
  async createNewArticleGroup(articleGroup) {
    return this.http.post('admin/article/group', articleGroup)
      .then(response => {
        return response.data;
      })
  }

  async getAllArticleGroups() {
    return this.http.get('admin/article/group')
      .then(response => {
        return response.data;
      })
  }

  async updateDisplayOrder(id, displayIndex) {
    return this.http.post(`admin/article/group/order/${id}`, { display_index: displayIndex })
      .then(response => {
        return response.data;
      })
  }

}