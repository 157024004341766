import httpClient from "../http/http-client";

export default class ArticleService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getOneArticle(id) {
    return this.http.get(`hub/article/${id}`)
      .then(response => {
        return response.data;
      })
  }

  async getAllArticles() {
    return this.http.get('hub/article')
      .then(response => {
        return response.data;
      })
  }

  async createNewArticle(file) {
    return this.http.post('admin/article', file)
      .then(response => {
        return response.data;
      })
  }

  async getOgMetaTag(url) {
    return this.http.get(`admin/article/opengraph?url=${url}`)
      .then(response => {
        return response.data;
      })
  }

  async editArticle(id, details) {
    return this.http.post(`admin/article/edit/${id}`, details)
      .then(response => {
        return response.data;
      })
  }

  async deleteArticle(id) {
    return this.http.post(`admin/article/delete/${id}`)
      .then(response => {
        return response.data;
      })
  }

  async updateDisplayOrder(id, displayIndex) {
    return this.http.post(`admin/article/order/${id}`, { display_index: displayIndex })
      .then(response => {
        return response.data;
      })
  }
}