import { Alert, Box, Button, Card, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import AdminFormService from '../../../services/admin-form-service';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import TabPanel from '../../../../shared/components/tab-panel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { KeyboardArrowLeft } from '@mui/icons-material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const CreateCustomForm = (props) => {

  const adminFormService = AdminFormService.instance;
  const navigate = useNavigate();

  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [formName, setFormName] = useState();
  const [question, setQuestion] = useState({
    question: undefined,
    additional_information: undefined,
    answer_required: true,
    question_type_id: undefined,
    show_in_public_listing: false
  });
  const [errors, setErrors] = useState();
  const [formQuestions, setFormQuestions] = useState([]);
  const [questionTypes, setQuestionTypes] = useState();
  const [selectedQuestionType, setSelectedQuestionType] = useState();
  const [controlledAnswers, setControlledAnswers] = useState([]);
  const [maxAnswerCount, setMaxAnswerCount] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getQuestionTypes();
  }, []);

  useEffect(() => {
    if (selectedQuestionType) {
      setMaxAnswerCount(questionTypes.find(type => type.question_type === selectedQuestionType)?.max_answer_count || 0);
    }
  }, [selectedQuestionType])

  const getQuestionTypes = () => {
    adminFormService.getAllQuestionTypes()
      .then(response => {
        setQuestionTypes(response)
      })
  }

  const updateFormName = (event) => {
    setFormName(event.target.value)
  }

  const handleChange = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.value });
  }

  const handleUpdateControlledAnswer = (event, index) => {
    const updated = controlledAnswers;
    updated[index].answer_text = event.target.value;

    setControlledAnswers([...updated]);
  }

  const handleQuestionTypeChange = (event) => {
    setSelectedQuestionType(event.target.value);
    setControlledAnswers([]);

    const minAnswerCount = questionTypes.find(type => type.question_type === event.target.value).min_answer_count;
    const questionTypeId = questionTypes.find(type => type.question_type === event.target.value).id;

    setQuestion({ ...question, question_type_id: questionTypeId });

    for (let x = 0; x < minAnswerCount; x++) {
      addAnswerOption(event.target.value);
    }
  }

  const handleCheckbox = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.checked });
  }

  const toggleAddingQuestion = () => {
    isAddingQuestion && setIsAddingQuestion(false);
    setErrors({})
    const isValid = checkErrors();
    if (isValid) {
      (formQuestions.length === 0 || activeStep === 1) && setIsAddingQuestion(true);
      if (activeStep !== 1) {
        setActiveStep(1);
      }
    }
  }

  useEffect(() => {
    console.log(formQuestions)
  }, [formQuestions]);

  const handleCancelAddingQuestion = () => {
    if (formQuestions.length === 0) {
      setSelectedQuestionType(null);
    } else {
      toggleAddingQuestion();
    }
  }

  /**
   * Check the question for errors and add it to the form object.
   */
  function addQuestionToForm() {
    const hasControlledAnswers = questionTypes.find(type => type.question_type === selectedQuestionType).has_controlled_answers;
    setErrors({})
    const isValid = hasControlledAnswers ? validateSelectTypeQuestion() : checkErrors();
    if (isValid) {
      const currentQuestion = question;
      if (hasControlledAnswers) {
        const filteredAnswers = controlledAnswers.filter(answer => answer.answer_text);
        currentQuestion.controlled_answers = filteredAnswers;
      }
      const updated = formQuestions;
      updated.push(currentQuestion);
      setFormQuestions(updated);
      setQuestion({
        question: undefined,
        additional_information: undefined,
        answer_required: true,
        question_type_id: undefined,
        show_in_public_listing: false,
        display_label: undefined
      });
      setControlledAnswers([]);
      setSelectedQuestionType();
      setIsAddingQuestion(false);
    }
  }

  const addAnswerOption = (questionType = selectedQuestionType) => {
    const maxAnswerCount = questionTypes.find(type => type.question_type === questionType).max_answer_count;
    if (controlledAnswers.length < maxAnswerCount) {
      const options = controlledAnswers;
      options.push({ answer_text: '', answer_additional_information: undefined });
      setControlledAnswers([...options]);
    }
  }

  /**
   * Remove a question from the form at a given index.
   * @param {*} i - The index of the question to remove.
   */
  const removeQuestion = (i) => {
    const formattedQuestions = formQuestions.filter((question, index) => index !== i);
    setFormQuestions(formattedQuestions);
  }

  /**
   * Check the form for errors.
   * @returns - TRUE if NO ERRORS.
   */
  function checkErrors() {
    const tempErrors = {}
    if (!question.question && isAddingQuestion) {
      tempErrors.question = 'You must enter a question.'
    }
    if (!formName) {
      tempErrors.formName = 'You must give this form a name.'
    }

    setErrors(tempErrors)
    return (Object.keys(tempErrors).length === 0)
  }

  const validateSelectTypeQuestion = () => {

    const tempErrors = {};
    const filteredAnswers = controlledAnswers.filter(answer => answer.answer_text);
    const minAnswerCount = questionTypes.find(type => type.question_type === selectedQuestionType).min_answer_count;

    if (minAnswerCount > filteredAnswers.length) {
      tempErrors.controlledAnswersError = `You must add at least ${minAnswerCount} options for this type of question.`
    }

    if (!question.question && isAddingQuestion) {
      tempErrors.question = 'You must enter a question.'
    }

    setErrors(tempErrors)
    return (Object.keys(tempErrors).length === 0)
  }

  /**
   * Submit the completed form the the BE.
   */
  const submitForm = () => {
    const form = {
      form_name: formName,
      informationQuestions: formQuestions
    };

    adminFormService.submitNewForm(form)
      .then(async (response) => {
        props.setActiveCustomFormTab(0);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const steps = [
    'Form details',
    'Fields',
    'Save form'
  ];

  // Move back one section.
  const handleBack = () => {
    if (activeStep === 1) {
      setSelectedQuestionType(null);
      setIsAddingQuestion(false)
    }
    setActiveStep(activeStep - 1);
  };

  return (<>
    <Typography variant="h5" color="text.primary" sx={{ flex: 1, pb: 4, pt: 2 }}>
      <b>Create new form</b>
    </Typography>
    <Box sx={{ width: '100%', pb: 2 }}>
      <Stepper activeStep={activeStep} className='noGutter'>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    {activeStep !== 0 && (
      <Button
        variant='text'
        onClick={handleBack}
        disabled={activeStep === 0}
        className='removeUppercase'
        color='primary'
        startIcon={<KeyboardArrowLeft />}
        sx={{ mb: 2, mt: 2 }}
      >
        Back
      </Button>
    )}
    <TabPanel value={activeStep} index={0}>
      {errors && errors.formName && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>Please give this form a name.</Alert>}
      <TextField
        required
        id="form-name"
        label="Form name"
        disabled={isAddingQuestion || formQuestions.length > 0}
        onChange={updateFormName}
        placeholder='Please enter a name for this form...'
        sx={{ width: '100%', mb: 4, mt: 3 }}
        InputLabelProps={{
          shrink: true,
        }}
        value={formName}
      />
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => toggleAddingQuestion()}
        className='removeUppercase'
        endIcon={<ChevronRightIcon />}
        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
      >
        Next
      </Button>
    </TabPanel>
    <TabPanel value={activeStep} index={1}>
      {isAddingQuestion && <>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Select field type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedQuestionType || ''}
            label="Select field type"
            onChange={handleQuestionTypeChange}
          >
            {questionTypes && questionTypes.map((questionType, index) => {
              return <MenuItem key={index} value={questionType.question_type}>{questionType.question_name}</MenuItem>
            })}
          </Select>
        </FormControl>

        {selectedQuestionType && <>
          <Box sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox defaultChecked
                  name="answer_required"
                  disabled={selectedQuestionType === 'SELECT' || selectedQuestionType === 'CONFIRM' || selectedQuestionType === 'RADIO_GROUP'}
                  value={question.answer_required}
                  onChange={handleCheckbox}
                />}
              label="An answer is required for this question"
              sx={{ width: '100%', mb: '16px' }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="show_in_public_listing"
                  value={question.show_in_public_listing}
                  onChange={handleCheckbox}
                />}
              label="Show this question and answer in the public listing."
              sx={{ width: '100%', mb: '16px' }}
            />
          </Box>

          {errors && errors.question && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.question}</Alert>}
          <TextField
            id="outlined-multiline-static"
            label="Question"
            name="question"
            value={question.question}
            //multiline
            onChange={handleChange}
            //rows={4}
            placeholder='Enter a question...'
            sx={{ width: '100%', mb: '16px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="outlined-multiline-static"
            label="Display label"
            name="display_label"
            value={question.display_label}
            // multiline
            onChange={handleChange}
            // rows={1}
            placeholder='If provided, will be used when displaying the answer to the public.'
            sx={{ width: '100%', mb: '16px' }}
          />

          <TextField
            id="outlined-multiline-static"
            label="Additional help text"
            name="additional_information"
            value={question.additional_information}
            multiline
            onChange={handleChange}
            rows={4}
            placeholder='Optionally enter any additional informational text for the user...'
            sx={{ width: '100%', mb: '16px' }}
          />
        </>}

        {/* For question types with CONTROLLED_ANSWERS */}
        {selectedQuestionType && questionTypes.find(questionType => questionType.question_type === selectedQuestionType).has_controlled_answers && <>
          {maxAnswerCount > controlledAnswers.length && <Box sx={{ display: 'flex' }}>
            <Button
              onClick={() => addAnswerOption()}
              color='primary'
              variant="outlined"
              size="medium"
              disableElevation
              className="removeUppercase"
              sx={{ float: "right", mr: 8, mt: 2, mb: 2 }}
            >
              Add another answer option
            </Button>
          </Box>}

          {errors && errors.controlledAnswersError && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.controlledAnswersError}</Alert>}

          {controlledAnswers && controlledAnswers.map((controlledAnswer, index) => {
            return <TextField key={index}
              id="outlined-multiline-static"
              label={`Answer option ${index + 1}`}
              name="controlledAnswer"
              onChange={(event) => handleUpdateControlledAnswer(event, index)}
              placeholder='Enter answer option...'
              sx={{ width: '100%', mb: '16px' }}
            />
          })}
        </>}

        {selectedQuestionType && <Stack direction='row'>
          <Button
            variant="outlined"
            className="removeUppercase"
            color="primary"
            disableElevation
            onClick={() => handleCancelAddingQuestion()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => addQuestionToForm()}
            className='removeUppercase'
            sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
          >
            Save question
          </Button>
        </Stack>}
      </>}
      {!isAddingQuestion && formQuestions?.length > 0 && <>
        {formQuestions.map((question, index) => {
          return <Card
            sx={{ alignItems: 'center', justifyContent: 'center', mb: 1 }}
            key={index}
          ><List sx={{ flex: 1 }} dense>
              <ListItem
                secondaryAction={
                  <IconButton
                    onClick={() => removeQuestion(index)}
                    color='error'
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={<Stack direction='row' spacing={2}>
                    <Typography color="text.primary" variant="subtitle1">{index + 1}</Typography>
                    <Typography color="text.primary" variant="subtitle1">{question.question}</Typography>
                  </Stack>
                  }
                />
              </ListItem>
            </List>
          </Card>
        })}
        <Stack direction="row" sx={{ mt: 4 }}>
          {!isAddingQuestion &&
            <Button
              variant="text"
              startIcon={<AddIcon />}
              className="removeUppercase"
              onClick={() => toggleAddingQuestion()}
              sx={{ ml: 3 }}
            >
              Add a new field
            </Button>
          }
          <Button
            onClick={() => submitForm()}
            color='primary'
            variant="contained"
            size="medium"
            disableElevation
            className="removeUppercase"
            endIcon={<SaveOutlinedIcon />}
            sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
          >
            Save form
          </Button>
        </Stack>
      </>}
    </TabPanel >
  </>
  );
};

export default CreateCustomForm 