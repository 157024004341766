import { KeyboardArrowLeft } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HubDetailsContext } from '../../context/hub-details'

/**
 * If no props are passed, the button will route back to the Hubs home page by default.
 */
export const GlobalBackButton = ({ buttonLink, buttonCopy }) => {
  const hubContenxt = useContext(HubDetailsContext);
  const [homePageRoute, setHomePageRoute] = useState()

  useEffect(() => {
    setHomePageRoute(hubContenxt?.hubDetails?.home_page_url)
  }, [hubContenxt])

  return (
    <Button
      startIcon={<KeyboardArrowLeft />}
      className='removeUppercase'
      component={Link}
      sx={{ mb: 2 }}
      to={buttonLink ? buttonLink : homePageRoute}>
      {buttonCopy ? buttonCopy : 'Back to home page'}
    </Button>
  )
}