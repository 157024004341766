import React, { useEffect } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import CreateHubForm from '../../components/config/create-hub/create-hub-form';

const CreateHub = () => {

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Container maxWidth="md">
          <Paper sx={{ p: 4, pb: 12 }}>
            <Typography variant="h5" color="text.primary">
              Create New Environment Hub
            </Typography>
            <Divider style={{ margin: '20px 0px' }} />
            <CreateHubForm />
          </Paper>
        </Container>
      </Container>
    </>
  );
};

export default CreateHub