import { useEffect, useState } from 'react';
import PageContentSectionService from '../../services/page-content-section-service'
import { Box, Container, Grid, Toolbar, Typography } from '@mui/material';
import { HtmlParserDisplay } from '../../components/html-parser-display/html-parser-display';

export const OurOfferPage = () => {

  const pageContentService = new PageContentSectionService();
  const [pageContentSections, setPageContentSections] = useState([])
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    pageContentService.getContent(2)
      .then(response => {
        setPageContentSections(response)
      })
  }, [])

  return (<>
    <Toolbar />
    <Box
      sx={{
        height: '45vh',
        mt: 2,
        backgroundImage: `url('/cornwall/estuaries_simon_jay_photography.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              Our Offer
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              The pioneering match-making platform, connecting private buyers with natural capital projects
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Container maxWidth='lg' sx={{ marginTop: 2 }}>
      <Box sx={{ margin: 3 }}>
        {pageContentSections.length > 0 && imageLoaded && pageContentSections.map((section, index) => {
          return <Box key={index} sx={{ mb: 4, ml: 0, mr: 0 }} >
            <HtmlParserDisplay html={section.content}></HtmlParserDisplay>
          </Box>
        })}
        {pageContentSections.length > 0 && <Box sx={{ maxWidth: '100%', mb: 4, mt: 2, ml: 0, mr: 0 }}>
          <img
            src='/cornwall/linc-infographic-about-us.jpg'
            alt="infographic"
            maxWidth='100%'
            onLoad={() => setImageLoaded(true)}
          />
        </Box>}
      </Box>
    </Container>
  </>)
}