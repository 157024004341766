import axios from 'axios';
import httpClient from '../../shared/http/http-client';

export default class DemoHubService {
  http;

  constructor() {
    this.http = httpClient
  }

  async createDemoHub() {
    return this.http.post('demo/create-demo-hub', {})
      .then(response => {
        return response.data;
      })
  }

  async requestSubDomain() {
    return this.http.post('demo/request-domain', {})
      .then(response => {
        return response.data;
      })
  }

  async checkForCompletedDomainSetUp(organisationSlug) {
    axios.get(`https://demoapi.gaiacompany.io/health-check`)
      // return axios.get(`http://localhost:3001/health-check`)
      .then(response => {
        console.log('success')
        return response
      })
      .catch(err => {
        console.log('failure')
        throw err
      })
  }
}