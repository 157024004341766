import { useContext, useEffect, useState } from 'react';
import AdminStaticPageService from '../../../services/admin-static-page-service'
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { HtmlParserDisplay } from '../../../../shared/components/html-parser-display/html-parser-display';
import ReactQuill from 'react-quill';
import { UserInformationProviderContext } from '../../../../shared/context/user-information-provider';
import { GaiaTextField } from '../../../../shared/components/form-elements/text-field';
import { StaticPage } from '../../../../shared/pages/static-page';


export const StaticPageDetail = () => {

  const adminStaticPageService = new AdminStaticPageService();
  const params = useParams()
  const userInformationContext = useContext(UserInformationProviderContext);
  const navigate = useNavigate();

  const [page, setPage] = useState();
  const [sectionBeingEditedId, setSectionBeingEditedId] = useState();
  const [pageInformationBeingEdited, setPageInformationBeingEdited] = useState(false);
  const [editedContent, setEditedContent] = useState();
  const [isAddingNewSection, setIsAddingNewSection] = useState(false);
  const [viewingPreview, setViewingPreview] = useState(false);
  const [buttonId, setButtonId] = useState();

  const [pageInformation, setPageInformation] = useState({
    name: '',
    slug: '',
    banner_image_url: '',
    page_title: '',
    page_subtitle: '',
    hasBannerImage: false
  })

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  useEffect(() => {
    getPage();
  }, [])

  useEffect(() => {
    if (page) {
      adminStaticPageService.getHubButton(page.id, page.slug)
        .then(response => {
          if (response?.is_navbar_button) {
            setButtonId(response.id)
          } else {
            setButtonId(undefined);
          }
        })
    }
  }, [page])

  const getPage = (slug) => {
    adminStaticPageService.getPageDetail(slug ? slug : params.slug)
      .then(response => {
        setPage(response)
        setPageInformation({
          name: response.name,
          slug: response.slug,
          banner_image_url: response.banner_image_url,
          page_title: response.page_title,
          page_subtitle: response.page_subtitle,
          hasBannerImage: !!response.banner_image_url
        })
      })
  }

  const handlePageInformationUpdate = (formControl, value) => {
    if (formControl === 'slug') {
      value = value.toLowerCase();
    }

    if (formControl === 'hasBannerImage' && !value) {
      setPageInformation({
        ...pageInformation,
        banner_image_url: '',
        page_title: '',
        page_subtitle: '',
        [formControl]: value
      })
    } else {
      setPageInformation({ ...pageInformation, [formControl]: value })
    }
  }

  const handlePageInformationEdit = () => {
    setPageInformationBeingEdited(true);
  }

  const handleEdit = (id) => {
    setSectionBeingEditedId(id);
    setEditedContent(page.content_sections.find(section => section.id === id).content)
  }

  const cancelEdit = () => {
    setSectionBeingEditedId(undefined);
    setEditedContent(undefined);
    setIsAddingNewSection(false);
    setPageInformationBeingEdited(false);
    setPageInformation({
      name: page.name,
      slug: page.slug,
      banner_image_url: page.banner_image_url,
      page_title: page.page_title,
      page_subtitle: page.page_subtitle,
      hasBannerImage: !!page.banner_image_url
    })
  }

  const saveUpdatedSection = () => {
    adminStaticPageService.updateSection(sectionBeingEditedId, editedContent)
      .then(() => {
        getPage();
        setSectionBeingEditedId(undefined);
        setEditedContent(undefined);
        userInformationContext.openSnackbar({ open: true, message: 'Changes save successfully.', backgroundColor: 'green' });

      })
  }

  const createNewSection = () => {
    adminStaticPageService.createContentSection(page.id, { section_type: 'rich_text', content: editedContent })
      .then(() => {
        getPage();
        setSectionBeingEditedId(undefined);
        setEditedContent(undefined);
        setIsAddingNewSection(false);
      })
  }

  const deleteSection = (id) => {
    userInformationContext.openDialog({
      active: true,
      title: 'Delete this section?',
      message: 'Are you sure you would like to permanently delete this content?'
    })
      .then(response => {
        if (response) {
          adminStaticPageService.deleteSection(id)
            .then(() => {
              getPage();
              setSectionBeingEditedId(undefined);
              setEditedContent(undefined);
            })
        }
      })
  }

  const deletePage = () => {
    userInformationContext.openDialog({
      active: true,
      title: 'Delete this page?',
      message: 'Are you sure you would like to permanently delete this page and all its content? This action cannot be undone.'
    })
      .then(response => {
        if (response) {
          adminStaticPageService.deletePage(page.id)
            .then(() => {
              userInformationContext.openSnackbar({ open: true, message: 'Page deleted successfully.', backgroundColor: 'green' });
              navigate('/admin/configuration/static-pages');
            })
        }
      })
  }

  const togglePublished = () => {
    adminStaticPageService.togglePublished(page.id)
      .then(() => {
        if (page.published) {
          userInformationContext.openSnackbar({ open: true, message: 'Page unpublished.', backgroundColor: 'blue' });
        } else {
          userInformationContext.openSnackbar({ open: true, message: 'Page published successfully.', backgroundColor: 'green' });

        }
        getPage();
        setSectionBeingEditedId(undefined);
        setEditedContent(undefined);
      })
  }

  const updatePageInformation = () => {
    adminStaticPageService.updatePageInformation(page.id, pageInformation)
      .then(response => {
        setSectionBeingEditedId(undefined);
        setEditedContent(undefined);
        setPageInformationBeingEdited(false);
        userInformationContext.openSnackbar({ open: true, message: 'Changes save successfully.', backgroundColor: 'green' });
        navigate(`/admin/configuration/static-pages/${response.slug}`, { shallow: true });
        getPage(response.slug);
      })
      .catch(err => {
        userInformationContext.openDialog({
          active: true,
          title: 'Error',
          message: err.response.data.message,
          hideCancelButton: true,
          buttonCopy: 'Ok'
        })
      })
  }

  const handleUpdateDisplayOrder = (sectionId, index) => {
    adminStaticPageService.updateDisplayIndex(page.id, sectionId, index)
      .then(() => {
        userInformationContext.openSnackbar({ open: true, message: 'Display order updated.', backgroundColor: 'green' });
        getPage()
      })
  }

  const handleHubButtonToggle = () => {
    if (buttonId) {
      adminStaticPageService.deleteHubButton(page.id, buttonId)
        .then(response => {
          getPage();
          setSectionBeingEditedId(undefined);
          setEditedContent(undefined);
          userInformationContext.openSnackbar({ open: true, message: 'Button deleted.', backgroundColor: 'green' });

        })
    } else {

      userInformationContext.openDialog({
        active: true,
        title: 'Access this page from the Navigation Bar?',
        message: 'Please add the copy for the button below, and once this page is published the button will be added automatically.',
        buttonCopy: 'Create button',
        have_form_input: true
      })
        .then(response => {
          if (response.form_value) {
            adminStaticPageService.addHubButton(page.id, page.slug, response.form_value)
              .then(() => {
                getPage();
                setSectionBeingEditedId(undefined);
                setEditedContent(undefined);
                userInformationContext.openSnackbar({ open: true, message: 'Button added successfully.', backgroundColor: 'green' });
              })
          }
        })
    }
  }

  return (<Box p={2}>
    {page && viewingPreview && <>

      <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>

        <Button
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          color='info'
          sx={{ mr: 2 }}
          onClick={() => setViewingPreview(false)}
        >
          Back to edit
        </Button>
      </Stack>
      <Box sx={{ border: '1px solid grey' }}>
        <StaticPage previewContent={page} />
      </Box>

    </>}

    {page && !viewingPreview && <>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          {page.name}
        </Typography>

        {!isAddingNewSection && !pageInformationBeingEdited && <Stack direction={'row'}>

          {!page.published && <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            color='info'
            sx={{ mr: 2 }}
            onClick={() => handleHubButtonToggle()}
          >
            {buttonId ? 'Delete Button' : 'Add Button'}
          </Button>}

          {!page.published && <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            color='error'
            sx={{ mr: 2 }}
            onClick={() => deletePage()}
          >
            Delete Page
          </Button>}

          <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            sx={{ mr: 2 }}
            onClick={togglePublished}
          >
            {page.published ? 'Unpublish' : 'Publish'}
          </Button>
        </Stack>}


      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      {isAddingNewSection && <Box sx={{ mb: 10 }}>
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            sx={{ mr: 2 }}
            color='info'
            onClick={() => createNewSection()}
          >
            Save
          </Button>

          <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            color='error'
            onClick={cancelEdit}
          >
            Cancel
          </Button>
        </Stack>

        <FormControl fullWidth margin="normal" required>
          <InputLabel id="articleGroupLabel">Section type</InputLabel>
          <Select
            fullWidth
            className="formField"
            label="Section type"
            value={'rich_text'}
            placeholder="Select a format"
            disabled={true}
          // onChange={e => setArticleData('format', e.target.value)}
          >

            <MenuItem key={1} value={'rich_text'}>Rich text</MenuItem>

          </Select>
        </FormControl>

        <ReactQuill
          formats={formats}
          modules={modules}
          theme="snow"
          style={{ height: '400px', marginBottom: '24px' }}
          placeholder='Please enter the article content'
          value={editedContent || ''}
          onChange={e => setEditedContent(e)}
        />
      </Box>}

      {!isAddingNewSection && <Box sx={{ mb: 2 }}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>

          <Typography variant="p" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
            Page information:
          </Typography>
          <Stack direction={'row'} justifyContent={'flex-end'}>

            {!pageInformationBeingEdited && <Button
              variant="outlined"
              size="medium"
              disableElevation
              className="removeUppercase"
              color='info'
              sx={{ mr: 2 }}
              onClick={() => setViewingPreview(true)}
            >
              See page preview
            </Button>}

            {!pageInformationBeingEdited && <Button
              variant="outlined"
              size="medium"
              disableElevation
              className="removeUppercase"
              sx={{ mr: 2 }}
              color='info'
              onClick={() => handlePageInformationEdit()}
            >
              Edit page information
            </Button>}

            {pageInformationBeingEdited && <Button
              variant="outlined"
              size="medium"
              disableElevation
              className="removeUppercase"
              color='info'
              sx={{ mr: 2 }}
              onClick={() => updatePageInformation()}
            >
              Save
            </Button>}

            {pageInformationBeingEdited && <Button
              variant="outlined"
              size="medium"
              disableElevation
              className="removeUppercase"
              color='error'
              onClick={() => cancelEdit()}
            >
              Cancel
            </Button>}
          </Stack>
        </Stack>

        <GaiaTextField
          label="Page name"
          value={pageInformation.name}
          charchterCount={50}
          minRows={1}
          helperText={'This name is not shown to the user.'}
          disabled={!pageInformationBeingEdited}
          placeholder="Page name"
          formControlName='name'
          onChange={handlePageInformationUpdate}>
        </GaiaTextField>

        <GaiaTextField
          label="Page route"
          value={pageInformation.slug}
          charchterCount={250}
          minRows={1}
          helperText={'This value must be unique.'}
          disabled={!pageInformationBeingEdited}
          placeholder="Page route"
          formControlName='slug'
          onChange={handlePageInformationUpdate}>
        </GaiaTextField>

        <FormControlLabel
          control={
            <Checkbox
              variant="outlined"
              size="small"
              disabled={!pageInformationBeingEdited}
              // disabled={!props.isEditMode}
              onChange={e => handlePageInformationUpdate('hasBannerImage', e.target.checked)}
              checked={pageInformation.hasBannerImage || false}
              sx={{ justifySelf: 'flex-start' }} />
          }
          disabled={!pageInformationBeingEdited}
          label="Check this box to add an image overlay at the top of the page"
          sx={{ width: '100%', mb: '16px', pl: 0 }} />

        {pageInformation.hasBannerImage && <>

          <GaiaTextField
            label="Banner image URL"
            value={pageInformation.banner_image_url}
            charchterCount={2048}
            minRows={1}
            disabled={!pageInformationBeingEdited}
            placeholder="Banner image URL"
            formControlName='banner_image_url'
            onChange={handlePageInformationUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Overlay title"
            value={pageInformation.page_title}
            charchterCount={250}
            minRows={1}
            disabled={!pageInformationBeingEdited}
            placeholder="Overlay title"
            formControlName='page_title'
            onChange={handlePageInformationUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Overlay sub-title"
            value={pageInformation.page_subtitle}
            charchterCount={250}
            minRows={1}
            disabled={!pageInformationBeingEdited}
            placeholder="Overlay sub-title"
            formControlName='page_subtitle'
            onChange={handlePageInformationUpdate}>
          </GaiaTextField>
        </>}
      </Box>}

      {!isAddingNewSection && !pageInformationBeingEdited && <Divider sx={{ marginTop: 4, marginBottom: 4 }} />}

      {!isAddingNewSection && !pageInformationBeingEdited && <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 4 }}>
        <Typography variant="p" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Page content:
        </Typography>
        <Button
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          onClick={() => setIsAddingNewSection(true)}
        >
          Add new section
        </Button>
      </Stack>}

      {!isAddingNewSection && !pageInformationBeingEdited && page.content_sections.map((section, index) => {
        return <Box key={index}>
          {sectionBeingEditedId === section.id && <>
            <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>

              <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                sx={{ mr: 2 }}
                color='info'
                onClick={() => saveUpdatedSection()}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                color='error'
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </Stack>
            <Box sx={{ mb: 10 }}>
              <ReactQuill
                formats={formats}
                modules={modules}
                theme="snow"
                style={{ height: '400px', marginBottom: '24px' }}
                placeholder='Please enter the article content'
                value={editedContent || ''}
                onChange={e => setEditedContent(e)}
              />
            </Box>
          </>
          }

          {sectionBeingEditedId !== section.id && section.section_type === 'rich_text' && <Box key={`${index}-2`} >
            <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
              <FormControl size="small" sx={{ width: '150px' }}>
                <InputLabel id="user-type-label">Display order</InputLabel>
                <Select
                  disabled={page.content_sections.length < 2}
                  labelId="user-type-label"
                  id="user-type"
                  value={index + 1}
                  label="Display order"
                  sx={{ mr: 2 }}
                  onChange={(e) => handleUpdateDisplayOrder(section.id, e.target.value - 1)}
                >
                  {page.content_sections.map((section, rowIndex) => {
                    return <MenuItem key={rowIndex} value={rowIndex + 1}>{rowIndex + 1}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                sx={{ mr: 2 }}
                color='info'
                onClick={() => handleEdit(section.id)}
              >
                Edit
              </Button>

              <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                color='error'
                onClick={() => deleteSection(section.id)}
              >
                Delete
              </Button>
            </Stack>
            <Box sx={{ mb: 4, border: '1px solid black', p: 2 }}>
              <HtmlParserDisplay html={section.content}></HtmlParserDisplay>
            </Box>
          </Box>}
        </Box>

      })}
    </>}
  </Box >)

}