import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Alert, Box, Chip, Container, Grid, IconButton, Paper, Snackbar, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import IosShareIcon from '@mui/icons-material/IosShare';
import SellerInvestmentProjectService from "../services/seller-investment-project-service";
import PledgeProgressBar from '../components/pledge-progress-bar';
import { NumericFormat } from 'react-number-format';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { ProjectApprovalMessageContainer } from '../../shared/components/project-approval-message-container';
import InvestmentProjectBody from '../../shared/components/investment-project/investment-project-body';
import { ContactButton } from '../../shared/components/buttons/contact-button';

const InvestmentProjectDetails = () => {

  const sellerInvestmentProjectService = new SellerInvestmentProjectService();
  const hub = useContext(HubDetailsContext);

  const [shareAlertOpen, setShareAlertOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [baseUrl, setBaseUrl] = useState('');
  const [updatedProjectDetails, setUpdatedProjectDetails] = useState(projectDetails || []);


  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setBaseUrl(hub.hubDetails.base_url);
  }, [hub])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const shareLinkClick = () => {
    navigator.clipboard.writeText(`https://${baseUrl}/hub/invest/${projectDetails.id}`)
    setShareAlertOpen(true);
  }

  const getInvestmentProject = async () => {
    sellerInvestmentProjectService.getInvestmentProject(params.investmentProjectId)
      .then(response => {
        const dateAddedISO = new Date(response.created_at.replace(' ', 'T'));
        const dateAdded = dateAddedISO.toLocaleDateString('en-GB');
        const project = { ...response, dateAdded: dateAdded };
        setProjectDetails(project);
      })
  }

  useEffect(() => {
    window.analytics.page();
    getInvestmentProject();
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
        <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
          <Box sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(-1)}
              underline='hover'
              style={{ ml: '-7px', color: 'primary' }}
            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back
                </Typography>
              </Grid>
            </Link>
          </Box>
          <Tooltip title="Copy public link to clipboard">
            <IconButton aria-label="share" onClick={() => shareLinkClick()}>
              <IosShareIcon />
            </IconButton>
          </Tooltip>
          <Snackbar
            open={shareAlertOpen}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setShareAlertOpen(false)}
          >
            <Alert
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Public link copied to clipboard
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <InvestmentProjectBody
              projectDetails={projectDetails}
              setProjectDetails={setProjectDetails}
              updatedProjectDetails={updatedProjectDetails}
              setUpdatedProjectDetails={setUpdatedProjectDetails}
              getInvestmentProject={getInvestmentProject}
              function='seller'
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '20vh',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container>
                <Grid item xs={12} md={12} lg={4} sx={{ mb: 2 }}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    Summary
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={8} sx={{ mb: 2 }}>
                  <Box>
                    <Chip
                      label={projectDetails?.admin_status?.status_name}
                      variant="outlined"
                      sx={{
                        justifyContent: "flex-end",
                        ml: "auto",
                        float: "right",
                        color: projectDetails?.admin_status?.color || "primary",
                        borderColor: projectDetails?.admin_status?.color || "primary"
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <PledgeProgressBar value={projectDetails ? (projectDetails?.amount_pledged / projectDetails?.investment_target) * 100 : 0} />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ mt: 1 }}>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 600 }}>
                    <NumericFormat value={projectDetails?.amount_pledged} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="body2" color="text.secondary">
                    Raised of <NumericFormat value={projectDetails?.investment_target || 0} displayType={'text'} thousandSeparator={true} prefix={'£'} /> target
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {projectDetails?.investment_project_comments?.length > 0 &&
              <ProjectApprovalMessageContainer
                sectionTitle='Approval comment history'
                projectComments={projectDetails.investment_project_comments}
                adminStatusCode={projectDetails.admin_status.status_code}>
              </ProjectApprovalMessageContainer>
            }
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '20vh',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600, mb: 2 }}>
                Contact Us
              </Typography>
              {projectDetails?.admin_status?.status_code === 'PUBLISHED' &&
                <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>If you need to edit your project, tell us about funding you have received or update your project status, please contact us using the button below.</Typography>
              }
              {projectDetails?.admin_status?.status_code !== 'PUBLISHED' &&
                <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>If you are unsure of any of the questions, then please use the ‘Contact us’ button below and one of our project officers will be in touch.</Typography>
              }
              <ContactButton />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default InvestmentProjectDetails