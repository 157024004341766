
import React, { useState, createContext, useEffect } from 'react'
import { deepmerge } from '@mui/utils';
import gaiaTheme from '../../theme';
import { createTheme } from '@mui/material';
import httpClient from '../http/http-client';
import { Helmet } from 'react-helmet';
import HubButtonService from '../services/hub-buttons-service';

export const HubDetailsContext = createContext();

export const HubDetailsProvider = props => {
  const [hubDetails, setHubDetails] = useState(undefined)
  const [change, setChange] = useState(0);
  const [initialDetailsRetreived, setInitialDetailsRetreived] = useState(false);

  const location = window.location.host;

  const defaultFonts = ['EffraMd',
    'Effra2',
    'Effra',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'].join(',');

  useEffect(() => {
    getHubDetails()
  }, [change, location])

  useEffect(() => {
    if (!hubDetails && initialDetailsRetreived) {
      getHubDetails();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (hubDetails) {
      HubButtonService.getInstance();
    }
  }, [hubDetails])

  async function getHubDetails() {
    httpClient.get(`data/hub/${location}`)
      .then(response => {
        const defaultTypographyFonts = `${response.data.hub_styles?.font_name},${defaultFonts}`;
        const headingTypographyFonts = response.data.hub_styles?.font_name === 'Fira sans' ? `'Fira sans bold',${defaultFonts}` : defaultTypographyFonts;
        let hub = response.data
        hub.theme = deepmerge(gaiaTheme, createTheme({
          typography: {
            fontFamily: defaultTypographyFonts,
            h1: {
              fontFamily: headingTypographyFonts,
              '@media (max-width:600px)': {
                fontSize: '2rem',
              },
            },
            h2: {
              fontFamily: headingTypographyFonts
            },
            h3: {
              fontFamily: headingTypographyFonts,
              '@media (max-width:600px)': {
                fontSize: '2rem',
              },
            },
            h4: {
              fontFamily: headingTypographyFonts,
            },
            h5: {
              fontFamily: headingTypographyFonts,
            },
            h6: {
              fontFamily: headingTypographyFonts,
              fontWeight: '600'
            },
            subtitle1: {
              fontSize: '1rem'
            },
            subtitle2: {
              fontSize: '1rem'
            },
            button: {
              fontSize: '1rem'
            },
            p: {
              fontSize: '1rem'
            },
          },
          palette: {
            primary: {
              main: response.data.hub_styles?.primary_color,
              contrastText: "#fff"
            },
            secondary: {
              main: response.data.hub_styles?.secondary_color,
              contrastText: "#fff"
            },
            text: {
              primary: "#111E21"
            }
          },
          shape: {
            borderRadius: 5
          }
        }))

        sessionStorage.setItem('hub_id', response.data.id);
        setHubDetails(hub);
        setInitialDetailsRetreived(true);

      })
      .catch((err) => {
        console.log(err)
        sessionStorage.removeItem('hub_id');
        let hub = undefined;
        setHubDetails(hub);
        setInitialDetailsRetreived(true);
      })
  }

  function forceUpdateHubDetails() {
    return new Promise(function (resolve, reject) {
      httpClient.get(`data/hub/${location}`)
        .then(response => {
          sessionStorage.setItem('hub_id', response.data.id);
          setHubDetails(response.data);
          resolve(response.data.id);
        })
        .catch(() => {
          setHubDetails(undefined);
          sessionStorage.removeItem('hub_id');
          reject();
        })
    })
  }


  return (
    <HubDetailsContext.Provider value={{ hubDetails, change, setChange, forceUpdateHubDetails }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{hubDetails?.organisation_settings?.meta_title || 'Gaia'}</title>
        <meta name="description" content={hubDetails?.organisation_settings?.meta_description || 'Gaia is the UK’s leading marketplace for natural capital assets. We provide qualified, ready to purchase biodiversity units and other climate solutions for UK organisations who are looking to achieve both governmental and internal green targets.'} />
        {/* <link rel="icon" type="image/png" src="/gaia_icon_grey.png" sizes="16x16" /> */}
      </Helmet>
      {hubDetails && props.children}
    </HubDetailsContext.Provider>
  )
}