import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HubDetailsContext } from '../../../context/hub-details';

export const NavBarBrand = () => {
  const [pageReady, setPageReady] = useState(false);
  const [logoUrl, setLogoUrl] = useState("/gaia_logo.png");
  const [homeUrl, setHomeUrl] = useState("");
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    setLogoUrl(hub.hubDetails.hub_styles.logo_url);
    setHomeUrl(hub.hubDetails.home_page_url)
    setPageReady(true);
  }, [hub])

  return (<>
    {pageReady && (
      <div className="nav-bar__brand">
        <NavLink to={`${homeUrl}`}>
          <img
            className="nav-bar__logo"
            src={logoUrl ? logoUrl : "/gaia_logo.png"}
            alt="logo"
            //width="122"
            height="60"
            style={{ verticalAlign: 'middle' }}
          />
        </NavLink>
      </div>
    )}
  </>
  )
};
