import { useContext } from 'react'
import { HubDetailsContext } from '../context/hub-details'
import { Helmet } from 'react-helmet';

export const MetaTagProvider = ({ metaTitle, metaDescription, metaImage, removeHubOwnerName = false }) => {

  const hubContext = useContext(HubDetailsContext);

  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      {metaTitle && <title>{removeHubOwnerName ? '' : hubContext?.hubDetails?.owner_name}{removeHubOwnerName ? '' : ' | '}{metaTitle}</title>}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {hubContext?.hubDetails?.hubStyles?.logo_url && <meta name="og:image" content={hubContext?.hubDetails?.hubStyles?.logo_url} />}
    </Helmet>
  </>)
}