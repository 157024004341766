import React, { useContext, useEffect, useState } from "react";
import gaiaTheme from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { UserDetailsContext } from '../../shared/context/user-details';
import { Autocomplete, Button, Card, CardContent, Divider, InputAdornment, Paper, Slider, Stack } from '@mui/material';
import { CustomFilter } from '../../shared/components/custom-filter';
import InvestmentProjectService from '../services/investment-project-service';
import InvestmentProjectCard from '../../shared/components/investment-project/investment-project-card';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import LocationOn from '@mui/icons-material/LocationOn';
import Tune from '@mui/icons-material/Tune';
import ProjectMapCornwall from '../../shared/components/project-map-cornwall';
import HubCustomListService from '../../shared/services/hub-custom-list-service';
import { Link, useNavigate } from 'react-router-dom';
import { Add, Search } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const InvestInNature = (props) => {

  const hub = useContext(HubDetailsContext);
  const investmentProjectService = new InvestmentProjectService();
  const hubCustomListService = new HubCustomListService();
  const navigate = useNavigate();

  const [investmentProjects, setInvestmentProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  // new filters
  const [searchResultsCount, setSearchResultsCount] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState('/background-land.png');
  const [titleCopy, setTitleCopy] = useState('Invest in nature')
  const [bodyCopy, setBodyCopy] = useState('Invest in natural capital projects')
  const [isLoading, setIsLoading] = useState(true);
  const [filtersActive, setFiltersActive] = useState(true);
  const [rangeValue, setRangeValue] = React.useState([0, 100]);
  const [controlledAnswerIds, setControlledAnswerIds] = useState();
  const [comingSoonProjects, setComingSoonProjects] = useState([]);
  const [customRegionList, setCustomRegionList] = useState();
  const [selectedCustomRegion, setSelectedCustomRegion] = useState();
  const [filtersInitialised, setFiltersInitialised] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const user = useContext(UserDetailsContext);

  useEffect(() => {
    window.analytics.page();
    // getComingSoonProjects();
    setFiltersFromUrl()
  }, []);

  useEffect(() => {
    if (searchTerm?.length > 2 || searchTerm?.length === 0) {

      const delayDebounceFn = setTimeout(() => {
        investmentProjectService.searchProjects(searchTerm)
          .then(response => {
            setInvestmentProjects(undefined)
            if (response.investmentProjects?.length) {
              setInvestmentProjects(response.investmentProjects);
              console.log(response)
              setSearchResultsCount(response.count);
              setNextPageNumber(nextPageNumber + 1);
              if (nextPageNumber * pageSize > searchResultsCount) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
              setProjectsLoading(false);
            } else {
              // setActiveGroup(undefined)
              // setArticleGroups([])
              // setArticles([])
              // setNoArticles(true)
              setSearchResultsCount(0);
            }

          })
      }, 800)

      return () => clearTimeout(delayDebounceFn)

    }

  }, [searchTerm])

  const setFiltersFromUrl = (search) => {
    let params = new URLSearchParams(search ? search : window.location.search);
    for (const [key, value] of params.entries()) {
      if (key === 'region') {
        setSelectedCustomRegion(value)
      }

      if (key === 'rangeValue') {
        console.log(value.split(','))
        setRangeValue(value.split(',').map(v => parseInt(v)))
      }
    }

    setFiltersInitialised(true);
  }

  const amendUrlWithSearchParams = () => {
    let params = new URLSearchParams();
    if (selectedCustomRegion) {
      params.append('region', selectedCustomRegion)
    }

    if (rangeValue) {
      params.append('rangeValue', rangeValue.join())
    }

    // if (controlledAnswerIds) {
    //   console.log(controlledAnswerIds)
    //   params.append('customFilters', controlledAnswerIds.join())
    // }

    navigate(`${window.location.pathname}?${params.toString()}`, { replace: true })

  }

  const handleRange = (event, newValue) => {
    setNextPageNumber(0)
    setRangeValue(newValue);
  };

  useEffect(() => {
    if (nextPageNumber === 0 && filtersInitialised) {
      getInvestmentProjects(true);
    }
  }, [nextPageNumber, filtersInitialised]);

  useEffect(() => {
    setNextPageNumber(0)
  }, [controlledAnswerIds, selectedCustomRegion])

  useEffect(() => {
    hubCustomListService.getCustomRegionList(true)
      .then(response => {
        setCustomRegionList(response.data);
      })
  }, [])

  function valuetext(value) {
    return `£${value * 10000}`;
  }

  const marks = [
    {
      value: 0,
      label: '£0',
    },
    {
      value: 25,
      label: '£25k',
    },
    {
      value: 50,
      label: '£100k',
    },
    {
      value: 75,
      label: '£250k',
    },
    {
      value: 100,
      label: '£500k+',
    }
  ];

  useEffect(() => {
    investmentProjectService.getModuleStyle()
      .then(moduleStyle => {
        if (moduleStyle[0].banner_image_url) {
          setBannerImageUrl(moduleStyle[0]?.banner_image_url)
        } else {
          setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        }
        setTitleCopy(moduleStyle[0]?.title_copy ? moduleStyle[0].title_copy : "Invest in nature");
        setBodyCopy(moduleStyle[0]?.body_copy ? moduleStyle[0].body_copy : "Find projects for investment");
        setIsLoading(false);
      })
  }, [hub, user]);

  // get function

  const getInvestmentProjects = async (newSearch = false) => {
    setProjectsLoading(true);
    setInvestmentProjects(undefined)
    let params = new URLSearchParams();
    params.append('page', newSearch ? 0 : nextPageNumber);
    params.append('size', pageSize);
    params.append('max_price', rangeValue[1])
    params.append('min_price', rangeValue[0])

    if (controlledAnswerIds?.length) {
      params.append('controlled_answer_ids', controlledAnswerIds)
    }

    if (selectedCustomRegion) {
      params.append('custom_region_tag', selectedCustomRegion)
    }

    investmentProjectService.getProjects(params.toString())
      .then(response => {
        setInvestmentProjects(response.investmentProjects);
        setSearchResultsCount(response.count);
        setNextPageNumber(nextPageNumber + 1);
        if (nextPageNumber * pageSize > searchResultsCount) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setProjectsLoading(false);
        amendUrlWithSearchParams()
      })
      .catch(err => {
        console.log(err)
      })
  };

  const handleMapRegionClick = (region) => {
    window.scrollTo(0, 0);
    setSelectedCustomRegion(region);
    setActiveTab(0)
  }

  return (<>
    {!isLoading && <>
      <Toolbar />
      <Box
        sx={{
          minHeight: '45vh',
          backgroundImage: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          display: 'flex',
          alignItems: 'center',
          pb: 12
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Grid container sx={{ pl: 4, pr: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: hub?.hubDetails?.main_hub ? 'center' : 'left', fontWeight: 600, color: "common.white", textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}
              >
                {titleCopy}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: hub?.hubDetails?.main_hub ? 'center' : 'left', color: "common.white", textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}
              >
                {bodyCopy}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            border: '1px solid #a9a9a9',
            padding: '15px',
            mt: '-80px',
            ml: "24px",
            mr: "24px",
            p: 3
          }}
        >
          {/* {customRegionList && <Autocomplete
            disablePortal
            id="region"
            blurOnSelect={true}
            options={customRegionList}
            renderInput={(params) => <TextField {...params} label="Search projects..." />}
            value={selectedCustomRegion || ""}
            getOptionLabel={(option) => (option ? option.value ? option.value : option : "")}
            onChange={(e, option, reason) => {
              setSelectedCustomRegion(option?.value)
            }}
          />} */}
          <TextField
            id="hub-search"
            type="text"
            placeholder="Start typing to search..."
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ color: "#fff", backgroundColor: "#fff", borderRadius: '5px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={8} lg={8}></Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Stack direction='row' justifyContent={'space-around'}>
                {activeTab === 2 ? (
                  <Button
                    color="primary"
                    variant="link"
                    startIcon={<FormatListBulleted />}
                    onClick={() => setActiveTab(0)}
                    className="removeUppercase"
                  // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                  >
                    List View
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="link"
                    startIcon={<LocationOn />}
                    onClick={() => setActiveTab(2)}
                    className="removeUppercase"
                  // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                  >
                    Map View
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="link"
                  startIcon={<Tune />}
                  //onClick={() => toggleFilter()}
                  className="removeUppercase"
                // sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
                >
                  Filter & sort
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={3}>
            {filtersActive && <Grid item xs={12} md={4} lg={4}>
              <Card variant="outlined" sx={{ minHeight: isTablet ? 'auto' : 'calc(100vh - 110px)', borderColor: '#a9a9a9', borderRadius: '10px', position: 'sticky', top: '85px' }}>
                <CardContent>
                  <Box sx={{ padding: '24px 24px' }}>
                    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, mb: 4 }}>
                      Filters
                    </Typography>
                    <Box sx={{ mb: 4 }}>
                      {customRegionList && <Autocomplete
                        disablePortal
                        id="region"
                        options={customRegionList}
                        renderInput={(params) => <TextField {...params} label="Region" />}
                        value={selectedCustomRegion || ""}
                        getOptionLabel={(option) => (option ? option.value ? option.value : option : "")}
                        onChange={(e, option, reason) => {
                          setSelectedCustomRegion(option?.value)
                        }}
                      />}
                    </Box>
                    <Divider sx={{ mb: 4 }} />
                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, mb: 2 }}>
                      Funding Requirement
                    </Typography>
                    <Box sx={{ m: 1, mr: 3 }}>
                      <Slider
                        getAriaLabel={() => 'Price range'}
                        value={rangeValue}
                        onChange={handleRange}
                        valueLabelDisplay="off"
                        getAriaValueText={valuetext}
                        defaultValue={rangeValue}
                        marks={marks}
                        step={10}
                        sx={{ mb: 4 }}
                      />
                    </Box>
                    <Divider sx={{ mb: 4 }} />
                    <CustomFilter pageCode={'INVESTMENT_PROJECT'} setControlledAnswerIds={setControlledAnswerIds}></CustomFilter>
                  </Box>
                </CardContent>
              </Card>
            </Grid>}

            <Grid item xs={12} md={filtersActive ? 8 : 12} lg={filtersActive ? 8 : 12}>
              <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
                <Grid item xs={12} md={6} lg={9}>
                  <Button
                    variant='contained'
                    startIcon={<Add />}
                    component={Link}
                    to='/hub/site-enquiry/invest'
                    size="large"
                    disableElevation
                    className="removeUppercase"
                    color='primary'
                    sx={{ maxWidth: '240px', borderRadius: '30px' }}
                  >
                    Request a project
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl sx={{ m: 1, minWidth: 120, float: 'right', mr: 0 }} size="small">
                    <InputLabel id="sort-label">Sort</InputLabel>
                    <Select
                      labelId="sort-label"
                      id="sort"
                      value='Relevance'
                      label="Sort"
                    //onChange={handleChange}
                    >
                      <MenuItem value={'Relevance'}>Relevance</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {searchResultsCount === 0 && comingSoonProjects.length === 0 && (
                <Alert severity="info" sx={{ p: 2 }}>
                  <Stack direction={'column'}>
                    <Typography
                      variant="p"
                      color="text.primary"
                      sx={{ mb: 2 }} >
                      There are currently no listings that meet your search criteria.
                    </Typography>

                    <Typography
                      variant="p"
                      color="text.primary"
                      sx={{ mb: 1 }} >
                      You can make a request by clicking the button below.
                    </Typography>

                    <Button
                      variant='contained'
                      component={Link}
                      to='/hub/site-enquiry/invest'
                      size="medium"
                      disableElevation
                      className="removeUppercase"
                      color='info'
                      sx={{ maxWidth: '240px' }}
                    >
                      Make a request
                    </Button>
                  </Stack>
                </Alert>
              )}

              <Grid container spacing={3}>
                {investmentProjects && investmentProjects.map((project, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={filtersActive ? 6 : 4} key={index}>
                    {project && (
                      <InvestmentProjectCard
                        id={project.id}
                        title_text={project.title_text}
                        subtitle_text={project.subtitle_text}
                        description={project.description}
                        primaryImageURL={project.investment_project_images[0]?.location}
                        shallow_status={project.shallow_status}
                        un_goals={project.un_goals}
                        investment_target={project.investment_target}
                        isMobileMode={isMobileMode}
                        externalProjectUrl={project.external_project_url}
                        amountPledged={project.amount_pledged}
                        comingSoon={project.coming_soon}
                      />
                    )}
                  </Grid>
                ))}


              </Grid>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          <Grid container spacing={3} sx={{ pt: 8 }}>
            <Paper
              sx={{
                ml: '24px',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                borderColor: '#a9a9a9',
                p: 2,
                mt: -4
              }}
              variant="outlined"
            >
              <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, p: 1, pl: 2 }}>
                Interactive Map
              </Typography>

              <Typography variant="subtitle1" color="text.secondary" sx={{ pl: 2 }}>
                Use the map to find units in your region.
              </Typography>
              <ProjectMapCornwall handleMapRegionClick={handleMapRegionClick} />
            </Paper>
          </Grid>
        </TabPanel>
      </Container>
    </>}
  </>
  );
};

export default InvestInNature;