import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../../../../shared/context/hub-details';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import HubAdminService from '../../../services/hub-admin-service';

const FooterSettingsForm = () => {

  const hubAdminService = new HubAdminService();
  const hub = useContext(HubDetailsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [footerSettings, setFooterSettings] = useState({
    column_one_html: '',
    column_two_html: '',
    column_three_html: '',
  });

  useEffect(() => {
    // Set footer settings.
    if (hub.hubDetails.hub_footer) {
      const footerSettings = {
        column_one_html: hub.hubDetails.hub_footer.column_one_html,
        column_two_html: hub.hubDetails.hub_footer.column_two_html,
        column_three_html: hub.hubDetails.hub_footer.column_three_html,
      }

      setFooterSettings(footerSettings);
    }
  }, [hub])

  // Set the required branding config data.
  const setFooterSettingsData = (formControl, value) => {
    const settings = footerSettings;
    settings[formControl] = value;

    setFooterSettings({ ...settings });
  }

  const toggleEditing = () => {
    if (!isEditing) {
      setIsEditing(true)
    } else {
      hubAdminService.updateFooter(footerSettings)
        .then(() => {
          hub.setChange(hub.change + 1);
          setIsEditing(false);
        })
    }
  }

  return (<>
    {footerSettings && <>

      <Stack direction='row' sx={{ width: '100%', justifyContent: 'flex-end', mb: 1, pl: '56px', pr: '56px' }}>
        <Button
          variant={isEditing ? 'contained' : 'outlined'}
          disableElevation
          className="removeUppercase"
          startIcon={isEditing ? <SaveOutlinedIcon /> : <EditIcon />}
          onClick={() => toggleEditing()}
          sx={{ minWidth: '90px' }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Stack>

      <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px', mb: 4 }}>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Footer HTML:
        </Typography>

        <TextField
          minRows={10}
          maxRows={50}
          multiline
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Enter HTML...'
          disabled={!isEditing}
          onChange={e => setFooterSettingsData('column_one_html', e.target.value)}
          value={footerSettings.column_one_html || ''}
          sx={{ width: '100%' }}
        />

        {/* <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Column two html:
        </Typography>

        <TextField
          minRows={10}
          maxRows={50}
          multiline
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Enter HTML...'
          disabled={!isEditing}
          onChange={e => setFooterSettingsData('column_two_html', e.target.value)}
          value={footerSettings.column_two_html || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Column three html:
        </Typography>

        <TextField
          minRows={10}
          maxRows={50}
          multiline
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Enter HTML...'
          disabled={!isEditing}
          onChange={e => setFooterSettingsData('column_three_html', e.target.value)}
          value={footerSettings.column_three_html || ''}
          sx={{ width: '100%' }}
        /> */}

      </Grid>
    </>}
  </>)
}

export default FooterSettingsForm