import React from "react";
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Avatar, Button, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import { BuyerEnquiryButton } from './enquiry-button';

export default function EnquiryMessages(props) {
  const enquiry = props.enquiry;
  const project = props.project;
  const investmentProject = props.investmentProject;
  const userId = props.userId;
  const userRole = props.userRole;

  const navigate = useNavigate();

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const goToProject = () => {
    if (userRole === 'admin') {
      navigate(`/admin/${investmentProject ? 'investment-project' : 'bng-project'}/${investmentProject ? investmentProject.id : project.p_id}`)
    } else {
      if (investmentProject) {
        navigate(`${userRole === 'buyer' ? '/hub' : ''}/${userRole === 'buyer' ? 'invest' : 'investment-project'}/${investmentProject.id}`)

      } else {
        navigate(`${userRole === 'buyer' ? '/hub' : ''}/project${(userRole && userRole === 'buyer') ? ('s') : ('')}/${project && project.p_id}`)
      }
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '32px', pl: 1 }}>
            <b>{project && project.p_summary}</b>
          </Typography>
          <Typography variant="subtitle2" color="text.primary" sx={{ lineHeight: '32px', mb: 4, pl: 1 }}>
            {project && project.p_description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ alignItems: 'center' }}>
          <Stack
            direction='row'
            sx={{
              justifyContent: "flex-end",
              float: 'right',
              mb: 2
            }}
            spacing={1}
          >

            {(project || investmentProject) && userRole !== 'admin' && <BuyerEnquiryButton
              projectId={project ? project.p_id : investmentProject.id}
              enquiryId={enquiry.e_id}
              projectType={project ? 'BNG_PROJECT' : 'INVESTMENT_PROJECT'}
              onSuccess={() => props.onSuccess()}
              isReply={true}
              userRole={userRole}
            />}
            <Button
              variant="contained"
              color="primary"
              //size="large"
              disableElevation
              className='removeUppercase'
              onClick={() => goToProject()}
              sx={{
                justifyContent: "flex-end",
                float: 'right',

              }}
              startIcon={<OpenInNewIcon />}
            >
              View Listing
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {enquiry && userId && enquiry.enquiry_messages.sort((a, b) => a.id < b.id ? 1 : -1).map((message, index) => {
        console.log(message)
        const isAuthor = userRole === 'admin' ? message.author === enquiry.enquiry_messages[enquiry.enquiry_messages.length - 1].author : message.author === userId;
        return <Accordion
          key={index}
          expanded
          disableGutters
          elevation={0}
          sx={{
            border: '1px solid #0000001f',
            borderRadius: '5px',
            mb: 1,
            backgroundColor: (userRole && userRole === 'buyer') ? '#ffffff' : '#f6f6f6'
          }}
        >
          <AccordionSummary

            id="panel1bh-header"
            sx={{
              alignItems: 'center',
              borderBottom: '1px solid #0000001f',
              p: 0,
              pl: '12px',
            }}
          >
            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: isAuthor ? '#70942c' : '#0288d1' }}>{getInitials(message.author_details.name)}</Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={(message) && (<b>{message.author_details.name} {userRole === 'admin' ? enquiry.e_buyer_id === message.author ? '(Buyer)' : '(Seller)' : ''}</b>)}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Sent at:
                      </Typography>
                      {moment(message.created_at).format('DD/MM/yyyy HH:mm')}
                      {message.read_at &&
                        <Typography
                          sx={{ display: 'inline', ml: 2 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Read at:
                        </Typography>
                      }
                      {message.read_at && moment(message.read_at).format('DD/MM/yyyy HH:mm')}
                    </>
                  }
                />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '32px', p: 2, whiteSpace: 'pre-wrap' }}>
              {message.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      })}
    </>
  );
}
