import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'

const UnitProjectDataInputForm = ({ unitProject, setUnitProject }) => {


  return (
    <>
      {unitProject && <>
        {/* <FormControl sx={{ width: '100%', mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Please select...</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='unit_project_type'
            value={unitProject.unit_project_type || ''}
            label="Please select..."
            onChange={(e) => setUnitProject(e, false)}
          >
            <MenuItem key={0} value='carbon'>Carbon</MenuItem>
          </Select>
        </FormControl> */}

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Price per unit*</InputLabel>
          <OutlinedInput
            id="total-price"
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            fullWidth
            className="formField"
            name='price'
            label="Price per unit*"
            type="number"
            step="any"
            value={unitProject.price || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl sx={{ width: '100%', mb: 2 }}>
          <InputLabel htmlFor="total-price">Price unit*</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='price_unit'
            value={unitProject.price_unit || ''}
            label="Please select..."
            onChange={(e) => setUnitProject(e, false)}
          >
            <MenuItem key={0} value='tonne'>Tonne</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={<Checkbox
            checked={unitProject.hide_price || false}
            onChange={(e) => setUnitProject(e, true)}
            name='hide_price'
          />}
          label="Hide price from buyers"
          sx={{ color: "#00000099" }} />
      </>}
    </>
  )
}

export default UnitProjectDataInputForm