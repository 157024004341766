import httpClient from '../http/http-client';

export default class ProjectService {
  http;

  constructor() {
    this.http = httpClient
  }

  /**
   * Get all projects for buyers or sellers. Optionally provide query params.
   * @param {*} userRole - The current role the user is using. buyer OR seller.
   * @param {*} params - OPTIONAL. Query params.
   * @returns - A list of available projects.
   */
  async getProjects(userRole, params) {
    return this.http.get(`${userRole}/project${params ? '?' : ''}${params ? params : ''}`)
      .then(response => {
        return response.data
      });
  }

  async getRegionalPrices(regions) {
    return this.http.get(`hub/project/regional-prices?regions=${regions}`)
      .then(response => {
        return response.data
      });
  }

  /**
   * Create new project.
   * @param {*} projectDetials - The created project details.
   * @returns - A success or error message.
   */
  async createNewProject(form) {
    return this.http.post('seller/project', form)
      .then(response => {
        return response.data;
      })
  }

  async postBulkSearch(queries) {
    return this.http.post(`buyer/project/bulk-search`, queries)
      .then(response => {
        return response.data
      });
  }
}