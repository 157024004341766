import httpClient from '../http/http-client';
import React, { useEffect, useState } from "react";

export default class QuestionsService {
  http;
  lastQuestionForm;

  static instance = new QuestionsService();

  static getInstance() {
    if (QuestionsService.instance === null) QuestionsService.instance = new QuestionsService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient
  }

  async getQuestionsByAnswerFormId(answerFormId) {
    return this.http.get(`site-user/question/${answerFormId}`)
      .then(response => {
        this.lastQuestionForm = response.data.questions;
        console.log(this.lastQuestionForm.description)
        return response.data
      });
  }
}