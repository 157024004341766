import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { UserInformationProviderContext } from '../../context/user-information-provider';
import { GaiaTextField } from '../form-elements/text-field';

export const InfoDialog = () => {

  const infoDialogContext = useContext(UserInformationProviderContext);

  const [details, setDetails] = useState({ active: false, title: '', message: '', buttonCopy: '', cancelButtonCopy: '', hideCancelButton: false, have_form_input: false, form_value: '' })

  useEffect(() => {
    setDetails(infoDialogContext.dialogDetails)
  }, [infoDialogContext.dialogDetails]);

  const onCancel = () => {
    infoDialogContext.onUserResponse(false);
  };

  const onConfirm = () => {
    infoDialogContext.onUserResponse(true, details.form_value);
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={details.active}>
      <DialogTitle sx={{ p: 8, pb: 4 }}>
        <Typography variant="h5">
          {details.title}
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 8, pb: 6, pt: 0 }}>
        <Typography variant="p">
          {details.message}
        </Typography>

        {details.have_form_input && <GaiaTextField
          value={details.form_value}
          charchterCount={25}
          minRows={1}
          formControlName='form_value'
          emitValueOnly={true}
          onChange={(e) => setDetails({ ...details, form_value: e })}>
        </GaiaTextField>}

      </DialogContent>

      <DialogActions>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          {!details.hideCancelButton && <Button onClick={() => onCancel()} className="removeUppercase">{details.cancelButtonCopy || 'Cancel'}</Button>}
          <Button
            variant="contained"
            disableElevation
            disabled={details.have_form_input && !details.form_value}
            onClick={() => onConfirm()}
            className="removeUppercase"
          >
            {details.buttonCopy || 'Continue'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog >
  )
}