import React, { useEffect, useState, useContext } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Chip, List, ListItem, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import { ImageFullSizeDisplay } from '../../../shared/components/image-full-size-display';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../../../shared/components/icon-picker';
import AnswerFormDisplay from '../../../shared/components/answer-form-display';
import ProjectDetailSummary from '../../../shared/components/project-details-summary';
import { ProjectResourceUploadButton } from '../../../shared/components/project-resources/project-resource-upload-button';
import { ProjectResouceList } from '../../../shared/components/project-resources/project-resource-list';
import AdminProjectService from '../../services/admin-project-service';
import { BngProjectDisplayNotes } from '../../../shared/components/bng-project-display-notes';
import { HubDetailsContext } from '../../../shared/context/hub-details';


const AdminProjectDetailsBody = (props) => {

  const adminProjectService = new AdminProjectService();
  const hubContext = useContext(HubDetailsContext);

  const [projectDetails, setProjectDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [hubId, setHubId] = useState();

  useEffect(() => {
    if (hubContext.hubDetails) {
      setHubId(hubContext.hubDetails.id);
    }
  }, [hubContext.hubDetails])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddNewImages = () => {
    const form = new FormData();

    for (let i = 0; i < fileData.length; i++) {
      form.append("files", fileData[i]);
    }

    adminProjectService.uploadNewProjectImage(props.projectDetails.p_id, form)
      .then(() => {
        props.getProject();;
        setTimeout(() => {
          setFileData([]);
        }, 1000);
      })
  }

  const handleDeleteImage = (imageKey) => {
    adminProjectService.deleteProjectImage(props.projectDetails.p_id, imageKey)
      .then(() => {
        props.getProject();
      })
  }

  useEffect(() => {
    setProjectDetails(props.projectDetails);
    setIsLoading(false)
  }, [props.projectDetails])

  return (<>
    {projectDetails && <>
      <Paper
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          borderRadius: '10px'
        }}
        variant='outlined'
      >

        {projectDetails?.project_images && <ImageFullSizeDisplay
          images={projectDetails.project_images}
          canEdit={true}
          handleAddImage={handleAddNewImages}
          handleDeleteImage={handleDeleteImage}
          indicators={true}
          fileData={fileData}
          setFileData={setFileData}
        >
        </ImageFullSizeDisplay>}

        {projectDetails && <Stack direction={'column'} alignItems={'flex-end'} sx={{ width: '100%', mt: 2, pr: 4 }}>
          <Stack direction='row' sx={{ justifyContent: 'flex-end' }}>
            {projectDetails.resources?.length === 0 && <ProjectResourceUploadButton
              projectId={projectDetails.p_id}
              projectType={'BNG_PROJECT'}
              onSuccess={props.getProject}
              buttonCopy={'Upload brochure'}>
            </ProjectResourceUploadButton>}
          </Stack>

          {projectDetails.resources?.length > 0 && <>
            <ProjectResouceList
              resources={projectDetails.resources}
              onSuccess={props.getProject} />
          </>}
        </Stack>}

        <Box sx={{ p: 4 }}>

          {projectDetails?.admin_status?.status_code === 'AUTO_DECLINE' &&
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity="error" sx={{ mb: 4 }}>
                This project has been automatically declined by the system.
                <br></br>
                <ul>
                  {projectDetails.answer_forms.map(form => {
                    return form.answers.filter(answer => answer.decline_message).map(answer => {
                      return <li>{answer.decline_message}</li>
                    })
                  })}
                </ul>
              </Alert>
            </Grid>
          }

          <ProjectDetailSummary projectDetails={projectDetails} hubId={hubId} />

          <Divider sx={{ mt: 6, mb: 6 }} />

          <BngProjectDisplayNotes
            isAdmin={true}
            isSeller={false}
            project={projectDetails} />

          <Divider sx={{ mt: 6, mb: 6 }} />

          {projectDetails?.answer_forms?.length > 0 && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
            Information provided:
          </Typography>}

          {projectDetails?.answer_forms?.map((form, index) => {
            return <Accordion
              key={index}
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}>

              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ alignItems: 'center', justifyContent: 'center' }}

              >
                <List sx={{ p: 0 }}>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemIcon>
                      <IconPicker icon="form" color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={form.question_form?.form_name} />
                  </ListItem>
                </List>
              </AccordionSummary>
              <AccordionDetails>
                <AnswerFormDisplay key={index} suppliedAnswers={form.answers} />
              </AccordionDetails>
            </Accordion>
          })}
          {isLoading && (
            <>
              <Grid container spacing={2} sx={{ pl: '6px' }}>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant="h5"><Skeleton /></Typography>
                  <Typography variant="body"><Skeleton /></Typography>
                  <Typography variant="body"><Skeleton height={100} /></Typography>
                </Grid>
              </Grid>
              <Stack spacing={2} mt={2}>
                <Skeleton variant="rounded" height={80} />
                <Skeleton variant="rounded" height={200} />
                <Skeleton variant="rounded" height={200} />
              </Stack>
            </>
          )}
        </Box>
      </Paper>

    </>}
  </>
  );
};

export default AdminProjectDetailsBody 