import httpClient from "../http/http-client";

export default class HubCustomListService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getCustomRegionList(provideFallbackDefautRegions = false) {
    return httpClient.get(`hub/custom-list?list_type=custom_region`)
      .then(async response => {
        if (response.data.length > 0 || !provideFallbackDefautRegions) {
          return { data: response.data.sort((a, b) => a.value.localeCompare(b.value)), custom_list_retreived: true };
        } else {
          return { data: await this.getRegionList(), custom_list_retreived: false };
        }
      })
  }

  async getRegionList() {
    return httpClient.get(`hub/location/regions`)
      .then(response => {
        return response.data.regions.map(region => ({ value: region.region_name, group: 'Region', group_id: 5 })).sort((a, b) => a.value.localeCompare(b.value))
      })
  }
}