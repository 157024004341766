import httpClient from '../../shared/http/http-client';

export default class AdminStaticPageService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getPages() {
    return this.http.get(`admin/static-page`)
      .then(response => {
        return response.data
      });
  }

  async getPageDetail(pageSlug) {
    return this.http.get(`admin/static-page/page-detail/${pageSlug}`)
      .then(response => {
        return response.data
      });
  }

  async createNewPage(pageInformation) {
    return this.http.post(`admin/static-page/create`, pageInformation)
      .then(response => {
        return response.data
      });
  }

  async updateSection(sectionId, content) {
    return this.http.post(`admin/static-page/page-detail/update/${sectionId}`, { content: content })
      .then(response => {
        return response.data
      });
  }

  async createContentSection(pageId, content) {
    return this.http.post(`admin/static-page/${pageId}/page-detail/create`, content)
      .then(response => {
        return response.data
      });
  }

  async deleteSection(sectionId) {
    return this.http.delete(`admin/static-page/page-detail/delete/${sectionId}`)
      .then(response => {
        return response.data
      });
  }

  async togglePublished(pageId) {
    return this.http.post(`admin/static-page/toggle-published/${pageId}`)
      .then(response => {
        return response.data
      });
  }

  async updatePageInformation(pageId, information) {
    return this.http.post(`admin/static-page/${pageId}/update`, information)
      .then(response => {
        return response.data
      });
  }

  async deletePage(pageId) {
    return this.http.delete(`admin/static-page/${pageId}/delete`)
      .then(response => {
        return response.data
      });
  }

  async updateDisplayIndex(pageId, contentSectionId, displayIndex) {
    return this.http.post(`admin/static-page/${pageId}/page-detail/${contentSectionId}/update-display-index`, { display_index: displayIndex })
      .then(response => {
        return response.data
      });
  }

  async getHubButton(pageId, slug) {
    return this.http.get(`admin/static-page/${pageId}/get-hub-button?slug=${slug}`)
      .then(response => {
        return response.data
      });
  }

  async addHubButton(pageId, slug, title_copy) {
    return this.http.post(`admin/static-page/${pageId}/add-hub-button`, { title_copy: title_copy, url: slug })
      .then(response => {
        return response.data
      });
  }

  async deleteHubButton(pageId, buttonId) {
    return this.http.delete(`admin/static-page/${pageId}/delete-hub-button/${buttonId}`)
      .then(response => {
        return response.data
      });
  }


}