import { Alert, Box, Button, Card, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import AdminFormService from '../../../services/admin-form-service';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { EditOutlined } from '@mui/icons-material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { v4 as uuidv4 } from 'uuid';
import { UserInformationProviderContext } from '../../../../shared/context/user-information-provider';


const EditCustomForm = (props) => {

  const adminFormService = AdminFormService.instance;
  const navigate = useNavigate();
  const params = useParams();
  const userInformationContext = useContext(UserInformationProviderContext);

  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [formName, setFormName] = useState();
  const [question, setQuestion] = useState({
    question: undefined,
    additional_information: undefined,
    answer_required: true,
    question_type_id: undefined,
    show_in_public_listing: false,
    display_label: undefined
  });

  const [existingQuestions, setExistingQuestions] = useState([])
  const [errors, setErrors] = useState();
  const [formQuestions, setFormQuestions] = useState([]);
  const [questionTypes, setQuestionTypes] = useState();
  const [selectedQuestionType, setSelectedQuestionType] = useState();
  const [controlledAnswers, setControlledAnswers] = useState([]);
  const [maxAnswerCount, setMaxAnswerCount] = useState();
  const [questionBeingEdited, setQuestionBeingEdited] = useState();
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getQuestionTypes();
    getExistingForm();
  }, []);

  useEffect(() => {
    if (selectedQuestionType) {
      setMaxAnswerCount(questionTypes.find(type => type.question_type === selectedQuestionType)?.max_answer_count || 0);
    }
  }, [selectedQuestionType])

  const getQuestionTypes = () => {
    adminFormService.getAllQuestionTypes()
      .then(response => {
        setQuestionTypes(response)
      })
  }

  const getExistingForm = () => {
    adminFormService.getFormWithQuestions(params.formId)
      .then(response => {
        const formatted = response.informationQuestions.map((question, index) => {
          return { ...question, display_index: question.info.display_index, temp_global_identifier: uuidv4(), temp_question_type: 'new', not_updated: true }
        })
        const ordered = formatted.sort((a, b) => a.info.display_index - b.info.display_index)
        setFormQuestions(ordered.map((question, index) => {
          return { ...question, display_index: index }
        }))
        setFormName(response.form_name);
      })
  }

  const updateFormName = (event) => {
    setFormName(event.target.value)
  }

  const handleChange = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.value });
  }

  const handleUpdateControlledAnswer = (event, index) => {
    const updated = controlledAnswers;
    if (updated[index]) {
      updated[index].answer_text = event.target.value;
    } else {
      updated.push(event.target.value)
    }

    setControlledAnswers([...updated]);
  }

  const handleQuestionTypeChange = (event) => {
    setSelectedQuestionType(event.target.value);
    setControlledAnswers([]);

    const minAnswerCount = questionTypes.find(type => type.question_type === event.target.value).min_answer_count;
    const questionTypeId = questionTypes.find(type => type.question_type === event.target.value).id;

    setQuestion({ ...question, question_type_id: questionTypeId });

    for (let x = 0; x < minAnswerCount; x++) {
      addAnswerOption(event.target.value);
    }
  }

  const handleCheckbox = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.checked });
  }

  const handleSetAddingNewQuestion = () => {
    setIsAddingQuestion(true);
    setQuestion({
      question: undefined,
      additional_information: undefined,
      answer_required: true,
      question_type_id: undefined,
      show_in_public_listing: false,
      display_label: undefined,
      display_index: formQuestions.length + existingQuestions.length,
      temp_question_type: 'new',
      temp_global_identifier: uuidv4()
    })
  }

  const handleCancelAddingQuestion = () => {
    setSelectedQuestionType(null);
    setIsAddingQuestion(false);
    setQuestionBeingEdited();
  }

  /**
   * Check the question for errors and add it to the form object.
   */
  function addQuestionToForm() {
    const hasControlledAnswers = questionTypes.find(questionType => questionType.question_type === selectedQuestionType).has_controlled_answers;
    setErrors({})
    const isValid = hasControlledAnswers ? validateSelectTypeQuestion() : checkErrors();
    if (isValid) {
      const currentQuestion = question;
      if (hasControlledAnswers) {
        const filteredAnswers = controlledAnswers.filter(answer => answer.answer_text);
        currentQuestion.controlled_answers = filteredAnswers;
      }
      const updated = formQuestions;
      updated.push(currentQuestion);
      setFormQuestions(updated);
      setFormQuestions(formQuestions.filter((question, questionIndex) => question.temp_global_identifier !== questionBeingEdited?.temp_global_identifier));
      setQuestionBeingEdited();
      setQuestion({ question: undefined, additional_information: undefined, answer_required: true, question_type_id: undefined, show_in_public_listing: false, display_label: undefined });
      setControlledAnswers([]);
      setSelectedQuestionType();
      setIsAddingQuestion(false);
    }
  }

  const addAnswerOption = (questionType = selectedQuestionType) => {
    const maxAnswerCount = questionTypes.find(type => type.question_type === questionType).max_answer_count;
    if (controlledAnswers.length < maxAnswerCount) {
      const options = controlledAnswers;
      options.push({ answer_text: '', answer_additional_information: undefined });
      setControlledAnswers([...options]);
    }
  }

  /**
   * Remove a question from the form at a given index.
   * @param {*} i - The index of the question to remove.
   * @param type - 'existing' or 'new'.
   */
  const removeQuestion = (i, type) => {

    const formattedQuestions = formQuestions.filter((question, index) => question.temp_global_identifier !== i);
    setFormQuestions(formattedQuestions);
  }

  /**
   * Check the form for errors.
   * @returns - TRUE if NO ERRORS.
   */
  function checkErrors() {
    const tempErrors = {}
    if (!question.question && isAddingQuestion) {
      tempErrors.question = 'You must enter a question.'
    }
    if (!formName) {
      tempErrors.formName = 'You must give this form a name.'
    }

    setErrors(tempErrors)
    return (Object.keys(tempErrors).length === 0)
  }

  const validateSelectTypeQuestion = () => {

    const tempErrors = {};
    const filteredAnswers = controlledAnswers.filter(answer => answer.answer_text);
    const minAnswerCount = questionTypes.find(type => type.question_type === selectedQuestionType).min_answer_count;

    if (minAnswerCount > filteredAnswers.length) {
      tempErrors.controlledAnswersError = `You must add at least ${minAnswerCount} options for this type of question.`
    }

    if (!question.question && isAddingQuestion) {
      tempErrors.question = 'You must enter a question.'
    }

    setErrors(tempErrors)
    return (Object.keys(tempErrors).length === 0)
  }

  /**
   * Submit the completed form the the BE.
   */
  const submitForm = () => {
    const form = {
      form_name: formName,
      informationQuestions: formQuestions
    };

    adminFormService.editForm(params.formId, form, deletedQuestionIds)
      .then(response => {
        navigate(`/admin/custom-forms/${response.id}`)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // const editQuestion = (tempGlobalIdentifier, type) => {

  //   if (formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier)?.decline_rules.length > 0) {
  //     userInformationContext.openDialog({
  //       active: true,
  //       title: 'Editing this question will remove the decline rule(s) applied to it',
  //       message: 'If you continue with this action you will need to re-create the decline rules after you have saved the form.'
  //     })
  //       .then(response => {
  //         if (response) {
  //           setQuestion({
  //             id: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).id,
  //             question: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question,
  //             additional_information: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).additional_information,
  //             answer_required: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).answer_required,
  //             question_type_id: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question_type_id,
  //             show_in_public_listing: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).show_in_public_listing,
  //             display_label: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).display_label,
  //             display_index: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).display_index,
  //             temp_global_identifier: uuidv4(),
  //             temp_question_type: 'new',
  //             not_updated: false
  //           });

  //           setControlledAnswers(formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).controlled_answers);
  //           setSelectedQuestionType(questionTypes.find(type => type.id === formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question_type_id).question_type);

  //           setIsAddingQuestion(true);
  //           setQuestionBeingEdited({ temp_global_identifier: tempGlobalIdentifier, type: type });
  //         }
  //       })
  //   } else {
  //     setQuestion({
  //       id: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).id,
  //       question: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question,
  //       additional_information: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).additional_information,
  //       answer_required: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).answer_required,
  //       question_type_id: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question_type_id,
  //       show_in_public_listing: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).show_in_public_listing,
  //       display_label: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).display_label,
  //       display_index: formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).display_index,
  //       temp_global_identifier: uuidv4(),
  //       temp_question_type: 'new',
  //       not_updated: false
  //     });

  //     setControlledAnswers(formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).controlled_answers);
  //     setSelectedQuestionType(questionTypes.find(type => type.id === formQuestions.find(q => q.temp_global_identifier === tempGlobalIdentifier).question_type_id).question_type);

  //     setIsAddingQuestion(true);
  //     setQuestionBeingEdited({ temp_global_identifier: tempGlobalIdentifier, type: type });
  //   }
  // }

  const handleChangeQuestionOrder = (targetIndex, existingIndex, tempGlobalIdentifier) => {

    let updatedNew = formQuestions.map(question => {
      if (question.temp_global_identifier === tempGlobalIdentifier) {
        return { ...question, display_index: targetIndex }
      } else if (targetIndex < existingIndex && question.display_index >= targetIndex) {
        return { ...question, display_index: question.display_index + 1 }
      } else if (targetIndex > existingIndex && question.display_index <= targetIndex) {
        return { ...question, display_index: question.display_index - 1 }
      } else {
        return question;
      }
    })

    updatedNew = updatedNew.sort((a, b) => a.display_index - b.display_index).map((question, index) => {
      return {
        ...question,
        display_index: index
      }
    })

    setFormQuestions(updatedNew);
  }

  return (<Box sx={{ p: 2 }}>
    <Typography variant="h5" color="text.primary" sx={{ flex: 1, pb: 4 }}>
      Edit form
    </Typography>
    {isAddingQuestion && <>
      <FormControl sx={{ mb: 3 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Select field type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedQuestionType || ''}
          label="Select field type"
          onChange={handleQuestionTypeChange}
        >
          {questionTypes && questionTypes.map((questionType, index) => {
            return <MenuItem key={index} value={questionType.question_type}>{questionType.question_name}</MenuItem>
          })}
        </Select>
      </FormControl>

      {selectedQuestionType && <>
        {console.log(question)}
        <Box sx={{ display: 'flex' }}>
          <FormControlLabel
            control={
              <Checkbox
                name="answer_required"
                disabled={selectedQuestionType === 'SELECT' || selectedQuestionType === 'CONFIRM'}
                value={question.answer_required || false}
                onChange={handleCheckbox}
              />}
            label="An answer is required for this question"
            sx={{ width: '100%', mb: '16px' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="show_in_public_listing"
                value={question.show_in_public_listing || false}
                onChange={handleCheckbox}
              />}
            label="Show this question and answer in the public listing."
            sx={{ width: '100%', mb: '16px' }}
          />
        </Box>

        {errors && errors.question && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.question}</Alert>}
        <TextField
          id="outlined-multiline-static"
          label="Question"
          name="question"
          value={question.question || ''}
          //multiline
          onChange={handleChange}
          //rows={4}
          placeholder='Enter a question'
          sx={{ width: '100%', mb: '16px' }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="outlined-multiline-static"
          label="Display label"
          name="display_label"
          value={question.display_label || ''}
          // multiline
          onChange={handleChange}
          // rows={1}
          placeholder='If provided, will be used when displaying the answer to the public.'
          sx={{ width: '100%', mb: '16px' }}
        />


        <TextField
          id="outlined-multiline-static"
          label="Additional help text"
          name="additional_information"
          value={question.additional_information || ''}
          multiline
          onChange={handleChange}
          rows={4}
          placeholder='Optionally enter any additional informational text for the user...'
          sx={{ width: '100%', mb: '16px' }}
        />
      </>}

      {/* For question types with CONTROLLED_ANSWERS */}
      {selectedQuestionType && questionTypes.find(questionType => questionType.question_type === selectedQuestionType).has_controlled_answers && <>
        {maxAnswerCount > controlledAnswers.length && <Box sx={{ display: 'flex' }}>
          <Button
            onClick={() => addAnswerOption()}
            color='primary'
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            sx={{ float: "right", mr: 8, mt: 2, mb: 2 }}
          >
            Add another answer option
          </Button>
        </Box>}

        {errors && errors.controlledAnswersError && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.controlledAnswersError}</Alert>}

        {controlledAnswers && controlledAnswers.map((controlledAnswer, index) => {
          return <TextField key={index}
            id="outlined-multiline-static"
            label={`Answer option ${index + 1}`}
            name="controlledAnswer"
            value={controlledAnswer.answer_text || ''}
            onChange={(event) => handleUpdateControlledAnswer(event, index)}
            placeholder='Enter answer option...'
            sx={{ width: '100%', mb: '16px' }}
          />
        })}
      </>}

      {selectedQuestionType && <Stack direction='row'>
        <Button
          variant="outlined"
          className="removeUppercase"
          color="primary"
          disableElevation
          onClick={() => handleCancelAddingQuestion()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => addQuestionToForm()}
          className='removeUppercase'
          sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
        >
          Save question
        </Button>
      </Stack>}
    </>}
    {!isAddingQuestion && <>
      {[...formQuestions, ...existingQuestions].sort((a, b) => a.display_index - b.display_index).map((question, index) => {
        return <Card
          sx={{ alignItems: 'center', justifyContent: 'center', mb: 1 }}
          key={index}
        ><List sx={{ flex: 1 }} dense>
            <ListItem
              secondaryAction={<>
                {/* <IconButton
                  onClick={() => editQuestion(question.temp_global_identifier)}
                  color='info'
                  edge="end"
                  aria-label="edit"
                  sx={{
                    mr: 1
                  }}
                >
                  <EditOutlined />
                </IconButton> */}

                <IconButton
                  onClick={() => removeQuestion(question.temp_global_identifier)}
                  color='error'
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </>
              }
            >
              <ListItemText
                primary={<Stack direction='row' spacing={2} justifyContent={'space-between'} sx={{ pr: '12%' }}>
                  <Stack direction='row' spacing={2}>
                    <Typography color="text.primary" variant="subtitle1">{index + 1}</Typography>
                    <Typography color="text.primary" variant="subtitle1">{question.question}</Typography>
                  </Stack>

                  <FormControl sx={{ minWidth: '110px', width: '110px', height: '45px' }}>
                    <InputLabel id="demo-simple-select-label">Select order</InputLabel>
                    <Select
                      sx={{ maxHeight: '45px' }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select order"
                      value={index}
                      onChange={(e) => handleChangeQuestionOrder(e.target.value, index, question.temp_global_identifier)}
                    >
                      {[...formQuestions, ...existingQuestions].map((q, index) => {
                        return <MenuItem key={index} value={index}>{index + 1}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                }
              />
            </ListItem>
          </List>
        </Card>
      })}
      <Stack direction="row" sx={{ mt: 4 }}>
        {!isAddingQuestion &&
          <Button
            variant="text"
            startIcon={<AddIcon />}
            className="removeUppercase"
            onClick={() => handleSetAddingNewQuestion()}
            sx={{ ml: 3 }}
          >
            Add a new question
          </Button>
        }
        <Button
          onClick={() => submitForm()}
          color='primary'
          variant="contained"
          size="medium"
          disableElevation
          disabled={(formQuestions.length === 0 && deletedQuestionIds.length === 0) || (existingQuestions.length + formQuestions.length === 0)}
          className="removeUppercase"
          endIcon={<SaveOutlinedIcon />}
          sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
        >
          Save form
        </Button>
      </Stack>
    </>}
  </Box>
  );
};

export default EditCustomForm 