export const lpas = [
    "Adur",
    "Allerdale",
    "Amber Valley",
    "Arun",
    "Ashfield",
    "Ashford",
    "Aylesbury Vale",
    "Babergh",
    "Barking and Dagenham",
    "Barnet",
    "Barnsley",
    "Barrow-in-Furness",
    "Basildon",
    "Basingstoke and Deane",
    "Bassetlaw",
    "Bath and North East Somerset",
    "Bedford",
    "Bexley",
    "Birmingham",
    "Blaby",
    "Blackburn with Darwen",
    "Blackpool",
    "Bolsover",
    "Bolton",
    "Boston",
    "Bournemouth, Christchurch and Poole",
    "Bracknell Forest",
    "Bradford",
    "Braintree",
    "Breckland",
    "Brent",
    "Brentwood",
    "Brighton and Hove",
    "Bristol, City of",
    "Broadland",
    "Bromley",
    "Bromsgrove",
    "Broxbourne",
    "Broxtowe",
    "Buckinghamshire",
    "Burnley",
    "Bury",
    "Calderdale",
    "Cambridge",
    "Camden",
    "Cannock Chase",
    "Canterbury",
    "Carlisle",
    "Castle Point",
    "Central Bedfordshire",
    "Charnwood",
    "Chelmsford",
    "Cheltenham",
    "Cherwell",
    "Cheshire East",
    "Cheshire West and Chester",
    "Chesterfield",
    "Chichester",
    "Chiltern",
    "Chorley",
    "City of London",
    "Colchester",
    "Copeland",
    "Corby",
    "Cornwall",
    "Cotswold",
    "County Durham",
    "Coventry",
    "Craven",
    "Crawley",
    "Croydon",
    "Dacorum",
    "Darlington",
    "Dartford",
    "Dartmoor National Park",
    "Daventry",
    "Derby",
    "Derbyshire Dales",
    "Doncaster",
    "Dorset",
    "Dover",
    "Dudley",
    "Ealing",
    "East Cambridgeshire",
    "East Devon",
    "East Hampshire",
    "East Hertfordshire",
    "East Lindsey",
    "East Northamptonshire",
    "East Riding of Yorkshire",
    "East Staffordshire",
    "East Suffolk",
    "Eastbourne",
    "Eastleigh",
    "Ebbsfleet Development Corporation",
    "Eden",
    "Elmbridge",
    "Enfield",
    "Epping Forest",
    "Epsom and Ewell",
    "Erewash",
    "Exeter",
    "Exmoor National Park",
    "Fareham",
    "Fenland",
    "Folkestone and Hythe",
    "Forest of Dean",
    "Fylde",
    "Gateshead",
    "Gedling",
    "Gloucester",
    "Gosport",
    "Gravesham",
    "Great Yarmouth",
    "Greenwich",
    "Guildford",
    "Hackney",
    "Halton",
    "Hambleton",
    "Hammersmith and Fulham",
    "Harborough",
    "Haringey",
    "Harlow",
    "Harrogate",
    "Harrow",
    "Hart",
    "Hartlepool",
    "Hastings",
    "Havant",
    "Havering",
    "Herefordshire, County of",
    "Hertsmere",
    "High Peak",
    "Hillingdon",
    "Hinckley and Bosworth",
    "Horsham",
    "Hounslow",
    "Huntingdonshire",
    "Hyndburn",
    "Ipswich",
    "Isle of Wight",
    "Isles of Scilly",
    "Islington",
    "Kensington and Chelsea",
    "Kettering",
    "King's Lynn and West Norfolk",
    "Kingston upon Hull, City of",
    "Kingston upon Thames",
    "Kirklees",
    "Knowsley",
    "Lake District National Park",
    "Lambeth",
    "Lancaster",
    "Leeds",
    "Leicester",
    "Lewes",
    "Lewisham",
    "Lichfield",
    "Lincoln",
    "Liverpool",
    "London Legacy Development Corporation",
    "Luton",
    "Maidstone",
    "Maldon",
    "Malvern Hills",
    "Manchester",
    "Mansfield",
    "Medway",
    "Melton",
    "Mendip",
    "Merton",
    "Mid Devon",
    "Mid Suffolk",
    "Mid Sussex",
    "Middlesbrough",
    "Milton Keynes",
    "Mole Valley",
    "New Forest",
    "New Forest National Park",
    "Newark and Sherwood",
    "Newcastle upon Tyne",
    "Newcastle-under-Lyme",
    "Newham",
    "North Devon",
    "North East Derbyshire",
    "North East Lincolnshire",
    "North Hertfordshire",
    "North Kesteven",
    "North Lincolnshire",
    "North Norfolk",
    "North Northamptonshire",
    "North Somerset",
    "North Tyneside",
    "North Warwickshire",
    "North West Leicestershire",
    "North York Moors National Park",
    "Northampton",
    "Northumberland",
    "Northumberland National Park",
    "Norwich",
    "Nottingham",
    "Nuneaton and Bedworth",
    "Oadby and Wigston",
    "Old Oak and Park Royal Development Corporation",
    "Oldham",
    "Oxford",
    "Peak District National Park",
    "Pendle",
    "Peterborough",
    "Plymouth",
    "Portsmouth",
    "Preston",
    "Reading",
    "Redbridge",
    "Redcar and Cleveland",
    "Redditch",
    "Reigate and Banstead",
    "Ribble Valley",
    "Richmond upon Thames",
    "Richmondshire",
    "Rochdale",
    "Rochford",
    "Rossendale",
    "Rother",
    "Rotherham",
    "Rugby",
    "Runnymede",
    "Rushcliffe",
    "Rushmoor",
    "Rutland",
    "Ryedale",
    "Salford",
    "Sandwell",
    "Scarborough",
    "Sedgemoor",
    "Sefton",
    "Selby",
    "Sevenoaks",
    "Sheffield",
    "Shropshire",
    "Slough",
    "Solihull",
    "Somerset West and Taunton",
    "South Bucks",
    "South Cambridgeshire",
    "South Derbyshire",
    "South Downs National Park",
    "South Gloucestershire",
    "South Hams",
    "South Holland",
    "South Kesteven",
    "South Lakeland",
    "South Norfolk",
    "South Northamptonshire",
    "South Oxfordshire",
    "South Ribble",
    "South Somerset",
    "South Staffordshire",
    "South Tyneside",
    "Southampton",
    "Southend-on-Sea",
    "Southwark",
    "Spelthorne",
    "St Albans",
    "St. Helens",
    "Stafford",
    "Staffordshire Moorlands",
    "Stevenage",
    "Stockport",
    "Stockton-on-Tees",
    "Stoke-on-Trent",
    "Stratford-on-Avon",
    "Stroud",
    "Sunderland",
    "Surrey Heath",
    "Sutton",
    "Swale",
    "Swindon",
    "Tameside",
    "Tamworth",
    "Tandridge",
    "Teignbridge",
    "Telford and Wrekin",
    "Tendring",
    "Test Valley",
    "Tewkesbury",
    "Thanet",
    "The Broads Authority",
    "Three Rivers",
    "Thurrock",
    "Tonbridge and Malling",
    "Torbay",
    "Torridge",
    "Tower Hamlets",
    "Trafford",
    "Tunbridge Wells",
    "Uttlesford",
    "Vale of White Horse",
    "Wakefield",
    "Walsall",
    "Waltham Forest",
    "Wandsworth",
    "Warrington",
    "Warwick",
    "Watford",
    "Waverley",
    "Wealden",
    "Wellingborough",
    "Welwyn Hatfield",
    "West Berkshire",
    "West Devon",
    "West Lancashire",
    "West Lindsey",
    "West Northamptonshire",
    "West Oxfordshire",
    "West Suffolk",
    "Westminster",
    "Wigan",
    "Wiltshire",
    "Winchester",
    "Windsor and Maidenhead",
    "Wirral",
    "Woking",
    "Wokingham",
    "Wolverhampton",
    "Worcester",
    "Worthing",
    "Wychavon",
    "Wycombe",
    "Wyre Forest",
    "Wyre",
    "York",
    "Yorkshire Dales National Park"
]

export const lpasAutocomplete = [
    {label: "Adur"},
    {label: "Allerdale"},
    {label: "Amber Valley"},
    {label: "Arun"},
    {label: "Ashfield"},
    {label: "Ashford"},
    {label: "Aylesbury Vale"},
    {label: "Babergh"},
    {label: "Barking and Dagenham"},
    {label: "Barnet"},
    {label: "Barnsley"},
    {label: "Barrow-in-Furness"},
    {label: "Basildon"},
    {label: "Basingstoke and Deane"},
    {label: "Bassetlaw"},
    {label: "Bath and North East Somerset"},
    {label: "Bedford"},
    {label: "Bexley"},
    {label: "Birmingham"},
    {label: "Blaby"},
    {label: "Blackburn with Darwen"},
    {label: "Blackpool"},
    {label: "Bolsover"},
    {label: "Bolton"},
    {label: "Boston"},
    {label: "Bournemouth, Christchurch and Poole"},
    {label: "Bracknell Forest"},
    {label: "Bradford"},
    {label: "Braintree"},
    {label: "Breckland"},
    {label: "Brent"},
    {label: "Brentwood"},
    {label: "Brighton and Hove"},
    {label: "Bristol, City of"},
    {label: "Broadland"},
    {label: "Bromley"},
    {label: "Bromsgrove"},
    {label: "Broxbourne"},
    {label: "Broxtowe"},
    {label: "Buckinghamshire"},
    {label: "Burnley"},
    {label: "Bury"},
    {label: "Calderdale"},
    {label: "Cambridge"},
    {label: "Camden"},
    {label: "Cannock Chase"},
    {label: "Canterbury"},
    {label: "Carlisle"},
    {label: "Castle Point"},
    {label: "Central Bedfordshire"},
    {label: "Charnwood"},
    {label: "Chelmsford"},
    {label: "Cheltenham"},
    {label: "Cherwell"},
    {label: "Cheshire East"},
    {label: "Cheshire West and Chester"},
    {label: "Chesterfield"},
    {label: "Chichester"},
    {label: "Chiltern"},
    {label: "Chorley"},
    {label: "City of London"},
    {label: "Colchester"},
    {label: "Copeland"},
    {label: "Corby"},
    {label: "Cornwall"},
    {label: "Cotswold"},
    {label: "County Durham"},
    {label: "Coventry"},
    {label: "Craven"},
    {label: "Crawley"},
    {label: "Croydon"},
    {label: "Dacorum"},
    {label: "Darlington"},
    {label: "Dartford"},
    {label: "Dartmoor National Park"},
    {label: "Daventry"},
    {label: "Derby"},
    {label: "Derbyshire Dales"},
    {label: "Doncaster"},
    {label: "Dorset"},
    {label: "Dover"},
    {label: "Dudley"},
    {label: "Ealing"},
    {label: "East Cambridgeshire"},
    {label: "East Devon"},
    {label: "East Hampshire"},
    {label: "East Hertfordshire"},
    {label: "East Lindsey"},
    {label: "East Northamptonshire"},
    {label: "East Riding of Yorkshire"},
    {label: "East Staffordshire"},
    {label: "East Suffolk"},
    {label: "Eastbourne"},
    {label: "Eastleigh"},
    {label: "Ebbsfleet Development Corporation"},
    {label: "Eden"},
    {label: "Elmbridge"},
    {label: "Enfield"},
    {label: "Epping Forest"},
    {label: "Epsom and Ewell"},
    {label: "Erewash"},
    {label: "Exeter"},
    {label: "Exmoor National Park"},
    {label: "Fareham"},
    {label: "Fenland"},
    {label: "Folkestone and Hythe"},
    {label: "Forest of Dean"},
    {label: "Fylde"},
    {label: "Gateshead"},
    {label: "Gedling"},
    {label: "Gloucester"},
    {label: "Gosport"},
    {label: "Gravesham"},
    {label: "Great Yarmouth"},
    {label: "Greenwich"},
    {label: "Guildford"},
    {label: "Hackney"},
    {label: "Halton"},
    {label: "Hambleton"},
    {label: "Hammersmith and Fulham"},
    {label: "Harborough"},
    {label: "Haringey"},
    {label: "Harlow"},
    {label: "Harrogate"},
    {label: "Harrow"},
    {label: "Hart"},
    {label: "Hartlepool"},
    {label: "Hastings"},
    {label: "Havant"},
    {label: "Havering"},
    {label: "Herefordshire, County of"},
    {label: "Hertsmere"},
    {label: "High Peak"},
    {label: "Hillingdon"},
    {label: "Hinckley and Bosworth"},
    {label: "Horsham"},
    {label: "Hounslow"},
    {label: "Huntingdonshire"},
    {label: "Hyndburn"},
    {label: "Ipswich"},
    {label: "Isle of Wight"},
    {label: "Isles of Scilly"},
    {label: "Islington"},
    {label: "Kensington and Chelsea"},
    {label: "Kettering"},
    {label: "King's Lynn and West Norfolk"},
    {label: "Kingston upon Hull, City of"},
    {label: "Kingston upon Thames"},
    {label: "Kirklees"},
    {label: "Knowsley"},
    {label: "Lake District National Park"},
    {label: "Lambeth"},
    {label: "Lancaster"},
    {label: "Leeds"},
    {label: "Leicester"},
    {label: "Lewes"},
    {label: "Lewisham"},
    {label: "Lichfield"},
    {label: "Lincoln"},
    {label: "Liverpool"},
    {label: "London Legacy Development Corporation"},
    {label: "Luton"},
    {label: "Maidstone"},
    {label: "Maldon"},
    {label: "Malvern Hills"},
    {label: "Manchester"},
    {label: "Mansfield"},
    {label: "Medway"},
    {label: "Melton"},
    {label: "Mendip"},
    {label: "Merton"},
    {label: "Mid Devon"},
    {label: "Mid Suffolk"},
    {label: "Mid Sussex"},
    {label: "Middlesbrough"},
    {label: "Milton Keynes"},
    {label: "Mole Valley"},
    {label: "New Forest"},
    {label: "New Forest National Park"},
    {label: "Newark and Sherwood"},
    {label: "Newcastle upon Tyne"},
    {label: "Newcastle-under-Lyme"},
    {label: "Newham"},
    {label: "North Devon"},
    {label: "North East Derbyshire"},
    {label: "North East Lincolnshire"},
    {label: "North Hertfordshire"},
    {label: "North Kesteven"},
    {label: "North Lincolnshire"},
    {label: "North Norfolk"},
    {label: "North Northamptonshire"},
    {label: "North Somerset"},
    {label: "North Tyneside"},
    {label: "North Warwickshire"},
    {label: "North West Leicestershire"},
    {label: "North York Moors National Park"},
    {label: "Northampton"},
    {label: "Northumberland"},
    {label: "Northumberland National Park"},
    {label: "Norwich"},
    {label: "Nottingham"},
    {label: "Nuneaton and Bedworth"},
    {label: "Oadby and Wigston"},
    {label: "Old Oak and Park Royal Development Corporation"},
    {label: "Oldham"},
    {label: "Oxford"},
    {label: "Peak District National Park"},
    {label: "Pendle"},
    {label: "Peterborough"},
    {label: "Plymouth"},
    {label: "Portsmouth"},
    {label: "Preston"},
    {label: "Reading"},
    {label: "Redbridge"},
    {label: "Redcar and Cleveland"},
    {label: "Redditch"},
    {label: "Reigate and Banstead"},
    {label: "Ribble Valley"},
    {label: "Richmond upon Thames"},
    {label: "Richmondshire"},
    {label: "Rochdale"},
    {label: "Rochford"},
    {label: "Rossendale"},
    {label: "Rother"},
    {label: "Rotherham"},
    {label: "Rugby"},
    {label: "Runnymede"},
    {label: "Rushcliffe"},
    {label: "Rushmoor"},
    {label: "Rutland"},
    {label: "Ryedale"},
    {label: "Salford"},
    {label: "Sandwell"},
    {label: "Scarborough"},
    {label: "Sedgemoor"},
    {label: "Sefton"},
    {label: "Selby"},
    {label: "Sevenoaks"},
    {label: "Sheffield"},
    {label: "Shropshire"},
    {label: "Slough"},
    {label: "Solihull"},
    {label: "Somerset West and Taunton"},
    {label: "South Bucks"},
    {label: "South Cambridgeshire"},
    {label: "South Derbyshire"},
    {label: "South Downs National Park"},
    {label: "South Gloucestershire"},
    {label: "South Hams"},
    {label: "South Holland"},
    {label: "South Kesteven"},
    {label: "South Lakeland"},
    {label: "South Norfolk"},
    {label: "South Northamptonshire"},
    {label: "South Oxfordshire"},
    {label: "South Ribble"},
    {label: "South Somerset"},
    {label: "South Staffordshire"},
    {label: "South Tyneside"},
    {label: "Southampton"},
    {label: "Southend-on-Sea"},
    {label: "Southwark"},
    {label: "Spelthorne"},
    {label: "St Albans"},
    {label: "St. Helens"},
    {label: "Stafford"},
    {label: "Staffordshire Moorlands"},
    {label: "Stevenage"},
    {label: "Stockport"},
    {label: "Stockton-on-Tees"},
    {label: "Stoke-on-Trent"},
    {label: "Stratford-on-Avon"},
    {label: "Stroud"},
    {label: "Sunderland"},
    {label: "Surrey Heath"},
    {label: "Sutton"},
    {label: "Swale"},
    {label: "Swindon"},
    {label: "Tameside"},
    {label: "Tamworth"},
    {label: "Tandridge"},
    {label: "Teignbridge"},
    {label: "Telford and Wrekin"},
    {label: "Tendring"},
    {label: "Test Valley"},
    {label: "Tewkesbury"},
    {label: "Thanet"},
    {label: "The Broads Authority"},
    {label: "Three Rivers"},
    {label: "Thurrock"},
    {label: "Tonbridge and Malling"},
    {label: "Torbay"},
    {label: "Torridge"},
    {label: "Tower Hamlets"},
    {label: "Trafford"},
    {label: "Tunbridge Wells"},
    {label: "Uttlesford"},
    {label: "Vale of White Horse"},
    {label: "Wakefield"},
    {label: "Walsall"},
    {label: "Waltham Forest"},
    {label: "Wandsworth"},
    {label: "Warrington"},
    {label: "Warwick"},
    {label: "Watford"},
    {label: "Waverley"},
    {label: "Wealden"},
    {label: "Wellingborough"},
    {label: "Welwyn Hatfield"},
    {label: "West Berkshire"},
    {label: "West Devon"},
    {label: "West Lancashire"},
    {label: "West Lindsey"},
    {label: "West Northamptonshire"},
    {label: "West Oxfordshire"},
    {label: "West Suffolk"},
    {label: "Westminster"},
    {label: "Wigan"},
    {label: "Wiltshire"},
    {label: "Winchester"},
    {label: "Windsor and Maidenhead"},
    {label: "Wirral"},
    {label: "Woking"},
    {label: "Wokingham"},
    {label: "Wolverhampton"},
    {label: "Worcester"},
    {label: "Worthing"},
    {label: "Wychavon"},
    {label: "Wycombe"},
    {label: "Wyre Forest"},
    {label: "Wyre"},
    {label: "York"},
    {label: "Yorkshire Dales National Park"}
]