import httpClient from '../../shared/http/http-client';

export default class AdminFormFlowService {
  http;

  constructor() {
    this.http = httpClient
  }

  /**
   * Get all current flows
   */
  async getAllFormFlows() {
    return this.http.get('admin/form-flow')
      .then((response) => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
   * Get all current flows
   */
  async getFormFlow(formFlowId) {
    return this.http.get(`admin/form-flow/${formFlowId}`)
      .then((response) => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  async createFormFlow(flow) {
    return this.http.post('admin/form-flow', flow)
      .then((response) => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  /**
 * Publish a new form flow. Calling this unpublishes any existing forms for the same object and hub.
 * @returns - The published form object.
 */
  async publishFormFlow(flowId, formableObjectCode) {
    return this.http.post(`admin/form-flow/publish/${flowId}`, { formable_object_code: formableObjectCode })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Publish a new form. Calling this unpublishes any existing forms for the same object and hub.
   * @returns - The published form object.
   */
  async unPublishFormFlow(flowId) {
    return this.http.post(`admin/form-flow/unpublish/${flowId}`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }
}