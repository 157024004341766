import { Slide, Snackbar, SnackbarContent } from '@mui/material'
import { useContext, useEffect, useState } from 'react';
import { UserInformationProviderContext } from '../../context/user-information-provider';

export const InfoSnackbar = () => {

  const infoDialogContext = useContext(UserInformationProviderContext);

  const [snackbar, setSnackbar] = useState({ open: false, transition: '', message: '', duration: 2000, vertical: '', horizontal: '', backgroundColor: 'grey' });

  useEffect(() => {
    setSnackbar(infoDialogContext.snackbarDetails)
  }, [infoDialogContext.snackbarDetails]);

  const handleClose = (event, reason) => {
    infoDialogContext.closeSnackbar();
    return;
  }

  return (
    <Snackbar
      open={snackbar.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: snackbar.vertical || 'bottom', horizontal: snackbar.horizontal || 'left' }}
      TransitionComponent={snackbar.transition || Slide}

      key={Slide.name}
      autoHideDuration={snackbar.duration || 2000}
    >
      <SnackbarContent style={{
        backgroundColor: snackbar.backgroundColor,
        fontSize: '16px'
      }}
        message={snackbar.message}>
      </SnackbarContent>
    </Snackbar>
  )
}


