import { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, MobileStepper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import QuestionsService from '../services/questions-service';
import QuestionAnswerService from '../services/question-answer-service';
import { useNavigate } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { CustomTooltip } from './presentation/tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { UserInformationProviderContext } from '../context/user-information-provider';
import { GaiaTextField } from './form-elements/text-field';

/**
 * This component MUST either be supplied with an answerFormId, or an array of questions. If questions are provided
 * then OPTIONALLY previouslyProvidedAnswers can be supplied as well - in which case the form will be pre-filled.
 * 
 * The formatted answers will ALWAYS be passed to the formattedAnswers prop on successful validation. There is no requirement to use this value.
 * 
 * IF an answerFormId is provided, OPTIONALLY a forwardRoute can be provided as an absolute route, and the user will be forwarded to the route on successful 
 * save of the answers given.
 * @returns 
 */
export default function QuestionForm({
  answerFormId,
  questions,
  previouslyProvidedAnswers,
  formattedAnswers,
  forwardRoute,
  forceForwardRoute,
  buttonCopy,
  setFormDescription,
  haveProgressBar = false }) {

  const [formattedQuestions, setFormattedQuestions] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [description, setDescription] = useState();
  const [formName, setFormName] = useState();
  const [formDetails, setFormDetails] = useState();
  const [currentAnswerFormId, setCurrentAnswerFormId] = useState();
  const [locale, setLocale] = useState('en-GB');

  const questionService = QuestionsService.instance;
  const questionAnswerService = new QuestionAnswerService();
  const navigate = useNavigate();
  const infoDialog = useContext(UserInformationProviderContext)


  useEffect(() => {
    if (answerFormId) {
      getQuestions(answerFormId);
    } else {
      formatQuestionsToDisplay(questions, previouslyProvidedAnswers);
    }
  }, []);

  const formatQuestionsToDisplay = (receivedQuestions, previouslyProvidedAnswers) => {
    const formatted = receivedQuestions.map((question) => {
      let answerAlreadyConfirmed = false;
      let answerAlreadyGiven = undefined;
      let answerAlreadySelected = [];
      const answer = previouslyProvidedAnswers?.find(answer => answer.information_question_id === question.id);
      if (answer) {
        if (question.question_type.question_type === 'CONFIRM' && question.answer_text === 'CONFIRMED') {
          answerAlreadyConfirmed = true;
        } else if (question.controlled_answers?.length > 0) {
          const answerArray = answer.answer_text.split(';--;');
          question.controlled_answers.forEach(controlledAnswer => {
            if (answerArray.includes(controlledAnswer.answer_text)) {
              answerAlreadySelected.push(controlledAnswer.id);
            }
          })
        } else {
          answerAlreadyGiven = answer.answer_text;
        }
      }

      return {
        question: question.question,
        additional_information: question.additional_information,
        question_type: question.question_type,
        answer_required: question.answer_required,
        question_id: question.id,
        controlled_answers: question.controlled_answers,
        answer_given: answerAlreadyGiven,
        controlled_answers_selected: answerAlreadySelected,
        confirmed: answerAlreadyConfirmed,
        visibility_rules: question.visibility_rules
      }
    })
    // setFormattedQuestions(formatted);
    calculateQuestionVisibility(formatted);
  }

  // Calculate the visibility of the questions initially, and each time an answer is provided.
  const calculateQuestionVisibility = (formattedQuestions) => {
    const visibility = formattedQuestions.map(question => {
      if (question?.visibility_rules.length === 0) {
        return { ...question, visible: true };
      } else {
        let visibilityPassCount = 0;
        question?.visibility_rules.forEach(rule => {
          if (rule.evaluation_question_type === 'SELECT') {
            if (formattedQuestions.find(formattedQuestion => formattedQuestion.question_id === rule.evaluation_question_id).controlled_answers_selected.includes(rule.required_selected_answers[0])) {
              visibilityPassCount++;
            }
          } else if (rule.evaluation_question_type === 'CONFIRM') {
            if (formattedQuestions.find(formattedQuestion => formattedQuestion.question_id === rule.evaluation_question_id).confirmed) {
              visibilityPassCount++;
            }
          }
        })

        if (visibilityPassCount === question.visibility_rules.length) {
          return { ...question, visible: true };
        } else {
          return { ...question, visible: false };
        }
      }
    })
    setFormattedQuestions(visibility);
  }

  const getQuestions = (answerFormId) => {

    questionService.getQuestionsByAnswerFormId(answerFormId)
      .then(response => {
        setCurrentAnswerFormId(answerFormId)
        setSubmitted(false);
        formatQuestionsToDisplay(
          response.questions.informationQuestions.sort((a, b) => a.info.display_index - b.info.display_index),
          response.previously_provided_answers
        );
        if (setFormDescription) {
          setFormDescription(response.questions?.description)
        }
        setDescription(response.questions.description);
        setFormName(response.questions.form_name);
        setFormDetails(response.form_details)
      })
  }

  const saveAnswers = (answers) => {
    questionAnswerService.saveAnswers(currentAnswerFormId, answers)
      .then(response => {
        setFormattedQuestions()
        if (forceForwardRoute) {
          navigate(forceForwardRoute);
        } else if (forwardRoute) {
          if (response.next_answer_form_id && !response.declined) {
            infoDialog.openSnackbar({ open: true, message: 'Answers saved successfully.', backgroundColor: 'green' });
            getQuestions(response.next_answer_form_id);
            window.scrollTo(0, 0);
          } else {
            navigate(forwardRoute);
          }

        } else {
          formattedAnswers(formattedQuestions);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const handleChange = (formControl, value) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === formControl);
    updatedQuestion.answer_given = value;
    updated[formControl] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
  };

  const handleDate = (questionId, event) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === questionId);
    updatedQuestion.answer_given = event;
    updated[questionId] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
  };

  const handleCheckbox = (event) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === event.target.name);
    updatedQuestion.confirmed = event.target.checked;
    updated[event.target.name] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
  };

  const handleSelectControlledAnswer = (event) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === event.target.name);
    updatedQuestion.controlled_answers_selected = [event.target.value.id];
    updated[event.target.name] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
  }

  const handleMultiCheckboxControlledAnswer = (event, answerId) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === event.target.name);
    if (updatedQuestion.controlled_answers_selected.includes(answerId)) {
      updatedQuestion.controlled_answers_selected = updatedQuestion.controlled_answers_selected.filter(id => id !== answerId);
    } else {
      updatedQuestion.controlled_answers_selected.push(answerId);
    }
    updated[event.target.name] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
  }

  const handleRadioControlledAnswer = (event, answerId) => {
    const updated = formattedQuestions;
    const updatedQuestion = updated.find((question, index) => index.toString() === event.target.name);
    updatedQuestion.controlled_answers_selected = [answerId];
    updated[event.target.name] = updatedQuestion;
    // setFormattedQuestions([...updated]);
    calculateQuestionVisibility([...updated])
    console.log(updated)
  }

  const submitForm = () => {
    if (answersValid()) {
      if (answerFormId) {
        saveAnswers(formattedQuestions);
      } else {
        formattedAnswers(formattedQuestions);
      }
    }
  }

  const answersValid = () => {
    setSubmitted(true);
    return formattedQuestions.every(
      question => !question.visible ||
        !question.answer_required ||
        question.answer_given ||
        question.controlled_answers_selected?.length > 0
        || question.confirmed);
  }

  const handleBack = () => {
    setFormattedQuestions()
    getQuestions(formDetails.previous_answer_form_id);
  }

  return (<>
    {formDetails?.form_count && formDetails?.current_index !== null && haveProgressBar && <>
      {/* {formDetails?.previous_answer_form_id && <Button
        startIcon={<KeyboardArrowLeft />}
        className='removeUppercase'
        onClick={handleBack}
        sx={{ mb: 2, padding: 0 }}>
        Back
      </Button>} */}
      <Grid item xs={12} md={12} lg={12} >
        <Typography variant="h6">
          {formDetails?.current_index + 1} of {formDetails?.form_count}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
        <MobileStepper
          variant="progress"
          steps={formDetails?.form_count}
          position="static"
          activeStep={formDetails?.current_index}
          className="fullWidthStepper"
          sx={{ flexGrow: 1, pl: 0, width: '100%' }}
        />
      </Grid></>}

    {formName && <Typography
      color="text.primary"
      variant="h5"
      sx={{ fontWeight: 300, mb: description ? 2 : 4 }}>
      {formName}
    </Typography>}

    {/* {description && <Typography
      color="text.secondary"
      variant="subtitle1"
      sx={{ fontWeight: 300, mb: 4, whiteSpace: 'pre-wrap' }}>
      {description}
    </Typography>} */}

    {formattedQuestions?.length > 0 && formattedQuestions.filter(question => question.visible).map((question, index) => {
      return <div key={index}>

        {question.question_type.question_type !== 'CONFIRM' && <>
          <Box>
            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
              <Typography
                color="text.primary"
                variant="h6"
                sx={{ fontWeight: 200, display: 'inline', mb: '16px' }}>
                {question.question}{question.answer_required ? '*' : ''}
              </Typography>
              {(question.additional_information) && <CustomTooltip content={question.additional_information} placement='right'>
                <HelpOutlineIcon sx={{ color: '#00000099' }} />
              </CustomTooltip>}
            </Stack>
            {question.show_in_public_listing && <Typography color="text.primary" variant="p" sx={{ fontSize: 12, fontWeight: 100, display: 'inline' }}>(This answer will be displayed in the public project listing)</Typography>}
          </Box>

          {/* {(question.additional_information) && <Box>     
            <Typography
              color="text.secondary"
              variant="subtitle2"
              sx={{ fontWeight: 200, display: 'inline' }}>
              {question.additional_information}
            </Typography>
          </Box>} */}

        </>}

        {/* INPUT type question */}
        {(question.question_type.question_type === 'INPUT' || question.question_type.question_type === 'TEXT_AREA') && <Box key={index} sx={{ p: '16px 0px' }}>
          {(submitted && question.answer_required && (!question.answer_given || question.answer_given === '')) && <Alert severity='error' sx={{ mb: '16px' }}>Please answer this question.</Alert>}

          {/* <TextField
            id="outlined-multiline-static"
            name={index.toString()}
            multiline
            value={question.answer_given}
            onChange={handleChange}
            rows={question.question_type.question_type === 'INPUT' ? 1 : 4}
            maxRows={question.question_type.question_type === 'INPUT' ? 1 : 10}
            sx={{ width: '100%', mb: '16px' }}
          /> */}

          <GaiaTextField
            value={question.answer_given}
            minRows={question.question_type.question_type === 'INPUT' ? 1 : 4}
            maxRows={question.question_type.question_type === 'INPUT' ? 4 : 20}
            charchterCount={question.question_type.question_type === 'INPUT' ? 4000 : 10000}
            formControlName={index.toString()}
            onChange={handleChange}>
          </GaiaTextField>
        </Box>}


        {/* SELECT type question */}
        {question.question_type.question_type === 'SELECT' && <Box key={index} sx={{ p: '16px 0px' }}>
          {(submitted && question.answer_required && (question.controlled_answers_selected?.length === 0)) && <Alert severity='error' sx={{ mb: '16px' }}>Please answer this question.</Alert>}

          <FormControl sx={{ width: '100%', mb: '24px' }}>
            <InputLabel id="demo-simple-select-label">Please select...</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name={index.toString()}
              value={question.controlled_answers.find(controlledAnswer => controlledAnswer.id === question.controlled_answers_selected[0]) || ''}
              label="Please select..."
              onChange={handleSelectControlledAnswer}
            >
              {question.controlled_answers && question.controlled_answers.map((answer, index) => {
                return <MenuItem key={index} value={answer}>{answer.answer_text}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>}


        {/* CONFIRM type question */}
        {question.question_type.question_type === 'CONFIRM' && <Box key={index} sx={{ p: '16px 0px' }}>

          {(submitted && question.answer_required && (!question.confirmed)) && <Alert severity='error' sx={{ mb: '16px' }}>You must confirm this statement to continue.</Alert>}

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {/* <Typography color="text.primary" variant="h6" sx={{ fontWeight: 200, mr: '16px' }}>{index + 1}:</Typography> */}

            <FormControlLabel
              control={
                <Checkbox
                  name={index.toString()}
                  value={question.confirmed}
                  checked={question.confirmed}
                  onChange={handleCheckbox}
                />}
              label={question.question}
              sx={{ width: '100%' }}
            />
          </Box>


          {/* {question.additional_information && <Box sx={{ mb: '16px' }}>
            <Typography
              color="text.primary"
              variant="p"
              sx={{ fontWeight: 200, display: 'inline', ml: '8px', mb: '16px' }}>
              {question.additional_information}
            </Typography>
          </Box>} */}
        </Box>}


        {/* MULTI_CHECKBOX type question */}
        {question.question_type.question_type === 'MULTI_CHECKBOX' && <Box key={index} sx={{ p: '16px 0px' }}>
          {(submitted && question.answer_required && (question.controlled_answers_selected?.length === 0)) && <Alert severity='error' sx={{ mb: '16px' }}>Please select at least one answer to continue.</Alert>}
          <Grid container>
            {question.controlled_answers && question.controlled_answers.map((answer, answerIndex) => {
              return <Grid item xs={12} md={6} lg={6} key={answerIndex}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={index.toString()}
                      // value={}
                      checked={question.controlled_answers_selected.includes(answer.id) || false}
                      onChange={(event) => handleMultiCheckboxControlledAnswer(event, answer.id)}
                    />}
                  label={answer.answer_text}
                  sx={{ width: '100%', mb: '8px' }}
                />
              </Grid>
            })}
          </Grid>
        </Box>}

        {/* RADIO_GROUP type question */}
        {question.question_type.question_type === 'RADIO_GROUP' && <Box key={index} sx={{ p: '16px 0px' }}>
          {(submitted && question.answer_required && (question.controlled_answers_selected?.length === 0)) && <Alert severity='error' sx={{ mb: '16px' }}>Please select one answer to continue.</Alert>}
          <Grid container>
            <RadioGroup
              sx={{ width: '100%', display: 'block' }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group">
              {question.controlled_answers && question.controlled_answers.map((answer, answerIndex) => {
                return <Grid item xs={12} md={12} lg={12} key={answerIndex} sx={{ width: '100%' }} >
                  <FormControlLabel
                    // value={answer.answer_text}
                    checked={question.controlled_answers_selected.includes(answer.id) || false}
                    name={index.toString()}
                    control={<Radio />}
                    label={answer.answer_text}
                    onChange={(event) => handleRadioControlledAnswer(event, answer.id)} />
                </Grid>
              })}
            </RadioGroup>
          </Grid>
        </Box>}

        {/* DATE_PICKER type question */}
        {question.question_type.question_type === 'DATE_PICKER' && <Box key={index} sx={{ p: '16px 0px' }}>
          {(submitted && question.answer_required && (!question.answer_given || question.answer_given === '')) && <Alert severity='error' sx={{ mb: '16px' }}>Please select a date below to continue.</Alert>}
          <Grid container>
            <FormControl fullWidth margin='normal' sx={{ mb: 4 }}>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                {question.answer_given && <DatePicker
                  // minDate={moment(new Date())}
                  label="Investment Target Date"
                  name={index.toString()}
                  emptyLabel="DD/MM/YYYY"
                  value={moment(question.answer_given)}
                  onChange={value => handleDate(index.toString(), value)}
                />}

                {!question.answer_given && <DatePicker
                  // minDate={moment(new Date())}
                  name={index.toString()}
                  label="Investment Target Date"
                  emptyLabel="DD/MM/YYYY"
                  onChange={value => handleDate(index.toString(), value)}
                />}
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Box>}




      </div>
    }
    )}

    {formattedQuestions?.length > 0 && <>
      <Stack direction='row-reverse' justifyContent='space-between'>

        <Button
          onClick={() => submitForm()}
          color='primary'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<CheckCircleOutlineIcon />}
        >
          {buttonCopy ? buttonCopy : formDetails?.next_answer_form_id ? 'Save & Continue' : 'Save answers'}
        </Button>

        {formDetails?.previous_answer_form_id && <Button
          onClick={handleBack}
          color='primary'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<KeyboardArrowLeft />}
        >
          Back
        </Button>}
      </Stack>
    </>}
  </>);
};