import React, { useEffect, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { lpas } from '../../data/lpas';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import TollIcon from '@mui/icons-material/Toll';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalculatorService from '../../services/calculator-service';
import { NumbersOutlined } from '@mui/icons-material';


const Calculator = () => {
  const [locationField, setLocationField] = useState('');
  const [location, setLocation] = useState('');
  const [unitField, setUnitField] = useState('');
  const [units, setUnits] = useState();
  const [averageCost, setAverageCost] = useState(30000);
  const [unitsAvailableOnGaia, setUnitsAvailableOnGaia] = useState()
  const [showResults, setShowResults] = useState(false);
  const [averageNeighbourCost, setAverageNeighbourCost] = useState();
  const [neighbouringUnitsAvailableOnGaia, setNeighbouringUnitsAvailableOnGaia] = useState();
  const [averageNationalCost, setAverageNationalCost] = useState();
  const [nationalUnitsAvailableOnGaia, setNationalUnitsAvailableOnGaia] = useState()

  const navigate = useNavigate();
  const calculatorService = new CalculatorService();

  const submitCalculate = async (e) => {
    e.preventDefault();
    setUnits(unitField);
    setLocation(locationField);
    getAverageCost(locationField);
    setShowResults(true);
    segmentTrack('Calculator Submit');
  }

  const handleViewListingClick = () => {
    segmentTrack('Calculator used');
    navigate({
      pathname: "/hub/marketplace",
      search: createSearchParams({
        lpa: locationField,
        show_neighbour_lpas: true,
        show_neighbour_ncas: true
      }).toString()
    })
  }

  const segmentTrack = (category) => {
    window.analytics.track('Clicked Action', {
      category: category
    })
  }

  // get projects

  const getAverageCost = async (LPA) => {
    calculatorService.getLpaData(LPA)
      .then(response => {
        setAverageCost(response.current_lpa.average_unit_cost);
        setUnitsAvailableOnGaia(response.current_lpa.units_available);
        setAverageNeighbourCost(response.neighbour_lpas.average_unit_cost);
        setNeighbouringUnitsAvailableOnGaia(response.neighbour_lpas.units_available);
        setAverageNationalCost(response.national.average_unit_cost);
        setNationalUnitsAvailableOnGaia(response.national.units_available);
      })
  };

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <Box style={{ backgroundColor: '#F0F1F3', minHeight: '100vh' }}>
        <Toolbar />
        <Container maxWidth={showResults ? "lg" : "md"} sx={{ mt: 4, pb: 4 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={showResults ? 8 : 12}
              lg={showResults ? 8 : 12}
            >
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto'
                }}
              >
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                    Biodiversity Unit Calculator - Spatial Multiplier
                  </Typography>
                  {!showResults && (
                    <Typography variant="sub-heading" >
                      Select the Local Planning Authority and the number of BNG units required from your baseline assessment.<br /><br />
                      Gaia will then tell you the number of units that you will require depending on where you purchase the units and the estimated cost for those units.<br /><br />
                      <a href="https://marketplace.gaiacompany.io/signup?role=buyer" target="_blank">Subscribe to Gaia</a> to contact the land manager and purchase the units.<br /><br />
                    </Typography>
                  )}
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="location-label">Location of development</InputLabel>
                    <Select
                      labelId="location-label"
                      id="location"
                      value={locationField}
                      label="Location of development"
                      onChange={e => setLocationField(e.target.value)}
                    >
                      {lpas.map((lpa, index) => (
                        <MenuItem key={index} value={lpa}>{lpa}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin='normal'>
                    <TextField
                      fullWidth
                      className="formField"
                      label="Number of units required"
                      id="units"
                      placeholder="Number of units required"
                      onChange={e => setUnitField(e.target.value)}
                      value={unitField}
                      type="number"
                    />
                  </FormControl>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      onClick={(e) => submitCalculate(e)}
                      className='removeUppercase'
                      fullWidth
                      sx={{ textAlign: 'center', mt: '26px' }}
                      type="submit"
                    >
                      Calculate
                    </Button>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            {showResults && (
              <Grid item xs={12} md={4} lg={4}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    position: 'sticky',
                    top: '65px'
                  }}
                >
                  <Grid item xs={12} md={12} lg={12}>
                    <Collapse orientation="horizontal" in={showResults}>
                      <Card sx={{ p: 2 }}>
                        <CardContent>
                          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                            Your required units
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography variant="p">
                                <b>Within LPA: </b><NumericFormat value={units} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography variant="p">
                                <b>Neighbouring LPAs: </b><NumericFormat value={units / 0.75} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography variant="p">
                                <b>Nationally: </b><NumericFormat value={units / 0.5} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          {showResults && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8} lg={8}>
                <Card sx={{ p: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={1} lg={1}>
                      <LooksOneIcon color="primary" />
                    </Grid>
                    <Grid item xs={11} md={11} lg={11}>
                      <Typography variant="h6" color="text.primary">
                        <span style={{ lineHeight: '24px' }}>
                          Purchase within Local Planning Authority - {location}
                        </span>
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <TollIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units required: </b>
                            <NumericFormat value={units} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <SellOutlinedIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated cost per unit: </b>
                            <NumericFormat value={Math.round(averageCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <CurrencyPoundIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated total cost: </b>
                            <NumericFormat value={Math.round(units * averageCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <NumbersOutlined color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units available: </b>
                            <NumericFormat value={Math.round(unitsAvailableOnGaia)} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </Typography>
                      </Stack>
                      <Divider sx={{ pt: '10px', mb: '15px' }} />
                      <Stack direction="column" spacing={2} >
                        <Typography variant="p" color="text.primary" >
                          <span style={{ lineHeight: '24px' }}>
                            Sign up to the Gaia marketplace to view the units and send an enquiry to the land manager.
                          </span>
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          disableElevation
                          className='removeUppercase'
                          onClick={() => handleViewListingClick()}
                          endIcon={<ChevronRightIcon />}
                          sx={{ textTransform: 'none' }}
                        >
                          View Listings
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          )}

          {showResults && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8} lg={8}>
                <Card sx={{ p: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={1} lg={1}>
                      <LooksTwoIcon color="primary" />
                    </Grid>
                    <Grid item xs={11} md={11} lg={11}>
                      <Typography variant="h6" color="text.primary">
                        <span style={{ lineHeight: '24px' }}>
                          Purchase in neighbouring LPAs
                        </span>
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <TollIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units required: </b>
                            <NumericFormat value={units / 0.75} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <SellOutlinedIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated cost per unit: </b>
                            <NumericFormat value={Math.round(averageNeighbourCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <CurrencyPoundIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated total cost: </b>
                            <NumericFormat value={Math.round((units / 0.75) * averageNeighbourCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <NumbersOutlined color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units available: </b>
                            <NumericFormat value={Math.round(neighbouringUnitsAvailableOnGaia)} displayType={'text'} thousandSeparator={true} />
                          </span>
                        </Typography>
                      </Stack>
                      <Divider sx={{ pt: '10px', mb: '15px' }} />
                      <Stack direction="column" spacing={2} >
                        <Typography variant="p" color="text.primary" >
                          <span style={{ lineHeight: '24px' }}>
                            Sign up to the Gaia marketplace to view the units and send an enquiry to the land manager.
                          </span>
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          disableElevation
                          className='removeUppercase'
                          onClick={() => handleViewListingClick()}
                          endIcon={<ChevronRightIcon />}
                          sx={{ textTransform: 'none' }}
                        >
                          View Listings
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          )}
          {showResults && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8} lg={8}>
                <Card sx={{ p: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={1} lg={1}>
                      <Looks3Icon color="primary" />
                    </Grid>
                    <Grid item xs={11} md={11} lg={11}>
                      <Typography variant="h6" color="text.primary">
                        <span style={{ lineHeight: '24px' }}>
                          Purchase nationally
                        </span>
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <TollIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Units required: </b>
                            <NumericFormat value={units / 0.5} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <SellOutlinedIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated cost per unit: </b>
                            <NumericFormat value={Math.round(averageNationalCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                        <CurrencyPoundIcon color="primary" />
                        <Typography variant="p" color="text.primary">
                          <span style={{ lineHeight: '24px' }}>
                            <b>Estimated total cost: </b>
                            <NumericFormat value={Math.round((units / 0.5) * averageNationalCost)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </span>
                        </Typography>
                      </Stack>
                      <Divider sx={{ pt: '10px', mb: '15px' }} />
                      <Stack direction="column" spacing={2} >
                        <Typography variant="p" color="text.primary" >
                          <span style={{ lineHeight: '24px' }}>
                            Sign up to the Gaia marketplace to view the units and send an enquiry to the land manager.
                          </span>
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          disableElevation
                          className='removeUppercase'
                          onClick={() => handleViewListingClick()}
                          endIcon={<ChevronRightIcon />}
                          sx={{ textTransform: 'none' }}
                        >
                          View Listings
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Calculator