import { Box } from '@mui/material'
import { HtmlParserDisplay } from './html-parser-display/html-parser-display'

export const StaticPageContentDisplay = ({ content }) => {

  return (<Box>
    {content && content.map((section, index) => {
      return section.section_type === 'rich_text' && <Box key={index} sx={{ mb: 4 }} >
        <HtmlParserDisplay html={section.content}></HtmlParserDisplay>
      </Box>
    })}
  </Box>
  )
}