import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { styled } from "@mui/material/styles";
import * as data from '../data/cornwall';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InvestmentProjectService from '../services/investment-project-service';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    whiteSpace: "pre-line"
  }
}));

export default function ProjectMapCornwall(props) {

  const investmentProjectService = new InvestmentProjectService();
  const [investmentProjects, setInvestmentProjects] = useState([]);
  const [groupedProjects, setGroupedProjects] = useState([]);

  const colorArray = ['#5baeb5', '#1999a2', '#5baeb5', '#1999a2', '#5baeb5', '#1999a2', '#5baeb5', '#1999a2', '#5baeb5', '#1999a2', '#5baeb5', '#1999a2'];

  const handleRegionClick = (geo) => {
    const geoRegion = geo.properties.NAME;
    props.handleMapRegionClick(geoRegion);
  }

  return (
    <ComposableMap
      projection="geoAlbers"
      projectionConfig={{
        center: [-0.5, 50.4],
        rotate: [4.4, 0],
        parallels: [50, 60],
        scale: 30000
      }}
      width={700}
      height={600}
    >
      <Geographies geography={data} >
        {({ geographies }) =>
          geographies.map((geo, i) => {
            return (
              <StyledTooltip
                title={
                  <Card sx={{ minWidth: 350 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.primary">
                        {geo.properties.NAME}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                className='mapTooltip'
                placement="right"
                arrow
                sx={{
                  backgroundColor: 'transparent'
                }}
                key={i}
              >
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleRegionClick(geo)}
                  style={{
                    default: { fill: colorArray[i], outline: "none" },
                    hover: { fill: "#0f71a3", outline: "none", cursor: "pointer" },
                    pressed: { fill: "#0f71a3", outline: "none" },
                    focus: { outline: "none" }
                  }}
                />
              </StyledTooltip>
            )
          })
        }
      </Geographies>
    </ComposableMap>
  )
}
