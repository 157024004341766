import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from 'react-number-format';
import ActionsMenu from "../components/actions-menu";
import ProjectSummary from "../../shared/components/project-summary";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import WaterIcon from '@mui/icons-material/Water';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Habitats from '../../shared/data/habitats';
import { Accordion, AccordionDetails, AccordionSummary, Chip, List, ListItem, ListItemIcon, ListItemText, MenuItem, Skeleton, Snackbar } from "@mui/material";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import httpClient from '../../shared/http/http-client';
import ProjectService from '../services/project-service';
import ProjectHabitatService from '../services/project-habitat-service';
import { EditProjectDialog } from '../components/dialogs/edit-project-dialog';
import IosShareIcon from '@mui/icons-material/IosShare';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { ProjectApprovalMessageContainer } from '../../shared/components/project-approval-message-container';
import { GlobalBackButton } from '../../shared/components/buttons/global-back-button';
import { ImageFullSizeDisplay } from '../../shared/components/image-full-size-display';
import { UserInformationProviderContext } from '../../shared/context/user-information-provider';
import { EditSharp, LockRounded, Verified } from '@mui/icons-material';
import EditHabitatDialog from '../components/dialogs/edit-habitat-dialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../../shared/components/icon-picker';
import AnswerFormDisplay from '../../shared/components/answer-form-display';
import { HtmlParserDisplay } from '../../shared/components/html-parser-display/html-parser-display';
import { ProjectHistory } from '../../shared/components/project-history';
import { CustomerServiceTextEmailLink } from '../../shared/components/presentation/customer-service-text-email-link';
import { DialogChangeShallowStatus } from '../../shared/components/dialogs/dialog-change-shallow-status';
import { BngProjectDisplayNotes } from '../../shared/components/bng-project-display-notes';

const HabitatSaleDialog = (
  props
) => {
  const [unitsSold, setUnitsSold] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [enquiryId, setEnquiryId] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleRecordSale = () => {
    setFormSubmitted(true);
    if (totalPrice) {
      props.submitSale(unitsSold, totalPrice, enquiryId);
      props.handleAreaHabitatRowSaleClick();
      setUnitsSold('');
      setTotalPrice('');
      setEnquiryId('');
      setFormSubmitted(false);
    }
  }

  return (
    <Dialog
      open={props.habitatSaleFormActive}
      onClose={props.handleAreaHabitatRowSaleClick}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle sx={{ pb: '5px' }}>Record sale</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <DialogContentText sx={{ pb: '10px' }}>
          <Typography variant="p" display="block">
            Record a sale and update your Gaia listing.
          </Typography>
        </DialogContentText>
        <Box
          component="form"
          noValidate
          fullWidth
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth margin='normal'>
                <TextField
                  fullWidth
                  className="formField"
                  label="Units Sold"
                  error={unitsSold > props.unitsRemaining}
                  type='number'
                  id="unitsSold"
                  value={unitsSold}
                  onChange={e => setUnitsSold(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth margin='normal'>
                <InputLabel htmlFor="totalPrice">Total Price</InputLabel>
                <OutlinedInput
                  id="totalPrice"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  fullWidth
                  className="formField"
                  label="Total Price"
                  type="number"
                  error={formSubmitted && !totalPrice}
                  step="any"
                  value={totalPrice}
                  onChange={e => setTotalPrice(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="p" display="block" sx={{ mt: 1, color: 'primary' }}>
            Units available: {props.unitsRemaining}
          </Typography>

          {unitsSold > props.unitsRemaining && <Alert severity='error' sx={{ mt: 2 }}>The maximum remaining units for sale is {props.unitsRemaining}.</Alert>}
          {formSubmitted && !totalPrice && <Alert severity='error' sx={{ mt: 2 }}>Please enter the price at which these units were sold.</Alert>}

        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' spacing={1} p={2} pt={0}>
          <Button
            onClick={props.handleAreaHabitatRowSaleClick}
            className="removeUppercase"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={unitsSold > props.unitsRemaining || unitsSold === 0}
            disableElevation
            onClick={() => handleRecordSale()}
            className="removeUppercase"
          >
            Save sale
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

const ProjectDetails = (props) => {

  const projectService = new ProjectService();
  const projectHabitatService = new ProjectHabitatService();
  const hub = useContext(HubDetailsContext);

  const location = useLocation();
  const state = location.state;
  const project = state && state.project ? state.project : null;
  let navigate = useNavigate();
  const infoDialog = useContext(UserInformationProviderContext)

  const [projectImages, setProjectImages] = useState([]);
  const [projectDetails, setProjectDetails] = useState(project);
  const [updatedProjectDetails, setUpdatedProjectDetails] = useState(project || []);
  const [editFormActive, setEditFormActive] = useState(false);
  const [habitatFormActive, setHabitatFormActive] = useState(false);
  const [habitatSaleFormActive, setHabitatSaleFormActive] = useState(false);
  const [broadHabitat, setBroadHabitat] = useState(null);
  const [habitat, setHabitat] = useState(null);
  const [area, setArea] = useState(null);
  const [units, setUnits] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [hidePrice, setHidePrice] = useState(null);
  const [habitatId, setHabitatId] = useState(null);
  const [activeHabitat, setActiveHabitat] = useState(null);
  const [activeHabitatType, setActiveHabitatType] = useState(null);
  const [habitatFormType, setHabitatFormType] = useState(null);
  const [habitatFormFunction, setHabitatFormFunction] = useState('Edit');
  const [refreshEnquiriesActive, setRefreshEnquiriesActive] = useState(1);
  const [refreshHistoryActive, setRefreshHistoryActive] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareAlertOpen, setShareAlertOpen] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [areaUnitsRemaining, setAreaUnitsRemaining] = useState();
  const [shallowStausDialogActive, setShallowStatusDialogActive] = useState(false);

  const [fileData, setFileData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  useEffect(() => {
    setBaseUrl(hub.hubDetails.base_url);
  }, [hub])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getProjectDetails()
  }, []);

  useEffect(() => {
    console.log(units);
    console.log(totalPrice);
  }, [units, totalPrice]);

  const handleEditClick = () => {

    if (projectDetails.admin_status.status_code === 'PUBLISHED' || projectDetails.admin_status.status_code === 'PENDING_REVIEW') {
      handleSetToDraft()
    } else {
      setEditFormActive(!editFormActive);
      setAnchorEl(null);
    }
  }

  const refreshEnquiries = async () => {
    setRefreshEnquiriesActive(!refreshEnquiriesActive);
  }

  const refreshHistory = async () => {
    setRefreshHistoryActive(!refreshHistoryActive);
  }

  const handleUpdateProjects = async (summary, description, location, statusId, local_planning_authority, national_character_area, group_at_project_level, bng_registration_number) => {
    const status_id = statusId;
    try {
      const body = {
        "summary": summary,
        "description": description,
        "location": location,
        "status_id": status_id,
        "local_planning_authority": local_planning_authority,
        "national_character_area": national_character_area,
        group_at_project_level: group_at_project_level,
        bng_registration_number: bng_registration_number
      };

      projectService.updateOneProject(projectDetails.p_id, body)
        .then((res) => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
      getProjectDetails();
      handleEditClick();
      refreshHistory();
    } catch (err) {
      console.error(err.message);
    }
  };

  const submitSale = async (unitsSold, totalPrice, enquiryId) => {
    const project_id = projectDetails.p_id;
    const sold_externally = false;
    const units_sold = unitsSold;
    const total_price = totalPrice;
    const area = 0;
    const habitat_type = activeHabitatType;
    const habitat_id = activeHabitat;
    const enquiry_id = enquiryId || null;
    const body = { project_id, sold_externally, units_sold, total_price, area, habitat_type, habitat_id, enquiry_id };

    projectService.recordSale(projectDetails.p_id, body)
      .then(() => {
        getProjectDetails();
        refreshEnquiries();
        refreshHistory();
        setAreaUnitsRemaining(undefined);
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  const handlePauseProject = () => {
    setAnchorEl(null);
    infoDialog.openDialog({
      active: true,
      title: 'Pause listing?',
      message: 'If you pause this listing it will be removed from public view. You can continue any ongoing enquiries and un-pause at any time.'
    })
      .then(response => {
        if (response) {
          projectService.pauseProject(projectDetails.p_id)
            .then((res) => {
              getProjectDetails();
              refreshHistory();
              infoDialog.openSnackbar({ open: true, message: 'Project paused.', backgroundColor: 'orange' });
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  const handleResumeProject = () => {
    setAnchorEl(null);
    infoDialog.openDialog({
      active: true,
      title: 'Resume listing?',
      message: 'This will re-list your listing on the public marketplace.'
    })
      .then(response => {
        if (response) {
          projectService.resumeProject(projectDetails.p_id)
            .then((res) => {
              getProjectDetails();
              refreshHistory();
              infoDialog.openSnackbar({ open: true, message: 'Project available.', backgroundColor: 'green' });
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  const handleCloseProject = () => {
    setAnchorEl(null);
    infoDialog.openDialog({
      active: true,
      title: 'Close this listing?',
      message: 'This will remove your listing from the marketplace and prevent any further enquiries. This action cannot be undone.'
    })
      .then(response => {
        if (response) {
          projectService.closeProjects(projectDetails.p_id)
            .then((res) => {
              getProjectDetails();
              refreshHistory();
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  const getProjectDetails = async () => {
    const path = window.location.pathname;
    const projectId = path.substring(path.lastIndexOf('/') + 1);

    httpClient.get(`seller/project/${projectId}`)
      .then(response => {
        setProjectImages(response.data.project_images);
        setProjectDetails(response.data);
        setIsLoading(false);
        setUpdatedProjectDetails(response.data)
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  const handleHedgerowHabitatRowEdit = (habitat) => {
    setBroadHabitat(habitat.phh_broad_habitat);
    setHabitat(habitat.phh_habitat);
    setArea(habitat.phh_area);
    setUnits(habitat.phh_biodiversity_units);
    setTotalPrice(habitat.phh_total_price);
    setHidePrice(habitat.phh_hide_price);
    setHabitatId(habitat.phh_id);
    return showHabitatForm('hedgerow', 'Edit');
  }

  const handleAreaHabitatRowEdit = (habitat) => {
    setBroadHabitat(habitat.pah_broad_habitat);
    setHabitat(habitat.pah_habitat);
    setArea(habitat.pah_area);
    setUnits(habitat.pah_biodiversity_units);
    setTotalPrice(habitat.pah_total_price);
    setHidePrice(habitat.pah_hide_price);
    setHabitatId(habitat.pah_id);
    return showHabitatForm('area', 'Edit');
  }

  const handleHabitatRowAdd = () => {
    setBroadHabitat('');
    setHabitat('');
    setArea('');
    setUnits('');
    setTotalPrice('');
    setHidePrice('');
    setHabitatId('');
    setAnchorEl(null);
    showHabitatForm('', 'Add');
  }

  const handleWatercourseHabitatRowEdit = (habitat) => {
    setBroadHabitat(habitat.pwh_broad_habitat);
    setHabitat(habitat.pwh_habitat);
    setArea(habitat.pwh_area);
    setUnits(habitat.pwh_biodiversity_units);
    setTotalPrice(habitat.pwh_total_price);
    setHidePrice(habitat.pwh_hide_price);
    setHabitatId(habitat.pwh_id);
    return showHabitatForm('watercourse', 'Edit');
  }

  const showHabitatForm = (formType, formFunction) => {
    setHabitatFormActive(!habitatFormActive);
    setHabitatFormType(formType);
    setHabitatFormFunction(formFunction);
  };

  const closeHabitatForm = () => {
    setHabitatFormActive(false);

    setTimeout(() => {
      setBroadHabitat('');
      setHabitat('');
      setArea('');
      setUnits('');
      setTotalPrice('');
      setHidePrice('');
      setHabitatId('');
      setAnchorEl(null);
      setHabitatFormType('');
      setHabitatFormFunction('');
    }, 500);

  }

  const handleAreaHabitatRowSaleClick = (id, unitsRemaining) => {
    setActiveHabitat(id);
    setAreaUnitsRemaining(unitsRemaining);
    setActiveHabitatType('area');
    setHabitatSaleFormActive(!habitatSaleFormActive);
  }

  const handleHedgerowHabitatRowSaleClick = (id, unitsRemaining) => {
    setActiveHabitat(id);
    setAreaUnitsRemaining(unitsRemaining);
    setActiveHabitatType('hedgerow');
    setHabitatSaleFormActive(!habitatSaleFormActive);
  }

  const handleWatercourseHabitatRowSaleClick = (id, unitsRemaining) => {
    setActiveHabitat(id);
    setAreaUnitsRemaining(unitsRemaining);
    setActiveHabitatType('watercourse');
    setHabitatSaleFormActive(!habitatSaleFormActive);
  }

  const handleSelectBroadHabitat = (event, newAnswer) => {
    setBroadHabitat(newAnswer.props.value);
  };

  const handleSelectHabitat = (event, newAnswer) => {
    setHabitat(newAnswer.props.value);
  };

  const getHabitatArray = () => {
    switch (broadHabitat) {
      case 'Cropland':
        return Habitats.croplandHabitats
      case 'Grassland':
        return Habitats.grasslandHabitats
      case 'Heathland and shrub':
        return Habitats.heathlandHabitats
      case 'Lakes':
        return Habitats.lakesHabitats
      case 'Sparsely vegetated land':
        return Habitats.sparselyVegetatedHabitats
      case 'Urban':
        return Habitats.urbanHabitats
      case 'Wetland':
        return Habitats.wetlandHabitats
      case 'Woodland and forest':
        return Habitats.woodlandHabitats
      case 'Coastal lagoons':
        return Habitats.coastalLagoonsHabitats
      case 'Rocky shore':
        return Habitats.rockyShoreHabitats
      case 'Coastal salt marsh':
        return Habitats.coastalSaltmarshHabitats
      case 'Intertidal sediment':
        return Habitats.intertidalSedimentHabitats
      case 'Intertidal hard structures':
        return Habitats.intertidalHardStructuresHabitats
      case 'Watercourse footprint':
        return Habitats.watercourseFootprintHabitats
      case 'Individual trees':
        return Habitats.individualTreesHabitats
      case 'Hedgerow':
        return Habitats.hedgerowHabitats
      case 'Watercourse':
        return Habitats.watercourseHabitats
      default:
        return []
    }
  }

  const handleAddHabitat = () => {

    const newHabitat = {
      biodiversity_type: habitatFormType,
      broad_habitat: broadHabitat,
      habitat: habitat,
      area: area || null,
      biodiversity_units: units || null,
      total_price: totalPrice || null,
      hide_price: hidePrice || false
    }

    projectService.addNewHabitat(projectDetails.p_id, newHabitat)
      .then(() => {
        closeHabitatForm();
        getProjectDetails(projectDetails.p_id)
      })
      .catch(err => {
        console.log(err)
      })

  };

  const handleUpdateHabitat = (habitatId) => {
    const habitatData = {
      id: habitatId,
      biodiversityType: habitatFormType,
      broadHabitat: broadHabitat,
      habitat: habitat,
      area: area,
      units: units,
      totalPrice: totalPrice,
      hidePrice: hidePrice
    }

    postUpdatedHabitat(habitatData);

    setBroadHabitat(null);
    setHabitat(null);
    setArea("");
    setUnits("");
    setTotalPrice("");
    setHidePrice("");
    setHabitatId("");
    setHabitatFormActive(false);
  };

  const postUpdatedHabitat = async (habitatData) => {
    const broad_habitat = habitatData.broadHabitat;
    const habitat = habitatData.habitat;
    const area = habitatData.area || null;
    const biodiversity_units = habitatData.units || null;
    const total_price = habitatData.totalPrice || null;
    const hide_price = habitatData.hidePrice || false;
    const type = habitatData.biodiversityType;
    const project_id = projectDetails.p_id;
    const id = habitatData.id;

    const body = {
      "project_id": project_id,
      "broad_habitat": broad_habitat,
      "habitat": habitat,
      "area": area,
      "biodiversity_units": biodiversity_units,
      "total_price": total_price,
      "hide_price": hide_price
    }
    projectHabitatService.updateOneProjectHabitat(id, type, body)
      .then(async (response) => {
        const updatedHabitat = response;
        refreshHistory();

        if (type === 'area') {
          const newAreaHabitats = projectDetails.project_area_habitats.map(habitat => {
            if (habitat.pah_id === updatedHabitat.pah_id) {
              return updatedHabitat;
            }
            return habitat;
          });

          const newProjectDetails = {
            ...projectDetails,
            project_area_habitats: newAreaHabitats
          }
          setProjectDetails(newProjectDetails);

        } else if (type === 'hedgerow') {
          const newHedgerowHabitats = projectDetails.project_hedgerow_habitats.map(habitat => {
            if (habitat.phh_id === updatedHabitat.phh_id) {
              return updatedHabitat;
            }
            return habitat;
          });

          const newProjectDetails = {
            ...projectDetails,
            project_hedgerow_habitats: newHedgerowHabitats
          }
          setProjectDetails(newProjectDetails);
        } else if (type === 'watercourse') {
          const newWatercourseHabitats = projectDetails.project_watercourse_habitats.map(habitat => {
            if (habitat.pwh_id === updatedHabitat.pwh_id) {
              return updatedHabitat;
            }
            return habitat;
          });

          const newProjectDetails = {
            ...projectDetails,
            project_watercourse_habitats: newWatercourseHabitats
          }
          setProjectDetails(newProjectDetails);
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDeleteHabitat = (requiredHabitatId, areatType) => {
    infoDialog.openDialog({
      active: true,
      title: 'Are you sure you would like to delete this record?',
      message: 'This action will permanently delete this record and cannot be reversed.'
    })
      .then(response => {
        if (response) {
          projectService.deleteHabitat(requiredHabitatId, areatType)
            .then(response => {
              console.log(response);
              getProjectDetails()
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  const shareLinkClick = () => {
    navigator.clipboard.writeText(`https://${baseUrl}/hub/projects/${projectDetails.p_id}`)
    setShareAlertOpen(true);
  }

  const handleApprovalClick = () => {
    projectService.submitProjectApproval(projectDetails.p_id)
      .then((res) => {
        getProjectDetails();
        refreshHistory();
      })
      .catch(err => {
        if (err.response?.data?.code === 4) {
          infoDialog.openDialog({
            active: true,
            title: 'Your user account is still pending',
            message: `Your account has not yet been approved. If you would like to publish this project please contact customer support - ${hub?.hubDetails?.organisation_settings?.support_email_address}`,
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
            .then(() => {
            })
        } else if (err.response?.data?.code === 5) {
          infoDialog.openDialog({
            active: true,
            title: 'You have not completed all the forms required to publish this listing',
            message: `Please use the "Actions" button to complete the missing forms. (${err.response.data?.details.map(form => form.question_form_name)?.join(', ')})`,
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
            .then(() => {
            })
        }

      })
  }

  const handleSetToDraft = () => {

    infoDialog.openDialog({
      active: true,
      title: 'Please note',
      message: 'Editing this listing will set this project back to draft, and you will need to complete the approval process again.'
    })
      .then(response => {
        if (response) {
          projectService.setProjectToDraftStatus(projectDetails.p_id)
            .then((res) => {
              getProjectDetails();
              refreshHistory();
              setAnchorEl(null);
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  useEffect(() => {
    window.analytics.page();
  }, []);

  const handlePrimaryImageClick = (imageKey) => {
    projectService.updateOneProject(projectDetails.p_id, { primary_image_key: imageKey })
      .then(() => {
        getProjectDetails();
        infoDialog.openSnackbar({ open: true, message: 'The primary image has been successfully updated.', backgroundColor: 'green' });
      })
  }

  const handleAddNewImages = () => {
    const form = new FormData();

    for (let i = 0; i < fileData.length; i++) {
      form.append("files", fileData[i]);
    }

    projectService.uploadNewBngProjectImage(projectDetails.p_id, form)
      .then(() => {
        getProjectDetails();
        infoDialog.openSnackbar(
          {
            open: true, message: `${fileData.length} ${fileData.length === 1 ? 'image' : 'images'} succesfully uploaded.`, backgroundColor: 'green'
          });
        setTimeout(() => {
          setFileData([]);
        }, 500);
      })
  }

  const handleDeleteImage = (imageKey) => {
    projectService.deleteProjectImage(projectDetails.p_id, imageKey)
      .then(() => {
        getProjectDetails();
        infoDialog.openSnackbar(
          { open: true, message: 'Image deleted.', backgroundColor: 'green' });
      })
  }

  const handleChangeShallowStatus = () => {
    setShallowStatusDialogActive(true)
    setAnchorEl(null);
  }

  const handleShallowStatusUpdated = (cancelled = false) => {
    setShallowStatusDialogActive(false)
    if (!cancelled) {
      getProjectDetails();
      refreshHistory();

    }

  }

  const goToEditQuestionPage = (form) => {
    console.log(form);
    navigate(`/project/questions/${projectDetails.p_id}/${form.id}`);
  }

  return (
    <>
      <Toolbar />
      {projectDetails && <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
        <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
          <Box sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            <GlobalBackButton buttonCopy={'Back to dashboard'} buttonLink={'/dashboard'}></GlobalBackButton>
          </Box>
          <Tooltip title="Copy public link to clipboard">
            <IconButton aria-label="share" onClick={() => shareLinkClick()}>
              <IosShareIcon />
            </IconButton>
          </Tooltip>
          <Snackbar
            open={shareAlertOpen}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setShareAlertOpen(false)}
          >
            <Alert
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Public link copied to clipboard
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8} sx={{ mb: 2 }}>
            <Paper
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '10px'
              }}
              elevation={0}
            >

              {projectDetails && <ImageFullSizeDisplay
                images={projectImages}
                canEdit={projectDetails.can_edit}
                primaryImageKey={projectDetails.p_primary_image_name}
                handlePrimaryImageClick={handlePrimaryImageClick}
                handleAddImage={handleAddNewImages}
                handleDeleteImage={handleDeleteImage}
                indicators={true}
                fileData={fileData}
                setFileData={setFileData}
                imageCountLimit={10}
              >
              </ImageFullSizeDisplay>}

              <Box sx={{ p: 4 }}>
                {!isLoading && (
                  <Grid container spacing={2} sx={{ pl: '6px' }}>
                    {projectDetails.p_closed_status === true && (<Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
                      <Alert severity="success">Project closed</Alert>
                    </Grid>)}

                    {projectDetails?.can_edit === false && projectDetails?.admin_status?.status_code === 'PENDING_REVIEW' &&
                      <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="info" sx={{ mb: 4 }}>
                          {hub.hubDetails.organisation_settings.bng_awaiting_approval_message && <HtmlParserDisplay html={hub.hubDetails.organisation_settings.bng_awaiting_approval_message}></HtmlParserDisplay>}
                          {!hub.hubDetails.organisation_settings.bng_awaiting_approval_message && 'The project is currently being reviewed, and you will be informed once it is approved or if any amendments are required.'}
                        </Alert>
                      </Grid>
                    }

                    {projectDetails?.admin_status?.status_code === 'APPROVED' &&
                      <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="info" sx={{ mb: 4 }}>
                          This project has been approved, but has not yet been published.
                          It will be published by an administrator. If you have any questions please contact {<CustomerServiceTextEmailLink isAlert={true} />}.
                        </Alert>
                      </Grid>
                    }

                    {projectDetails?.admin_status?.status_code === 'AUTO_DECLINE' &&
                      <Grid item xs={12} md={12} lg={12}>
                        <Alert severity="error" sx={{ mb: 4 }}>
                          This project has been automatically declined by the system.
                          <br></br>
                          <ul>
                            {projectDetails.answer_forms.map(form => {
                              return form.answers.filter(answer => answer.decline_message).map(answer => {
                                return <li>{answer.decline_message}</li>
                              })
                            })}
                          </ul>
                        </Alert>
                      </Grid>
                    }

                    <Grid item xs={12} md={4} lg={4}>
                      <Box>
                        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                          {projectDetails.p_local_planning_authority}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body" color="text.primary" sx={{ mb: '20px', whiteSpace: 'pre-wrap' }}>
                          {projectDetails.p_summary}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-end' }}>
                        {projectDetails?.can_edit && projectDetails &&
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => handleApprovalClick()}
                            //startIcon={<ThumbUpOutlinedIcon />}
                            className='removeUppercase'
                          >
                            Submit for Approval
                          </Button>}

                        {projectDetails.shallow_status?.status_code !== 'CLOSED' && <ActionsMenu
                          // handleSoldClick={handleSoldClick}
                          handleAddNewHabitat={handleHabitatRowAdd}
                          handleEditClick={handleEditClick}
                          handlePauseClick={handlePauseProject}
                          handleChangeShallowStatus={handleChangeShallowStatus}
                          handleResumeClick={handleResumeProject}
                          handleCloseProject={handleCloseProject}
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          shallowStatus={projectDetails.shallow_status}
                          isPublished={projectDetails.admin_status.status_code === 'PUBLISHED'}
                          isInReview={projectDetails.admin_status.status_code === 'PENDING_REVIEW'}
                          projectId={projectDetails.p_id}
                        />}
                      </Stack>
                    </Grid>

                    <EditProjectDialog
                      editFormActive={editFormActive}
                      handleEditClick={handleEditClick}
                      projectDetails={projectDetails}
                      summary={projectDetails.p_summary}
                      description={projectDetails.p_description}
                      location={projectDetails.p_location}
                      handleUpdateProjects={handleUpdateProjects}
                      setProjectDetails={setProjectDetails}
                      updatedProjectDetails={updatedProjectDetails}
                      setUpdatedProjectDetails={setUpdatedProjectDetails}
                    />
                    <EditHabitatDialog
                      habitatFormActive={habitatFormActive}
                      habitatFormType={habitatFormType}
                      setHabitatFormType={setHabitatFormType}
                      habitatFormFunction={habitatFormFunction}
                      closeHabitatForm={closeHabitatForm}
                      showHabitatForm={showHabitatForm}
                      broadHabitat={broadHabitat}
                      setBroadHabitat={setBroadHabitat}
                      handleSelectBroadHabitat={handleSelectBroadHabitat}
                      habitat={habitat}
                      setHabitat={setHabitat}
                      handleSelectHabitat={handleSelectHabitat}
                      getHabitatArray={getHabitatArray}
                      area={area}
                      setArea={setArea}
                      units={units}
                      setUnits={setUnits}
                      totalPrice={totalPrice}
                      setTotalPrice={setTotalPrice}
                      hidePrice={hidePrice}
                      setHidePrice={setHidePrice}
                      habitatId={habitatId}
                      handleAddHabitat={handleAddHabitat}
                      handleUpdateHabitat={handleUpdateHabitat}
                    />
                    <HabitatSaleDialog
                      habitatSaleFormActive={habitatSaleFormActive}
                      handleAreaHabitatRowSaleClick={handleAreaHabitatRowSaleClick}
                      submitSale={submitSale}
                      projectId={projectDetails.p_id}
                      unitsRemaining={areaUnitsRemaining}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
                  <Box>
                    <Divider sx={{ mt: '34px', mb: '50px' }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                          Overview
                        </Typography>

                        {projectDetails.bng_gov_registered && <Stack direction={'row'} justifyContent={'flex-start'}>
                          <Typography variant="body" color={'#1DA1F2'} sx={{ mr: 1 }}>
                            BNG registered
                          </Typography>
                          <Tooltip title="BNG registered" placement="right" arrow>
                            <Verified
                              sx={{
                                color: '#1DA1F2',
                              }} />
                          </Tooltip>
                        </Stack>}
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            <b style={{ marginRight: '10px' }}>Description</b>
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary" sx={{ whiteSpace: 'pre-wrap' }}>
                            {projectDetails.p_description}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            <b style={{ marginRight: '10px' }}>Local Planning Authority</b>
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            {projectDetails.p_local_planning_authority}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            <b style={{ marginRight: '10px' }}>National Character Area</b>
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            {projectDetails.p_national_character_area}
                          </Typography>
                        </Stack>
                      </Grid>
                      {projectDetails.totalArea > 0 && (
                        <>
                          <Grid item xs={12} md={4} lg={3} >
                            <Stack spacing={2} direction="row" alignItems="center">
                              <Typography variant="subtitle1" color="text.primary">
                                <b style={{ marginRight: '10px' }}>Size</b>
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={8} lg={9} >
                            <Stack spacing={2} direction="row" alignItems="center">
                              <Typography variant="subtitle1" color="text.primary">
                                (`${projectDetails.totalArea} hectares`)
                              </Typography>
                            </Stack>
                          </Grid>
                        </>
                      )}
                      {!projectDetails.unit_project && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Total Units</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            {projectDetails.totalBiodiversityUnitsRemaining > 0 && <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={projectDetails.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                            </Typography>}
                            {projectDetails.totalBiodiversityUnitsRemaining === 0 && <Typography variant="subtitle1" color="text.primary">
                              TBC
                            </Typography>}
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Unit Price</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {(!projectDetails.hidePrice && projectDetails.totalPrice !== "0") ? (
                                <NumericFormat value={Math.round(projectDetails.totalPrice / projectDetails.totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                              ) : ("POA")}
                            </Typography>

                          </Stack>
                        </Grid>
                      </>}

                      {projectDetails.unit_project && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Price</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={Math.round(projectDetails.unit_project.price)} displayType={'text'} thousandSeparator={true} prefix={'£'} /> per {projectDetails.unit_project.price_unit}
                            </Typography>
                          </Stack>
                        </Grid>

                      </>}
                    </Grid>
                  </Box>
                </Grid>

                <Divider sx={{ mt: 6, mb: 6 }} />

                <BngProjectDisplayNotes
                  isAdmin={false}
                  isSeller={true}
                  project={projectDetails} />
                {!isLoading && (
                  projectDetails.totalAreaHabitats > 0 && (
                    <Paper
                      sx={{
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        mt: '30px'
                      }}
                      variant='outlined'
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={9} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                            <SpaOutlinedIcon color="primary" />
                            <Typography vvariant="subtitle1" color="text.primary">
                              <b>Area Units</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <Typography vvariant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                            <b><NumericFormat value={projectDetails.totalAreaPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container spacing={2} sx={{ p: 2, pr: 0, mt: 2 }}>
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === false).length > 0 && (
                          <>
                            <Typography vvariant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Available Units</b>
                            </Typography>
                            <TableContainer sx={{ pb: 2 }}>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Area (hectares)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Unit Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === false).map((filteredAreaHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredAreaHabitat.pah_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredAreaHabitat.pah_habitat}</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.pah_area}</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredAreaHabitat.pah_total_price / filteredAreaHabitat.pah_biodiversity_units} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      {projectDetails.p_closed_status === false && (
                                        <TableCell align="right">
                                          <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                            {projectDetails && projectDetails.can_edit && <IconButton aria-label="edit" onClick={() => handleAreaHabitatRowEdit(filteredAreaHabitat)}>
                                              <EditIcon />
                                            </IconButton>}
                                            {filteredAreaHabitat.biodiversity_units_remaining > 0 && <Tooltip title="Record sale" placement="right" arrow>
                                              <IconButton aria-label="sell" onClick={() => handleAreaHabitatRowSaleClick(filteredAreaHabitat.pah_id, filteredAreaHabitat.biodiversity_units_remaining)}>
                                                <SellOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}

                                            {filteredAreaHabitat.biodiversity_units_remaining === 0 && <Tooltip title="All units sold" placement="right" arrow sx={{ mt: 1 }}>
                                              <LockRounded></LockRounded>
                                            </Tooltip>}

                                            {projectDetails && projectDetails.can_edit && <Tooltip title="Delete record" placement="right" arrow>
                                              <IconButton aria-label="edit" onClick={() => handleDeleteHabitat(filteredAreaHabitat.pah_id, 'area')}>
                                                <DeleteOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}

                                          </Stack>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true).length > 0 &&
                          projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === false).length > 0 && (
                            <Divider sx={{ mb: 2 }} />
                          )}
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true).length > 0 && (
                          <>
                            <Typography variant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Units Sold</b>
                            </Typography>
                            <TableContainer>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Area (hectares)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Unit Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true).map((filteredAreaHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredAreaHabitat.areaHabitat.pah_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredAreaHabitat.pah_habitat}</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.pah_area}</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredAreaHabitat.pah_total_price / filteredAreaHabitat.pah_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  ))}
                {!isLoading && (
                  projectDetails.totalHedgerowHabitats > 0 && (
                    <Paper
                      sx={{
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        mt: '15px'
                      }}
                      variant='outlined'
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={9} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                            <GrassOutlinedIcon color="primary" />
                            <Typography variant="subtitle1" color="text.primary">
                              <b>Hedgerow Units</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                            <b><NumericFormat value={projectDetails.totalHedgerowPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mb: '32px' }} />
                      <Grid container spacing={2} sx={{ p: 2, pr: 0 }}>
                        {projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === false).length > 0 && (
                          <>
                            <Typography variant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Available Units</b>
                            </Typography>
                            <TableContainer>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Length (km)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Unit Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === false).map((filteredHedgerowHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredHedgerowHabitat.phh_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredHedgerowHabitat.phh_habitat}</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.phh_area}</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredHedgerowHabitat.phh_total_price / filteredHedgerowHabitat.phh_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      {projectDetails.p_closed_status === false && (
                                        <TableCell align="right">
                                          <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                            {projectDetails && projectDetails.can_edit && <IconButton aria-label="edit" onClick={() => handleHedgerowHabitatRowEdit(filteredHedgerowHabitat)}>
                                              <EditIcon />
                                            </IconButton>}

                                            {filteredHedgerowHabitat.biodiversity_units_remaining > 0 && <Tooltip title="Record sale" placement="right" arrow>
                                              <IconButton aria-label="sell" onClick={() => handleHedgerowHabitatRowSaleClick(filteredHedgerowHabitat.phh_id, filteredHedgerowHabitat.biodiversity_units_remaining)}>
                                                <SellOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}

                                            {filteredHedgerowHabitat.biodiversity_units_remaining === 0 && <Tooltip title="All units sold" placement="right" arrow sx={{ mt: 1 }}>
                                              <LockRounded></LockRounded>
                                            </Tooltip>}

                                            {projectDetails && projectDetails.can_edit && <Tooltip title="Delete record" placement="right" arrow>
                                              <IconButton aria-label="edit" onClick={() => handleDeleteHabitat(filteredHedgerowHabitat.phh_id, 'hedgerow')}>
                                                <DeleteOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}
                                          </Stack>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                        {projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === true).length > 0 &&
                          projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === false).length > 0 && (
                            <Divider />
                          )}
                        {projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === true).length > 0 && (
                          <>
                            <Typography vvariant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Units Sold</b>
                            </Typography>
                            <TableContainer>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Length (km)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_hedgerow_habitats.filter(hedgerowHabitat => hedgerowHabitat.phh_closed_status === true).map((filteredHedgerowHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredHedgerowHabitat.phh_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredHedgerowHabitat.phh_habitat}</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.phh_area}</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredHedgerowHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredHedgerowHabitat.phh_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  ))
                }
                {!isLoading && (
                  projectDetails.totalWatercourseHabitats > 0 && (
                    <Paper
                      sx={{
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        mt: '15px'
                      }}
                      variant='outlined'
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={9} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                            <WaterIcon color="primary" />
                            <Typography variant="subtitle1" color="text.primary">
                              <b>Watercourse Units</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                            <b><NumericFormat value={projectDetails.totalWatercoursePrice} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mb: '32px' }} />
                      <Grid container spacing={2} sx={{ p: 2, pr: 0 }}>
                        {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === false).length > 0 && (
                          <>
                            <Typography variant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Available Units</b>
                            </Typography>
                            <TableContainer>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Length (km)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === false).map((filteredWatercourseHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredWatercourseHabitat.pwh_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredWatercourseHabitat.pwh_habitat}</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.pwh_area}</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredWatercourseHabitat.pwh_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      {projectDetails.p_closed_status === false && (
                                        <TableCell align="right">
                                          <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                            {projectDetails && projectDetails.can_edit && <IconButton aria-label="edit" onClick={() => handleWatercourseHabitatRowEdit(filteredWatercourseHabitat)}>
                                              <EditIcon />
                                            </IconButton>}

                                            {filteredWatercourseHabitat.biodiversity_units_remaining > 0 && <Tooltip title="Record sale" placement="right" arrow>
                                              <IconButton aria-label="sell" onClick={() => handleWatercourseHabitatRowSaleClick(filteredWatercourseHabitat.pwh_id, filteredWatercourseHabitat.biodiversity_units_remaining)}>
                                                <SellOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}

                                            {filteredWatercourseHabitat.biodiversity_units_remaining === 0 && <Tooltip title="All units sold" placement="right" arrow sx={{ mt: 1 }}>
                                              <LockRounded></LockRounded>
                                            </Tooltip>}

                                            {projectDetails && projectDetails.can_edit && <Tooltip title="Delete record" placement="right" arrow>
                                              <IconButton aria-label="edit" onClick={() => handleDeleteHabitat(filteredWatercourseHabitat.pwh_id, 'watercourse')}>
                                                <DeleteOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>}
                                          </Stack>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                        {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).length > 0 &&
                          projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === false).length > 0 && (
                            <Divider />
                          )}
                        {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).length > 0 && (
                          <>
                            <Typography vvariant="subtitle1" color="primary" sx={{ pb: 1 }}>
                              <b>Units Sold</b>
                            </Typography>
                            <TableContainer>
                              <Table aria-label="caption table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                                    <TableCell>Habitat</TableCell>
                                    <TableCell align="right">Length (km)</TableCell>
                                    <TableCell align="right">Units Remaining</TableCell>
                                    <TableCell align="right">Units Sold</TableCell>
                                    <TableCell align="right">Price (£)</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).map((filteredWatercourseHabitat) => (
                                    <TableRow key={uuidv4()} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                                        {filteredWatercourseHabitat.pwh_broad_habitat}
                                      </TableCell>
                                      <TableCell>{filteredWatercourseHabitat.pwh_habitat}</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.pwh_area}</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.biodiversity_units_remaining
                                      }</TableCell>
                                      <TableCell align="right">{filteredWatercourseHabitat.units_sold}</TableCell>
                                      <TableCell align="right">
                                        <NumericFormat value={filteredWatercourseHabitat.pwh_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  ))
                }
                {isLoading && (
                  <>
                    <Grid container spacing={2} sx={{ pl: '6px' }}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h5"><Skeleton /></Typography>
                        <Typography variant="body"><Skeleton /></Typography>
                        <Typography variant="body"><Skeleton height={100} /></Typography>
                      </Grid>
                    </Grid>
                    <Stack spacing={2} mt={2}>
                      <Skeleton variant="rounded" height={80} />
                      <Skeleton variant="rounded" height={200} />
                      <Skeleton variant="rounded" height={200} />
                    </Stack>
                  </>
                )}

                <Divider sx={{ mt: 6, mb: 6 }} />

                {projectDetails?.answer_forms?.length > 0 && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                  Information provided:
                </Typography>}

                {projectDetails?.answer_forms?.map((form, index) => {
                  return <Accordion
                    key={index}
                    expanded={expanded === `panel-${index}`}
                    onChange={handleChange(`panel-${index}`)}>

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ alignItems: 'center', justifyContent: 'center' }}

                    >
                      <List sx={{ p: 0 }}>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemIcon>
                            <IconPicker icon="form" color='primary' />
                          </ListItemIcon>
                          <ListItemText primary={form.question_form?.form_name} />
                        </ListItem>
                      </List>
                    </AccordionSummary>
                    <AccordionDetails>
                      {projectDetails.can_edit && <Button
                        variant='outlined'
                        endIcon={<EditSharp />}
                        className="removeUppercase"
                        onClick={() => goToEditQuestionPage(form)}
                        // sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
                        sx={{ mb: 2, float: 'right' }}
                        color='info'
                      >
                        Edit answers
                      </Button>}

                      <AnswerFormDisplay key={index} suppliedAnswers={form.answers} />
                    </AccordionDetails>
                  </Accordion>
                })}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container justifyContent="space-between">
                <Grid item key={uuidv4()}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    Summary
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {!isLoading && <>

                    <Grid item sx={{ mb: 2 }}>
                      <Box>
                        <Chip
                          label={projectDetails?.admin_status?.status_name}
                          variant="outlined"
                          sx={{
                            justifyContent: "flex-end",
                            ml: "auto",
                            float: "right",
                            color: projectDetails?.admin_status?.color || "primary",
                            borderColor: projectDetails?.admin_status?.color || "primary"
                          }}
                        />
                      </Box>
                    </Grid>
                    {/* <ShallowStatusDialog
                      projectId={projectDetails.p_id}
                      shallowStatus={projectDetails.shallow_status}
                      shallowStatusDialogActive={shallowStausDialogActive}
                      statusUpdated={handleShallowStatusUpdated}>
                    </ShallowStatusDialog> */}
                    {projectDetails && hub.hubDetails.main_hub && <DialogChangeShallowStatus
                      projectId={projectDetails.p_id}
                      statusCode={projectDetails.shallowStatus?.status_code}
                      active={shallowStausDialogActive}
                      onCancel={() => handleShallowStatusUpdated(true)}
                      onUpdate={() => handleShallowStatusUpdated()}
                      projectType={'BNG_PROJECT'}
                      isAdmin={false}
                    />}

                    {/* {!projectDetails?.shallow_status?.open_edit_allowed &&
                      <Grid item xs={12} md={12} lg={8} sx={{ mb: 2 }}>
                        <Box>
                          <Chip
                            label={projectDetails?.shallow_status?.status_name}
                            variant="outlined"
                            sx={{
                              justifyContent: "flex-end",
                              ml: "auto",
                              float: "right",
                              color: projectDetails?.shallow_status?.color || "primary",
                              borderColor: projectDetails?.shallow_status?.color || "primary"
                            }}
                          />
                        </Box>
                      </Grid>} */}
                    {/* {projectDetails?.shallow_status?.open_edit_allowed && <Box>
                      <Button
                        id="status-button"
                        variant='outlined'
                        // color={}
                        startIcon={<CircleIcon style={{ fontSize: '12px', color: projectDetails?.shallow_status?.color || 'success' }} />}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                          borderRadius: '30px',
                          justifyContent: "flex-end",
                          ml: "auto",
                          float: "right",
                          color: projectDetails?.shallow_status?.color || 'success',
                          borderColor: projectDetails?.shallow_status?.color || 'success',
                          "&:hover": {
                            backgroundColor: `${projectDetails?.shallow_status?.color}1a` || 'success',
                            borderColor: projectDetails?.shallow_status?.color || 'success',
                          }

                        }}
                        className='removeUppercase'
                        onClick={e => handleStatusDropDownClick(e.currentTarget)}
                        aria-controls={statusDropdownOpen ? 'status-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={statusDropdownOpen ? 'true' : undefined}
                      >
                        {projectDetails?.shallow_status?.status_name || projectDetails.status}
                      </Button>
                      <DropDownMenu
                        id="status-menu"
                        MenuListProps={{
                          'aria-labelledby': 'status-button',
                        }}
                        anchorEl={statusAnchorEl}
                        open={statusDropdownOpen}
                        onClose={handleStatusDropDownClose}
                        sx={{ padding: '16px' }}
                      >
                        {shallowStatuses.map((status, index) => {
                          return <MenuItem key={index}
                            onClick={() => updateProjectShallowStatus(status.status_code)}
                            disableRipple
                          >
                            <CircleIcon style={{ fontSize: '12px', color: status.color }} />
                            {status.status_name}
                          </MenuItem>
                        })}
                      </DropDownMenu>
                    </Box>} */}
                  </>
                  }
                </Grid>
                {!isLoading && (
                  <ProjectSummary projectId={projectDetails.p_id} refreshEnquiries={refreshEnquiries} refreshEnquiriesActive={refreshEnquiriesActive} />
                )}
                {isLoading && (
                  <Stack spacing={2} mt={2}>
                    <Skeleton variant="rounded" height={90} />
                    <Skeleton variant="rounded" height={90} />
                    <Skeleton variant="rounded" height={90} />
                    <Skeleton variant="rounded" height={90} />
                    <Skeleton variant="rounded" height={90} />
                  </Stack>
                )}
              </Grid>
            </Paper>

            <Paper
              sx={{
                p: 4,
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                History
              </Typography>
              {!isLoading && (
                <ProjectHistory projectHistoryRecord={projectDetails?.project_history} />
              )}
              {isLoading && (
                <Stack spacing={2} mt={2}>
                  <Skeleton variant="rounded" height={90} />
                  <Skeleton variant="rounded" height={90} />
                  <Skeleton variant="rounded" height={90} />
                  <Skeleton variant="rounded" height={90} />
                  <Skeleton variant="rounded" height={90} />
                </Stack>
              )}
            </Paper>

            {projectDetails?.project_comments?.length > 0 &&
              <ProjectApprovalMessageContainer
                sectionTitle='Approval comment history'
                projectComments={projectDetails.project_comments}
                adminStatusCode={projectDetails.admin_status.status_code}>
              </ProjectApprovalMessageContainer>
            }
          </Grid>
        </Grid >
      </Container >}
    </>
  );
};

export default ProjectDetails;