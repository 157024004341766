import { useContext, useEffect, useState } from 'react'
import { HubDetailsContext } from '../../context/hub-details'

export const CompanyDisplayName = ({ overrideValue }) => {

  const hubContext = useContext(HubDetailsContext);
  const [displayName, setDisplayName] = useState('Gaia');

  useEffect(() => {
    if (hubContext) {
      setDisplayName(hubContext.hubDetails?.organisation_settings?.organisation_name);
    }
  }, [hubContext])

  return (overrideValue ? overrideValue : displayName)

}