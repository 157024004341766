import React, { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Habitats from '../../../shared/data/habitats';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { Alert, Grid } from '@mui/material';

const SellerHabitatDialog = (props) => {

  const [error, setError] = useState();

  const getHabitatArray = () => {
    switch (props.createdProject.project_habitats[props.index]['broadHabitat']) {
      case 'Cropland':
        return Habitats.croplandHabitats
      case 'Grassland':
        return Habitats.grasslandHabitats
      case 'Heathland and shrub':
        return Habitats.heathlandHabitats
      case 'Lakes':
        return Habitats.lakesHabitats
      case 'Sparsely vegetated land':
        return Habitats.sparselyVegetatedHabitats
      case 'Urban':
        return Habitats.urbanHabitats
      case 'Wetland':
        return Habitats.wetlandHabitats
      case 'Woodland and forest':
        return Habitats.woodlandHabitats
      case 'Coastal lagoons':
        return Habitats.coastalLagoonsHabitats
      case 'Rocky shore':
        return Habitats.rockyShoreHabitats
      case 'Coastal salt marsh':
        return Habitats.coastalSaltmarshHabitats
      case 'Intertidal sediment':
        return Habitats.intertidalSedimentHabitats
      case 'Intertidal hard structures':
        return Habitats.intertidalHardStructuresHabitats
      case 'Watercourse footprint':
        return Habitats.watercourseFootprintHabitats
      case 'Individual trees':
        return Habitats.individualTreesHabitats
      case 'Hedgerow':
        return Habitats.hedgerowHabitats
      case 'Watercourse':
        return Habitats.watercourseHabitats
      default:
        return []
    }
  }

  // Check for any missing data, otherwise close the dialog.
  function saveData() {
    if (!props.createdProject.project_habitats[props.index]['broadHabitat'] ||
      // !props.createdProject.project_habitats[props.index]['habitat'] ||
      !props.createdProject.project_habitats[props.index]['units'] ||
      !props.createdProject.project_habitats[props.index]['totalPrice']) {
      setError('Please enter all the required information below');
    } else {
      setError(undefined);
      props.handleHabitatDialogClose(props.index, true);
    }
  }

  return (
    <Dialog open={props.habitatFormActive} onClose={props.showHabitatForm}>
      <DialogTitle sx={{ pb: '5px' }}>{props.habitatFormFunction} Habitat</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Enter the habitat that has been created or enhanced.
        </DialogContentText>

        {error && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px", mb: "10px" }}>
          <Alert severity="error">{error}</Alert>
        </Grid>}
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth margin='normal'>
            {props.createdProject.project_habitats[props.index]['biodiversityType'] === 'area' && (
              <Autocomplete
                disablePortal
                id="area-broad-habitat"
                options={Habitats.broadHabitatsAutocomplete}
                value={props.createdProject.project_habitats[props.index]['broadHabitat']}
                renderInput={(params) => <TextField {...params} label="Broad Habitat*" />}
                onInputChange={(event, value) => props.setHabitatData(props.index, 'broadHabitat', value)}
              />
            )}
            {props.createdProject.project_habitats[props.index]['biodiversityType'] === 'hedgerow' && (
              <Autocomplete
                disablePortal
                id="hedgerow-broad-habitat"
                options={Habitats.hedgerowBroadHabitatsAutocomplete}
                value={props.createdProject.project_habitats[props.index]['broadHabitat']}
                renderInput={(params) => <TextField {...params} label="Broad Habitat*" />}
                onInputChange={(event, value) => props.setHabitatData(props.index, 'broadHabitat', value)}
              />
            )}
            {props.createdProject.project_habitats[props.index]['biodiversityType'] === 'watercourse' && (
              <Autocomplete
                disablePortal
                id="watercourse-broad-habitat"
                options={Habitats.watercourseBroadHabitatsAutocomplete}
                value={props.createdProject.project_habitats[props.index]['broadHabitat']}
                renderInput={(params) => <TextField {...params} label="Broad Habitat*" />}
                onInputChange={(event, value) => props.setHabitatData(props.index, 'broadHabitat', value)}
              />
            )}
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              id="habitat"
              options={getHabitatArray()}
              value={props.createdProject.project_habitats[props.index]['habitat']}
              renderInput={(params) => <TextField {...params} label="Habitat" />}
              onInputChange={(event, value) => props.setHabitatData(props.index, 'habitat', value)}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label={(props.createdProject.project_habitats[props.index]['biodiversityType'] === 'hedgerow' || props.createdProject.project_habitats[props.index]['biodiversityType'] === 'watercourse') ? "Length (km)" : "Area (hectares)"}
              id="area"
              type="number"
              value={props.createdProject.project_habitats[props.index]['area']}
              onChange={e => props.setHabitatData(props.index, 'area', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Biodiversity Units*"
              id="units"
              type="number"
              value={props.createdProject.project_habitats[props.index]['units']}
              onChange={e => props.setHabitatData(props.index, 'units', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <InputLabel htmlFor="total-price">Price per unit*</InputLabel>
            <OutlinedInput
              id="total-price"
              startAdornment={<InputAdornment position="start">£</InputAdornment>}
              fullWidth
              className="formField"
              disabled={!props.createdProject.project_habitats[props.index]['units']}
              label="Price per unit*"
              type="number"
              step="any"
              value={props.createdProject.project_habitats[props.index]['units'] && props.createdProject.project_habitats[props.index]['totalPrice'] ? (props.createdProject.project_habitats[props.index]['totalPrice'] / (props.createdProject.project_habitats[props.index]['units'])).toFixed(0) : ''}
              onChange={e => props.setHabitatData(props.index, 'totalPrice', e.target.value * props.createdProject.project_habitats[props.index]['units'])}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox
              checked={props.createdProject.project_habitats[props.index]['hidePrice']}
              onChange={e => props.setHabitatData(props.index, 'hidePrice', !!e.target.checked)}
            />}
            label="Hide price from buyers"
            sx={{ color: "#00000099" }} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={() => props.handleHabitatDialogClose(props.index, false)} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => saveData()}
          className="removeUppercase">
          {props.createdProject.project_habitats[props.index]['saved'] ? 'Update' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SellerHabitatDialog