import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { HubDetailsContext } from '../context/hub-details';


const HomePage = () => {
  const navigate = useNavigate();
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    if (hub.hubDetails) {
      navigate(`${hub.hubDetails.home_page_url}`);
    }
  }, [hub])
};

export default HomePage