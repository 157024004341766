export const ImageName = {
  Trees: 'trees',
  Environment: 'environment',
  Growing: 'growing-plant',
  Protect: 'protect',
  World: 'world',
  Ecology: 'ecology',
  Biology: 'biology',
  Recycle: 'recycle',
  Nature: 'nature',
  Leaves: 'leaves',
  Windmills: 'windmills'
}

export const CornwallImageName = {
  Wall: 'cornish_wall',
  Grass: 'grass',
  Handshake: 'hand_shake',
  Marsh: 'marsh',
  MoneyTree: 'money_tree',
  Money: 'money',
  Ocean: 'ocean',
  Pond: 'pond',
  Stream: 'stream',
  Tree: 'tree',
  Buyer: 'buyers_icon',
  Investor: 'investors_icon',
  Funder: 'funders_icon'
}