import { useContext, useEffect, useState } from 'react';
import { Box, Card, CardActionArea, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Toolbar, Typography } from '@mui/material';
import { HubDetailsContext } from '../../context/hub-details';
import { CalendarMonth, NewspaperRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import EventService from '../../services/event-service';
import moment from 'moment/moment';

export const NewsPage = () => {

  const hubContext = useContext(HubDetailsContext);
  const eventService = new EventService();

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const lincEvents = [
    { label: 'LINC launch at Planet C Conference', date: '30th April', link: 'https://www.cornwallchamber.co.uk/events/details/planet-c-spring-conference-11574' },
    { label: 'LINC at UKREiiF', date: '21 - 23rd May', link: 'https://www.ukreiif.com/' },
    { label: 'LINC at Cornwall Chamber of Commerce Big Breakfast', date: '29th May', link: 'https://www.cornwallchamber.co.uk/events/details/2024-may-big-breakfast-the-pig-haryln-bay-11342' },
    { label: 'LINC at Royal Cornwall Show', date: '6 - 8th June', link: 'https://www.royalcornwallshow.org/' },
    { label: 'LINC at Cornwall Business Fair', date: '17th July', link: 'https://www.cornwallchamber.co.uk/cornwall-business-fair-2024' }
  ];

  const lincPress = [
    { label: 'Launch of new programme to boost investment in nature in Cornwall - Cornwall Council', date: '30th April 2024', link: 'https://www.cornwall.gov.uk/council-news/environment-culture-and-planning/launch-of-new-programme-to-boost-investment-in-nature-in-cornwall/' },
    { label: 'Cornish nature investment project launched - BBC News', date: '30th April 2024', link: 'https://www.bbc.co.uk/news/articles/c1ej7x02p91o' },
    { label: 'Nature investment project launched in Cornwall (aol.com)', date: '30th April 2024', link: 'https://www.aol.com/nature-investment-project-launched-cornwall-182959684.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAIE0wx4Mz3h5VeflpzawT2zApV13-mebGLV3N4phjo8z3PgRJHZWvEuYY9oHtbSDfE8FpzQwp3J2q95Vc6MndB_RzysGSYCteDrb8gXOxRM_ssnbz5uqAgALf3-jq6psfcOQryDcofmmmUEpp26v5OUaiyFUN2ACBxHDN9vZ3rYd' },
    { label: 'Nature investment project launched in Cornwall (yahoo.com)', date: '30th April 2024', link: 'https://finance.yahoo.com/news/nature-investment-project-launched-cornwall-182959115.html?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAFgQb_TEvs_L5rJYbJOdxEC2RTrJuOIly6qOUDcM4mMRNb5Loq19ph2WD7RSiF6YnCZ3j9wXV0KYTYArYquRb_bkGwrta5S5ZWIFvCjw6rdM9Wncgc9L5x06NO_mOiajM0NVUfbGDLbNbZAngdZ7XMcCIuCGoIk59c3oPYpB95bF' },
    { label: 'Cornwall Launches new programme to boost investment in nature - Cornish Stuff', date: '30th April 2024', link: 'https://cornishstuff.com/2024/04/30/cornwall-launches-new-programme-to-boost-investment-in-nature/' },
    { label: 'Investment in nature programme launched | Business Cornwall', date: '3rd May 2024', link: 'https://businesscornwall.co.uk/news-by-industry/public-sector-news-categories/2024/05/investment-in-nature-programme-launched/?utm_source=rss&utm_medium=rss&utm_campaign=investment-in-nature-programme-launched' }

  ];

  useEffect(() => {
    eventService.getAllEvents()
      .then(response => {
        setNews(response.filter(item => item.event_type === 'news'));
        setEvents(response.filter(item => item.event_type === 'event'));
      });
  }, []);

  return (<>
    <Toolbar />
    <Box
      sx={{
        height: '45vh',
        mt: 2,
        backgroundImage: `url(${hubContext.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={7} lg={7}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              News and Events
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              All the latest news and events
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Container maxWidth='lg' sx={{ mt: 2 }}>
      {events.length > 0 && <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant='h6' sx={{ pt: 1, pb: 2 }}>
          Events where LINC will feature:
        </Typography>
        {events.map(obj => {
          return <Card variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
            <CardActionArea to={obj.link_url} target={'_blank'} component={Link} sx={{ p: 2 }}>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <CalendarMonth />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('dd/MM/yyyy')}
                  />
                </ListItem>
              </List>
            </CardActionArea>
          </Card>;
        })}
      </Box>}

      {news.length > 0 && <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h6' sx={{ pt: 1, pb: 2 }}>
          News and press releases:
        </Typography>
        {news.map(obj => {
          return <Card variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
            <CardActionArea to={obj.link_url} component={Link} target={'_blank'} sx={{ p: 2 }}>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <NewspaperRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('DD MMMM yyyy')} />
                </ListItem>
              </List>
            </CardActionArea>
          </Card>;
        })}
      </Box>}
    </Container>
  </>);
};