import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { NumericFormat } from 'react-number-format';
import { ungoalsAutocomplete } from '../../data/un-goals';
import IconPicker from '../icon-picker';

const InvestmentProjectCard = ({
  id,
  title_text,
  subtitle_text,
  primaryImageURL,
  shallow_status,
  un_goals,
  investment_target,
  comingSoon,
  isMobileMode,
  externalProjectUrl,
  amountPledged
}) => {

  const [un_goals_array, setUn_goals_array] = useState([]);

  let imagePath;
  if (primaryImageURL) {
    imagePath = primaryImageURL
  } else {
    imagePath = 'https://ychef.files.bbci.co.uk/976x549/p0b6bt6f.jpg'
  }

  useEffect(() => {
    const goals_array = un_goals?.split(',');
    let new_goals_array = [];
    goals_array?.forEach(goal => {
      const goalInfo = ungoalsAutocomplete.filter((obj) => obj.id === Number(goal));
      return new_goals_array?.push(goalInfo[0]);
    })
    setUn_goals_array(new_goals_array);
  }, [])

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: '10px', minHeight: '100%', maxWidth: '100%', width: '100%', display: 'flex', alignItems: comingSoon ? 'flex-start' : 'stretch' }}
      key={id}
    >
      <CardActionArea
        component={comingSoon ? undefined : Link}
        to={comingSoon ? undefined : externalProjectUrl ? externalProjectUrl : `/hub/invest/${id}`}
        target={externalProjectUrl ? '_blank' : '_self'}
        disabled={comingSoon ? true : false}
        sx={{ alignItems: 'stretch' }}
      >
        <CardMedia
          component="img"
          height="200"
          image={imagePath}
          alt="project"
          key={uuidv4()}
        />
        <Stack direction={'row'} alignItems={'center'} sx={{
          position: "absolute",
          top: 10,
          left: 10,
        }}>
          <Chip
            label={comingSoon ? 'Coming soon' : shallow_status?.status_name || 'Available'}
            variant="outlined"
            sx={{

              backgroundColor: '#fff',
              color: shallow_status?.color || '#70942c',
              borderColor: shallow_status?.color || '#70942c'
            }}
          />

          {/* {externalProjectUrl && <Chip
            label='External project'
            variant="outlined"
            icon={<IconPicker icon='openInNew' size='small' />}
            color='primary'
            sx={{
              backgroundColor: '#fff',
              p: 1,
              ml: 1
            }}
          />} */}
        </Stack>
        <CardContent>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              fontWeight: 600,
              pt: '16px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {title_text}
          </Typography>
          <Typography variant="body2" color="text.secondary"
            sx={{
              // whiteSpace: 'nowrap',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',

              // "@supports (WebkitLineClamp: 2)": {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'initial',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              minHeight: '41px'
              // }
            }}>
            {subtitle_text}
          </Typography>
          {investment_target && !comingSoon && <>
            <LinearProgress
              variant="determinate"
              value={(amountPledged / investment_target) * 100}
              sx={{ height: '10px', borderRadius: '5px', mt: '20px', mb: '10px' }}
            />
            <Typography variant="body2" color="text.primary">
              <b><NumericFormat value={amountPledged} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b> raised of <b><NumericFormat value={investment_target} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b> requirement
            </Typography>
          </>
          }
          {un_goals_array.length > 0 && <>
            <Typography variant="body2" color="text.primary" sx={{ mt: 2 }}>
              <b>Related UN SDGs:</b>
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 1 }}>
              {un_goals_array?.map(goal => {
                return <Chip
                  key={goal.id}
                  label={goal.id}
                  sx={{
                    backgroundColor: goal.color,
                    color: '#fff',
                    fontWeight: 600,
                    pl: 1,
                    pr: 1
                  }}
                //icon={icon}
                />
              })}
            </Stack>
          </>
          }
        </CardContent>
      </CardActionArea>
    </Card >
  )
};

export default InvestmentProjectCard