import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { XAxis, YAxis, Label, ResponsiveContainer, BarChart, Bar, Tooltip, Legend } from 'recharts';

export default function BarChartWidget(props) {
  const theme = useTheme();

  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={props.data1}
          margin={{
            top: 48,
            right: 32,
            bottom: 32,
            left: 0,
          }}
        >
          <XAxis
            dataKey={props.xDataKey}
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[0, 400]}
          />
          <Tooltip cursor={false} />
          <Legend />
          <Bar
            isAnimationActive={false}
            type="monotone"
            dataKey={props.barDataKey}
            name={props.barName}
            fill={theme.palette.primary.main}
            dot={false}
            label={{ fill: '#ffffff', fontSize: 14 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
