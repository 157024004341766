import httpClient from '../../shared/http/http-client';

export default class AdminFormService {
  http;
  questionTypes;
  formableObjects;

  static instance = new AdminFormService();

  static getInstance() {
    if (AdminFormService.instance === null) AdminFormService.instance = new AdminFormService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient
  }

  /**
   * Submit a new custom form to the BE.
   * @param {*} form - The form information with all associated questions.
   * @returns - The complete form object.
   */
  async submitNewForm(form) {
    return this.http.post('admin/question/question-form', { form: form })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Edit a form.
   * @param {*} form - The form information with all associated questions.
   * @returns - The complete form object.
   */
  async editForm(formId, form, deletedQuestionIds) {
    return this.http.post(`admin/question/question-form/${formId}/edit`, { form: form, deleted_question_ids: deletedQuestionIds })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Publish a new form. Calling this unpublishes any existing forms for the same object and hub.
   * @returns - The published form object.
   */
  async publishQuestionForm(formId, formableObjectCode) {
    return this.http.post(`admin/question/question-form/publish/${formId}`, { formable_object_code: formableObjectCode })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Publish a new form. Calling this unpublishes any existing forms for the same object and hub.
   * @returns - The published form object.
   */
  async unPublishQuestionForm(formId) {
    return this.http.post(`admin/question/question-form/unpublish/${formId}`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Get a custom form with all attatched questions.
   * @param {*} formId - The ID of the form to retreive.
   * @returns - The complete form object.
   */
  async getFormWithQuestions(formId) {
    return this.http.get(`admin/question/question-form/${formId}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  };

  /**
   * Get all question forms for the current hub.
   * @returns - The list of forms, WITHOUT questions.
   */
  async getAllQuestionForms() {
    return this.http.get(`admin/question/question-form`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Get a list of all object types that a form can be attatched to.
   * @returns - The list of objects.
   */
  async getFormableObjectList() {
    if (this.formableObjects) {
      return this.formableObjects;
    } else {
      return this.http.get(`admin/question/available-form-objects`)
        .then(response => {
          this.formableObjects = response.data;
          return response.data;
        })
        .catch(err => {
          console.log(err);
          throw err;
        })
    }
  }

  /**
   * Get all question types.
   * @returns - The list of available question types.
   */
  async getAllQuestionTypes() {
    if (this.questionTypes) {
      return this.questionTypes;
    } else {
      return this.http.get(`admin/question/question-types`)
        .then(response => {
          this.questionTypes = response.data;
          return response.data;
        })
        .catch(err => {
          console.log(err);
          throw err;
        })
    }
  }

  async updateQuestion(questionId, updatedQuestion) {
    return this.http.post(`admin/question/${questionId}/update`, updatedQuestion)
      .then(response => {
        return response.data;
      })
  }

}
