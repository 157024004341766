import httpClient from '../../shared/http/http-client';

export default class AdminProjectService {
  http;

  static instance = new AdminProjectService();

  static getInstance() {
    if (AdminProjectService.instance === null) AdminProjectService.instance = new AdminProjectService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient
  }

  /**
   * Get all investment projects pending review.
   * @returns - The list of investment projects pending review.
   */
  async getAllBngProjectsPendingReview() {
    return this.http.get(`admin/bng-project/pending-review`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Get all investment projects.
   * @returns - The list of investment projects.
   */
  async getAllBngProjects() {
    return this.http.get(`admin/bng-project/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }


  async editProject(id, details) {
    return this.http.post(`admin/bng-project/${id}/edit`, details)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }


  /**
   * Get one investment project.
   * @returns - The investment project of the specified id.
   */
  async getBngProject(id) {
    return this.http.get(`admin/bng-project/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async changeStatus(id, statusCode, sendEmail, comment) {
    return this.http.post(`admin/bng-project/${id}/change-status`, { status_code: statusCode, admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async removeDecline(id, statusCode) {
    return this.http.post(`admin/bng-project/${id}/remove-decline`, { status_code: statusCode },)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async approveBngProject(id, sendEmail, comment) {
    return this.http.post(`admin/bng-project/${id}/approve`, { admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async rejectBngProject(id, sendEmail, comment) {
    return this.http.post(`admin/bng-project/${id}/reject`, { admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async addComment(id, comment) {
    return this.http.post(`admin/bng-project/${id}/comment`, { admin_comment: comment })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }


  async uploadNewProjectImage(projectId, form) {
    return this.http.post(`admin/bng-project/${projectId}/add-project-image`, form)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

  async deleteProjectImage(projectId, imageKey) {
    return this.http.post(`admin/bng-project/${projectId}/delete-image`, { image_key: imageKey })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

  async deleteProject(projectId) {
    return this.http.delete(`admin/bng-project/${projectId}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }
}
