import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../../shared/context/user-details';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { CompanyDisplayName } from '../../shared/components/presentation/company-display-name';
import { Box, Container, Typography, Grid } from '@mui/material';

export const UserInfoTopDisplay = ({ userDetails, subTitle, children, containerMaxWidth }) => {
  const userDetailsContext = useContext(UserDetailsContext);
  const hub = useContext(HubDetailsContext);
  const [user, setUser] = useState();

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      setUser(userDetailsContext.userDetails)
    }
  }, [userDetailsContext])

  return (<>
    {(user || userDetails) && <Box
      sx={{
        height: '35vh',
        //backgroundImage: "url('/hub_banner_1.jpg')",
        backgroundImage: `url(${hub.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        pl: 4,
        pr: 4
      }}
    >
      <Container maxWidth={containerMaxWidth ? containerMaxWidth : "lg"} >
        <Grid container spacing={3} sx={{ height: '30vh', alignItems: 'center' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 1, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              Hi {userDetails ? userDetails.name.split(' ')[0] : user.name.split(' ')[0]}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              {subTitle || <>Welcome to <CompanyDisplayName /></>}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>}

    <Box sx={{ mt: -12, mr: 4, ml: 4 }}>
      {children}
    </Box>

  </>)
}