
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Stack, TextField } from '@mui/material'
import React from 'react'
import CreateInvestmentProjectForm from '../investment-projects/create-investment-project-form'

export const EditInvestmentProjectDialog = (
  props
) => {
  return (
    <Dialog open={props.editFormActive} maxWidth='md' fullWidth>
      <DialogTitle sx={{ pl: 7, pr: 7, pt: 5, pb: 3 }}>Edit Investment Project</DialogTitle>
      <DialogContent>
        <Box sx={{ pl: 4, pr: 4 }}>
          <CreateInvestmentProjectForm
            projectDetails={props.projectDetails}
            closeDialog={props.handleEditClick}
            getInvestmentProject={props.getInvestmentProject}
            isAdmin={props.isAdmin} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          {/* <Button onClick={props.handleEditClick} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => props.handleUpdateProjects(
              props.updatedProjectDetails.p_summary,
            )}
            className="removeUppercase"
          >
            Update
          </Button> */}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}