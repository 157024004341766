import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import InvestmentProjectCard from '../../shared/components/investment-project/investment-project-card';
import { useEffect, useState } from 'react';

const ProjectCarousel = (props) => {
  const [projects, setProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);

  const handleNext = () => {
    let newProjects = projects;
    const first = projects.splice(0, 1);
    newProjects.push(first[0]);
    setProjects(newProjects);
    setActiveProjects(projects.slice(0, 3));
  }

  const handlePrev = () => {
    let newProjects = projects;
    const first = projects.splice(projects.length - 1, projects.length);
    newProjects.unshift(first[0]);
    setProjects(newProjects);
    setActiveProjects(projects.slice(0, 3));
  }

  useEffect(() => {
    setProjects(props.investmentProjects);
    setActiveProjects(props.investmentProjects.slice(0, 3));
  }, [props.investmentProjects])

  return (
    <Carousel
      navButtonsAlwaysVisible
      indicators={false}
      autoPlay={false}
      animation='slide'
      height='500px'
      sx={{ mt: 4 }}
      next={() => handleNext()}
      prev={() => handlePrev()}
    >
      <Grid container spacing={4} sx={{ pr: 2, pl: 2 }} >
        {activeProjects?.length > 0 && activeProjects?.map(project => (
          <Grid item xs={12} md={4} lg={4} key={project.id}>
            <InvestmentProjectCard
              id={project.id}
              title_text={project.title_text}
              subtitle_text={project.subtitle_text}
              description={project.description}
              primaryImageURL={project?.investment_project_images[0]?.location}
              shallow_status={project.shallow_status}
              un_goals={project.un_goals}
              investment_target={project.investment_target}
              externalProjectUrl={project.external_project_url}
              amountPledged={project.amount_pledged}
              comingSoon={project.coming_soon}
            />
          </Grid>
        ))}
      </Grid>
    </Carousel>
  )
}

export default ProjectCarousel;