import React, { useState } from "react";
import { NumericFormat } from 'react-number-format';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';

const InvestmentPackageCard = (props) => {

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '10px',
        mt: 2
      }}
      key={props.investment_package.id}
    >
      <Typography variant="subtitle1" color="text.primary">
        <b>{props.investment_package.title_text}</b>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {props.investment_package.description}
      </Typography>
      <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={5} lg={5}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disableElevation
            className='removeUppercase'
            //startIcon={<InfoOutlinedIcon />}
            //onClick={() => showEnquiryForm()}
            //disabled={!canSendEnquiry}
            sx={{ width: '100%' }}
          >
            Details
          </Button>
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className='removeUppercase'
            startIcon={<SpaOutlinedIcon />}
            //onClick={() => goToMessages()}
            sx={{ width: '100%' }}
          >
            Donate <NumericFormat value={props.investment_package.cost} displayType={'text'} thousandSeparator={true} prefix={'£'} style={{ paddingLeft: '5px' }} />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
};

export default InvestmentPackageCard