import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Container, Divider, Grid, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography } from '@mui/material'
import { CircularProgressBar } from './presentation/circular-progress-bar'
import { Edit, ExpandMore } from '@mui/icons-material'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab'
import IconPicker from './icon-picker'
import moment from 'moment'
import { ProjectResourceUploadButton } from './project-resources/project-resource-upload-button'
import { DownloadResourceButton } from './project-resources/download-resource-button'
import { ProjectResouceList } from './project-resources/project-resource-list'
import { useEffect } from 'react'
// import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

export const GaiaPlusProjectDetail = ({ project, user, updateStatus, isAdmin, onUpdate, customMarginTop = -12 }) => {

  const handleChangeStatus = (id, status) => {
    updateStatus({ project_task_id: id, new_status: status });
  }

  useEffect(() => {
    console.log(project)
  }, [])


  return (<>
    {project && <Box sx={{ backgroundColor: '#F3F7FA', minHeight: '100vh' }} >
      <Toolbar />
      <Box
        sx={{
          height: '35vh',
          mt: 2,
          backgroundImage: "url('/hub_banner_1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          {console.log(project)}
          <Grid container spacing={2} style={{ height: '30vh', alignItems: 'center' }}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 1, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                Hi {user.name.split(' ')[0]}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                <b>Track your BNG progress here</b>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ mt: customMarginTop, pt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper
              sx={{
                p: 5,
                pb: 4,
                border: '1px solid #a9a9a9',
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={3} lg={2}>
                  <CircularProgressBar value={project.percentageComplete} />
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                  <List sx={{ p: 0, flex: 1 }}>
                    <ListItem sx={{ p: 0, flex: 1 }}>
                      <ListItemText primary={`Active Stage: ${project.currentStage.name}`} secondary={project.currentStage.description} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
            <Timeline sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              width: '100%',
              pr: 0
            }}>
              {project.stages.map((stage, index) => (
                <TimelineItem key={index} sx={{ width: '100%', pr: 0 }}>
                  <TimelineSeparator>
                    <TimelineConnector color='primary' sx={{ visibility: index === 0 ? 'hidden' : 'visible' }} />
                    <TimelineDot color='primary' />
                    <TimelineConnector sx={{ backgroundColor: 'primary', visibility: index === project.stages.length - 1 ? 'hidden' : 'visible' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ pr: 0 }}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        borderRadius: '10px',
                        width: '100%'
                      }}
                      variant='outlined'
                    >
                      <Accordion
                        sx={{ boxShadow: 0 }}
                        defaultExpanded={project.currentStage.name === stage.name}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ flex: 1 }}
                        >
                          <List sx={{ p: 0, flex: 1 }}>
                            <ListItem sx={{ p: 0, flex: 1 }}>
                              <ListItemIcon>
                                <IconPicker icon={stage.icon} />
                              </ListItemIcon>
                              <ListItemText primary={stage.name} />
                              <Chip
                                label={stage.tasks.every(task => task.completed) ? 'Done' : stage.tasks.every(task => !task.completed && !task.in_progress) ? 'Coming up' : 'In progress'}
                                variant={stage.tasks.every(task => task.completed) ? "contained" : "outlined"}
                                color={stage.tasks.every(task => task.completed) ? 'primary' : 'primary'}
                                sx={{
                                  justifyContent: "flex-end",
                                  ml: "auto",
                                  float: "right",
                                  mr: 4
                                }}
                              />
                            </ListItem>
                          </List>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List>
                            {stage.tasks.map((task, index) => {
                              return <>
                                <ListItem key={index}>
                                  <ListItemIcon>
                                    <IconPicker icon={task.completed ? 'checkCircleFilled' : task.in_progress ? 'inProgress' : 'circleOutlined'} color='primary' />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={<>
                                      <p>{task.label}</p>
                                      {task.started_at && isAdmin && <Stack direction={'row'} alignItems={'center'} sx={{ mb: 1 }}>
                                        {task.started_at && <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          Started at:&nbsp; {moment(task.started_at).format('DD/MM/yyyy')}
                                        </Typography>}

                                        {task.completed_at && <Typography
                                          sx={{ display: 'inline', ml: 4 }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          Completed at:&nbsp; {moment(task.completed_at).format('DD/MM/yyyy')}
                                        </Typography>}

                                        {!task.completed_at && <Typography
                                          sx={{ display: 'inline', ml: 4 }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          Duration:&nbsp; {moment(task.started_at).fromNow(true)}
                                        </Typography>}

                                        {task.completed_at && <Typography
                                          sx={{ display: 'inline', ml: 4 }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          Duration:&nbsp; {moment(task.completed_at).diff(moment(task.started_at), 'days')} days
                                        </Typography>}
                                      </Stack>}
                                      {isAdmin && <Stack direction={'column'}>

                                        <Box sx={{ mb: 1 }}>
                                          <ProjectResourceUploadButton
                                            projectId={task.id}
                                            projectType={'GAIA_PLUS_TASK'}
                                            onSuccess={() => onUpdate()}
                                            buttonCopy={'Upload document'}
                                            size='SMALL'
                                            color={'info'}>
                                          </ProjectResourceUploadButton>
                                        </Box>

                                        <ProjectResouceList
                                          resources={project.project_tasks.find(p => p.gaia_plus_task_id === task.gaia_plus_task_id)?.resources}
                                          onSuccess={() => onUpdate()}
                                          hideTitle={true} />
                                      </Stack>}
                                    </>
                                    }
                                  />

                                  {!task.completed && isAdmin && <Button
                                    variant={task.in_progress ? 'contained' : 'outlined'}
                                    disableElevation
                                    className="removeUppercase"
                                    startIcon={<Edit />}
                                    onClick={() => handleChangeStatus(task.id, task.in_progress ? 'COMPLETED' : 'IN_PROGRESS')}
                                    sx={{ minWidth: '200px', width: '200px', ml: 2 }}
                                  >
                                    {task.in_progress ? 'Move to completed' : 'Start task'}
                                  </Button>}

                                </ListItem>

                              </>


                            })}
                          </List>
                        </AccordionDetails>
                      </Accordion >
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                //height: 'calc(100vh - 110px)',
                //height: '400px',
                width: '100%',
                position: 'sticky',
                top: '90px',
                borderRadius: '10px',
                border: '1px solid #a9a9a9',
              }}
            >
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
              <Box sx={{ width: '100%', mt: 3, mb: 2 }}>
                <LinearProgress variant="determinate" sx={{ height: '10px', borderRadius: '5px' }} value={project.percentageComplete} />
              </Box>
              <List dense>
                {project.stages.map((stage, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <IconPicker
                        icon={stage.tasks.every(task => task.completed) ? 'checkCircleFilled' : 'circleOutlined'}
                        color={stage.tasks.every(task => task.completed) ? "primary" : 'gray'}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={stage.name}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider sx={{ mt: 2, mb: 3 }} />
              <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                Your Details
              </Typography>
              <Typography variant="body2" color="text.primary">
                {user.name}
              </Typography>
              <Typography variant="body2" color="text.primary">
                {user.email}
              </Typography>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                Your Ecologist
              </Typography>
              <Typography variant="body2" color="text.primary">
                {project.ecologist.name}
              </Typography>

              <Divider sx={{ mt: 3, mb: 3 }} />
              <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                Your documents
              </Typography>

              {project.task_resources.length === 0 && <Typography variant="body2" color="text.primary" sx={{ fontWeight: 200, mb: 2 }}>
                No current documents
              </Typography>}

              {project.task_resources.map((resource, index) => {
                return resource.map((item, itemIndex) => {
                  return <Box sx={{ mb: 1, width: '100%' }} key={itemIndex}>
                    <DownloadResourceButton key={index} route={item.location} buttonCopy={item.name}></DownloadResourceButton>
                  </Box>
                })
              })}
            </Paper >
          </Grid>
        </Grid>
      </Container>
    </Box>}
  </>)
}