import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../context/hub-details';


const IsHub = ({ children }) => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isHubAllowed, setIsHubAllowed] = useState();

  const hub = useContext(HubDetailsContext);


  useEffect(() => {
    if (true) {
      setIsHubAllowed(true);
      setIsLoading(false);
    } else {
      setIsHubAllowed(false);
      setIsLoading(false);
    }
  }, [hub])

  return isLoading ? null : isHubAllowed ? children ? children : <Outlet /> : <Navigate to="/payment" state={{ from: location }} replace />;
}

export default IsHub;