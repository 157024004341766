import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import AdminGaiaPlusService from '../../services/admin-gaia-plus-service';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const AddGaiaPlusTask = () => {

  const [stages, setStages] = useState();
  const [details, setDetails] = useState({
    code: '',
    label: '',
    icon: '',
    gaia_plus_stage_id: ''
  });

  const userInformationContext = useContext(UserInformationProviderContext);
  const adminGaiaPlusService = new AdminGaiaPlusService();
  const navigate = useNavigate();

  const getStages = () => {
    adminGaiaPlusService.getStages()
      .then(response => {
        console.log(response)
        setStages(response)
      })
  }

  useEffect(() => {
    getStages();
  }, [])

  const handleUpdate = (formControl, value) => {
    setDetails({ ...details, [formControl]: value })
  }

  const addTask = () => {
    adminGaiaPlusService.addTask(details)
      .then(() => {
        userInformationContext.openSnackbar({ open: true, message: 'Task created.', backgroundColor: 'green' });
        navigate('/admin/gaia-plus/tasks')
      })
  }

  return (<>
    <Toolbar />
    <Box sx={{ margin: 2, mt: 2 }}>
      <Paper sx={{ padding: 4 }} >
        <Stack direction='row' spacing={2}>
          <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>Create new task</Typography>
        </Stack>

        <GaiaTextField
          label="Code"
          value={details.code}
          charchterCount={50}
          rows={1}
          placeholder="Stage code"
          formControlName='code'
          onChange={handleUpdate}>
        </GaiaTextField>

        <GaiaTextField
          label="Label"
          value={details.label}
          charchterCount={1000}
          rows={1}
          placeholder="Label"
          formControlName='label'
          onChange={handleUpdate}>
        </GaiaTextField>

        <GaiaTextField
          label="Icon"
          value={details.icon}
          charchterCount={50}
          rows={1}
          placeholder="Icon"
          formControlName='icon'
          onChange={handleUpdate}>
        </GaiaTextField>

        <FormControl fullWidth margin="normal" required sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="articleGroupLabel">Select stage</InputLabel>
          <Select
            fullWidth
            className="formField"
            label="Select stage"
            placeholder="Select stage"
            onChange={e => handleUpdate('gaia_plus_stage_id', e.target.value)}
          >
            {stages?.map((stage, index) => {
              return <MenuItem key={stage} value={stage.id}>{stage.name}</MenuItem>
            })}
          </Select>
        </FormControl>

        <Stack direction={'row'} justifyContent={'flex-end'}>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={addTask}
            startIcon={<CheckCircleOutline />}
            className='removeUppercase'
          >
            Create task
          </Button>
        </Stack>


      </Paper>
    </Box>
  </>

  )
}