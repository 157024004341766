import { useEffect, useState } from 'react';
import AdminStaticPageService from '../../../services/admin-static-page-service'
import { Avatar, Box, Button, Card, CardActionArea, Chip, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import IconPicker from '../../../../shared/components/icon-picker';

export const StaticPageList = () => {

  const adminStaticPageService = new AdminStaticPageService();

  const [pages, setPages] = useState();

  useEffect(() => {
    adminStaticPageService.getPages()
      .then(response => {
        setPages(response)
      })
  }, [])

  return (<Box p={2}>
    <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
      <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
        Static pages
      </Typography>

      <Button
        variant="outlined"
        size="medium"
        disableElevation
        className="removeUppercase"
        component={Link}
        to={`/admin/configuration/static-pages/create`}
      >
        Create new page
      </Button>

    </Stack>
    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
    {pages && pages.map((page, index) => {
      return <Box key={index} sx={{ mb: 1 }}>
        <Card
          sx={{ alignItems: 'center', justifyContent: 'center' }}
          key={index}
          variant='outlined'
        >
          <CardActionArea
            sx={{ p: 2 }}
            component={Link}
            to={`/admin/configuration/static-pages/${page.slug}`}
          >
            <Chip
              label={page.published ? 'Published' : 'Draft'}
              color={page.published ? 'success' : 'info'}
              variant="outlined"
              sx={{
                position: 'absolute',
                right: '8px',
                top: '8px',
                minWidth: '80px',
                justifyContent: "center"
              }}
            />
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: 'primary.light' }}>
                    <IconPicker icon="article" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={page.name}
                  secondary={page.published ? `Route: /hub/pages/${page.slug}` : 'Draft'}
                />
              </ListItem>
            </List>
          </CardActionArea>
        </Card>
      </Box>
    })}
  </Box>)

}