
import React, { useState, createContext, useEffect, useContext } from 'react'
import httpClient from '../http/http-client';

import Cookies from 'universal-cookie';
import { Navigate } from 'react-router-dom';
import { HubDetailsContext } from './hub-details';
import CognitoUserAuthenticationService from '../services/cognito-user-authentication-service';


export const UserDetailsContext = createContext();

export const UserDetailsProvider = props => {
  const [userDetails, setUserDetails] = useState();
  const [change, setChange] = useState(0);

  const cookies = new Cookies(null, { path: '/' });
  const hubDetailsContext = useContext(HubDetailsContext);
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);

  useEffect(() => {

    cognitoUserAuthenticationService.getCookie()
      .then(response => {

        if (response) {
          httpClient.get('/user/user-details')
            .then(response => {
              setUserDetails(response.data.user_details);
            })
            .catch(() => {
              setUserDetails(undefined);
              return <Navigate to="/login" replace />;
            })

        } else {
          setUserDetails(undefined);
        }
      })

  }, [change]);

  function forceUpdateUserDetails() {
    return new Promise(function (resolve, reject) {
      httpClient.get('/user/user-details')
        .then(response => {
          setUserDetails(response.data.user_details);
          resolve(response.data.user_details);
        })
        .catch(() => {
          setUserDetails(undefined);
          reject();
        })
    })
  }

  function clearUserDetails() {
    setUserDetails(undefined);
    cookies.remove("token", { path: '/', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : `.${hubDetailsContext.hubDetails?.cookie_domain}` });
  };

  return (
    <UserDetailsContext.Provider value={{ userDetails, change, setChange, forceUpdateUserDetails, clearUserDetails }}>
      {props.children}
    </UserDetailsContext.Provider>
  )
}