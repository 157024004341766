import httpClient from '../http/http-client';

export default class UserService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getSavedBngProjects() {
    return this.http.get(`buyer/project/saved-projects`)
      .then(response => {
        return response.data
      });
  }


  async getSavedInvestmentProjects() {
    return this.http.get(`site-user/user/investment-project/saved`)
      .then(response => {
        return response.data
      });
  }
}