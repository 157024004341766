import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import SalesService from '../../shared/services/sale-service';

export default function SalesTable() {
  const [salesLoading, setSalesLoading] = useState(0);
  const [sales, setSales] = useState([]);
  const [noSales, setNoSales] = useState(false);

  const navigate = useNavigate();
  const salesService = new SalesService();

  const setSelectedRow = (sale) => {
    navigate(`/project/${sale.project.p_id}`, {
      state: { sale }
    })
  }

  // get sales

  const getSales = async () => {
    setSalesLoading(true);

    salesService.getSales()
      .then(salesRes => {
        setSales([]);

        for (let sale of salesRes) {
          const dateSoldISO = new Date(sale.created_at.replace(' ', 'T'));
          const dateSold = dateSoldISO.toLocaleDateString('en-GB');
          sale = { ...sale, s_date_sold: dateSold };
          setSales(sales => [...sales, { ...sale }])
        }
        setSalesLoading(false);
        if (salesRes.length === 0) {
          setNoSales(true);
        }
      })
  };

  useEffect(() => {
    getSales();
  }, []);

  return (
    <>
      {noSales && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No sales have been recorded yet.</Alert>
      )}
      {!noSales && (
        <Table>
          <TableHead>
            <TableRow sx={{ fontWeight: 600 }}>
              <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Date Sold</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Listing</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Units Sold</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(salesLoading ? Array.from(new Array(8)) : sales).map((sale, index) => (
              sale ? (
                <TableRow key={index} onClick={() => setSelectedRow(sale)} hover={true}>
                  <TableCell>{sale.s_id}</TableCell>
                  <TableCell>{sale.s_date_sold}</TableCell>
                  <TableCell>{sale.project.p_summary}</TableCell>
                  <TableCell>{sale.s_units_sold}</TableCell>
                  <TableCell>
                    <NumericFormat value={sale.s_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>)}
    </>
  );
}
