import { Alert, Box, Button, Container, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../../context/user-details';
import { HubDetailsContext } from '../../context/hub-details';
import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';
import { RegistrationForm } from './registration-form';
import { LoginForm } from './login-form';
import { ChevronRight } from '@mui/icons-material';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';

export const AuthenticationPage = (props) => {

  const hubDetailsContext = useContext(HubDetailsContext);
  const userDetailsContext = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);
  const [searchParams] = useSearchParams();

  const [details, setDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    organisation: '',
    password: '',
    passwordConfirmation: '',
    role: props.role ? props.role : !!searchParams.get('role') ? searchParams.get('role') : undefined,
    hubId: hubDetailsContext.hubDetails.id
  });

  const [errors, setErrors] = useState([]);
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  const [isLogInForm, setIsLogInForm] = useState(false);
  const [roleAutoProvided, setRoleAutoProvided] = useState(false);

  useEffect(() => {
    setRoleAutoProvided(!!searchParams.get('role'));

    if (props.role) {
      setRoleAutoProvided(true)
    }
    if (props.isLogin) {
      setIsLogInForm(true)
      navigate(`/login`, { shallow: true });
    }
  }, [])

  const toggleFormType = () => {

    if (isLogInForm) {
      navigate(`/signup`, { shallow: true });
    } else {
      navigate(`/login`, { shallow: true });
    }

    setIsLogInForm(!isLogInForm);
  };

  const submitVerification = () => {
    cognitoUserAuthenticationService.confirmRegistration(details, verificationCode)
      .then(() => {
        loginUser();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const loginUser = () => {
    cognitoUserAuthenticationService.loginUser(details.email, details.password)
      .then(res => {
        let from = '/login';
        userDetailsContext.forceUpdateUserDetails()
          .then((updatedDetails) => {
            const forwardLink = sessionStorage.getItem('forward_link');
            const searchParams = sessionStorage.getItem('search');
            sessionStorage.removeItem('forward_link');
            sessionStorage.removeItem('search');

            if (updatedDetails.roles.includes('SELLER') || updatedDetails.roles.includes('ECOLOGIST')) {

              const dashboardPath = updatedDetails?.isAdmin ? '/admin/dashboard' : '/dashboard';
              from = dashboardPath;
              from = forwardLink ? forwardLink : dashboardPath;
              segmentIdentify(details.email);
              // segmentTrack(details.email);
              navigate({ pathname: from, search: searchParams, replace: true })
            } else if (updatedDetails.roles.includes('BUYER')) {
              from = forwardLink ? forwardLink : `${hubDetailsContext.hubDetails.home_page_url}`;
              segmentIdentify(details.email);
              // segmentTrack(details.email);
              navigate({ pathname: from, search: searchParams, replace: true })
            }
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const resendConfirmationCode = () => {
    cognitoUserAuthenticationService.sendConfirmationCode(details.email)
      .then(response => {
      })
      .catch(err => {
        console.log(err);
      })
  }

  const segmentIdentify = (email) => {
    window.analytics.identify(email, {
      email: email
    }, {
      integrations: {
        Intercom: {
          user_hash: "crypto.createHmac('sha256', '7tleEEJ4NRaLWRNGURs0Oz3m2y-g7t0hYEFMysGi').update(user.id).digest('hex')"
        }
      }
    })
      .then(response => {
        segmentTrack(email);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const segmentTrack = (email) => {
    window.analytics.track('user_login', {
      category: 'user_login',
      email: email
    })
      .catch(err => {
        console.log(err);
      })
  }

  return (<>

    <Box style={{
      backgroundColor: '#F0F1F3',
      minHeight: '100vh',
      backgroundImage: `url(${hubDetailsContext.hubDetails.hub_styles?.auth_image_url})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'cover'
    }}>

      <Toolbar />
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <NavLink to={hubDetailsContext.hubDetails?.home_page_url}>
              <img
                src={hubDetailsContext?.hubDetails?.hub_styles?.logo_url ? hubDetailsContext.hubDetails.hub_styles.logo_url : "/gaia_logo.png"}
                alt="GAIA logo"
                //width="122"
                height="100"
                style={{ verticalAlign: 'middle', paddingLeft: '45px', paddingBottom: '15px' }}
              />
            </NavLink>
            <Paper
              sx={{
                p: 2,
                pb: 3,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 4,
                opacity: 0.96
              }}
            >
              <Box sx={{ width: '100%', p: 3, pb: 3 }}>
                {!showAuthCode && <>

                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" sx={{ mb: 4 }}>
                      {isLogInForm ? 'Sign in' : 'Sign up for a free account'}
                    </Typography>

                    {errors && errors.map((error, index) => {
                      return <Alert key={index} severity='error' sx={{ mb: 1 }}>{error}</Alert>
                    })}

                    {!isLogInForm && <>
                      <RegistrationForm
                        roleAutoProvided={roleAutoProvided}
                        toggleFormType={toggleFormType}
                        setShowAuthCode={setShowAuthCode}
                        details={details} setDetails={setDetails}
                        setErrors={setErrors} />
                    </>}

                    {isLogInForm && <>
                      <LoginForm
                        toggleFormType={toggleFormType}
                        setShowAuthCode={setShowAuthCode}
                        details={details}
                        setDetails={setDetails}
                        closeDialog={props.closeDialog}
                        redirectOnLogin={true} />
                    </>}


                  </Box>
                </>}

                {showAuthCode && <>
                  <Typography sx={{ pb: '5px', mb: 2 }}>
                    Verify email address
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" >
                          Please enter the 6 digit verification code that has been sent to <b>{details.email}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                        <TextField
                          fullWidth
                          className="formField"
                          label="Verification Code"
                          id="verificationCode"
                          placeholder="Verification Code"
                          onChange={e => setVerificationCode(e.target.value)}
                          // value={verificationCode}
                          onKeyDown={(event) => (
                            event.key === 'Enter' ? submitVerification() : null
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={submitVerification}
                          className='removeUppercase'
                          endIcon={<ChevronRight />}
                          sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                        >
                          Submit
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={resendConfirmationCode}
                          className='removeUppercase'
                          endIcon={<ChevronRight />}
                          sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right", mr: 2 }}
                        >
                          Resend code
                        </Button>

                      </Grid>
                    </Grid>
                  </Box>
                </>}

                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <Link to={hubDetailsContext.hubDetails?.home_page_url}>
                    <Button
                      color="primary"
                      variant="text"
                      size="large"
                      disableElevation
                      className='removeUppercase'
                      fullWidth
                      sx={{ textAlign: 'center' }}
                    >
                      Back to search
                    </Button>
                  </Link>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>)
}