import httpClient from "../http/http-client";

export default class ArticleGroupService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getOneArticleGroup(id) {
    return this.http.get(`hub/article-group/${id}`)
      .then(response => {
        return response.data;
      })
  }

  async getAllArticleGroups() {
    return this.http.get('hub/article-group')
      .then(response => {
        return response.data;
      })
  }

  async searchAllArticles(searchTerm) {
    return this.http.get(`hub/article-group/search?search_term=${searchTerm}`)
      .then(response => {
        return response.data;
      })
  }
}