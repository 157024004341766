// Static data file exporting habitat arrays

// Area Habitats

export const broadHabitats = [
    'Cropland',
    'Grassland',
    'Heathland and shrub',
    'Lakes',
    'Sparsely vegetated land',
    'Urban',
    'Wetland',
    'Woodland and forest',
    'Coastal lagoons',
    'Rocky shore',
    'Coastal salt marsh',
    'Intertidal sediment',
    'Intertidal hard structures',
    'Watercourse footprint',
    'Individual trees',
];
  
export const croplandHabitats = [
    'Arable field margins cultivated annually',
    'Arable field margins game bird mix',
    'Arable field margins pollen and nectar',
    'Arable field margins tussocky',
    'Cereal crops',
    'Winter stubble',
    'Horticulture',
    'Intensive orchards',
    'Non-cereal crops',
    'Temporary grass and clover leys'
]
  
export const grasslandHabitats = [
    'Traditional orchards',
    'Bracken',
    'Floodplain wetland mosaic and CFGM',
    'Lowland calcareous grassland',
    'Lowland dry acid grassland',
    'Lowland meadows',
    'Modified grassland',
    'Other lowland acid grassland',
    'Other neutral grassland',
    'Tall herb communities (H6430)',
    'Upland acid grassland',
    'Upland calcareous grassland',
    'Upland hay meadows'
]

export const heathlandHabitats = [
    'Blackthorn scrub',
    'Bramble scrub',
    'Gorse scrub',
    'Hawthorn scrub',
    'Hazel scrub',
    'Lowland heathland',
    'Mixed scrub',
    'Mountain heaths and willow scrub',
    'Rhododendron scrub',
    'Dunes with sea buckthorn (H2160)',
    'Other sea buckthorn scrub',
    'Willow scrub',
    'Upland heathland'
]

export const lakesHabitats = [
    'Aquifer fed naturally fluctuating water bodies',
    'Ornamental lake or pond',
    'High alkalinity lakes',
    'Low alkalinity lakes',
    'Marl lakes',
    'Moderate alkalinity lakes',
    'Peat lakes',
    'Ponds (priority habitat)',
    'Ponds (non-priority habitat)',
    'Reservoirs',
    'Temporary lakes ponds and pools (H3170)'
]

export const sparselyVegetatedHabitats = [
    'Calaminarian grasslands',
    'Coastal sand dunes',
    'Coastal vegetated shingle',
    'Ruderal/Ephemeral',
    'Tall forbs',
    'Inland rock outcrop and scree habitats',
    'Limestone pavement',
    'Maritime cliff and slopes',
    'Other inland rock and scree'
]

export const urbanHabitats = [
    'Allotments',
    'Artificial unvegetated, unsealed surface',
    'Bioswale',
    'Intensive green roof',
    'Built linear features',
    'Cemeteries and churchyards',
    'Developed land; sealed surface',
    'Other green roof',
    'Facade-bound green wall',
    'Ground based green wall',
    'Ground level planters',
    'Biodiverse green roof',
    'Introduced shrub',
    'Open mosaic habitats on previously developed land',
    'Rain garden',
    'Actively worked sand pit quarry or open cast mine',
    'Sustainable drainage system',
    'Unvegetated garden',
    'Vacant or derelict land',
    'Bare ground',
    'Vegetated garden'
]

export const individualTreesHabitats = [
    'Urban tree',
    'Rural tree'
]

export const wetlandHabitats = [
    'Blanket bog',
    'Depressions on peat substrates (H7150)',
    'Fens (upland and lowland)',
    'Lowland raised bog',
    'Oceanic valley mire[1] (D2.1)',
    'Purple moor grass and rush pastures',
    'Reedbeds',
    'Transition mires and quaking bogs (H7140)'
]

export const woodlandHabitats = [
    'Felled',
    'Lowland beech and yew woodland',
    'Lowland mixed deciduous woodland',
    'Native pine woodlands',
    'Other coniferous woodland',
    "Other Scot's pine woodland",
    'Other woodland; broadleaved',
    'Other woodland; mixed',
    'Upland birchwoods',
    'Upland mixed ashwoods',
    'Upland oakwood',
    'Wet woodland',
    'Wood-pasture and parkland'
]

export const coastalLagoonsHabitats = [
    'Coastal Lagoons'
]

export const rockyShoreHabitats = [
    'High energy littoral rock',
    'High energy littoral rock - on peat, clay or chalk',
    'Moderate energy littoral rock',
    'Moderate energy littoral rock - on peat, clay or chalk',
    'Low energy littoral rock',
    'Low energy littoral rock - on peat, clay or chalk',
    'Features of littoral rock',
    'Features of littoral rock - on peat, clay or chalk'
]

export const intertidalSedimentHabitats = [
    'Littoral coarse sediment',
    'Littoral mud',
    'Littoral mixed sediments',
    'Littoral seagrass',
    'Littoral seagrass on peat, clay or chalk',
    'Littoral biogenic reefs',
    'Littoral biogenic reefs',
    'Features of littoral sediment',
    'Artificial littoral coarse sediment',
    'Artificial littoral mud',
    'Artificial littoral sand',
    'Artificial littoral muddy sand',
    'Artificial littoral mixed sediments',
    'Artificial littoral seagrass',
    'Artificial littoral biogenic reefs',
    'Littoral sand',
    'Littoral muddy sand'
]

export const coastalSaltmarshHabitats = [
    'Saltmarshes and saline reedbeds',
    'Artificial saltmarshes and saline reedbeds'
]

export const intertidalHardStructuresHabitats = [
    'Artificial hard structures',
    'Artificial features of hard structures',
    'Artificial hard structures with integrated'
]

export const watercourseFootprintHabitats = [
    'Watercourse footprint'
]

export const broadHabitatsAutocomplete = [
    {label: "Cropland"},
    {label: "Grassland"},
    {label: "Heathland and shrub"},
    {label: "Lakes"},
    {label: "Sparsely vegetated land"},
    {label: "Urban"},
    {label: "Wetland"},
    {label: "Woodland and forest"},
    {label: "Coastal lagoons"},
    {label: "Rocky shore"},
    {label: "Coastal salt marsh"},
    {label: "Intertidal sediment"},
    {label: "Intertidal hard structures"},
    {label: "Watercourse footprint"},
    {label: "Individual trees"}
];

export const croplandHabitatsAutocomplete = [
    {label: "Arable field margins cultivated annually"},
    {label: "Arable field margins game bird mix"},
    {label: "Arable field margins pollen and nectar"},
    {label: "Arable field margins tussocky"},
    {label: "Cereal crops"},
    {label: "Winter stubble"},
    {label: "Horticulture"},
    {label: "Intensive orchards"},
    {label: "Non-cereal crops"},
    {label: "Temporary grass and clover leys"}
]
  
export const grasslandHabitatsAutocomplete = [
    {label: "Traditional orchards"},
    {label: "Bracken"},
    {label: "Floodplain wetland mosaic and CFGM"},
    {label: "Lowland calcareous grassland"},
    {label: "Lowland dry acid grassland"},
    {label: "Lowland meadows"},
    {label: "Modified grassland"},
    {label: "Other lowland acid grassland"},
    {label: "Other neutral grassland"},
    {label: "Tall herb communities (H6430)"},
    {label: "Upland acid grassland"},
    {label: "Upland calcareous grassland"},
    {label: "Upland hay meadows"}
]

export const heathlandHabitatsAutocomplete = [
    {label: "Blackthorn scrub"},
    {label: "Bramble scrub"},
    {label: "Gorse scrub"},
    {label: "Hawthorn scrub"},
    {label: "Hazel scrub"},
    {label: "Lowland heathland"},
    {label: "Mixed scrub"},
    {label: "Mountain heaths and willow scrub"},
    {label: "Rhododendron scrub"},
    {label: "Dunes with sea buckthorn (H2160)"},
    {label: "Other sea buckthorn scrub"},
    {label: "Willow scrub"},
    {label: "Upland heathland"}
]

export const lakesHabitatsAutocomplete = [
    {label: "Aquifer fed naturally fluctuating water bodies"},
    {label: "Ornamental lake or pond"},
    {label: "High alkalinity lakes"},
    {label: "Low alkalinity lakes"},
    {label: "Marl lakes"},
    {label: "Moderate alkalinity lakes"},
    {label: "Peat lakes"},
    {label: "Ponds (priority habitat)"},
    {label: "Ponds (non-priority habitat)"},
    {label: "Reservoirs"},
    {label: "Temporary lakes ponds and pools (H3170)"}
]

export const sparselyVegetatedHabitatsAutocomplete = [
    {label: "Calaminarian grasslands"},
    {label: "Coastal sand dunes"},
    {label: "Coastal vegetated shingle"},
    {label: "Ruderal/Ephemeral"},
    {label: "Tall forbs"},
    {label: "Inland rock outcrop and scree habitats"},
    {label: "Limestone pavement"},
    {label: "Maritime cliff and slopes"},
    {label: "Other inland rock and scree"}
]

export const urbanHabitatsAutocomplete = [
    {label: "Allotments"},
    {label: "Artificial unvegetated, unsealed surface"},
    {label: "Bioswale"},
    {label: "Intensive green roof"},
    {label: "Built linear features"},
    {label: "Cemeteries and churchyards"},
    {label: "Developed land; sealed surface"},
    {label: "Other green roof"},
    {label: "Facade-bound green wall"},
    {label: "Ground based green wall"},
    {label: "Ground level planters"},
    {label: "Biodiverse green roof"},
    {label: "Introduced shrub"},
    {label: "Open mosaic habitats on previously developed land"},
    {label: "Rain garden"},
    {label: "Actively worked sand pit quarry or open cast mine"},
    {label: "Sustainable drainage system"},
    {label: "Unvegetated garden"},
    {label: "Vacant or derelict land"},
    {label: "Bare ground"},
    {label: "Vegetated garden"}
]

export const individualTreesHabitatsAutocomplete = [
    {label: "Urban tree"},
    {label: "Rural tree"}
]

export const wetlandHabitatsAutocomplete = [
    {label: "Blanket bog"},
    {label: "Depressions on peat substrates (H7150)"},
    {label: "Fens (upland and lowland)"},
    {label: "Lowland raised bog"},
    {label: "Oceanic valley mire[1] (D2.1)"},
    {label: "Purple moor grass and rush pastures"},
    {label: "Reedbeds"},
    {label: "Transition mires and quaking bogs (H7140)"}
]

export const woodlandHabitatsAutocomplete = [
    {label: "Felled"},
    {label: "Lowland beech and yew woodland"},
    {label: "Lowland mixed deciduous woodland"},
    {label: "Native pine woodlands"},
    {label: "Other coniferous woodland"},
    {label: "Other Scot's pine woodland"},
    {label: "Other woodland; broadleaved"},
    {label: "Other woodland; mixed"},
    {label: "Upland birchwoods"},
    {label: "Upland mixed ashwoods"},
    {label: "Upland oakwood"},
    {label: "Wet woodland"},
    {label: "Wood-pasture and parkland"}
]

export const coastalLagoonsHabitatsAutocomplete = [
    {label: "Coastal Lagoons"}
]

export const rockyShoreHabitatsAutocomplete = [
    {label: "High energy littoral rock"},
    {label: "High energy littoral rock - on peat, clay or chalk"},
    {label: "Moderate energy littoral rock"},
    {label: "Moderate energy littoral rock - on peat, clay or chalk"},
    {label: "Low energy littoral rock"},
    {label: "Low energy littoral rock - on peat, clay or chalk"},
    {label: "Features of littoral rock"},
    {label: "Features of littoral rock - on peat, clay or chalk"}
]

export const intertidalSedimentHabitatsAutocomplete = [
    {label: "Littoral coarse sediment"},
    {label: "Littoral mud"},
    {label: "Littoral mixed sediments"},
    {label: "Littoral seagrass"},
    {label: "Littoral seagrass on peat, clay or chalk"},
    {label: "Littoral biogenic reefs"},
    {label: "Littoral biogenic reefs"},
    {label: "Features of littoral sediment"},
    {label: "Artificial littoral coarse sediment"},
    {label: "Artificial littoral mud"},
    {label: "Artificial littoral sand"},
    {label: "Artificial littoral muddy sand"},
    {label: "Artificial littoral mixed sediments"},
    {label: "Artificial littoral seagrass"},
    {label: "Artificial littoral biogenic reefs"},
    {label: "Littoral sand"},
    {label: "Littoral muddy sand"}
]

export const coastalSaltmarshHabitatsAutocomplete = [
    {label: "Saltmarshes and saline reedbeds"},
    {label: "Artificial saltmarshes and saline reedbeds"}
]

export const intertidalHardStructuresHabitatsAutocomplete = [
    {label: "Artificial hard structures"},
    {label: "Artificial features of hard structures"},
    {label: "Artificial hard structures with integrated"}
]

export const watercourseFootprintHabitatsAutocomplete = [
    {label: "Watercourse footprint"}
]


// Hedgerow Habitats


export const hedgerowBroadHabitats = [
    'Hedgerow'
]

export const hedgerowHabitats = [
    'Species-rich native hedgerow with trees - associated with bank or ditch',
    'Species-rich native hedgerow with trees',
    'Species-rich native hedgerow - associated with bank or ditch',
    'Native hedgerow with trees - associated with bank or ditch',
    'Species-rich native hedgerow',
    'Native hedgerow - associated with bank or ditch',
    'Native hedgerow with trees',
    'Ecologically valuable line of trees',
    'Ecologically valuable line of trees - associated with bank or ditch',
    'Native hedgerow',
    'Line of trees',
    'Line of trees - associated with bank or ditch',
    'Non-native and ornamental hedgerow'
];

export const hedgerowBroadHabitatsAutocomplete = [
    {label: "Hedgerow"}
]

export const hedgerowHabitatsAutocomplete = [
    {label: "Species-rich native hedgerow with trees - associated with bank or ditch"},
    {label: "Species-rich native hedgerow with trees"},
    {label: "Species-rich native hedgerow - associated with bank or ditch"},
    {label: "Native hedgerow with trees - associated with bank or ditch"},
    {label: "Species-rich native hedgerow"},
    {label: "Native hedgerow - associated with bank or ditch"},
    {label: "Native hedgerow with trees"},
    {label: "Ecologically valuable line of trees"},
    {label: "Ecologically valuable line of trees - associated with bank or ditch"},
    {label: "Native hedgerow"},
    {label: "Line of trees"},
    {label: "Line of trees - associated with bank or ditch"},
    {label: "Non-native and ornamental hedgerow"}
];

// Watercourse Habitats

export const watercourseBroadHabitats = [
    'Watercourse'
]

export const watercourseHabitats = [
    'Priority habitat',
    'Other rivers and streams',
    'Ditches',
    'Canals',
    'Culvert'
];

export const watercourseBroadHabitatsAutocomplete = [
    {label: "Watercourse"}
]

export const watercourseHabitatsAutocomplete = [
    {label: "Priority habitat"},
    {label: "Other rivers and streams"},
    {label: "Ditches"},
    {label: "Canals"},
    {label: "Culvert"}
];