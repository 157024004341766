import { useContext, useEffect, useState } from 'react';
import PageContentSectionService from '../../../services/page-content-section-service'
import { Box, Container, Grid, Toolbar, Typography } from '@mui/material';
import { HtmlParserDisplay } from '../../../components/html-parser-display/html-parser-display';
import { HubDetailsContext } from '../../../context/hub-details';

export const WhoPage = () => {

  const pageContentService = new PageContentSectionService();
  const [pageContentSections, setPageContentSections] = useState([]);
  const hubContext = useContext(HubDetailsContext);

  useEffect(() => {
    pageContentService.getContent(6)
      .then(response => {
        console.log(response)
        setPageContentSections(response)
      })
  }, [])

  return (<>
    <Toolbar />
    <Box
      sx={{
        height: '45vh',
        mt: 2,
        backgroundImage: `url(${hubContext.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              Who are we? Meet the team
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              The pioneering match-making platform, connecting private buyers with natural capital projects
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Container maxWidth='lg' sx={{ marginTop: 2 }}>
      <Box sx={{ margin: 3 }}>
        {pageContentSections.length > 0 && pageContentSections.map((section, index) => {
          return <Box key={index} sx={{ mb: 4 }} >
            <HtmlParserDisplay html={section.content}></HtmlParserDisplay>
          </Box>
        })}
      </Box>
    </Container>
  </>)
}