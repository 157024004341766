import { useState } from 'react';
import { DialogPdfUploader } from '../dialogs/dialog-pfd-uploader'
import { Button } from '@mui/material';
import { Upload } from '@mui/icons-material';

export const ProjectResourceUploadButton = ({
  projectId,
  projectType,
  onSuccess,
  buttonCopy,
  size = 'STANDARD',
  color
}) => {

  const handleSuccess = () => {
    setResourceDialogActive(false);
    onSuccess()
  }

  const [resourceDialogActive, setResourceDialogActive] = useState(false);

  return (<>
    {projectId && projectType && <DialogPdfUploader
      projectId={projectId}
      projectType={projectType}
      dialogActive={resourceDialogActive}
      cancelUpload={() => setResourceDialogActive(false)}
      onSuccess={handleSuccess} />}

    <Button
      variant='contained'
      color={color}
      onClick={() => setResourceDialogActive(true)}
      disableElevation
      startIcon={<Upload />}
      sx={size === 'SMALL' && { height: '24px', width: '200px' }}
      className='removeUppercase'>{buttonCopy || 'Upload resource'}</Button>
  </>)
}