import { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Toolbar, Typography } from '@mui/material';
import StaticPageService from '../services/static-page.service';
import { useNavigate, useParams } from 'react-router-dom';
import { StaticPageContentDisplay } from '../components/static-page-content-display';
import { UserInformationProviderContext } from '../context/user-information-provider';

export const StaticPage = ({ previewContent }) => {
  const staticPageService = new StaticPageService();
  const [pageContentSections, setPageContentSections] = useState([]);
  const [pageData, setPageData] = useState();
  const params = useParams();
  const userInformationContext = useContext(UserInformationProviderContext);
  const navigate = useNavigate()

  useEffect(() => {
    if (!previewContent) {
      staticPageService.getContent(params.slug)
        .then(response => {
          setPageContentSections(response.content_sections);
          setPageData(response);
        })
        .catch(err => {
          userInformationContext.openDialog({
            active: true,
            title: 'This page does not exist',
            message: 'The page you are looking for either does not exist or is no longer published.',
            hideCancelButton: true,
            buttonCopy: 'Back to home'
          })
            .then(() => {
              navigate('/')
            })
        })
    } else {
      setPageContentSections(previewContent.content_sections);
      setPageData(previewContent);
    }
  }, [])

  return (<>
    {!previewContent && <Toolbar />}
    {pageData && pageData.banner_image_url && <Box
      sx={{
        height: '45vh',
        mt: 2,
        backgroundImage: `url(${pageData.banner_image_url})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              {pageData.page_title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              {pageData.page_subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>}
    {pageData && pageContentSections && <Container maxWidth='lg' sx={{ marginTop: 2 }}>
      <Box sx={{ margin: 3 }}>
        <StaticPageContentDisplay content={pageContentSections} />
      </Box>
    </Container>}
  </>)
}