import httpClient from '../../shared/http/http-client';

export default class SiteEnquiryFormPageService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getQuestions(formableObjectCode) {
    return this.http.get(`hub/contact/site-enquiry-form?formable_object_code=${formableObjectCode}`)
      .then(response => {
        return response.data
      });
  }

  async saveEnquiryForm(form) {
    return this.http.post(`hub/contact/save-enquiry-form`, form)
      .then(response => {
        return response.data
      });
  }


}