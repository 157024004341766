import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SellIcon from '@mui/icons-material/Sell';
import httpClient from '../http/http-client';

export default function ProjectSummary(props) {

  const [projectEnquiries, setProjectEnquiries] = useState([]);
  const [projectSales, setProjectSales] = useState([]);

  // get project history

  const getProjectEnquiries = async (projectId) => {
    httpClient.get(`seller/enquiry?p=${projectId}`)
      .then(response => {
        setProjectEnquiries(response.data);
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  const getProjectSales = async (projectId) => {
    httpClient.get(`seller/sale/${projectId}`)
      .then(response => {
        setProjectSales(response.data.data);
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  useEffect(() => {
    if (props.refreshEnquiriesActive) {
      getProjectEnquiries(props.projectId);
      getProjectSales(props.projectId);
      props.refreshEnquiries();
    }
  }, [projectEnquiries, props]);

  return (
    <Grid container sx={{ mb: 1 }}>
      <Grid item xs={12} md={12} lg={6} sx={{ mt: 1 }} key={uuidv4()}>
        <Stack direction="row" spacing={1} sx={{ mt: '20px' }}>
          <AccountCircleIcon color="primary" />
          <Typography variant="p" color="text.secondary">
            <span style={{ lineHeight: '24px' }}>
              <b>Enquiries: </b>{projectEnquiries.length || 0}
            </span>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12} lg={6} sx={{ mt: 1 }} key={uuidv4()}>
        <Stack direction="row" spacing={1} sx={{ mt: '20px' }}>
          <SellIcon color="primary" />
          <Typography variant="p" color="text.secondary">
            <span style={{ lineHeight: '24px' }}>
              <b>Units sold: </b>{projectSales.reduce((accumulator, sale) => sale.s_units_sold + accumulator, 0)}
            </span>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
