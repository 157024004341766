import React from "react";
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import './article-body.css';
import { HtmlParserDisplay } from '../../html-parser-display/html-parser-display';
import { ContentCopy, Download } from '@mui/icons-material';
import { EmailIcon, EmailShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from 'react-share';
import { SocialMediaShareComponent } from '../../presentation/social-media-share';

const ArticleBodyDisplay = ({ article }) => {

  const download = () => {
    window.location.assign(article.download_url);
  }

  const copyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href)
    //setShareAlertOpen(true);
  }

  return (
    article && (
      <>
        {/* <Typography
          variant="h4"
          color="text.primary"
          sx={{
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            mb: 2
          }}
        >
          {article.title_copy}
        </Typography> */}
        {/* <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {article.subtitle_copy}
        </Typography> */}
        {/* <Typography
          variant="body1"
          color="text.primary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            mt: '20px',
            whiteSpace: 'pre-wrap'
          }}
        >
          {parse(article.body_copy)}
        </Typography> */}

        <Box sx={{ maxWidth: '100%', mt: 2 }}>
          <Stack direction="row" justifyContent="flex-end" alignItems={'center'}>
            <Tooltip title="Copy link to clipboard">
              <IconButton aria-label="share" onClick={() => copyLinkClick()} style={{ marginLeft: 0 }}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
            <SocialMediaShareComponent />
          </Stack>
          {article.body_copy && <HtmlParserDisplay html={article.body_copy}></HtmlParserDisplay>}

          {article.download_url && <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={download}
            startIcon={<Download />}
            className='removeUppercase'
            sx={{ mt: 4 }}
          >
            Download resource
          </Button>
          }
        </Box>
      </>
    )
  )
};

export default ArticleBodyDisplay