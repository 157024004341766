import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Legend, Label, Cell } from 'recharts';

export default function PieChartWidget(props) {
  const theme = useTheme();
  const COLORS = ['rgb(112, 148, 44)', 'rgb(102,153,224)', 'rgb(243,193,95)', 'rgb(219,122,114)', 'rgb(80,123,150)']

  return (
    <ResponsiveContainer minWidth='100%'>
      <PieChart width='100%'>
        <Pie
          dataKey="value"
          nameKey="name"
          data={props.data}
          cx='50%'
          cy='50%'
          innerRadius={60}
          outerRadius={100}
          fill="#70942c"
          label
        >
          {
            props?.data?.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
