import httpClient from '../../shared/http/http-client';

export default class GaiaPlusService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getProject(id) {
    return this.http.get(`seller/gaia-plus/${id}`)
      .then(response => {

        response.data.stages = formatTasksInToRequiredGroups(response.data?.tasks);

        response.data.stages = response.data.stages.sort((b, a) => b.order_index - a.order_index);
        response.data.stages.forEach(stage => {
          stage.tasks = stage.tasks.sort((b, a) => b.order_index - a.order_index)
        });

        response.data.currentStage = getCurrentStage(response.data.stages);
        response.data.percentageComplete = getPercentageComplete(response.data?.tasks)

        delete response.data.tasks
        return response.data;
      })
  }

  async getAllProjects() {
    return this.http.get(`seller/gaia-plus`)
      .then(response => {

        response.data.forEach(project => {
          project.tasks = project.tasks.map(task => {
            return formatTaskObject(task);
          })

          project.percentageComplete = getPercentageComplete(project?.tasks)
        })

        return response.data;
      })
  }
}

// Helper functions.

function formatTasksInToRequiredGroups(tasks) {
  const stages = [];
  tasks.forEach(task => {
    if (stages.find(stage => stage.code === task.stage.code)) {
      const requiredStage = stages.find(stage => stage.code === task.stage.code);
      requiredStage.tasks.push(formatTaskObject(task));
    } else {
      const stage = task.stage;
      stage.tasks = [formatTaskObject(task)];
      stages.push(stage)
    }
  })

  return stages
}

function formatTaskObject(task) {
  Object.entries(task.gaia_plus_project_task).forEach(entry => {
    task[entry[0]] = entry[1]
  })

  delete task.gaia_plus_project_task;
  delete task.stage;

  return task;
}

function getCurrentStage(formattedStages) {
  const currentStage = {};
  formattedStages.forEach(stage => {
    if (!currentStage.name) {
      if (stage.tasks.some(task => !task.completed)) {
        currentStage.name = stage.name;
        currentStage.description = stage.description
      }
    }
  })

  return currentStage;
}

function getPercentageComplete(tasks) {
  const taskCount = tasks.length;
  const completed = tasks.filter(task => task.completed).length;

  return (completed / taskCount) * 100
}



