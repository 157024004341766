import React from "react";
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../title';
import { Card, CardActionArea, Stack } from '@mui/material';

export default function Counter(props) {
  const date = new Date().toLocaleDateString('en-GB');

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        minHeight: '100%',
        borderRadius: '10px'
      }}
      elevation={0}
    >
      <CardActionArea
        component={RouterLink}
        to={props.linkTo}
        sx={{
          p: 3,
          flexGrow: 1
        }}
      >
        <Typography variant="subtitle1">
          <b>{props.title}</b>
        </Typography>
        <Typography variant="h4" color='primary' sx={{ mt: 1, fontSize: '2.5rem' }}>
          <NumericFormat
            value={props.value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={props.type === 'money' ? '£' : ''}
          />
        </Typography>
        {!props.noDate &&
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            on {date}
          </Typography>
        }
        {!props.noLink &&
          <Stack direction={'row'} justifyContent={'flex-start'} sx={{ mt: 1 }}>
            <Link
              color="primary"
              to={props.linkTo}
              component={RouterLink}
            >
              View {props.viewLabel}
            </Link>
          </Stack >
        }
      </CardActionArea>
    </Card >
  );
}
