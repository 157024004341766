import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel, IconButton, Stack, TextField } from '@mui/material';
import AdminFormService from '../../services/admin-form-service';
import { Delete, DesktopAccessDisabledRounded, EventAvailableRounded } from '@mui/icons-material';

export const DialogEditInformationQuestion = ({ currentQuestion, active, onUpdate, onCancel }) => {

  const adminFormService = AdminFormService.getInstance();

  const [question, setQuestion] = useState();
  const [questionTypes, setQuestionTypes] = useState();
  const [currentQuestionType, setCurrentQuestionType] = useState();

  useEffect(() => {
    adminFormService.getAllQuestionTypes()
      .then(response => {
        setQuestionTypes(response)
      })
  })

  useEffect(() => {
    setQuestion(currentQuestion);
    if (questionTypes && currentQuestion) {
      setCurrentQuestionType(questionTypes.find(type => type.id === currentQuestion.question_type_id));
    }
  }, [currentQuestion, questionTypes])

  const addAnswerOption = () => {
    const updated = question;
    question.controlled_answers.push({});
    setQuestion({ ...updated })
  }

  const handleUpdateControlledAnswer = (event, index) => {
    const updated = question;
    updated.controlled_answers[index].answer_text = event.target.value;
    setQuestion({ ...updated })
  }

  const handleDisableAnswer = (value, index) => {
    const updated = question;
    updated.controlled_answers[index].active = value;
    setQuestion({ ...updated })
  }

  const handleRemoveControlledAnswer = (index) => {
    const updated = question;
    updated.controlled_answers = updated.controlled_answers.filter((answer, answerIndex) => answerIndex !== index);
    setQuestion({ ...updated })
  }

  const handleUpdateQuestion = () => {
    adminFormService.updateQuestion(question.id, question)
      .then(() => {
        onUpdate()
      })
  }

  const handleChange = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.value });
  }

  const handleCheckbox = (event) => {
    setQuestion({ ...question, [event.target.name]: event.target.checked });
  }

  return (<>
    {question && <Dialog open={active}>
      <DialogTitle sx={{ pb: '5px' }}>Edit question</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Update this question below.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          {console.log(question)}
          <Box sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="answer_required"
                  disabled={question.question_type === 'SELECT' || question.question_type === 'CONFIRM'}
                  checked={question.answer_required || false}
                  onChange={handleCheckbox}
                />}
              label="An answer is required for this question"
              sx={{ width: '100%', mb: '16px' }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="show_in_public_listing"
                  checked={question.show_in_public_listing || false}
                  onChange={handleCheckbox}
                />}
              label="Show this question and answer in the public listing."
              sx={{ width: '100%', mb: '16px' }}
            />
          </Box>

          {/* {errors && errors.question && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.question}</Alert>} */}
          <TextField
            id="outlined-multiline-static"
            label="Question"
            name="question"
            value={question.question || ''}
            //multiline
            onChange={handleChange}
            minRows={2}
            placeholder='Enter a question'
            sx={{ width: '100%', mb: '16px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Display label"
            name="display_label"
            value={question.display_label || ''}
            // multiline
            onChange={handleChange}
            // rows={1}
            placeholder='If provided, will be used when displaying the answer to the public.'
            sx={{ width: '100%', mb: '16px' }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Additional help text"
            name="additional_information"
            value={question.additional_information || ''}
            multiline
            onChange={handleChange}
            rows={4}
            placeholder='Optionally enter any additional informational text for the user...'
            sx={{ width: '100%', mb: '16px' }}
          />

          {/* For question types with CONTROLLED_ANSWERS */}
          {question && currentQuestionType.has_controlled_answers && <>
            {currentQuestionType.max_answer_count > question.controlled_answers.length && <Box sx={{ display: 'flex' }}>
              <Button
                onClick={() => addAnswerOption()}
                color='primary'
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                sx={{ float: "right", mr: 8, mt: 2, mb: 2 }}
              >
                Add another answer option
              </Button>
            </Box>}

            {/* {errors && errors.controlledAnswersError && <Alert severity='error' sx={{ mt: 2, mb: 4 }}>{errors.controlledAnswersError}</Alert>} */}

            {question.controlled_answers && question.controlled_answers.map((controlledAnswer, index) => {
              return <Stack direction={'row'} key={index} alignItems={'center'} sx={{ mb: 2 }}>
                <TextField key={index}
                  id="outlined-multiline-static"
                  label={`Answer option ${index + 1}`}
                  name="controlledAnswer"
                  disabled={!!controlledAnswer.id}
                  value={controlledAnswer.answer_text || ''}
                  onChange={(event) => handleUpdateControlledAnswer(event, index)}
                  placeholder='Enter answer option...'
                  sx={{ width: '100%', color: controlledAnswer.active ? '' : 'error' }}
                />

                {controlledAnswer.active && controlledAnswer.id && <IconButton aria-label="add" onClick={() => handleDisableAnswer(false, index)}>
                  <DesktopAccessDisabledRounded />
                </IconButton>}

                {!controlledAnswer.active && controlledAnswer.id && <IconButton aria-label="add" onClick={() => handleDisableAnswer(true, index)}>
                  <EventAvailableRounded />
                </IconButton>}


                {!controlledAnswer.id && <IconButton aria-label="add" onClick={() => handleRemoveControlledAnswer(index)}>
                  <Delete />
                </IconButton>}
              </Stack>

            })}
          </>}

        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleUpdateQuestion}
          className="removeUppercase"
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog >}
  </>

  )
}