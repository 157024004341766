import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconPicker from '../../../shared/components/icon-picker';
import { HubDetailsContext } from '../../../shared/context/hub-details';

const ConfigNavBar = (props) => {

  const navigate = useNavigate();


  const [configAreas, setConfigAreas] = React.useState([
    {
      id: 1,
      title: 'Organisation Settings',
      description: 'Manage your organisation settings and configure your hub',
      icon: 'folderOpen',
      to: '/admin/configuration/branding'
    },
    {
      id: 2,
      title: 'Team',
      description: 'Manage your users and their access levels',
      icon: 'users',
      to: '/admin/configuration/team'
    },
    {
      id: 3,
      title: 'Modules',
      description: 'Enable and configure the modules of your hub',
      icon: 'modules',
      to: '/admin/configuration/modules'
    },
    {
      id: 4,
      title: 'Forms & Fields',
      description: 'Create and manage forms and fields for your hub',
      icon: 'form',
      to: '/admin/configuration/forms'
    },
    {
      id: 5,
      title: 'Workflows',
      description: 'Create and manage workflows',
      icon: 'flow',
      iconSize: 'large',
      to: '/admin/configuration/form-flow'
    },
    {
      id: 6,
      title: 'News & Events',
      description: 'Manage your news and events page.',
      icon: 'news',
      iconSize: 'large',
      to: '/admin/configuration/news-and-events'
    },
    {
      id: 7,
      title: 'Static Pages',
      description: 'Create and manage your static pages',
      icon: 'article',
      iconSize: 'large',
      to: '/admin/configuration/static-pages'
    }
  ]);

  return (
    <List sx={{ width: '100%', maxWidth: 360, p: 0 }}>
      {configAreas.map((area) => {
        return (
          <ListItemButton
            selected={props.activeConfigArea === area.title}
            //onClick={() => props.setActiveConfigArea(area.title)}
            onClick={() => navigate(area.to)}
            key={area.id}
          >
            <ListItem sx={{ alignItems: 'start', pl: 0, pr: 0 }}>
              <ListItemIcon sx={{ minWidth: '45px', pt: '2px' }}>
                <IconPicker icon={area.icon} color="primary" />
              </ListItemIcon>
              <ListItemText sx={{ mt: 0 }} primary={area.title} secondary={area.description} />
            </ListItem>
          </ListItemButton>
        );
      }
      )}
    </List>
  );
};

export default ConfigNavBar;