import { useEffect, useState } from 'react';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import DemoHubService from '../services/demo-hub-service';
import LinearProgressWithLabel from '../../shared/components/linear-progress';
import { Container } from '@mui/material';

const DemoHubLandingPage = () => {

  const [loadingMessage, setLoadingMessage] = useState('Setting up your hub, please wait...');
  const [messageIndex, setMessageIndex] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState('10');

  const loadingMessages = ['Setting up your hub. Please wait...', 'Creating databases. Please wait...', 'Creating global settings. Please wait...', 'Formating organisation data. Please wait...'];
  const loadingProgressArray = ['10', '25', '55', '80', '95'];

  const demoHubService = new DemoHubService();

  useEffect(() => {
    demoHubService.createDemoHub()
      .then(() => {
        runInterval();
        demoHubService.requestSubDomain()
          .then(response => {
            pollForSuccessfulSetUp(response.baseUrl)
          })
      })
  }, [])

  const runInterval = () => {
    setInterval(() => {
      if (messageIndex < loadingMessages.length) {
        const newIndex = messageIndex + 1;
        setMessageIndex(newIndex);
      }
    }, 8000);
  }

  const pollForSuccessfulSetUp = (baseUrl) => {
    demoHubService.checkForCompletedDomainSetUp(baseUrl)
      .then(response => {
        console.log(response)
        setTimeout(() => {
          window.open(`https://${baseUrl}`, '_self');
        }, 30000);

      })
      .catch(err => {
        console.log(err)
        setTimeout(() => {
          pollForSuccessfulSetUp();
        }, 5000);
      })
  }

  useEffect(() => {
    setLoadingProgress(loadingProgressArray[messageIndex]);
    setLoadingMessage(loadingMessages[messageIndex]);
  }, [messageIndex])

  return (<>
    <LoadingSpinner isLoading={true} loadingMessage={loadingMessage} />
    <Container maxWidth="md" sx={{ mt: '-18vh' }}>
      <LinearProgressWithLabel value={loadingProgress} />
    </Container>
  </>

  )
}

export default DemoHubLandingPage;