import httpClient from '../http/http-client';

export default class SalesService {
  http;

  constructor() {
    this.http = httpClient
  }

  /**
   * Get all projects for buyers or sellers. Optionally provide query params.
   * @param {*} userRole - The current role the user is using. buyer OR seller.
   * @param {*} params - OPTIONAL. Query params.
   * @returns - A list of available projects.
   */
  async getSales() {
    return this.http.get(`seller/sale`)
      .then(response => {
        return response.data
      });
  }


  async createSale(saleData) {
    return this.http.post(`seller/sale`, saleData)
      .then(response => {
        return response.data
      });
  }
}