import httpClient from "../../shared/http/http-client";

export default class BngMetricService {
  http;

  constructor() {
    this.http = httpClient
  }

  async createResource(file) {
    return this.http.post('buyer/bng-metric', file)
      .then(response => {
        return response.data;
      })
  }
}