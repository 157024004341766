import React, { useContext, useEffect, useState } from "react";
import EnquiryMessages from "../../shared/components/enquiry-messages";
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import httpClient from '../../shared/http/http-client';
import TollIcon from '@mui/icons-material/Toll';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { UserDetailsContext } from '../../shared/context/user-details';
import InvestmentProjectService from "../../shared/services/investment-project-service";

const EnquiryDetail = (props) => {

  const params = useParams();
  const user = useContext(UserDetailsContext);
  const investmentProjectService = new InvestmentProjectService()

  const [enquiry, setEnquiry] = useState();
  const [project, setProject] = useState();
  const [investmentProject, setInvestmentProject] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState('buyer');
  const [updated, setUpdated] = useState(0);


  useEffect(() => {
    if (user.userDetails) {
      let role;
      setUserId(user.userDetails.id)
      setUserName(user.userDetails.name)
      if (props.roles.includes('Seller')) {
        setUserRole('seller');
        role = 'seller'
      } else {
        setUserRole('buyer');
        role = 'buyer'
      }

      httpClient.get(`${role}/enquiry/${params.enquiryId}`)
        .then(response => {
          setEnquiry(response.data.enquiry);

          if (response.data.enquiry.project_p_id) {
            httpClient.get(`${role}/project/${response.data.enquiry.project_p_id}`)
              .then(response => {
                setProject(response.data);
              })
          } else if (response.data.enquiry.investment_project_id) {
            investmentProjectService.getOneInvestmentProject(response.data.enquiry.investment_project_id)
              .then(response => {
                setInvestmentProject(response);
              })
          }
        })
    }
  }, [params.enquiryId, updated, user, props.userRole])

  const handleUpdate = () => {
    setUpdated(updated + 1);
  };

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ pl: 2, pb: 3 }}>
          <Link
            underline="hover"
            color="inherit"
            to={`/${userRole === 'buyer' ? 'enquiries' : 'deals'}`}
            component={RouterLink}
          >
            My Enquiries
          </Link>
          {(project || investmentProject) && <Typography color="text.secondary">{project?.p_summary ? project.p_summary : investmentProject.title_text}</Typography>}
        </Breadcrumbs>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9} lg={9}>
            <Paper variant="outlined" sx={{ p: 4 }}>
              <EnquiryMessages
                enquiry={enquiry}
                project={project}
                investmentProject={investmentProject}
                expanded={expanded}
                userId={userId}
                setExpanded={setExpanded}
                userName={userName}
                userRole={userRole}
                onSuccess={handleUpdate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper
              //elevation={5}
              variant="outlined"
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'sticky',
                top: '90px',
                minHeight: 'calc(100vh - 110px)',
                //borderColor: '#a9a9a9', 
                borderRadius: '5px'
              }}
            >
              <Grid container>
                {project && <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    {project && project.p_local_planning_authority}
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    {project && project.p_national_character_area}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>{project && project.p_summary}</b>
                  </Typography>
                  <Typography variant="subtitle1" color="text.primary">
                    {project && project.p_description}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <TollIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Units available: </b><NumericFormat value={project && project.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                      </span>
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <SellOutlinedIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Unit price: </b>
                        {project && !project.hidePrice ? (
                          <NumericFormat value={Math.round(project && project.totalPrice / project.totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                        ) : ("POA")}
                      </span>
                    </Typography>
                  </Stack>
                </Grid>}

                {investmentProject && <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    {investmentProject.title_text}
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    {investmentProject.subtitle_text}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>{investmentProject.description}</b>
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <TollIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Investment target: </b><NumericFormat value={investmentProject.investment_target} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='£' />
                      </span>
                    </Typography>
                  </Stack>
                </Grid>}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EnquiryDetail 