/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, Chip, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import AdminFormService from '../../services/admin-form-service';
import { DeleteOutlined, EditRounded, SaveOutlined } from '@mui/icons-material';
import AdminFormFlowService from '../../services/admin-form-flow-service';


const EditFormFlow = (props) => {

  const params = useParams();
  const adminFormService = AdminFormService.instance;
  const adminFormFlowService = new AdminFormFlowService();
  const navigate = useNavigate()

  const [formFlow, setFormFlow] = useState();
  const [formableObjects, setFormableObjects] = useState();
  const [availableForms, setAvailableForms] = useState();
  const [addedForms, setAddedForms] = useState()

  const [addingNewForm, setAddingNewForm] = useState(false);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getFlow();
    getFormableObjectList();

    adminFormService.getAllQuestionForms()
      .then(response => {
        setAvailableForms(response)
      })
  }, []);

  const getFormableObjectList = () => {
    adminFormService.getFormableObjectList()
      .then(response => {
        setFormableObjects(response)
      })
  }

  const getFlow = () => {
    adminFormFlowService.getFormFlow(params.formFlowId)
      .then(response => {
        setFormFlow(response);
        setAddedForms(response.forms)
      })
  }

  const removeForm = (formId) => {
    const updated = addedForms.filter(form => form.id !== formId);
    setAddedForms([...updated])
  }

  const handleSelectForm = (event) => {
    const tempForms = addedForms
    tempForms.push(availableForms.find(form => form.id === event.target.value));
    setAddedForms([...tempForms]);
    setAddingNewForm(false);
  }

  const submitFormFlow = () => {
    const flow = formFlow;
    flow.formable_object_code = formFlow.formable_object_code
    flow.form_ids = addedForms.map(form => form.id);

    adminFormFlowService.createFormFlow(flow)
      .then(response => {
        navigate(`/admin/form-flow/${response.id}`)
      })
  }

  return (<Box sx={{ p: 2 }}>

    {formFlow && <Stack direction='row' sx={{ alignItems: 'center', marginBottom: 3 }}>
      <Stack direction='row' spacing={2}>
        <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>Workflows / {formableObjects?.find(obj => obj.code === formFlow.formable_object_code)?.name}</Typography>
        <Chip
          edge="end"
          label={formFlow.status.toLowerCase()}
          variant='outlined'
          color={formFlow.status === 'DRAFT' ? 'info' : 'primary'}
          sx={{ textTransform: 'capitalize' }}
        />
      </Stack>

      <Stack direction='row' spacing={2} sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
        <Button
          color='primary'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<SaveOutlined />}
          sx={{ textTransform: 'capitalize' }}
          onClick={() => submitFormFlow()}
        >
          Save
        </Button>
      </Stack>
    </Stack>
    }
    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />

    {formFlow && <>
      <Box sx={{ mb: 3 }}>
        {addedForms.map((form, index) => {
          return <Card
            sx={{ alignItems: 'center', justifyContent: 'center', mb: 1 }}
            key={index}
          ><List sx={{ flex: 1 }} dense>
              <ListItem
                secondaryAction={<>
                  <IconButton
                    onClick={() => removeForm(form.id)}
                    color='error'
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteOutlined />
                  </IconButton>
                </>
                }
              >
                <ListItemText
                  primary={<Stack direction='row' spacing={2}>
                    <Typography color="text.primary" variant="subtitle1">{index + 1}</Typography>
                    <Typography color="text.primary" variant="subtitle1">{form.form_name}</Typography>
                  </Stack>
                  }
                />
              </ListItem>
            </List>
          </Card>
        })}
      </Box>
    </>}

    {!addingNewForm && <Button
      onClick={() => setAddingNewForm(true)}
      color='primary'
      variant="outlined"
      disabled={(formFlow?.max_form_count && addedForms.length >= formFlow?.max_form_count)}
      size="medium"
      disableElevation
      className="removeUppercase"
      startIcon={<EditRounded />}
      sx={{ textTransform: 'capitalize' }}
    >
      Add new form
    </Button>}

    {addingNewForm && <FormControl sx={{ mb: 3 }} fullWidth>
      <InputLabel id="demo-simple-select-label">Select form to add</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Select form to add"
        value={''}
        onChange={handleSelectForm}
      >
        {availableForms && availableForms.filter(form => !addedForms.find(added => added.id === form.id)).map((form, index) => {
          return <MenuItem key={index} value={form.id}>{form.form_name}</MenuItem>
        })}
      </Select>
    </FormControl>}
  </Box>
  );
};

export default EditFormFlow 