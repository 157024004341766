import { Add } from '@mui/icons-material';
import { Button } from '@mui/material'
import { useState } from 'react'
import { DialogCreateInvestmentPledge } from './dialogs/dialog-create-investment-pledge';

export const CreateInvestmentPledgeButton = ({ projectId, existingPledge, onUpdate, buttonCopy, color }) => {

  const [dialogActive, setDialogActive] = useState(false);

  const handleOnUpdate = () => {
    setDialogActive(false);
    onUpdate();
  }

  return (<>

    <DialogCreateInvestmentPledge
      projectId={projectId}
      existingPledge={existingPledge}
      active={dialogActive}
      onUpdate={handleOnUpdate}
      onCancel={() => setDialogActive(false)}>
    </DialogCreateInvestmentPledge>

    <Button
      variant='outlined'
      color={color}
      onClick={() => setDialogActive(true)}
      disableElevation
      sx={{ width: '100%' }}
      startIcon={<Add />}
      className='removeUppercase'>{buttonCopy || 'Add pledge'}</Button>
  </>)
}