import { Accordion, Avatar, Button, Container, Grid, ListItem, ListItemAvatar, ListItemText, Paper, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import AdminSiteEnquiryFormListService from '../services/admin-site-enquiry-form-list-service';
import gaiaTheme from '../../theme';
import AnswerFormDisplay from '../../shared/components/answer-form-display';
import { UserInformationProviderContext } from '../../shared/context/user-information-provider';

export const SiteEnquiryFormDetail = () => {
  const adminSiteEnquiryFormListService = new AdminSiteEnquiryFormListService();

  const params = useParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));
  const userInformationContext = useContext(UserInformationProviderContext);

  const [form, setForm] = useState();

  useEffect(() => {
    getForm();
  }, [])

  const getForm = () => {
    adminSiteEnquiryFormListService.getForm(params.id)
      .then(response => {
        console.log(response)
        setForm(response)
      })
  }

  const markAsCompleted = () => {
    adminSiteEnquiryFormListService.markAsHandled(form.id)
      .then(response => {
        getForm()
      })
  }

  const deleteEnquiry = () => {
    userInformationContext.openDialog({
      active: true,
      title: 'Delete this user request?',
      message: 'Are you sure you would like to permanently delete this request? This action cannot be undone.'
    })
      .then(response => {
        if (response) {
          adminSiteEnquiryFormListService.deleteForm(form.id)
            .then(() => {
              navigate('/admin/site-enquiries')
            })
        }
      })
  }

  return (
    <>

      <Toolbar />
      {form && <Container maxWidth={false} sx={{ mt: 3, mb: 4 }}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper variant='outlined' sx={{ padding: 4, height: '100%', borderRadius: '10px' }}>
              <Typography variant="h5" color="text.primary" sx={{ mt: 2, mb: 4, whiteSpace: 'pre-wrap' }}>
                Information provided by this user:
              </Typography>

              {form.searched_url && <Typography variant="p" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                Searched URL: {form.searched_url}
              </Typography>}

              {form.comment && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 1, whiteSpace: 'pre-wrap' }}>
                Comment:
              </Typography>}

              {form.comment && <Typography variant="p" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                {form.comment}
              </Typography>}

              <AnswerFormDisplay suppliedAnswers={form.answers[0]?.answers} />
            </Paper>

          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                borderRadius: '10px',
                mb: 2,
              }}>

              {!form.reviewed_by_admin && <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                color='info'
                sx={{ mb: 2 }}
                onClick={() => markAsCompleted()}
              >
                Mark as completed
              </Button>}

              <Button
                variant="outlined"
                size="medium"
                disableElevation
                className="removeUppercase"
                color='error'
                onClick={() => deleteEnquiry()}
              >
                Delete
              </Button>
            </Paper>
            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
              }}
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                User Details
              </Typography>

              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {`${form.first_name.split('')[0]}${form.surname.split('')[0]}`}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${form.first_name} ${form.surname}`} secondary={form.email} />
              </ListItem>
            </Paper>

            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '10px'
              }}
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                Organisation:
              </Typography>

              <Typography variant="p" color="text.primary" sx={{ mb: 1 }}>
                {form.organisation}
              </Typography>
            </Paper>
          </Grid>
        </Grid >
      </Container >}
    </>
  );
};

