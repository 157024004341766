import httpClient from '../../shared/http/http-client';

export default class AdminInvestmentProjectService {
  http;
  adminStatuses;

  static instance = new AdminInvestmentProjectService();

  static getInstance() {
    if (AdminInvestmentProjectService.instance === null) AdminInvestmentProjectService.instance = new AdminInvestmentProjectService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient
  }

  async getAllAdminStatuses() {
    if (this.adminStatuses) {
      return this.adminStatuses;
    } else {
      return this.http.get(`admin/investment-project/get-admin-status`)
        .then(response => {
          this.adminStatuses = response.data;
          return response.data;
        })
        .catch(err => {
          console.log(err);
          throw err;
        })
    }
  };

  /**
   * Get all investment projects pending review.
   * @returns - The list of investment projects pending review.
   */
  async getAllInvestmentProjectsPendingReview() {
    return this.http.get(`admin/investment-project/pending-review`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Get all investment projects.
   * @returns - The list of investment projects.
   */
  async getAllInvestmentProjects() {
    return this.http.get(`admin/investment-project/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  /**
   * Get one investment project.
   * @returns - The investment project of the specified id.
   */
  async getInvestmentProject(id) {
    return this.http.get(`admin/investment-project/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  // async updateAdminStatus(id, updatedStatus) {
  //   return this.http.post(`admin/investment-project/${id}/change-admin-status`, updatedStatus)
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       throw err;
  //     })
  // }

  async approveInvestmentProject(id, sendEmail, comment) {
    return this.http.post(`admin/investment-project/${id}/approve`, { admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async rejectInvesmentProject(id, sendEmail, comment) {
    return this.http.post(`admin/investment-project/${id}/reject`, { admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async changeStatus(id, statusCode, sendEmail, comment) {
    return this.http.post(`admin/investment-project/${id}/change-status`, { status_code: statusCode, admin_comment: comment, send_email_notification: sendEmail })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async removeDecline(id, statusCode) {
    return this.http.post(`admin/investment-project/${id}/remove-decline`, { status_code: statusCode })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async addComment(id, comment) {
    return this.http.post(`admin/investment-project/${id}/comment`, { admin_comment: comment })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async editInvestmentProject(id, details) {
    return this.http.post(`admin/investment-project/${id}/edit`, details)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  // async toggleComingSoon(id) {
  //   return this.http.post(`admin/investment-project/${id}/coming-soon`)
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       throw err;
  //     })
  // }

  async addInvestmentPledge(id, details) {
    return this.http.post(`admin/investment-pledge/${id}/create`, details)
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async uploadNewInvestmentProjectImage(projectId, form) {
    return this.http.post(`admin/investment-project/${projectId}/add-project-image`, form)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

  async deleteProjectImage(projectId, imageKey) {
    return this.http.post(`admin/investment-project/${projectId}/delete-image`, { image_key: imageKey })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

  async deleteProject(projectId) {
    return this.http.delete(`admin/investment-project/${projectId}/delete`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }
}
