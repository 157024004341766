import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import { Alert, Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import AdminAutomaticDeclineRuleService from '../../services/admin-automatic-decline-rule-service';

export const DialogCreateDeclineRule = ({ active, question, formId, existingRule, onUpdate, onCancel }) => {

  const adminAutomaticDeclineRuleService = new AdminAutomaticDeclineRuleService();
  const [rule, setRule] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (existingRule) {
      setRule(existingRule);
    } else if (question) {
      setRule({
        controlled_answer_evaluation_type: '',
        controlled_answer_ids: [],
        evaluation_question_id: question.id,
        question_form_id: formId,
        decline_message: ''
      })
    }
  }, [existingRule, active, question, formId])

  // Set the required investment project data.
  function handleRuleUpdate(formControl, value) {
    const updated = rule;
    updated[formControl] = value;

    setRule({ ...updated });
  }

  function formIsValid() {
    return rule.controlled_answer_evaluation_type && rule.controlled_answer_ids.length > 0 && rule.decline_message;
  }

  const handleControlledAnswerChange = (value) => {
    if (rule.controlled_answer_ids.includes(value)) {
      const ids = rule.controlled_answer_ids.filter(id => id !== value);
      handleRuleUpdate('controlled_answer_ids', ids)
    } else {
      const ids = rule.controlled_answer_ids;
      ids.push(value)
      handleRuleUpdate('controlled_answer_ids', ids)
    }
  }

  const handleAddRule = () => {
    if (formIsValid()) {
      adminAutomaticDeclineRuleService.addDeclineRule(formId, rule)
        .then(() => {
          onUpdate()
        })
    } else {
      setShowError(true);
    }
  }

  return (<>
    {rule && <Dialog open={active} onClose={onUpdate}>
      <DialogTitle>Create new decline rule</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          Please provide the details of this rule below.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          {showError && !formIsValid() && <Alert severity='error' sx={{ mb: 2 }}>
            Please provide all required information below.
          </Alert>}

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Rule function</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={(e) => handleRuleUpdate('controlled_answer_evaluation_type', e.target.value)}
            >
              <FormControlLabel value="IS_SELECTED" control={<Radio />} label="Is selected" />
              <FormControlLabel value="IS_NOT_SELECTED" control={<Radio />} label="Is not selected" />
            </RadioGroup>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <FormLabel id="demo-radio-buttons-group-label">Answers to evaluate</FormLabel>
            {question && question.controlled_answers.map((answer, index) => {
              return <FormControlLabel key={index}
                control={
                  <Checkbox
                    name={answer.id}
                    checked={rule.controlled_answer_ids.includes(answer.id)}
                    onClick={() => { handleControlledAnswerChange(answer.id) }}
                  />}
                label={answer.answer_text}
              />
            })}
          </FormControl>

          <GaiaTextField
            label="Decline message"
            value={rule.decline_message || ''}
            placeholder="Message to show to the user when this rule is triggered"
            formControlName='decline_message'
            minRows={3}
            maxRows={5}
            onChange={handleRuleUpdate}>
          </GaiaTextField>


        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleAddRule}
          className="removeUppercase"
        >
          Add rule
        </Button>
      </DialogActions>
    </Dialog>}
  </>

  )
}
