/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import TeamForm from '../../components/config/team/team-form';
import { DialogAddAdminUser } from '../../components/dialogs/dialog-add-admin-user';

const Team = () => {

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  const [showDialog, setShowDialog] = useState(false);
const [update, setUpdate] = useState(0)
  const onCancel = () => {
    setShowDialog(false);
  }

  const onUpdate = () => {
    setShowDialog(false);
    setUpdate(update + 1)

  }

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Team
        </Typography>

        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => setShowDialog(true)}
          className="removeUppercase"
        >
          Add New Admin User
        </Button>
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <TeamForm newUserAdded={update} />

      <DialogAddAdminUser
        active={showDialog}
        onCancel={onCancel}
        onUpdate={onUpdate}>
      </DialogAddAdminUser>
    </Box>
  );
};

export default Team 