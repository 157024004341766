import { Alert, Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GaiaTextField } from '../../../shared/components/form-elements/text-field'
import { lpasAutocomplete } from '../../../shared/data/lpas'
import AdminUserService from '../../services/admin-user-service'
import AdminGaiaPlusService from '../../services/admin-gaia-plus-service'
import { CheckCircleOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export const CreateGaiaPlusProjectPage = () => {

  const adminUserService = new AdminUserService();
  const adminGaiaPlusService = new AdminGaiaPlusService();
  const navigate = useNavigate();

  const [ecologists, setEcologists] = useState();
  const [userEmail, setUserEmail] = useState();
  const [foundUser, setFoundUser] = useState();
  const [userNotFound, setUserNotFound] = useState(false);
  const [stages, setStages] = useState();
  const [selectedTaskCodes, setSelectedTaskCodes] = useState([])
  const [details, setDetails] = useState(
    {
      name: '',
      lpa: '',
      description: '',
      user_id: '',
      ecologist_id: ''
    }
  )

  useEffect(() => {
    getUsers('ECOLOGIST')

    adminGaiaPlusService.getAvaialbleTasks()
      .then(response => {
        const taskCodes = [];
        setStages(response)
        response.forEach(stage => {
          stage.tasks.forEach(task => {
            taskCodes.push(task.code)
          })
        })

        setSelectedTaskCodes(taskCodes);
      })
  }, [])

  const getUsers = (userType) => {
    adminUserService.getUsers(`?role=${userType}`)
      .then(response => {
        setEcologists(response)
      })
  }

  const searchForUserByEmail = () => {
    adminUserService.getUsers(`?email=${userEmail}`)
      .then(response => {
        if (response.length > 0) {
          setFoundUser(response[0]);
          setDetails({ ...details, user_id: response[0].id })
          setUserNotFound(false)
        } else {
          setUserNotFound(true);
          setDetails({ ...details, user_id: '' })
        }
      })
  }

  const handleUpdate = (formControl, value) => {
    setDetails({ ...details, [formControl]: value })
  }

  const handleTaskSelect = (e) => {
    if (selectedTaskCodes.find(taskCode => taskCode === e.target.value)) {
      const filtered = selectedTaskCodes.filter(taskCode => taskCode !== e.target.name);
      setSelectedTaskCodes([...filtered])
    } else {
      setSelectedTaskCodes([...selectedTaskCodes, e.target.name])
    }
  }

  const submitProject = () => {
    details.user_email_address = userEmail;
    details.taskCodes = selectedTaskCodes;

    adminGaiaPlusService.createProject(details)
      .then(response => {
        navigate(`/admin/gaia-plus/project/${response.id}`)
      })

  }

  return (<>
    <Toolbar />
    <Box sx={{ margin: 2, mt: 2 }}>
      <Paper sx={{ padding: 4 }} >
        <Stack direction='row' spacing={2}>
          <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>Create new Gaia Plus project</Typography>
        </Stack>

        <Stack direction='row' spacing={2} sx={{ mt: 4, mb: 2 }}>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>Project information:</Typography>
        </Stack>

        <GaiaTextField
          label="Project name"
          value={details.name}
          charchterCount={100}
          rows={1}
          placeholder="Project name"
          formControlName='name'
          onChange={handleUpdate}>
        </GaiaTextField>

        <GaiaTextField
          label="Project description"
          value={details.description}
          charchterCount={2500}
          rows={5}
          placeholder="Project description"
          formControlName='description'
          onChange={handleUpdate}>
        </GaiaTextField>

        <Grid item xs={12} md={12} lg={6} sx={{ mt: "10px" }}>
          <Autocomplete
            disablePortal
            id="lpa"
            options={lpasAutocomplete}
            value={details.lpa}
            renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
            onInputChange={(event, newLPA) => handleUpdate('lpa', newLPA)}
          />
        </Grid>


        <Stack direction='row' spacing={2} sx={{ mt: 4, mb: 2 }}>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>User information:</Typography>
        </Stack>

        <FormControl fullWidth margin="normal" required sx={{ mt: 2 }}>
          <InputLabel id="articleGroupLabel">Select ecologist</InputLabel>
          <Select
            fullWidth
            className="formField"
            label="Select ecologist"
            placeholder="Select ecologist"
            onChange={e => handleUpdate('ecologist_id', e.target.value)}
          >
            {ecologists?.map(ecologist => {
              return <MenuItem key={ecologist.id} value={ecologist.id}>{ecologist.name}</MenuItem>
            })}
          </Select>
        </FormControl>

        {!foundUser && <Stack direction='row' sx={{ mb: 2, alignItems: 'center' }}>
          <GaiaTextField
            emitValueOnly={true}
            label="User email"
            value={userEmail}
            rows={1}
            placeholder="User email"
            onChange={(e) => setUserEmail(e)}>
          </GaiaTextField>

          <Button
            sx={{ ml: 2, minWidth: '200px', height: '56px' }}
            variant={'outlined'}
            disableElevation
            className="removeUppercase"
            // startIcon={isEditing ? <SaveOutlined /> : <Edit />}
            onClick={() => searchForUserByEmail()}
          >
            Search for user
          </Button>
        </Stack>}

        {userNotFound && <Alert severity="info">
          <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>
            This user cannot be found on our platform. This email address will be recorded for project correspondance. <br></br>
            This project can be assigned to the required user at a later date.
          </Typography>

        </Alert>}

        {foundUser && <Alert severity="info">
          <Typography color="text.primary" variant="p" sx={{ fontWeight: 300 }}>
            This project will be assigned to: {foundUser.name} | ({foundUser.email})
          </Typography>

        </Alert>}

        <Stack direction='row' spacing={2} sx={{ mt: 4, mb: 2 }}>
          <Typography color="text.primary" variant="h6" sx={{ fontWeight: 300 }}>Select tasks to be completed:</Typography>
        </Stack>

        {stages && stages.map((stage, index) => {
          return <Box key={index}>
            <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
              <Typography color="primary" variant="p">Stage: {stage.name}</Typography>
            </Stack>

            {stage.tasks.map((task, index) => {
              return <FormControlLabel
                key={index}
                control={
                  <Checkbox defaultChecked
                    name={task.code}
                    value={selectedTaskCodes.find(taskCode => taskCode === task.code) || false}
                    onChange={handleTaskSelect}
                  />}
                label={task.label}
                sx={{ width: '100%' }}
              />
            })}
          </Box>
        })}

        <Stack direction='row' sx={{ justifyContent: 'flex-end' }}>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={submitProject}
            startIcon={<CheckCircleOutline />}
            className='removeUppercase'
          >
            Create project
          </Button>
        </Stack>

      </Paper>
    </Box>

  </>)
}