import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack } from '@mui/material';
import { ImageFileUpload } from '../image-file-upload';
import { useState } from 'react';
import ProjectResourceService from '../../services/project-resource-service';

export const DialogPdfUploader = (
  {
    dialogActive,
    projectType,
    projectId,
    cancelUpload,
    onSuccess
  }
) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileData, setFileData] = useState([]);

  const projectResourceService = new ProjectResourceService();

  const handleCancelUpload = () => {
    setFileData([]);
    cancelUpload();
  }

  // This needs to be change to listen for errors. For the moment it will stop double clicking if the image takes a few seconds to upload.
  const setUploadSpinner = () => {
    setIsUploading(true);

    setTimeout(() => {
      setIsUploading(false);
    }, 4000);
  }

  const completeUpload = () => {
    const form = new FormData();

    for (let i = 0; i < fileData.length; i++) {
      form.append("files", fileData[i]);
    }

    form.append('resource', JSON.stringify({ type: projectType, project_id: projectId }));

    projectResourceService.createResource(form)
      .then(() => {
        setFileData([]);
        onSuccess()
      })

  }

  return (
    <Dialog open={dialogActive} maxWidth='md' fullWidth>
      <DialogTitle sx={{ pb: '5px' }}>Add a resource</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Upload new resources using the form below
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          <Grid item xs={12} md={12} lg={12} sx={{ mt: "16px" }}>
            <ImageFileUpload
              fileData={fileData}
              setFileData={setFileData}
              imageCountLimit={1}
              pdf={true}
              maxSize={5}
            >
            </ImageFileUpload>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' spacing={1} p={2} pt={0}>
          <Button onClick={() => handleCancelUpload()} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            disabled={fileData.length === 0 || isUploading}
            color="primary"
            disableElevation
            onClick={() => [completeUpload(), setUploadSpinner()]}
            className="removeUppercase"
            sx={{ minWidth: '100px' }}
          >
            {isUploading ? <CircularProgress size={14} /> : 'Upload'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}