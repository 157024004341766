import httpClient from '../../shared/http/http-client';

export default class AdminUserService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getAllSellers(queryString) {
    return this.http.get(`admin/user/seller${queryString ? queryString : ''}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async getUsers(queryString) {
    return this.http.get(`admin/user/users${queryString ? queryString : ''}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async getSeller(id) {
    return this.http.get(`admin/user/seller/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }
  async toggleUserApproval(userId) {
    return this.http.post(`admin/user/toggle-user-approval`, { user_id: userId })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async updateUserDetails(userId, details) {
    return this.http.post(`admin/user/update/${userId}`, details)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }

  async getSellerByEmail(email) {
    return this.http.get(`admin/user/seller-by-email/${email}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

}
