import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HubDetailsContext } from '../../context/hub-details';
import { Box, Container, Grid, Link, Stack } from '@mui/material';
import parse from 'html-react-parser';


const WebsiteFooter = () => {

  const hub = useContext(HubDetailsContext);
  const [termsLink, setTermsLink] = useState(null);
  const [privacyLink, setPrivacyLink] = useState(null);
  const [footerDetails, setFooterDetails] = useState({
    column_one_html: '',
    column_two_html: '',
    column_three_html: ''
  })

  useEffect(() => {
    setTermsLink(hub.hubDetails?.organisation_settings?.terms_and_conditions_link);
    setPrivacyLink(hub.hubDetails?.organisation_settings?.privacy_policy_link);
    setFooterDetails({
      column_one_html: hub.hubDetails.hub_footer?.column_one_html,
      column_two_html: hub.hubDetails.hub_footer?.column_two_html,
      column_three_html: hub.hubDetails.hub_footer?.column_three_html
    })
  }, [hub.hubDetails])

  return (<>
    {(footerDetails?.column_one_html || footerDetails?.column_two_html) &&
      <Box
        sx={{
          width: '100%',
          marginTop: 'auto',
          backgroundColor: 'primary.main'
        }}
      >
        <Container maxWidth='lg' sx={{ pt: 8, pb: 8 }}>
          <Grid
            container
            direction='row'
            spacing={4}
          >
            <Grid item xs={12} md={12} lg={6}>
              {footerDetails?.column_one_html && parse(footerDetails?.column_one_html)}
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              {footerDetails?.column_two_html && parse(footerDetails.column_two_html)}
            </Grid>

            {/* <Grid item xs={12} md={4} lg={4}>
            {footerDetails?.column_three_html && parse(footerDetails.column_three_html)}
          </Grid> */}
          </Grid>
        </Container>
      </Box>
    }
    {(footerDetails?.column_one_html || footerDetails?.column_two_html) &&
      <Box sx={{ backgroundColor: 'primary.dark' }}>
        <Container maxWidth='lg' sx={{ pt: 1, pb: 1 }}>
          <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
            {termsLink && <Link to={termsLink} target="_blank" component={RouterLink} sx={{ color: '#fff', fontSize: '12px' }}>Terms and Conditions</Link>}
            {privacyLink && <Link to={privacyLink} target="_blank" component={RouterLink} sx={{ color: '#fff', fontSize: '12px' }}>Privacy Policy</Link>}
            <Link to='https://gaiacompany.io' target="_blank" component={RouterLink} sx={{ color: '#fff', fontSize: '12px', justifyContent: "flex-end", ml: "auto !important", mt: 0, float: "right" }}>Powered by Gaia Technologies</Link>
          </Stack>
        </Container>
      </Box>
    }
  </>
  )
}

export default WebsiteFooter