import { Typography } from '@mui/material';
import React from "react";

export function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://gaiacompany.io" style={{ color: '#70942c' }}>
        Gaia
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}