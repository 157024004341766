
import React, { useState, createContext, useContext, useEffect } from 'react'
import { InfoDialog } from '../components/dialogs/info-dialog';
import { InfoSnackbar } from '../components/dialogs/info-snackbar';
import { HubDetailsContext } from './hub-details';
import gaiaTheme from '../../theme';
import { ThemeProvider } from '@mui/material/styles';

export const UserInformationProviderContext = createContext();

export const UserInformationProvider = props => {

  const hub = useContext(HubDetailsContext);

  const [newTheme, setNewTheme] = useState(gaiaTheme)
  const [dialogDetails, setDialogDetails] = useState({ active: false, title: '', message: '', buttonCopy: '', cancelButtonCopy: '', hideCancelButton: false, have_form_input: false, form_value: '' });
  const [snackbarDetails, setSnackbarDetails] = useState({ open: false, transition: undefined, message: '', duration: 3000, vertical: undefined, horizontal: undefined, backgroundColor: 'grey' });
  const [resolveFunction, setResolveFunction] = useState();

  useEffect(() => {
    setNewTheme(hub.hubDetails.theme);
  }, [hub])

  async function openDialog(inputDetails) {
    setDialogDetails({ ...dialogDetails, ...inputDetails })
    var promise = new Promise((resolve) => { setResolveFunction(() => resolve) })

    return await promise
      .then((response) => {
        return response
      })
      .catch(err => {
        console.log(err)
      })
  }

  function onUserResponse(value, formValue) {
    if (formValue) {
      resolveFunction({ value: value, form_value: formValue });
    } else {
      resolveFunction(value);
    }

    // Cleanup
    setResolveFunction(undefined);
    setDialogDetails({ ...dialogDetails, active: false });

    // Used to stop the dialog changing height before it is removed from the page.
    setTimeout(() => {
      setDialogDetails({ active: false, title: '', message: '', buttonCopy: '', cancelButtonCopy: '', hideCancelButton: false, have_form_input: false, form_value: '' });
    }, 250);
  };

  function openSnackbar(inputDetails) {
    setSnackbarDetails({ ...snackbarDetails, ...inputDetails })
  }

  function closeSnackbar() {
    setSnackbarDetails({ ...snackbarDetails, open: false })

    // Used to stop the snackbar changing width before it is removed from the page.
    setTimeout(() => {
      setSnackbarDetails({ transition: undefined, message: '', duration: 3000, vertical: undefined, horizontal: undefined, backgroundColor: 'grey' })
    }, 250);
  }

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <UserInformationProviderContext.Provider value={{ onUserResponse, openDialog, openSnackbar, closeSnackbar, snackbarDetails, dialogDetails }}>
        {props.children}
        <InfoDialog></InfoDialog>
        <InfoSnackbar></InfoSnackbar>
      </UserInformationProviderContext.Provider>
    </ThemeProvider>
  )
}