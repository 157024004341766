import httpClient from '../../shared/http/http-client';

export default class InvestmentProjectService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getModuleStyle() {
    return this.http.get(`data/hub/module-style/invest`)
      .then(response => {
        return response.data
      });
  }

  async getProjects(queryParams) {
    return this.http.get(`hub/investment-project?${queryParams}`)
      .then(response => {
        return response.data
      });
  }

  async getComingSoonProjects() {
    return this.http.get(`hub/investment-project/coming-soon`)
      .then(response => {
        return response.data
      });
  }

  async toggleFavorite(id) {
    return this.http.post(`user/investment-project/toggle-favourite`, { project_id: id })
      .then(response => {
        return response.data
      });
  }

  async searchProjects(searchTerm) {
    return this.http.get(`hub/investment-project/search?search_term=${searchTerm}`)
      .then(response => {
        return response.data
      });
  }

}