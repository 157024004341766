import { Box, LinearProgress, Typography } from '@mui/material';

export default function PledgeProgressBar({ value }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" sx={{ height: '10px', borderRadius: '5px' }} value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2" color="text.secondary">{`${Math.round(
            value ? value : 0,
          )}%`}</Typography>
      </Box>
    </Box>
  );
}