import React, { useContext, useEffect, useState } from "react";
import EnquiryMessages from "../../shared/components/enquiry-messages";
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TollIcon from '@mui/icons-material/Toll';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { UserDetailsContext } from '../../shared/context/user-details';
import AdminEnquiriesService from '../services/admin-enquiries-service';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

export const AdminEnquiryDetail = (props) => {

  const params = useParams();
  const user = useContext(UserDetailsContext);
  const adminEnquiryService = new AdminEnquiriesService();

  const [enquiry, setEnquiry] = useState();
  const [project, setProject] = useState();
  const [investmentProject, setInvestmentProject] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    if (user.userDetails) {
      setUserId(user.userDetails.id)
      setUserName(user.userDetails.name)

      adminEnquiryService.getEnquiry(params.enquiryId)
        .then(response => {
          setEnquiry(response.enquiry)
          setProject(response.enquiry.project)
          setInvestmentProject(response.enquiry.investment_project)
        })
    }
  }, [params.enquiryId])

  const handleUpdate = () => {

  }

  return (
    <>
      <Toolbar />
      {enquiry && <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        {/* <Breadcrumbs aria-label="breadcrumb" sx={{ pl: 2, pb: 3 }}>
          <Link
            underline="hover"
            color="inherit"
            to={`/admin/enquiries`}
            component={RouterLink}
          >
            Enquiries
          </Link>
          {(project || investmentProject) && <Typography color="text.secondary">{project?.p_summary ? project.p_summary : investmentProject.title_text}</Typography>}
        </Breadcrumbs> */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={9} lg={9}>
            <Paper variant="outlined" sx={{ p: 4, flexGrow: 1, height: '100%' }}>
              <EnquiryMessages
                enquiry={enquiry}
                project={project}
                investmentProject={investmentProject}
                expanded={expanded}
                userId={userId}
                setExpanded={setExpanded}
                userName={userName}
                userRole={'admin'}
                onSuccess={handleUpdate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>

            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
                //position: 'sticky',
                //top: '265px',
              }}
              variant='outlined'
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                Buyer Details
              </Typography>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#70942c' }}>
                    {getInitials(enquiry.enquiry_messages[0]?.author_details?.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={enquiry.enquiry_messages[0]?.author_details?.name} secondary={enquiry.enquiry_messages[0]?.author_details?.organisation} />
              </ListItem>
            </Paper>

            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
                //position: 'sticky',
                //top: '265px',
              }}
              variant='outlined'
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                Seller Details
              </Typography>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#0288d1' }}>
                    {getInitials(enquiry.enquiry_messages[0]?.recipient_details?.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={enquiry.enquiry_messages[0]?.recipient_details?.name} secondary={enquiry.enquiry_messages[0]?.recipient_details?.organisation} />
              </ListItem>
            </Paper>


            <Paper
              //elevation={5}
              variant="outlined"
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'sticky',
                top: '90px',
                minHeight: 'calc(100vh - 110px)',
                //borderColor: '#a9a9a9', 
                borderRadius: '5px'
              }}
            >
              <Grid container>
                {project && <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    {project && project.p_local_planning_authority}
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    {project && project.p_national_character_area}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>{project && project.p_summary}</b>
                  </Typography>
                  <Typography variant="subtitle1" color="text.primary">
                    {project && project.p_description}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  {/* <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <TollIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Units available: </b><NumericFormat value={project && project.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                      </span>
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <SellOutlinedIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Unit price: </b>
                        {project && !project.hidePrice ? (
                          <NumericFormat value={Math.round(project && project.totalPrice / project.totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                        ) : ("POA")}
                      </span>
                    </Typography>
                  </Stack> */}
                </Grid>}

                {investmentProject && <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    {investmentProject.title_text}
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    {investmentProject.subtitle_text}
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>{investmentProject.description}</b>
                  </Typography>
                  <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
                  <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                    <TollIcon color="primary" />
                    <Typography variant="subtitle1" color="text.primary">
                      <span style={{ lineHeight: '24px' }}>
                        <b>Investment target: </b><NumericFormat value={investmentProject.investment_target} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='£' />
                      </span>
                    </Typography>
                  </Stack>
                </Grid>}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>}
    </>
  );
};
