import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';

const updateHeaderInterceptor = async (http) => {

  let refreshTokenPromise;
  let hubDetailsPromise;

  http.interceptors.request.use(async (config) => {
    const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance();
    refreshTokenPromise ??= cognitoUserAuthenticationService.getCookie(); // Check for any in flight requests.

    const token = await refreshTokenPromise;

    refreshTokenPromise = null; // clean in-flight state;
    const formattedToken = token ? `Bearer ${token}` : undefined;
    const hubId = sessionStorage.getItem('hub_id');
    if (formattedToken) {
      config.headers['Authorization'] = formattedToken;
    }

    if (hubId) {
      config.headers['hub-id'] = hubId;
    } else {

      try {
        hubDetailsPromise ??= getHubDetails();

        const hubDetails = await hubDetailsPromise;
        hubDetailsPromise = null;

        console.log(hubDetails)
        config.headers['hub-id'] = hubDetails.id;
        sessionStorage.setItem('hub_id', hubDetails.id);

      } catch (err) {
        console.error(err.message);
        config.headers['hub-id'] = 0;
      }
    }
    return config;
  })

  async function getHubDetails() {
    const location = window.location.host;
    const response = await fetch(`${process.env.REACT_APP_API_URL}/gaia-api/v1/data/hub/${location}`, {
      method: "GET",
      headers: {
        "hub-id": 0
      }
    });
    return await response.json();
  }
}

export default updateHeaderInterceptor;
