import React, { useState, useEffect } from "react";
import ArticleService from "../../../shared/services/article-service";
import ArticleBodyDisplay from '../../../shared/components/articles/article-body/article-body-display';
import { useParams } from 'react-router-dom';
import { Box, Paper, Toolbar } from '@mui/material';
import { GlobalBackButton } from '../../../shared/components/buttons/global-back-button';

const AdminArticleDetail = () => {
  const articleService = new ArticleService();
  const params = useParams();

  const [article, setArticle] = useState();

  useEffect(() => {
    articleService.getOneArticle(params.id)
      .then(response => {
        setArticle(response);
      })
  }, [params.id]);

  return (
    article && (
      <>
        <Toolbar />
        <Box sx={{ padding: 3 }}>
          <Paper
            sx={{
              padding: 4,
              borderRadius: '10px'
            }}
            variant='outlined'
          >
            <GlobalBackButton buttonCopy={'Back to articles'} buttonLink={'/admin/articles'}></GlobalBackButton>
            <ArticleBodyDisplay article={article}></ArticleBodyDisplay>
          </Paper>
        </Box>
      </>
    )
  )
};

export default AdminArticleDetail