import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from '@mui/material';
import HubSuperAdminService from "../../../services/hub-super-admin-service";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CreateHubForm = (props) => {
  const hubSuperAdminService = new HubSuperAdminService();

  const [createdHub, setCreatedHub] = useState();

  useEffect(() => {
    createHubObject();
  }, [])

  // Create the initial hub object
  function createHubObject(hub_url = '', admin_user_id = null, hub_owner_name = '') {
    const hub = {
      hub_url: hub_url,
      admin_user_id: admin_user_id,
      hub_owner_name: hub_owner_name
    };

    setCreatedHub(hub);
  };

  // Set the required form data.
  function setData(formControl, value) {
    const hub = createdHub;
    hub[formControl] = value;

    setCreatedHub({ ...hub });
  }

  // Submit the form
  function submitForm(event) {
    event.preventDefault();
    hubSuperAdminService.createHub(createdHub)
      .then(() => {
        event.target.reset();
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Box component="form" onSubmit={submitForm}>
      <TextField
        fullWidth
        className="formField"
        label="Hub URL"
        id="hubUrl"
        placeholder="Enter the new hub URL in the format - example.gaiacompany.io"
        onChange={e => setData('hub_url', e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        required
        margin="normal"
      />
      <TextField
        fullWidth
        className="formField"
        label="Organisation Name"
        id="hubOwnerName"
        placeholder="Enter the name of the organisation"
        onChange={e => setData('hub_owner_name', e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        required
        margin="normal"
      />
      <TextField
        fullWidth
        className="formField"
        label="Admin User ID"
        id="hubUrl"
        placeholder="Ask Pete for this."
        onChange={e => setData('admin_user_id', Number(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
        required
        margin="normal"
        type="number"
      />
      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        //disabled={imageUploadInProgress}
        startIcon={<CheckCircleOutlineIcon />}
        className='removeUppercase'
        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default CreateHubForm 