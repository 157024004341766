import { Stack, TextField, Typography } from '@mui/material'

export const GaiaTextField = ({
  formControlName,
  label,
  value,
  placeholder,
  onChange,
  charchterCount,
  rows,
  emitValueOnly,
  minRows,
  maxRows,
  required = false,
  helperText,
  disabled,
  error }) => {

  const handleChange = (event) => {
    if (((charchterCount && event.target.value.length <= charchterCount) || !charchterCount)) {
      if (!emitValueOnly) {
        onChange(formControlName, event.target.value);
      } else {
        onChange(event.target.value);
      }
    } else {
      if (!emitValueOnly) {
        onChange(formControlName, event.target.value.substring(0, charchterCount))
      } else {
        onChange(event.target.value.substring(0, charchterCount))

      }
    }
  }

  const checkForDeletePress = (event) => {
    if ((event.key === 'Delete' || event.key === 'Backspace') && (charchterCount && value > charchterCount)) {
      event.preventDefault();
      if (!emitValueOnly) {
        onChange(formControlName, value.substring(0, value.length - 1))
      } else {
        onChange(value.substring(0, value.length - 1))
      }
    }
  }

  return (<>
    <TextField
      required={required}
      helperText={helperText}
      fullWidth
      className="formField"
      label={label}
      id="description"
      value={value || ''}
      placeholder={placeholder}
      multiline
      rows={rows ? rows : undefined}
      minRows={minRows}
      maxRows={maxRows}
      onChange={e => handleChange(e)}
      onKeyDown={e => checkForDeletePress(e)}
      margin="normal"
      disabled={disabled}
      error={error}
    />
    {charchterCount && <Stack direction='row' justifyContent='flex-end'>
      <Typography variant="p" color={disabled ? 'grey' : value?.length === charchterCount ? 'error' : "text.primary"}>
        {value?.length || 0} / {charchterCount}
      </Typography>
    </Stack>}
  </>
  )
}