import { GrassOutlined, SpaOutlined, Water } from '@mui/icons-material';
import { Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const BngMetricTable = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ pl: 0 }}>Unit Type</TableCell>
          <TableCell>Broad Habitat</TableCell>
          <TableCell>Habitat</TableCell>
          <TableCell>Distinctiveness</TableCell>
          <TableCell>Trading Rules</TableCell>
          <TableCell align="right">Units Required</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.habitats.map((row, index) => (
          <TableRow
            key={index}
          >
            <TableCell sx={{ pl: 0 }}>
              <Stack direction='row' alignItems='center'>
                {row.unitType === 'Area' && <SpaOutlined color="primary" sx={{ mr: 1 }} />}
                {row.unitType === 'Hedgerow' && <GrassOutlined color="primary" sx={{ mr: 1 }} />}
                {row.unitType === 'Watercourse' && <Water color="primary" sx={{ mr: 1 }} />}
                {row.unitType}
              </Stack>
            </TableCell>
            <TableCell>{row.broadHabitat}</TableCell>
            <TableCell>{row.habitat}</TableCell>
            <TableCell>{row.distinctiveness}</TableCell>
            <TableCell><Tooltip title={row.tradingRules.tooltip} placement="right"><Chip label={row.tradingRules.label} /></Tooltip></TableCell>
            <TableCell align="right"><NumericFormat value={row.unitsRequired} decimalScale={2} thousandSeparator={true} displayType={'text'} /></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default BngMetricTable;