import { useContext, useEffect, useState } from 'react';
import { ImageName, CornwallImageName } from '../objects/image';
import { HubDetailsContext } from '../context/hub-details';

export default function ImagePicker({ image, multicolor = false }) {
  const hubContext = useContext(HubDetailsContext);

  const [imageName, setImageName] = useState();
  const [imageType, setImageType] = useState();
  const [images, setImages] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);


  const gaiaImages = [
    { name: ImageName.Environment, path: `/images/${imageType}/001-environment.svg` },
    { name: ImageName.Protect, path: `/images/${imageType}/002-protect-the-planet.svg` },
    { name: ImageName.World, path: `/images/${imageType}/003-world.svg` },
    { name: ImageName.Ecology, path: `/images/${imageType}/004-ecology.svg` },
    { name: ImageName.Growing, path: `/images/${imageType}/005-growing-plant.svg` },
    { name: ImageName.Biology, path: `/images/${imageType}/006-biology.svg` },
    { name: ImageName.Recycle, path: `/images/${imageType}/007-recycle.svg` },
    { name: ImageName.Nature, path: `/images/${imageType}/008-nature.svg` },
    { name: ImageName.Leaves, path: `/images/${imageType}/009-leaves.svg` },
    { name: ImageName.Trees, path: `/images/${imageType}/010-trees.svg` },
    { name: ImageName.Windmills, path: `/images/${imageType}/011-windmills.svg` }
  ]

  const cornwallImages = [
    { name: CornwallImageName.Wall, path: `/cornwall/icons/cornish_wall.png` },
    { name: CornwallImageName.Grass, path: `/cornwall/icons/grass.png` },
    { name: CornwallImageName.Handshake, path: `/cornwall/icons/hand_shake.png` },
    { name: CornwallImageName.Marsh, path: `/cornwall/icons/marsh.png` },
    { name: CornwallImageName.MoneyTree, path: `/cornwall/icons/money_tree.png` },
    { name: CornwallImageName.Money, path: `/cornwall/icons/money.png` },
    { name: CornwallImageName.Ocean, path: `/cornwall/icons/ocean.png` },
    { name: CornwallImageName.Pond, path: `/cornwall/icons/pond.png` },
    { name: CornwallImageName.Stream, path: `/cornwall/icons/stream.png` },
    { name: CornwallImageName.Tree, path: `/cornwall/icons/tree.png` },
    { name: CornwallImageName.Buyer, path: `/cornwall/icons/buyers_icon.png` },
    { name: CornwallImageName.Investor, path: `/cornwall/icons/investors_icon.png` },
    { name: CornwallImageName.Funder, path: `/cornwall/icons/funders_icon.png` },
  ]

  useEffect(() => {
    setImageName(image);
    setImageType(multicolor ? 'color' : 'line');
    if (hubContext.hubDetails) {
      setImages(hubContext.hubDetails.id === 4 ? cornwallImages : gaiaImages);
    }
  }, [image, imageName, multicolor, imageType, hubContext.hubDetails])

  return (
    <img
      src={images.filter(image => image.name === imageName)[0]?.path}
      onLoad={() => setImageLoaded(true)}
      style={{ width: '100%', visibility: imageLoaded ? 'visible' : 'hidden', }}
    />
  )
}