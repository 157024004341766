import { useContext, useEffect, useState } from 'react'
import { HubDetailsContext } from '../../context/hub-details'
import { Typography } from '@mui/material';

export const TermsAndConditionsLink = ({ overrideUrl, isLinc }) => {

  const hubContext = useContext(HubDetailsContext);
  const [url, setUrl] = useState();

  useEffect(() => {
    if (hubContext) {
      setUrl(hubContext.hubDetails?.organisation_settings?.terms_and_conditions_link);
    }
  }, [hubContext])

  const openFile = () => {
    window.open(overrideUrl ? overrideUrl : url, '_blank')
  }

  // return (<a href={overrideUrl ? overrideUrl : file} target='_blank' rel='noopener noreferrer'>Terms and conditions</a>)
  // return (<span onClick={openFile} style={{ cursor: 'pointer' }}>Terms and conditions</span>)

  return (<Typography
    sx={{ display: 'inline', cursor: 'pointer' }}
    color="primary"
  >
    <span onClick={openFile}>Terms and conditions{isLinc ? ' of use' : ''}</span>
  </Typography>)
}