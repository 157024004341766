import httpClient from "../http/http-client";

export default class PageContentSectionService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getContent(staticPageTpeId) {
    return this.http.get(`hub/page-content/${staticPageTpeId}`)
      .then(response => {
        return response.data
      });
  }
}