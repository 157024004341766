import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SavedProjectsTable from "../../shared/components/saved-projects-table";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const SavedProjects = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    window.analytics.page();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container alignItems="center" sx={{ mb: 4 }}>
          <Grid item xs={12} md={6} lg={6} alignItems="center" key={uuidv4()}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate('/hub/marketplace')}
              underline='hover'
              sx={{ ml: '-7px' }}
            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back to home
                </Typography>
              </Grid>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12} key={uuidv4()}>
            <Paper
              elevation={0}
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
              }}
            >
              <SavedProjectsTable />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SavedProjects