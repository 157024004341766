import { useContext, useEffect, useState } from 'react'
import { HubDetailsContext } from '../context/hub-details'
import { Alert, Avatar, Box, Button, Container, FormControl, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material';
import ContactUsService from '../services/contact-us-service';
import { UserInformationProviderContext } from '../context/user-information-provider';
import { CorporateFare, Email } from '@mui/icons-material';

export const ContactUsPage = () => {

  const hubContext = useContext(HubDetailsContext);
  const contactUsService = new ContactUsService();
  const infoDialog = useContext(UserInformationProviderContext);


  const [details, setDetails] = useState({ name: '', email: '', message: '' });
  const [address, setAddress] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log(address);
  }, [address])

  useEffect(() => {
    if (hubContext.hubDetails) {
      setAddress(hubContext.hubDetails.organisation_settings);
    }
  }, [hubContext])

  const handleChange = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value })

    if (details.name && details.email && details.message) {
      setError(false);
    }
  }

  const submitForm = () => {
    setError(false);
    if (!details.name || !details.email || !details.message) {
      setError(true);
    } else {
      contactUsService.submitForm(details)
        .then(() => {
          //infoDialog.openSnackbar({ open: true, message: 'Message sent successfully.', backgroundColor: 'green' });
          infoDialog.openDialog({
            active: true,
            title: 'Thank you for your message',
            message: 'Thanks for getting in contact, one of our team will be in touch with you soon.',
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
          setDetails({ name: '', email: '', message: '' })
        })
    }
  }

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          height: '45vh',
          mt: 2,
          backgroundImage: `url(${hubContext.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
            <Grid item xs={12} md={7} lg={7}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                You can write to us using the details below, or get in contact using the form on this page.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ pt: 8, pb: 8 }}>
        {address && <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant='h4' display="block" className='removeUppercase' sx={{ mb: 2 }}>
              Contact Us
            </Typography>
            <List>
              <ListItem sx={{ mb: 2 }}>
                <ListItemAvatar sx={{ mr: 4 }}>
                  <Avatar sx={{ backgroundColor: 'primary.main', width: 56, height: 56 }}>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant='h6' display="block">Email</Typography>}
                  secondary={<Link href={`mailto:${address.support_email_address}`}>{address.support_email_address}</Link>}
                >
                </ListItemText>
              </ListItem>
              {address.address_line_1 &&
                <ListItem sx={{ mb: 2 }}>
                  <ListItemAvatar sx={{ mr: 4 }}>
                    <Avatar sx={{ backgroundColor: 'primary.main', width: 56, height: 56 }}>
                      <CorporateFare />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='h6' display="block">Address</Typography>}
                    secondary={<>
                      {address.address_line_1}<br />
                      {address.address_line_2}<br />
                      {address.town}<br />
                      {address.postcode}<br />
                    </>
                    }
                  />
                </ListItem>
              }
            </List>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper elevation={5} sx={{ borderRadius: '10px', p: 6 }}>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-multiline-static"
                  name={'name'}
                  multiline
                  error={!details.name && error}
                  value={details.name}
                  label='Full name'
                  onChange={handleChange}
                  rows={1}
                  sx={{ width: '100%', mb: 3 }}
                />
              </FormControl>

              <FormControl fullWidth required>
                <TextField
                  id="outlined-multiline-static"
                  name={'email'}
                  multiline
                  error={!details.email && error}
                  label='Email address'
                  value={details.email}
                  onChange={handleChange}
                  rows={1}
                  sx={{ width: '100%', mb: 3 }}

                />
              </FormControl>

              <FormControl fullWidth required>
                <TextField
                  id="outlined-multiline-static"
                  name={'message'}
                  error={!details.details && error}
                  multiline
                  label='Message'
                  value={details.message}
                  onChange={handleChange}
                  rows={10}
                  sx={{ width: '100%', mb: 3 }}
                />
              </FormControl>

              {error && <Alert severity="error" sx={{ mb: 2 }}>Please enter all the required information above.</Alert>}


              <Button variant='contained' disableElevation onClick={() => submitForm()} className='removeUppercase'>Submit</Button>
            </Paper>
          </Grid>
        </Grid>}
      </Container>
    </>
  )
}