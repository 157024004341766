
import React, { } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Chip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import moment from 'moment';
import { CircleNotifications } from '@mui/icons-material';


export const ProjectApprovalMessageContainer = ({ projectComments, adminStatusCode, sectionTitle }) => {

  return (<>
    {
      projectComments.length > 0 &&
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          mb: 2,
          borderRadius: '10px'
        }}
        variant='outlined'
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={6} sx={{ mb: 2 }}>
            <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
              {sectionTitle || 'Approval'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={6} sx={{ mb: 2 }}>
            <Box>
              {adminStatusCode === 'PENDING_REVIEW' &&
                <Chip
                  variant='outlined'
                  icon={<CircleNotifications sx={{ fontSize: '16px' }} />}
                  label='Pending'
                  color='error'
                  sx={{
                    justifyContent: "flex-end",
                    ml: "auto",
                    float: "right",
                    pl: 1,
                    pr: 1
                  }}
                />
              }
              {adminStatusCode === 'DRAFT' && projectComments[projectComments.length - 1].comment_type === 'PROJECT_REJECTED' &&
                <Chip
                  variant='outlined'
                  icon={<ThumbDownOutlinedIcon sx={{ fontSize: '16px' }} />}
                  label='Unsuccesful'
                  color='error'
                  sx={{
                    justifyContent: "flex-end",
                    ml: "auto",
                    float: "right",
                    pl: 1,
                    pr: 1
                  }}
                />
              }
              {adminStatusCode === 'PUBLISHED' &&
                <Chip
                  variant='outlined'
                  icon={<ThumbUpOutlinedIcon sx={{ fontSize: '16px' }} />}
                  label='Successful'
                  color='primary'
                  sx={{
                    justifyContent: "flex-end",
                    ml: "auto",
                    float: "right",
                    pl: 1,
                    pr: 1,
                  }}
                />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 2 }}>
              Messages:
            </Typography>
            {projectComments.sort((a, b) => a.id < b.id ? 1 : -1).map((comment, index) => {
              return <Accordion
                key={index}
                expanded
                disableGutters
                elevation={0}
                sx={{
                  border: '1px solid #0000001f',
                  borderRadius: '5px',
                  mb: 1,
                  //backgroundColor: '#f6f6f6'
                  mb: 2
                }}
              >
                <AccordionSummary
                  id="panel1bh-header"
                  sx={{
                    alignItems: 'center',
                    borderBottom: '1px solid #0000001f',
                    p: 0,
                    pl: 3,
                  }}
                >
                  <List sx={{ width: '100%', p: 0 }}>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Avatar sx={{ bgcolor: '#70942c' }}><CommentOutlinedIcon /></Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={(comment) && (<b>Admin</b>)}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Sent at:&nbsp;
                            </Typography>
                            {moment(comment.created_at).format('DD/MM/yyyy HH:mm')}
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '32px', p: 2, whiteSpace: 'pre-wrap' }}>
                    {comment.comment}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            })}
          </Grid>
        </Grid>
      </Paper>

    }
  </>
  )
}

