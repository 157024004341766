import { Tooltip, Card, CardContent, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";

export const CustomTooltip = (props) => {
  const { children, content, placement } = props;

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
      whiteSpace: "pre-line"
    }
  }));

  return (
    <StyledTooltip
      title={
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            <Typography variant="subtitle1" color="text.secondary">
              {content}
            </Typography>
          </CardContent>
        </Card>
      }
      className='customTooltip'
      placement={placement}
      arrow
      sx={{
        backgroundColor: 'transparent'
      }}
    >
      {children}
    </StyledTooltip>
  )
}