import React, { useContext, useEffect, useState } from "react";
import gaiaTheme from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { UserDetailsContext } from '../../shared/context/user-details';
import { Autocomplete, Button, Card, CardContent, Divider, InputAdornment, Paper, Slider, Stack } from '@mui/material';
import { CustomFilter } from '../../shared/components/custom-filter';
import InvestmentProjectService from '../services/investment-project-service';
import InvestmentProjectCard from '../../shared/components/investment-project/investment-project-card';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import LocationOn from '@mui/icons-material/LocationOn';
import Tune from '@mui/icons-material/Tune';
import ProjectMapCornwall from '../../shared/components/project-map-cornwall';
import HubCustomListService from '../../shared/services/hub-custom-list-service';
import { useNavigate } from 'react-router-dom';
import { Search } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const InvestmentProjectMap = (props) => {

  const hub = useContext(HubDetailsContext);
  const investmentProjectService = new InvestmentProjectService();
  const hubCustomListService = new HubCustomListService();
  const navigate = useNavigate();

  const [investmentProjects, setInvestmentProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  // new filters
  const [searchResultsCount, setSearchResultsCount] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState('/background-land.png');
  const [titleCopy, setTitleCopy] = useState('Invest in nature')
  const [bodyCopy, setBodyCopy] = useState('Invest in natural capital projects')
  const [isLoading, setIsLoading] = useState(true);
  const [filtersActive, setFiltersActive] = useState(true);
  const [rangeValue, setRangeValue] = React.useState([0, 100]);
  const [controlledAnswerIds, setControlledAnswerIds] = useState();
  const [comingSoonProjects, setComingSoonProjects] = useState([]);
  const [customRegionList, setCustomRegionList] = useState();
  const [selectedCustomRegion, setSelectedCustomRegion] = useState();
  const [filtersInitialised, setFiltersInitialised] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const user = useContext(UserDetailsContext);

  const amendUrlWithSearchParams = () => {
    let params = new URLSearchParams();
    if (selectedCustomRegion) {
      params.append('region', selectedCustomRegion)
    }

    if (rangeValue) {
      params.append('rangeValue', rangeValue.join())
    }

    navigate(`/hub/invest?${params.toString()}`, { replace: true })

  }

  useEffect(() => {
    if (nextPageNumber === 0 && filtersInitialised) {
      getInvestmentProjects(true);
    }
  }, [nextPageNumber, filtersInitialised]);

  useEffect(() => {
    setNextPageNumber(0)
  }, [controlledAnswerIds, selectedCustomRegion])

  useEffect(() => {
    hubCustomListService.getCustomRegionList(true)
      .then(response => {
        setCustomRegionList(response.data);
      })
  }, [])

  useEffect(() => {
    investmentProjectService.getModuleStyle()
      .then(moduleStyle => {
        if (moduleStyle[0].banner_image_url) {
          setBannerImageUrl(moduleStyle[0]?.banner_image_url)
        } else {
          setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        }
        setTitleCopy(moduleStyle[0]?.title_copy ? moduleStyle[0].title_copy : "Invest in nature");
        setBodyCopy(moduleStyle[0]?.body_copy ? moduleStyle[0].body_copy : "Find projects for investment");
        setIsLoading(false);
      })
  }, [hub, user]);

  // get function

  const getInvestmentProjects = async (newSearch = false) => {
    setProjectsLoading(true);
    setInvestmentProjects(undefined)
    let params = new URLSearchParams();
    params.append('page', newSearch ? 0 : nextPageNumber);
    params.append('size', pageSize);
    params.append('max_price', rangeValue[1])
    params.append('min_price', rangeValue[0])

    if (controlledAnswerIds?.length) {
      params.append('controlled_answer_ids', controlledAnswerIds)
    }

    if (selectedCustomRegion) {
      params.append('custom_region_tag', selectedCustomRegion)
    }

    investmentProjectService.getProjects(params.toString())
      .then(response => {
        setInvestmentProjects(response.investmentProjects);
        setSearchResultsCount(response.count);
        setNextPageNumber(nextPageNumber + 1);
        if (nextPageNumber * pageSize > searchResultsCount) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setProjectsLoading(false);
        amendUrlWithSearchParams()
      })
      .catch(err => {
        console.log(err)
      })
  };

  const handleMapRegionClick = (region) => {
    window.scrollTo(0, 0);
    setSelectedCustomRegion(region);
    amendUrlWithSearchParams();
  }

  return (<>
    {!isLoading && <>
      <Toolbar />
      <Box
        sx={{
          height: '45vh',
          mb: 3,
          backgroundImage: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container sx={{ height: '38vh', alignItems: 'center', pl: 4, pr: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: 'center', fontWeight: 600, color: "common.white" }}
              >
                {titleCopy}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: 'center', color: "common.white" }}
              >
                {bodyCopy}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            border: '1px solid #a9a9a9',
            padding: '15px',
            mt: '-120px',
            ml: "24px",
            mr: "24px",
            pb: 3,
            p: 8
          }}
        >
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, p: 1, pl: 2 }}>
            Interactive Map
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" sx={{ pl: 2 }}>
            Use the map to find projects in your region.
          </Typography>
          <ProjectMapCornwall handleMapRegionClick={handleMapRegionClick} />
        </Box>
      </Container>
    </>}
  </>
  );
};

export default InvestmentProjectMap;