import React from "react";
import { Container, Grid, Paper, Toolbar } from '@mui/material';
import ConfigNavBar from '../../../admin/components/config/nav-bar';

const ConfigurationPageLayout = ({ children }) => {
  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 3 }}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minHeight: 'calc(100vh - 130px)',
            borderRadius: '10px'
          }}
          variant='outlined'
        >
          <Grid container sx={{ flex: 1 }}>
            <Grid item xs={12} md={4} lg={3} sx={{ p: 2, borderRight: '1px solid #F3F7FA' }}>
              <ConfigNavBar />
            </Grid>
            <Grid item xs={12} md={8} lg={9} sx={{ p: 2 }}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ConfigurationPageLayout