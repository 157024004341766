import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack } from '@mui/material';
import { ImageFileUpload } from '../image-file-upload';
import { useState } from 'react';

export const ImageUploadDialog = (
  {
    imageUploadDialogActive,
    cancelImageUpload,
    completeImageUpload,
    fileData = [],
    setFileData,
    imageCountLimit = 10
  }
) => {
  const [isUploading, setIsUploading] = useState(false);

  // This needs to be change to listen for errors. For the moment it will stop double clicking if the image takes a few seconds to upload.
  const setUploadSpinner = () => {
    setIsUploading(true);

    setTimeout(() => {
      setIsUploading(false);
    }, 4000);
  }

  return (
    <Dialog open={imageUploadDialogActive} maxWidth='md' fullWidth>
      <DialogTitle sx={{ pb: '5px' }}>Add Images</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Upload new images using the form below
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          <Grid item xs={12} md={12} lg={12} sx={{ mt: "16px" }}>
            <ImageFileUpload
              fileData={fileData}
              setFileData={setFileData}
              imageCountLimit={imageCountLimit}
            >
            </ImageFileUpload>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' spacing={1} p={2} pt={0}>
          <Button onClick={() => cancelImageUpload()} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            disabled={fileData.length === 0 || isUploading}
            color="primary"
            disableElevation
            onClick={() => [completeImageUpload(), setUploadSpinner()]}
            className="removeUppercase"
            sx={{ minWidth: '100px' }}
          >
            {isUploading ? <CircularProgress size={14} /> : 'Upload'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}