
import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, Link, matchPath } from 'react-router-dom';

export default function Breadcrumb({ blockNavigation = false }) {
  const [stepsArray, setStepsArray] = useState([]);
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split('/').filter(a => /^\d+$/.test(a) === false);
    path.shift();
    const formatted = path.map((element, index) => {
      let route = '';

      for (let i = 0; i <= index; i++) {
        route += '/';
        route += element;
      }
      const nameArray = path[index].split('-');
      for (let i = 0; i < nameArray.length; i++) {
        nameArray[i] = nameArray[i][0].toUpperCase() + nameArray[i].slice(1);
      }

      // const validPath = matchPath(route, {
      //   path: "/users/:id",
      //   exact: true,
      //   strict: false
      // });

      return {
        url: route,
        name: nameArray.join(' '),
        validPath: true
      }
    })
    setStepsArray(formatted);
  }, [location.pathname])

  return (<Breadcrumbs aria-label="breadcrumb">
    {stepsArray.map((step, index) => {
      return <div key={index}>
        {index !== stepsArray.length - 1 && !blockNavigation && <Link
          underline="hover"
          color="inherit"
          to={step.url}
          component={Link}
        >
          {step.name}
        </Link>}

        {(index === stepsArray.length - 1 || blockNavigation) && <Typography color="text.primary">{step?.name}</Typography>}

      </div>
    })}
  </Breadcrumbs>
  );
};

