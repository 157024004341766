import * as React from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DropDownMenu from '../../../shared/components/presentation/dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { Autorenew, ChangeCircleOutlined, EditNote, Photo } from '@mui/icons-material';
import { HubDetailsContext } from '../../../shared/context/hub-details';

const InvestmentProjectActionsMenu = (props) => {
  //const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(props.anchorEl);
  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const hub = React.useContext(HubDetailsContext)

  const goToQuestionPage = () => {
    navigate(`/investment-project/questions/${props.projectId}`)
  }

  return (
    <div>
      <Button
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={e => props.setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        className="removeUppercase"
        sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
      >
        Actions
      </Button>
      <DropDownMenu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={props.anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.canEdit && <MenuItem
          onClick={() => props.handleEditClick()}
          disableRipple
        >
          <EditIcon />
          Edit listing
        </MenuItem>}

        {/* {props.canEdit && <MenuItem
          onClick={() => goToQuestionPage()}
          disableRipple
        >
          <EditNote />
          Edit application answers
        </MenuItem>} */}

        {hub.hubDetails.main_hub && <MenuItem
          onClick={() => props.handleChangeShallowStatus()}
          disableRipple
        >
          <Autorenew />
          Change status
        </MenuItem>}

        {props.canEdit &&<MenuItem
          onClick={() => props.handleUploadImagesClick()}
          disableRipple
        >
          <Photo />
          Upload images
        </MenuItem>}
      </DropDownMenu>
    </div>
  );
}

export default InvestmentProjectActionsMenu