import { useEffect, useState } from 'react';
import './loading-spinner.css';
import { Typography } from '@mui/material';

export default function LoadingSpinner(props) {

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(props.isLoading)
  // const [isLoading, setIsLoading] = useState(props.isLoading);

  useEffect(() => {
    if (!props.isLoading && showLoadingSpinner) {
      transitionFromLoading();
    } else if (props.isLoading && !showLoadingSpinner) {
      transitionToLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading])

  function transitionFromLoading() {
    document.querySelector(".outer-container").classList.remove('elementToFadein');
    document.querySelector(".outer-container").classList.add('elementToFadeOut');
    setTimeout(() => {
      document.querySelector(".outer-container").classList.remove('elementToFadeOut');
      setShowLoadingSpinner(false)
      setTimeout(() => {
        document.querySelector("#child-component").classList.add('elementToFadeIn');
      }, 100);
    }, 700);
  }

  function transitionToLoading() {
    document.querySelector("#child-component").classList.remove('elementToFadeIn');
    document.querySelector("#child-component").classList.add('elementToFadeOut');
    setTimeout(() => {
      document.querySelector("#child-component").classList.remove('elementToFadeOut');
      setShowLoadingSpinner(true)
      setTimeout(() => {
        document.querySelector(".outer-container").classList.add('elementToFadeIn');
      }, 100);
    }, 700);
  }

  return (<div>
    {showLoadingSpinner && <div className="outer-container">
      <div className="loading-container">
        <div className="spin-container">
          <div className="spin"></div>
        </div >
        <div className="image-container">
          <img
            className="nav-bar__logo"
            src="/gaia_logo.png"
            alt="GAIA logo"
            height="60"
            style={{ verticalAlign: 'middle' }}
          />
        </div>
      </div >

      <Typography variant="subtitle1" color="text.primary" sx={{

        fontSize: '24px'
      }}>
        {props.loadingMessage ? props.loadingMessage : 'Loading...'}
      </Typography>
    </div >}

    {
      !showLoadingSpinner && <div id='child-component'>
        {props.children}
      </div>
    }
  </div >

  );
};