import httpClient from '../../shared/http/http-client';

export default class AdminUserFilteredSearchResultsService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getAllUserFilteredSearchResults() {
    return this.http.get(`admin/site-admin/user-filtered-search-results`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      })
  }
}
