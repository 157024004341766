import React from 'react';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { createRoot } from "react-dom/client";
import './index.css';
import App from './app';
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);