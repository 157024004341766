import { useEffect, useState } from 'react';
import AdminGaiaPlusService from '../../services/admin-gaia-plus-service'
import { useParams } from 'react-router-dom';
import { GaiaPlusProjectDetail } from '../../../shared/components/gaia-plus-project-detail';

export const GaiaPlusProjectDetailPageAdmin = () => {

  const adminGaiaPlusService = new AdminGaiaPlusService();
  const params = useParams();

  const [project, setProject] = useState();

  const changeTaskStatus = (details) => {
    adminGaiaPlusService.changeStatus(project.id, details)
      .then(() => {
        getProject();
      })
  }

  const getProject = async () => {
    adminGaiaPlusService.getProject(params.id)
      .then(response => {
        setProject(response)
      })
  }

  useEffect(() => {
    getProject()
  }, [])

  return (<>
    {project && <GaiaPlusProjectDetail
      project={project}
      user={project.user}
      isAdmin={true}
      updateStatus={changeTaskStatus}
      onUpdate={getProject} />}
  </>)
}