
const DEBUG = process.env.REACT_APP_NODE_ENV !== "production";
const errorInterceptor = (http) => {

  http.interceptors.response.use((response) => {
    return response
  }, (error) => {

    if (error?.response?.status === 401) {
      //Unauthorized
      //redirect to Login*
      if (error?.response?.data?.redirect_to) {
        window.location.href = error?.response?.data?.redirect_to
      } else {
        // window.location.reload()

      }

    } else if (error?.response?.status === 402) {
      // Not subscribed to correct tier.
      // redirect to payment page.

    } else {

      if (DEBUG) {
        console.group("Error");
        console.log(error);
        console.groupEnd();
      }
    }
    throw error;
  });

};

export default errorInterceptor;