
import { Alert, Box, Card, CardActionArea, Chip, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import IconPicker from '../../../shared/components/icon-picker';
import AdminUserService from '../../services/admin-user-service';
import gaiaTheme from '../../../theme';

const UserApprovalList = () => {
  const adminUserService = new AdminUserService();
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const [users, setUsers] = useState([]);

  const getUsers = () => {
    adminUserService.getAllSellers('?approved_for_sale=false')
      .then((response) => {
        console.log(response)
        setUsers(response);
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <Box>
      {users && users.map((user, index) => {
        return <Card
          sx={{ alignItems: 'center', justifyContent: 'center', mb: 1, borderRadius: '10px' }}
          key={index}
          variant='outlined'
        >
          <CardActionArea
            sx={{ p: 2 }}
            component={Link}
            to={`/admin/users/${user.id}`}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0, pt: isTablet ? 4 : 0 }}>
                <ListItemIcon>
                  <IconPicker icon="pause" color='info' size='medium' />
                </ListItemIcon>
                <ListItemText
                  primary={user.name}
                  secondary={<>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Email:&nbsp;
                    </Typography>
                    {user.email}
                  </>
                  }
                />
                <Box sx={isTablet ? { position: 'absolute', top: 1, right: 1 } : { minWidth: '217px' }}>
                  <Chip
                    edge="end"
                    label={user.name}
                    variant='outlined'
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    edge="end"
                    label={user.approved_for_sale ? 'Approved' : 'Pending'}
                    variant='outlined'
                    color={!user.approved_for_sale ? 'info' : 'primary'}
                  />
                </Box>
              </ListItem>
            </List>
          </CardActionArea>
        </Card>
      })}
      {users && users.length === 0 &&
        <Alert severity="info" sx={{ mb: 4, p: 2 }}>
          There are currently no new users awaiting approval.
        </Alert>
      }
    </Box>
  );
};

export default UserApprovalList
