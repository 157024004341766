import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Habitats from '../../../shared/data/habitats';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { MenuItem, Select } from '@mui/material';

const EditHabitatDialog = (
  props
) => {
  return (
    <Dialog open={props.habitatFormActive} onClose={props.showHabitatForm}>
      <DialogTitle sx={{ pb: '5px' }}>{props.habitatFormFunction} Habitat</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Enter the habitat that has been created or enhanced.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          {!props.habitatFormType && <FormControl sx={{ width: '100%', mb: '24px' }}>
            <InputLabel id="demo-simple-select-label">Please select...</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.habitatFormType}
              label="Select habitat type..."
              onChange={(event) => props.setHabitatFormType(event.target.value)}
            >
              <MenuItem key={0} value='area'>Area</MenuItem>
              <MenuItem key={1} value='hedgerow'>Hedgerow</MenuItem>
              <MenuItem key={2} value='watercourse'>Watercourse</MenuItem>
            </Select>
          </FormControl>}

          {props.habitatFormType && <>
            <FormControl fullWidth margin='normal'>
              {props.habitatFormType === 'area' && (
                <Autocomplete
                  disablePortal
                  id="area-broad-habitat"
                  options={Habitats.broadHabitatsAutocomplete}
                  value={props.broadHabitat}
                  renderInput={(params) => <TextField {...params} label="Broad Habitat" />}
                  onInputChange={(event, newBroadHabitat) => props.setBroadHabitat(newBroadHabitat)}
                />
              )}
              {props.habitatFormType === 'hedgerow' && (
                <Autocomplete
                  disablePortal
                  id="area-broad-habitat"
                  options={Habitats.hedgerowBroadHabitatsAutocomplete}
                  value={props.broadHabitat}
                  renderInput={(params) => <TextField {...params} label="Broad Habitat" />}
                  onInputChange={(event, newBroadHabitat) => props.setBroadHabitat(newBroadHabitat)}
                />
              )}
              {props.habitatFormType === 'watercourse' && (
                <Autocomplete
                  disablePortal
                  id="watercourse-broad-habitat"
                  options={Habitats.watercourseBroadHabitatsAutocomplete}
                  value={props.broadHabitat}
                  renderInput={(params) => <TextField {...params} label="Broad Habitat" />}
                  onInputChange={(event, newBroadHabitat) => props.setBroadHabitat(newBroadHabitat)}
                />
              )}
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <Autocomplete
                disablePortal
                id="habitat"
                options={props.getHabitatArray()}
                value={props.habitat}
                renderInput={(params) => <TextField {...params} label="Habitat" />}
                onInputChange={(event, newHabitat) => props.setHabitat(newHabitat)}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <TextField
                fullWidth
                className="formField"
                label={(props.habitatFormType === 'hedgerow' || props.habitatFormType === 'watercourse') ? "Length (km)" : "Area (hectares)"}
                id="area"
                type="number"
                value={props.area || ""}
                onChange={e => props.setArea(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <TextField
                fullWidth
                className="formField"
                label="Biodiversity Units"
                id="units"
                type="number"
                value={props.units || ""}
                onChange={e => props.setUnits(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <InputLabel htmlFor="total-price">Price per unit*</InputLabel>
              <OutlinedInput
                id="total-price"
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                fullWidth
                className="formField"
                disabled={!props.units}
                label="Price per unit*"
                type="number"
                step="any"
                value={props.units && ((props.totalPrice / props.units).toFixed(0))}
                onChange={e => props.setTotalPrice((e.target.value * props.units).toFixed(2))}
              />
            </FormControl>
            <FormControlLabel control={<Checkbox checked={!!props.hidePrice} onChange={e => props.setHidePrice(!!e.target.checked)} />} label="Hide price from buyers" sx={{ color: "#00000099" }} />
          </>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={props.closeHabitatForm} className="removeUppercase">Cancel</Button>
        {props.habitatFormType && <>
          {
            props.habitatFormFunction === 'Add'
              ? <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={props.handleAddHabitat}
                className="removeUppercase"
              >
                Submit
              </Button>
              : <Button
                color='primary'
                variant="contained"
                disableElevation
                onClick={() => props.handleUpdateHabitat(props.habitatId)}
                className="removeUppercase"
              >
                Update
              </Button>
          }
        </>}

      </DialogActions>
    </Dialog>
  )
}

export default EditHabitatDialog