import React, { useEffect } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ProjectsTable from "../components/projects-table";
import { GlobalBackButton } from '../../shared/components/buttons/global-back-button';

const ProjectsPage = (props) => {

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
        <GlobalBackButton buttonCopy={'Back to dashboard'} buttonLink={'/dashboard'} />
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: 'calc(100vh - 130px)',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '10px'
              }}
              elevation={0}
            >
              <ProjectsTable />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProjectsPage