import React, { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import SendIcon from '@mui/icons-material/Send';
import EnquiryService from '../services/enquiry-service';
import { UserInformationProviderContext } from '../context/user-information-provider';
import { GaiaTextField } from './form-elements/text-field';

const EnquiryDialog = ({
  active,
  projectId,
  enquiryId,
  projectType,
  onSuccess,
  onCancel,
  isReply,
  userRole,
  defaultMessage
}) => {

  const enquiryService = new EnquiryService();
  const userInformationContext = useContext(UserInformationProviderContext);

  const [message, setMessage] = useState();

  const handleSubmitEnquiry = () => {
    if (message?.length > 0) {
      if (!isReply) {
        const body = { project_id: projectId, message: message, project_type: projectType }
        enquiryService.submitNewEnquiry(body)
          .then(() => {
            userInformationContext.openSnackbar({ open: true, message: 'Enquiry sent successfully.', backgroundColor: 'green' });
            setMessage('')
            onSuccess();
          })
          .catch(err => {
            console.log(err);
          })
      } else {
        enquiryService.sendNewMessage(enquiryId, userRole, message)
          .then(() => {
            setMessage('')
            onSuccess();
          })
      }
    }
  }

  const handleCancel = () => {
    setMessage('')
    onCancel();
  }

  useEffect(() => {
    defaultMessage && setMessage(defaultMessage);
  }, [])

  return (
    <Dialog open={active} maxWidth='md' fullWidth>
      <DialogTitle sx={{ pb: '5px' }}>Send a new message</DialogTitle>
      <DialogContent>

        {!enquiryId && <DialogContentText sx={{ pb: '10px' }}>
          Send a new message to the land manager of this listing
        </DialogContentText>}

        {enquiryId && userRole === 'seller' && <DialogContentText sx={{ pb: '10px' }}>
          Reply to this enquiry using the box below
        </DialogContentText>}

        {enquiryId && userRole === 'buyer' && <DialogContentText sx={{ pb: '10px' }}>
          Send a new message to the land manager of this listing
        </DialogContentText>}

        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <GaiaTextField
            value={message || ''}
            label={'Your message'}
            charchterCount={4000}
            rows={10}
            placeholder="Enter your message..."
            emitValueOnly={true}
            onChange={e => setMessage(e)}>
          </GaiaTextField>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button
          onClick={handleCancel}
          className="removeUppercase"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmitEnquiry}
          startIcon={<SendIcon />}
          className="removeUppercase"
        >
          {enquiryId && userRole === 'seller' ? 'Reply' : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnquiryDialog