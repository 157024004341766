import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from '@mui/material';
import moment from 'moment';

const AnswerFormDisplay = ({ answerFormId, suppliedAnswers, isAdmin, useDisplayLabels = false }) => {

  const [answers, setAnswers] = useState();

  useEffect(() => {
    if (answerFormId) {
      // getAnswerForm()
      console.log('This component is not yet set up to receive an answerFormId. Please supply [suppliedAnswers] instead')
    } else {
      setAnswers(suppliedAnswers);
    }
  }, [answerFormId, suppliedAnswers])


  // const getAnswerForm = () => {
  //   questionAnswerService.getAnswerForm(answerFormId)
  //     .then(response => {
  //       setAnswers(response)
  //     })
  // }

  return (
    <>
      <Paper elevation={0}>
        {answers && answers.map((answer, index) => (

          <Box sx={{ mb: 4 }} key={index}>
            <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>
              {(!useDisplayLabels || !answer.display_label) && <b>{answer?.question_text}</b>}
              {(useDisplayLabels && answer.display_label) && <b>{answer?.display_label}</b>}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {answer.question_type !== 'DATE_PICKER' && answer?.answer_text}
              {answer.question_type === 'DATE_PICKER' && moment(answer?.answer_text).format('Do MMMM yyyy')}
            </Typography>
          </Box>
        )
        )}
      </Paper >
    </>
  )
};

export default AnswerFormDisplay