import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import AdminInvestmentProjectService from '../../services/admin-investment-project';
import { MenuItem, Select } from '@mui/material';

export const DialogCreateInvestmentPledge = ({ projectId, existingPledge, active, onUpdate, onCancel }) => {

  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const [pledge, setPledge] = useState();

  useEffect(() => {
    if (existingPledge) {
      setPledge(existingPledge);
    } else {
      setPledge({})
    }
  }, [existingPledge])

  // Set the required investment project data.
  function handlePledgeUpdate(formControl, value) {
    const updated = pledge;
    updated[formControl] = value;

    setPledge({ ...updated });
  }

  const handleAddPledge = () => {
    adminInvestmentProjectService.addInvestmentPledge(projectId, pledge)
      .then(() => {
        onUpdate()
      })
  }

  return (<>
    {pledge && <Dialog open={active} onClose={onUpdate}>
      <DialogTitle sx={{ pb: '5px' }}>Create new pledge</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Please provide the details of this pledge below.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          <GaiaTextField
            label="Full name"
            value={pledge.full_name || ''}
            placeholder="Full name of the person making the pledge"
            formControlName='full_name'
            onChange={handlePledgeUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Email"
            value={pledge.email || ''}
            placeholder="Email address of the person making the pledge"
            formControlName='email'
            onChange={handlePledgeUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Notes"
            value={pledge.extra_information || ''}
            placeholder="Any additional information"
            formControlName='extra_information'
            minRows={10}
            onChange={handlePledgeUpdate}>
          </GaiaTextField>

          <FormControl size="small" >
            <InputLabel id="project-status-label">Pledge category</InputLabel>
            <Select
              labelId="project-status-label"
              id="project-status"
              value={pledge.category || ''}
              label="Project Status"
              onChange={e => handlePledgeUpdate('category', e.target.value)}
              sx={{ minWidth: '200px' }}
            >
              <MenuItem value={'Donation'}>Donation</MenuItem>
              <MenuItem value={'Matched funding'}>Matched funding</MenuItem>
              <MenuItem value={'Not applicable'}>Not applicable</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin='normal'>
            <InputLabel htmlFor="investmentTarget">Amount pledged</InputLabel>
            <OutlinedInput
              startAdornment={<InputAdornment position="start">£</InputAdornment>}
              fullWidth
              className="formField"
              value={pledge.amount || ''}
              label="Amount pledged"
              placeholder="Provide the amount that has been pledged."
              type="number"
              onChange={e => handlePledgeUpdate('amount', e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleAddPledge}
          className="removeUppercase"
        >
          Add pledge
        </Button>
      </DialogActions>
    </Dialog>}
  </>

  )
}
