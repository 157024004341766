import httpClient from '../../shared/http/http-client';

export default class AdminSiteEnquiryFormListService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getAllForms() {
    return this.http.get(`admin/site-enquiry-form`)
      .then(response => {
        return response.data;
      })
  }

  async getForm(formId) {
    return this.http.get(`admin/site-enquiry-form/${formId}`)
      .then(response => {
        return response.data;
      })
  }
  async markAsHandled(formId) {
    return this.http.post(`admin/site-enquiry-form/${formId}`, {})
      .then(response => {
        return response.data;
      })
  }

  async deleteForm(formId) {
    return this.http.delete(`admin/site-enquiry-form/${formId}`)
      .then(response => {
        return response.data;
      })
  }
}