import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../context/hub-details';
import { UserDetailsContext } from '../context/user-details';
import CognitoUserAuthenticationService from '../services/cognito-user-authentication-service';


const SuperAdminRoute = ({ children }) => {
  const location = useLocation();
  const hubDetailsContext = useContext(HubDetailsContext);
  const userDetailsContext = useContext(UserDetailsContext)
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);

  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    setIsLoading(true);
    setIsAdmin(false);

    cognitoUserAuthenticationService.getCookie()
      .then(async response => {
        if (response) {

          let userDetails = userDetailsContext.userDetails;

          if (!userDetails) {
            userDetails = await userDetailsContext.forceUpdateUserDetails()
          }

          if (userDetails.isSuperAdmin) {
            setIsAdmin(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }

        } else {
          setIsLoading(false)
        }
      })
  }, [window.location.pathname])

  return isLoading ? null : isAdmin ? <Outlet /> : <Navigate to="/dashboard" state={{ from: location }} replace />;

}

export default SuperAdminRoute;