import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import gaiaTheme from '../../../theme';
import { HubDetailsContext } from '../../context/hub-details';
import NavBar from '../navigation/app/nav-bar';
import LeftNav from '../navigation/app/left-nav';
import RightNav from '../navigation/app/right-nav';

const AdminPageLayout = ({ children }) => {

  const [newTheme, setNewTheme] = useState(gaiaTheme);
  const [rightNavOpen, setRightNavOpen] = useState(false);
  const [leftNavExpanded, setLeftNavExpanded] = useState(false);
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    setNewTheme(gaiaTheme)
  }, [hub])

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <CssBaseline />
      <NavBar setRightNavOpen={setRightNavOpen} useGaiaLogo={true} isAdminPage={true} leftNavExpanded={leftNavExpanded} />
      <Box sx={{ display: 'flex' }}>
        <LeftNav isAdminPage={true} setLeftNavExpanded={setLeftNavExpanded} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <div>{children}</div>
        </Box>
        <RightNav open={rightNavOpen} setRightNavOpen={setRightNavOpen} />
      </Box>
    </ThemeProvider>
  );
};

export default AdminPageLayout