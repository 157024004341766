import { useEffect, useState } from 'react';
import AdminGaiaPlusService from '../../services/admin-gaia-plus-service'
import { GaiaPlusProjectList } from '../../../shared/components/gaia-plus-project-list';
import { Box, Paper, Toolbar } from '@mui/material';

export const GaiaPlusProjectsPageAdmin = () => {

  const adminGaiaPlusService = new AdminGaiaPlusService();

  const [projects, setProjects] = useState()

  useEffect(() => {
    adminGaiaPlusService.getAllProjects()
      .then(response => {
        setProjects(response)
      })
  }, [])

  return (<>
    <Toolbar />
    <Box sx={{ margin: 2 }}>
      <Paper sx={{ padding: 4 }}>
        {projects && <GaiaPlusProjectList projects={projects} isAdmin={true}></GaiaPlusProjectList>}
      </Paper>
    </Box>
  </>)
}