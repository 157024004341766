import httpClient from '../../shared/http/http-client';

export default class AdminEnquiriesService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getEnquiries(projectType) {
    return this.http.get(`admin/enquiry?project_type=${projectType}`)
      .then(response => {
        return response.data;
      })
  }

  async getEnquiry(enquiryId) {
    return this.http.get(`admin/enquiry/${enquiryId}`)
      .then(response => {
        return response.data;
      })
  }
}