import React, { Fragment, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20
  },
  section: {
    padding: 10,
    flexGrow: 0
  }
});

// Create Document Component
const ChecklistDocument = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={{ color: '#008938', fontWeight: '700', marginBottom: '10px', marginTop: '50px', fontFamily: 'Helvetica-Bold' }}>Habitat Management and Monitoring Plan Checklist</Text>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}>
            This checklist is to guide you when authoring the Habitat Management Monitoring Plan (HMMP), whether using the ‘HMMPT’ or an alternative template. It will help ensure consistency and support reviewers.
          </Text>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginTop: '10px' }}>
            This is a guide only. Refer to the relevant Local Planning Authority (LPA) for any specific requirements. You can append the list to your completed HMMP before submitting to the LPA.
          </Text>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '10px' }}>
            Use the list to check and reference relevant associated documents in your submission.
          </Text>
        </View>
        <View style={styles.section}>
          <View style={{ backgroundColor: '#00b050' }}>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica-Bold', color: '#fff' }}>
              Project and Development Information
            </Text>
          </View>
          {props.projectOverview.map((field, i) => {
            return (
              <View style={{ flexDirection: 'row', border: '1px solid #00af41' }} key={field.id}>
                <View style={{ backgroundColor: '#e2efd9', flex: 1, flexDirection: 'column', flexBasis: '100%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '10px', fontFamily: 'Helvetica' }}>
                    {field.label}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '10px', fontFamily: 'Helvetica' }}>
                    {field.value}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View style={styles.section}>
          <View style={{ backgroundColor: '#00b050' }}>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica-Bold', color: '#fff' }}>
              Sections Excluded Justification
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', minHeight: '100px', padding: '5px', fontFamily: 'Helvetica', border: '1px solid #00af41' }}>
              {props.sectionExcluded}
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ flexDirection: 'row', backgroundColor: '#00b050', marginTop: '20px', }}>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', flexDirection: 'column', flexBasis: '88%', fontFamily: 'Helvetica-Bold', color: '#fff', borderRight: '1px solid #fff' }}>
              Checklist of Contents Completed
            </Text>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', flexDirection: 'column', flexBasis: '30%', fontFamily: 'Helvetica-Bold', color: '#fff', borderRight: '1px solid #fff' }}>
              Tick if included
            </Text>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', flexDirection: 'column', flexBasis: '100%', fontFamily: 'Helvetica-Bold', color: '#fff' }}>
              Reference of supporting information
            </Text>
          </View>
          <View style={{ flexDirection: 'row', border: '1px solid #00af41' }}>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                1.
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                Project Background
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
          </View>
          {props.projectBackground.map((field, i) => {
            return (
              <View key={field.id} style={{ flexDirection: 'row', border: '1px solid #00af41', backgroundColor: (i % 2 !== 0) ? '#fff' : '#f1f6d0' }}>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}></Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.title}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.value === 'on' ? <Image src="/checked-checkbox-32.png" /> : <Image src="/square-outline-32.png" />}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.supportingInfo}
                  </Text>
                </View>
              </View>
            )
          })}
          <View style={{ flexDirection: 'row', border: '1px solid #00af41' }}>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                2.
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                Planned Management Activities
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
          </View>
          {props.plannedManagementActivities.map((field, i) => {
            return (
              <View key={field.id} style={{ flexDirection: 'row', border: '1px solid #00af41', backgroundColor: (i % 2 !== 0) ? '#fff' : '#f1f6d0' }}>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}></Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.title}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.value === 'on' ? <Image src="/checked-checkbox-32.png" /> : <Image src="/square-outline-32.png" />}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.supportingInfo}
                  </Text>
                </View>
              </View>
            )
          })}
          <View style={{ flexDirection: 'row', border: '1px solid #00af41' }}>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                3.
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold' }}>
                Monitoring Schedule
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', borderRight: '1px solid #00af41' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
              <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica' }}></Text>
            </View>
          </View>
          {props.monitoringSchedule.map((field, i) => {
            return (
              <View key={field.id} style={{ flexDirection: 'row', border: '1px solid #00af41', backgroundColor: (i % 2 !== 0) ? '#fff' : '#f1f6d0' }}>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '7%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}></Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '80%', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.title}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '30%', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #00af41' }}>
                  <Text style={{ fontSize: '12px', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.value === 'on' ? <Image src="/checked-checkbox-32.png" /> : <Image src="/square-outline-32.png" />}
                  </Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '5px', fontFamily: 'Helvetica' }}>
                    {field.supportingInfo}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
};

const HMMPchecklistPage = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [errors, setErrors] = useState([]);
  const [sectionExcluded, setSectionExcluded] = useState('');
  const [projectOverview, setProjectOverview] = useState([
    {
      id: 1,
      label: 'Site or development name',
      placeholder: 'Site or development name',
      value: ''
    },
    {
      id: 2,
      label: 'Planning application ref number (if applicable)',
      placeholder: 'Planning application reference number or N/A if Habitat Bank',
      value: ''
    },
    {
      id: 3,
      label: 'Register Reference Number (if applicable)',
      placeholder: 'Register reference number or N/A if onsite delivery',
      value: ''
    },
    {
      id: 4,
      label: 'Developer or Client details',
      placeholder: 'Developer or client name and address',
      value: ''
    },
    {
      id: 5,
      label: 'Author details',
      placeholder: 'Name, organisation, and company address of ecologist or other professional completing HMMP',
      value: ''
    },
    {
      id: 6,
      label: 'Name and reference of HMMP',
      placeholder: 'Provide the name of the HMMP and the relevant reference number',
      value: ''
    },
    {
      id: 7,
      label: 'Date checklist completed',
      placeholder: 'Click or tap to enter a date.',
      value: ''
    }
  ]);

  const [projectBackground, setProjectBackground] = useState([
    {
      id: 1,
      title: 'Project Information',
      description: 'See HMMPT for a list of relevant items to include in this section',
      value: false,
      supportingInfo: ''
    },
    {
      id: 2,
      title: 'Funding',
      description: 'Information relating to how funding has been agreed to deliver management',
      value: false,
      supportingInfo: ''
    },
    {
      id: 3,
      title: 'Legal Agreement',
      description: 'Legal agreement e.g., s106 or conservation covenant',
      value: false,
      supportingInfo: ''
    },
    {
      id: 4,
      title: 'Summary of Habitat Proposal and Plans',
      description: 'High-level summary, and plan or map, of the habitats to be created and managed as part of this HMMP',
      value: false,
      supportingInfo: ''
    },
    {
      id: 5,
      title: 'Site Boundary Map',
      description: 'Map, plan or drawing which outlines the boundary of the HMMP site',
      value: false,
      supportingInfo: ''
    },
    {
      id: 6,
      title: 'Site Context Map',
      description: 'e.g., associated LPA, national character area, relevant landscape scale policy',
      value: false,
      supportingInfo: ''
    },
    {
      id: 7,
      title: 'Phasing Strategy',
      description: 'If phased, how it will be tracked and monitored to ensure units set out in the metric are delivered on the ground',
      value: false,
      supportingInfo: ''
    },
    {
      id: 8,
      title: 'Roles & Responsibilities',
      description: 'i.e., HMMP author; responsible landowner; land management organisation; LPA or Responsible Body',
      value: false,
      supportingInfo: ''
    },
    {
      id: 9,
      title: 'Land Use Summary',
      description: 'Current site use and summary of habitats present; overview of proposed land use',
      value: false,
      supportingInfo: ''
    },
    {
      id: 10,
      title: 'Site Context Photos',
      description: 'Overview photographs of the site that will provide context',
      value: false,
      supportingInfo: ''
    },
    {
      id: 11,
      title: 'Site Baseline and Environmental Information Checklist',
      description: 'Refer to HMMPT for examples of what to include',
      value: false,
      supportingInfo: ''
    },
    {
      id: 12,
      title: 'Environmental Information',
      description: 'Refer to HMMPT and Companion Document for examples of environmental information insert forms',
      value: false,
      supportingInfo: ''
    }
  ])

  const [plannedManagementActivities, setPlannedManagementActivities] = useState([
    {
      id: 1,
      title: 'Management Plan Aims and Objectives',
      description: 'The overarching aims and objectives of the habitat management and monitoring plan.',
      value: false,
      supportingInfo: ''
    },
    {
      id: 2,
      title: 'Design Principles Informed by Baseline Information',
      description: 'How site features and baseline info influenced your site’s habitat retention, enhancement, and creation aspirationsInformation relating to how funding has been agreed to deliver management',
      value: false,
      supportingInfo: ''
    },
    {
      id: 3,
      title: 'Habitat and Condition Targets',
      description: 'Ref to a record of what you have agreed to deliver based on the biodiversity metric',
      value: false,
      supportingInfo: ''
    },
    {
      id: 4,
      title: 'Habitat Retention',
      description: 'Details of habitats to be retained throughout the proposals',
      value: false,
      supportingInfo: ''
    },
    {
      id: 5,
      title: 'Habitat Retention and Protection Measures Map',
      description: 'Show these on an accompanying habitat retention plan',
      value: false,
      supportingInfo: ''
    },
    {
      id: 6,
      title: 'Creation, Enhancement and Management Targets and Prescriptions',
      description: 'HMMPT Companion Document shows habitat creation, enhancement and management target templates',
      value: false,
      supportingInfo: ''
    },
    {
      id: 7,
      title: 'Habitat Creation, Enhancement and Management – Risk Register and Remedial Measures',
      description: 'Site-wide risk register associated with establishing and managing each habitat type',
      value: false,
      supportingInfo: ''
    }
  ])

  const [monitoringSchedule, setMonitoringSchedule] = useState([
    {
      id: 1,
      title: 'Monitoring Strategy',
      description: 'Details of methods that will be used to compare baseline to subsequent enhancement and creation',
      value: false,
      supportingInfo: ''
    },
    {
      id: 2,
      title: 'Monitoring Methods and Intervals',
      description: 'Details of the methods you will use to monitor the progress of habitats and ensure the targets are being met. Also provide details of HMMP review process.',
      value: false,
      supportingInfo: ''
    },
    {
      id: 3,
      title: 'Monitoring Reports',
      description: 'Person/s responsible for submitting monitoring reports and organisation responsible for reviewing along and monitoring schedule',
      value: false,
      supportingInfo: ''
    },
    {
      id: 4,
      title: 'Adaptive Management',
      description: 'Summarise how adaptive management will be incorporated',
      value: false,
      supportingInfo: ''
    }
  ])

  useEffect(() => {
    window.analytics.page();
  }, [])

  // Move to the next section.
  const handleNext = () => {
    setErrors([]);
    let nextTab = Number(activeTab) + 1;
    nextTab = String(nextTab);
    setActiveTab(nextTab);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const updateProjectOverview = (id, value) => {
    setProjectOverview(
      projectOverview.map(
        el => el.id === id ? { ...el, value: value } : el
      )
    );
  }

  const updateProjectBackground = (id, value, type) => {
    if (type === 'value') {
      setProjectBackground(
        projectBackground.map(
          el => el.id === id ? { ...el, value: value } : el
        )
      )
    } else if (type === 'info') {
      setProjectBackground(
        projectBackground.map(
          el => el.id === id ? { ...el, supportingInfo: value } : el
        )
      )
    }
  }

  const updatePlannedManagementActivities = (id, value, type) => {
    if (type === 'value') {
      setPlannedManagementActivities(
        plannedManagementActivities.map(
          el => el.id === id ? { ...el, value: value } : el
        )
      )
    } else if (type === 'info') {
      setPlannedManagementActivities(
        plannedManagementActivities.map(
          el => el.id === id ? { ...el, supportingInfo: value } : el
        )
      )
    }
  }

  const updateMonitoringSchedule = (id, value, type) => {
    if (type === 'value') {
      setMonitoringSchedule(
        monitoringSchedule.map(
          el => el.id === id ? { ...el, value: value } : el
        )
      )
    } else if (type === 'info') {
      setMonitoringSchedule(
        monitoringSchedule.map(
          el => el.id === id ? { ...el, supportingInfo: value } : el
        )
      )
    }
  }

  return (
    <>
      <Toolbar />
      <Container maxWidth="mdxl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '100%'
              }}
            >
              <Box sx={{ width: '100%', p: '24px' }}>
                <Grid container spacing={2} sx={{ pb: 2 }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h5" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px' }}>
                      <b>Habitat Management and Monitoring Plan Checklist</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'right' }}>
                    <Button
                      sx={{ mr: '20px' }}
                      variant='outlined'
                      className="removeUppercase"
                      startIcon={<SaveOutlinedIcon />}
                      style={{ textTransform: 'none', color: 'gray', borderColor: 'gray' }}
                      disabled
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ mr: '20px' }}
                      variant='outlined'
                      className="removeUppercase"
                      startIcon={<FileDownloadOutlinedIcon />}
                      style={{ textTransform: 'none', color: '#000', borderColor: '#000' }}
                    >
                      <PDFDownloadLink document={
                        <ChecklistDocument
                          projectOverview={projectOverview}
                          sectionExcluded={sectionExcluded}
                          projectBackground={projectBackground}
                          plannedManagementActivities={plannedManagementActivities}
                          monitoringSchedule={monitoringSchedule}
                        />
                      } fileName="hmmpt-checklist.pdf" style={{ textTransform: 'none', color: '#000' }}>
                        {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')} */}
                        Download PDF
                      </PDFDownloadLink>
                    </Button>
                  </Grid>
                </Grid>
                <TabContext value={activeTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable"
                      allowScrollButtonsMobile>
                      <Tab label="Project Overview" className="removeUppercase" value="1" />
                      <Tab label="1. Project Background" className="removeUppercase" value="2" />
                      <Tab label="2. Planned Management Activities" className="removeUppercase" value="3" />
                      <Tab label="3. Monitoring Schedule" className="removeUppercase" value="4" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Typography variant="h6" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px' }}>
                      <b>Project and Development Information</b>
                    </Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ paddingLeft: '10px', paddingBottom: '10px' }}>
                      This is a digital version of the HMMPT produced by Natural England - the orginal word version is available on the Natural England <a href='https://publications.naturalengland.org.uk/publication/5813530037846016'>website</a>.
                    </Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ paddingLeft: '10px', paddingBottom: '10px' }}>
                      This checklist is to guide you when authoring the Habitat Management Monitoring Plan (HMMP), whether using the ‘HMMPT’ or an alternative template. It will help ensure consistency and support reviewers.
                      <b>This is a guide only. Refer to the relevant Local Planning Authority (LPA) for any specific requirements.</b> You can append the list to your completed HMMP before submitting to the LPA.
                      Use the list to check and reference relevant associated documents in your submission.
                    </Typography>
                    <Grid container spacing={4} sx={{ pt: 4 }}>
                      {projectOverview.map((field, i) => {
                        return (
                          <Grid item xs={12} md={6} lg={6} style={{ paddingTop: "24px" }} key={field.id}>
                            <TextField
                              fullWidth
                              className="formField"
                              label={field.label}
                              placeholder={field.placeholder}
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={e => updateProjectOverview(field.id, e.target.value)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px', marginTop: '24px' }}>
                      <b>Sections Excluded Justification</b>
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ pt: "20px" }}>
                      <TextField
                        fullWidth
                        className="formField"
                        label="Sections Excluded Justification"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText="Please provide clear and concise justifications if you intend to exclude any sections from the HMMP in your submission. For example, if a section(s) has been covered elsewhere within the planning process and is referenced in this checklist.

                        Acceptance of excluded sections is at the discretion of the LPA or regulating authority – check requirements with the relevant LPA.
                        "
                        placeholder="Please provide clear and concise justifications if you intend to exclude any sections from the HMMP in your submission. For example, if a section(s) has been covered elsewhere within the planning process and is referenced in this checklist.

                        Acceptance of excluded sections is at the discretion of the LPA or regulating authority – check requirements with the relevant LPA.
                        "
                        multiline
                        rows={6}
                        onChange={e => setSectionExcluded(e.target.value)}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handleNext}
                      className='removeUppercase'
                      endIcon={<ChevronRightIcon />}
                      sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                    >
                      Next
                    </Button>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid
                        item
                        xs={10} md={4} lg={3}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px', paddingLeft: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ paddingLeft: '10px' }}>
                          <b>Checklist of Contents Completed</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2} md={2} lg={2}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'center' }}>
                          <b>Tick if included</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12} md={6} lg={7}
                        style={{ paddingTop: '0px', paddingBottom: '10px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary">
                          <b>Reference of supporting information</b>
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Where not included, provide reference or link for document/s supporting your HMMP. Or state if N/A
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                        <Divider />
                      </Grid>
                      {projectBackground.map((field, i) => {
                        return (
                          <Fragment key={field.id}>
                            <Grid
                              item
                              xs={10} md={4} lg={3}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px', paddingLeft: '0px' }}
                            >
                              <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px' }}>
                                {field.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2} md={2} lg={2}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" sx={{ lineHeight: '50px', textAlign: 'center' }}>
                                <Checkbox onChange={e => updateProjectBackground(field.id, e.target.value, 'value')} />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12} md={6} lg={7}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" >
                                <TextField
                                  fullWidth
                                  className="formField"
                                  //label={field.label}
                                  placeholder={field.description}
                                  autoComplete="off"
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ mt: '5px', mb: '5px' }}
                                  onChange={e => updateProjectBackground(field.id, e.target.value, 'info')}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                              <Divider />
                            </Grid>
                          </ Fragment>
                        )
                      })}
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handleNext}
                      className='removeUppercase'
                      endIcon={<ChevronRightIcon />}
                      sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                    >
                      Next
                    </Button>
                  </TabPanel>
                  <TabPanel value="3">
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid
                        item
                        xs={10} md={4} lg={3}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px', paddingLeft: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ paddingLeft: '10px' }}>
                          <b>Checklist of Contents Completed</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2} md={2} lg={2}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'center' }}>
                          <b>Tick if included</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12} md={6} lg={7}
                        style={{ paddingTop: '0px', paddingBottom: '10px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary">
                          <b>Reference of supporting information</b>
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Where not included, provide reference or link for document/s supporting your HMMP. Or state if N/A
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                        <Divider />
                      </Grid>
                      {plannedManagementActivities.map((field, i) => {
                        return (
                          <Fragment key={field.id}>
                            <Grid
                              item
                              xs={10} md={4} lg={3}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px', paddingLeft: '0px' }}
                            >
                              <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px' }}>
                                {field.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2} md={2} lg={2}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" sx={{ lineHeight: '50px', textAlign: 'center' }}>
                                <Checkbox onChange={e => updatePlannedManagementActivities(field.id, e.target.value, 'value')} />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12} md={6} lg={7}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" >
                                <TextField
                                  fullWidth
                                  className="formField"
                                  //label={field.label}
                                  placeholder={field.description}
                                  autoComplete="off"
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ mt: '5px', mb: '5px' }}
                                  onChange={e => updatePlannedManagementActivities(field.id, e.target.value, 'info')}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                              <Divider />
                            </Grid>
                          </ Fragment>
                        )
                      })}
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handleNext}
                      className='removeUppercase'
                      endIcon={<ChevronRightIcon />}
                      sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                    >
                      Next
                    </Button>
                  </TabPanel>
                  <TabPanel value="4">
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid
                        item
                        xs={10} md={4} lg={3}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px', paddingLeft: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ paddingLeft: '10px' }}>
                          <b>Checklist of Contents Completed</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2} md={2} lg={2}
                        sx={{
                          minHeight: '50px'
                        }}
                        style={{ paddingTop: '0px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'center' }}>
                          <b>Tick if included</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12} md={6} lg={7}
                        style={{ paddingTop: '0px', paddingBottom: '10px' }}
                      >
                        <Typography variant="subtitle1" color="text.primary">
                          <b>Reference of supporting information</b>
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Where not included, provide reference or link for document/s supporting your HMMP. Or state if N/A
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                        <Divider />
                      </Grid>
                      {monitoringSchedule.map((field, i) => {
                        return (
                          <Fragment key={field.id}>
                            <Grid
                              item
                              xs={12} md={4} lg={3}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px', paddingLeft: '0px' }}
                            >
                              <Typography variant="subtitle1" color="text.primary" sx={{ lineHeight: '50px', paddingLeft: '10px' }}>
                                {field.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12} md={2} lg={2}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" sx={{ lineHeight: '50px', textAlign: 'center' }}>
                                <Checkbox onChange={e => updateMonitoringSchedule(field.id, e.target.value, 'value')} />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12} md={6} lg={7}
                              sx={{
                                minHeight: '50px',
                                backgroundColor: (i % 2 !== 0) ? '#fff' : '#00000006'
                              }}
                              style={{ paddingTop: '0px' }}
                            >
                              <Typography variant="subtitle2" color="text.primary" >
                                <TextField
                                  fullWidth
                                  className="formField"
                                  //label={field.label}
                                  placeholder={field.description}
                                  autoComplete="off"
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ mt: '5px', mb: '5px' }}
                                  onChange={e => updateMonitoringSchedule(field.id, e.target.value, 'info')}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                              <Divider />
                            </Grid>
                          </ Fragment>
                        )
                      })}
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      //onClick={handleNext}
                      className='removeUppercase'
                      startIcon={<FileDownloadOutlinedIcon />}
                      sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                    >
                      <PDFDownloadLink document={
                        <ChecklistDocument
                          projectOverview={projectOverview}
                          sectionExcluded={sectionExcluded}
                          projectBackground={projectBackground}
                          plannedManagementActivities={plannedManagementActivities}
                          monitoringSchedule={monitoringSchedule}
                        />
                      } fileName="hmmpt-checklist.pdf" style={{ textTransform: 'none', color: '#fff' }}>
                        {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')} */}
                        Download PDF
                      </PDFDownloadLink>
                    </Button>
                  </TabPanel>
                </TabContext>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HMMPchecklistPage