import { Alert, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { UserDetailsContext } from '../../context/user-details';
import { ChevronRight } from '@mui/icons-material';
import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';
import { LoginForm } from '../auth/login-form';
import { RegistrationForm } from '../auth/registration-form';
import CloseIcon from '@mui/icons-material/Close';
import { HubDetailsContext } from '../../context/hub-details';
import { useNavigate } from 'react-router-dom';

export const SignUpDialog = ({ role, open, closeDialog, inputUserDetails, forwardUrl }) => {

  const hubDetailsContext = useContext(HubDetailsContext);
  const userDetailsContext = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain)
  const [details, setDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    organisation: '',
    password: '',
    passwordConfirmation: '',
    role: role,
    hubId: hubDetailsContext.hubDetails.id
  });

  const [errors, setErrors] = useState([]);
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  const [isLogInForm, setIsLogInForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      setIsLoggedIn(true);
    }
  }, [userDetailsContext])

  useEffect(() => {
    if (inputUserDetails) {
      setDetails({
        firstname: inputUserDetails.first_name,
        lastname: inputUserDetails.surname,
        email: inputUserDetails.email,
        organisation: inputUserDetails.organisation,
        role: role ? role : inputUserDetails.role,
        hubId: hubDetailsContext.hubDetails.id,
        password: '',
        passwordConfirmation: '',
      })
    }
  }, [inputUserDetails]);

  const toggleFormType = () => {
    setIsLogInForm(!isLogInForm);
  };

  const submitVerification = () => {
    cognitoUserAuthenticationService.confirmRegistration(details, verificationCode)
      .then(() => {
        loginUser();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const loginUser = () => {
    cognitoUserAuthenticationService.loginUser(details.email, details.password)
      .then(res => {
        userDetailsContext.forceUpdateUserDetails()
          .then((res) => {
            if (!inputUserDetails) {
              closeDialog()
            } else {
              setShowAuthCode(false)
              setIsLoggedIn(true);
            }
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const resendConfirmationCode = () => {
    cognitoUserAuthenticationService.sendConfirmationCode(details.email)
      .then(response => {
      })
      .catch(err => {
        console.log(err);
      })
  }

  const goToHomePage = () => {
    navigate(forwardUrl)
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open}>

      {!showAuthCode && <>
        <DialogTitle sx={{ p: 4, mt: 2, pb: 0 }}>
          {!inputUserDetails && <Typography variant="h5">
            Create a free account or sign in to contact the seller
          </Typography>}

          {inputUserDetails && <Typography variant="h5" sx={{ mb: 3 }}>
            Your enquiry has been submitted successfully.
          </Typography>}

          {inputUserDetails && !isLoggedIn && <Typography variant="p">
            Please enter a password below to create an account.
          </Typography>}

          {inputUserDetails && isLoggedIn && <Typography variant="p">
            Thank you for submitting this enquiry. A member of the team will be in touch. You can close this message or use the button below to visit the home page.
          </Typography>}
          <Divider sx={{ mt: 4 }} />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 4, pb: 6 }}>

          {errors && errors.map((error, index) => {
            return <Alert key={index} severity='error' sx={{ mb: 1 }}>{error}</Alert>
          })}

          {!isLogInForm && !isLoggedIn && <>
            <RegistrationForm roleAutoProvided={true} toggleFormType={toggleFormType} setShowAuthCode={setShowAuthCode} details={details} setDetails={setDetails} setErrors={setErrors} hasInputUserDetails={inputUserDetails} />
          </>}

          {isLogInForm && !isLoggedIn && <>
            <LoginForm toggleFormType={toggleFormType} setShowAuthCode={setShowAuthCode} details={details} setDetails={setDetails} closeDialog={closeDialog} />
          </>}

          {isLoggedIn && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={goToHomePage}
              className='removeUppercase'
              endIcon={<ChevronRight />}
              sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
            >
              Back to search
            </Button>

            <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => closeDialog()}
              className='removeUppercase'
              endIcon={<ChevronRight />}
              sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right", mr: 2 }}
            >
              Close
            </Button>
          </Grid>}


        </DialogContent>
      </>}

      {showAuthCode && <>
        <DialogTitle sx={{ pb: '5px', mb: 2 }}>
          Verify email address
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="subtitle2" color="text.secondary" >
                Please enter the 6 digit verification code that has been sent to <b>{details.email}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
              <TextField
                fullWidth
                className="formField"
                label="Verification Code"
                id="verificationCode"
                placeholder="Verification Code"
                onChange={e => setVerificationCode(e.target.value)}
                // value={verificationCode}
                onKeyDown={(event) => (
                  event.key === 'Enter' ? submitVerification() : null
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={submitVerification}
                className='removeUppercase'
                endIcon={<ChevronRight />}
                sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
              >
                Submit
              </Button>

              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={resendConfirmationCode}
                className='removeUppercase'
                endIcon={<ChevronRight />}
                sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right", mr: 2 }}
              >
                Resend code
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>}

    </Dialog>
  )
}