/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import TabPanel from '../../../shared/components/tab-panel';
import CustomFormList from '../../components/config/custom-forms/custom-form-list';
import CreateCustomForm from '../../components/config/custom-forms/create-custom-form';

const CustomFormControl = () => {

  const [activeCustomFormTab, setActiveCustomFormTab] = useState(0);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Forms & Fields
        </Typography>
        {activeCustomFormTab === 0
          ?
          <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            onClick={() => setActiveCustomFormTab(1)}
          >
            Create new form
          </Button>
          :
          <Button
            variant="outlined"
            size="medium"
            disableElevation
            className="removeUppercase"
            onClick={() => setActiveCustomFormTab(0)}
          >
            Cancel
          </Button>
        }
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <TabPanel value={activeCustomFormTab} index={0}>
        <CustomFormList />
      </TabPanel>
      <TabPanel value={activeCustomFormTab} index={1}>
        <CreateCustomForm setActiveCustomFormTab={setActiveCustomFormTab} />
      </TabPanel>
    </Box>
  );
};

export default CustomFormControl 