import httpClient from '../http/http-client';

export default class ShallowStatusService {
  http;
  bngShallowStatuses;
  investmentProjectShallowStatuses;

  static instance = new ShallowStatusService();

  static getInstance() {
    if (ShallowStatusService.instance === null) ShallowStatusService.instance = new ShallowStatusService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient
  }

  async getAllStatuses(projectType) {
    if (projectType === 'BNG_MARKETPLACE' && this.bngShallowStatuses) {
      return this.bngShallowStatuses;
    } else if (projectType === 'INVESTMENT_PROJECT' && this.investmentProjectShallowStatuses) {
      return this.investmentProjectShallowStatuses;
    } else {
      return this.http.get(`site-user/project-status/shallow-status?project_type=${projectType}`)
        .then(response => {
          if (projectType === 'BNG_MARKETPLACE') {
            this.bngShallowStatuses = response.data;
          };

          if (projectType === 'INVESTMENT_PROJECT') {
            this.investmentProjectShallowStatuses = response.data;
          };

          return response.data;
        })
        .catch(err => {
          console.log(err);
          throw err;
        })
    }
  };

  /**
   * Update the shallow status of a BNG project
   * @param {*} projectId - The project ID to update.
   * @param {*} statusCode - The status code retreived from the GET EP.
   * @returns
   */
  async updateBngProjectShallowStatus(projectId, statusCode, role = 'seller') {
    return this.http.post(`${role}/${role === 'seller' ? 'project' : 'bng-project'}/${projectId}/update-shallow-status`, { shallow_status_code: statusCode })
      .then(response => {
        return response.data
      });
  }

  /**
  * Update the shallow status of a BNG project
  * @param {*} projectId - The project ID to update.
  * @param {*} statusCode - The status code retreived from the GET EP.
  * @returns
  */
  async updateInvestmentProjectShallowStatus(projectId, statusCode, role = 'seller') {
    return this.http.post(`${role}/investment-project/${projectId}/update-shallow-status`, { shallow_status_code: statusCode })
      .then(response => {
        return response.data
      });
  }

}