import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import gaiaTheme from '../../../theme';
import Box from '@mui/material/Box';
import { HubDetailsContext } from '../../context/hub-details';
import RightNav from '../navigation/website/right-nav';
import NavBar from '../navigation/website/nav-bar';
import WebsiteFooter from '../website-footer/website-footer';

const WebsitePageLayout = ({ children }) => {
  const [rightNavOpen, setRightNavOpen] = useState(false);
  const [newTheme, setNewTheme] = useState(gaiaTheme);
  const [inFrame, setInFrame] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);

  const hub = useContext(HubDetailsContext);
  const pagesWithHiddenFooter = ['/hub/marketplace', '/hub/invest']

  useEffect(() => {
    setNewTheme(hub.hubDetails.theme)
    setInFrame(inIframe());
  }, [hub]);

  useEffect(() => {
    if (pagesWithHiddenFooter.includes(window.location.pathname)) {
      setHideFooter(true)
    } else {
      setHideFooter(false)
    }
  }, [window.location.pathname])

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <Box sx={{ display: 'flex', minHeight: '80vh' }}>
        {!inFrame && <NavBar
          setRightNavOpen={setRightNavOpen}
        />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: inFrame ? '-64px' : '',
            position: 'relative',
            minHeight: '101vh',
            maxWidth: '100vw'
          }}
        >
          <Box sx={{ maxWidth: '100%', minHeight: '100vh', }}>{children}</Box>
          {!hideFooter && <WebsiteFooter></WebsiteFooter>}
        </Box>
        {!inFrame && <RightNav open={rightNavOpen} setRightNavOpen={setRightNavOpen} />}
      </Box>
    </ThemeProvider>
  );
};

export default WebsitePageLayout