import httpClient from '../http/http-client';

export default class EventService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getAllEvents() {
    return this.http.get(`hub/event`)
      .then(response => {
        return response.data;
      });
  }
}