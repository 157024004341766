import httpClient from '../../shared/http/http-client';

export default class SellerInvestmentProjectService {
  http;

  constructor() {
    this.http = httpClient
  }

  async createInvestmentProject(file) {
    return this.http.post('seller/investment-project', file)
      .then(response => {
        return response.data;
      })
  }

  async getInvestmentProject(id) {
    return this.http.get(`seller/investment-project/${id}`)
      .then(response => {
        return response.data;
      })
  }

  async submitApprovalInvestmentProject(id) {
    return this.http.post(`seller/investment-project/${id}/submit-for-approval`, {})
      .then(response => {
        return response.data;
      })
  }

  async updateInvestmentProject(id, updatedDetails) {
    return this.http.post(`seller/investment-project/${id}/update`, updatedDetails)
      .then(response => {
        return response.data;
      })
  }

  async getAllInvestmentProjects() {
    return this.http.get('seller/investment-project')
      .then(response => {
        return response.data;
      })
  }

  async uploadNewInvestmentProjectImage(projectId, form) {
    return this.http.post(`seller/investment-project/${projectId}/add-project-image`, form)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

  async deleteProjectImage(projectId, imageKey) {
    return this.http.post(`seller/investment-project/${projectId}/delete-image`, { image_key: imageKey })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err
      })
  }

}