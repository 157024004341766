import React, { useContext, useEffect, useState } from "react";
import LinkButton from '@mui/material/Link';
import ProjectMap from "../../shared/components/project-map";
import InfiniteScroll from 'react-infinite-scroller';
import ProjectCard from "../../shared/components/project-card";
import RequestUnitsDialog from "../../shared/components/request-units-dialog";
import gaiaTheme from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WaterIcon from '@mui/icons-material/Water';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { lpasAutocomplete } from '../../shared/data/lpas';
import { nationalCharacterAreasAutocomplete } from '../../shared/data/national-character-areas';
import * as Habitats from '../../shared/data/habitats';
import BuyerSearch from '../components/buyer-search';
import TuneIcon from '@mui/icons-material/Tune';
import Alert from '@mui/material/Alert';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import StarIcon from '@mui/icons-material/Star';
import PageviewIcon from '@mui/icons-material/Pageview';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import httpClient from '../../shared/http/http-client';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Paper from '@mui/material/Paper';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { UserDetailsContext } from '../../shared/context/user-details';
import ProjectService from '../../shared/services/project-service.service';
import { Link, useNavigate } from 'react-router-dom';
import { MetaTagProvider } from '../../shared/components/mata-tag-provider';
import ProjectMapCornwall from '../../shared/components/project-map-cornwall';
import HubCustomListService from '../../shared/services/hub-custom-list-service';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grow, MobileStepper, Tab, TableCell, Tabs } from '@mui/material';
import BNGMetricUpload from '../components/bng-metric-upload';
import { East, ExpandMoreOutlined, GrassOutlined, LooksOne, LooksTwo, Looks3, Looks4, Looks5, Looks6, SpaOutlined, Upload, UploadFile, Water, West, ExpandLessOutlined, OpenInNew } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import BngMetricTable from '../components/bng-metric-table.js';
import MetricProjectCard from '../components/metric-project-card.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BuyerHomePage = () => {

  const hub = useContext(HubDetailsContext);
  const projectService = new ProjectService();
  const hubCustomListService = new HubCustomListService();

  const [freeTier, setFreeTier] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [searchSaved, setSearchSaved] = useState(false);
  const [savedSearchesOpen, setSavedSearchesOpen] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);
  const [saveSearchActive, setSaveSearchActive] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');
  const [activeSavedSearch, setActiveSavedSearch] = useState(null);
  const [requestUnitsDialogActive, setRequestUnitsDialogActive] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [regionsAutocomplete, setRegionsAutocomplete] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [sort, setSort] = useState('relevance');

  // new filters
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const [locationFilterOpen, setLocationFilterOpen] = useState(false);
  const [broadHabitatFilterOpen, setBroadHabitatFilterOpen] = useState(false);
  const [globalFilterChanged, setGlobalFilterChanged] = useState(false);
  const [searchResultsCount, setSearchResultsCount] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [pageSize, setPageSize] = useState(4);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [requestedPageNumbers, setRequestedPageNumbers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [titleCopy, setTitleCopy] = useState('Purchase biodiversity units')
  const [bodyCopy, setBodyCopy] = useState('Find a project that meets your offsite biodiversity requirements')
  const [isHub, setIsHub] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMainHub, setIsMainHub] = useState(true);
  const [firstSearchRetreived, setFirstSearchRetreived] = useState(false);
  const [customRegionListRetreived, setCustomRegionListRetreived] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [searchActiveTab, setSearchActiveTab] = useState(0);
  const [metricRequiredUnits, setMetricRequiredUnits] = useState({});
  const [uploadError, setUploadError] = useState(false);
  const [activeBngMetricStep, setActiveBngMetricStep] = useState(0);
  const [expandedAlternative, setExpandedAlternative] = useState(null);

  const handleSearchTabChange = (event, newValue) => {
    setSearchActiveTab(newValue);
  };

  const [searchFilters, setSearchFilters] = useState({
    region: [],
    nca: [],
    lpa: [],
    unitType: [],
    broadHabitat: [],
    show_neighbour_lpas: true,
    show_neighbour_ncas: true
  })

  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const user = useContext(UserDetailsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.userDetails?.accessLevel === 0) {
      setFreeTier(true);
    }

    if (user.userDetails) {
      if (user.userDetails.name) {
        setAuthenticated(true);
      }
    }

    if (hub.hubDetails) {
      setIsMainHub(hub.hubDetails?.main_hub);
      if (!hub.hubDetails?.main_hub) {
        setActiveTab(1);
      }
    }

    httpClient.get('data/hub/module-style/marketplace')
      .then(moduleStyle => {
        // if (moduleStyle.data[0]?.banner_image_url) {
        //   setBannerImageUrl(moduleStyle.data[0]?.banner_image_url)
        // } else {
        //   setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        // }
        setTitleCopy(moduleStyle.data[0]?.title_copy ? moduleStyle.data[0].title_copy : "Purchase biodiversity units");
        setBodyCopy(moduleStyle.data[0]?.body_copy ? moduleStyle.data[0].body_copy : "Find a project that meets your offsite biodiversity requirements");
        setIsHub(true);
        setIsLoading(false);
      })

    window.analytics.page();
  }, [hub, user]);

  // Save Search

  const handleSaveSearch = () => {
    if (!searchSaved) {
      postSavedSearch(false);
    }
    // setSearchSaved(!searchSaved);
    setSaveSearchActive(false);
  }

  const postSavedSearch = async (isRequestedUnit) => {
    const search_name = saveSearchName;

    try {
      const body = {
        local_planning_authority: searchFilters.lpa.join(','),
        national_character_area: searchFilters.nca.join(','),
        habitat_type: searchFilters.unitType.join(','),
        broad_habitat: searchFilters.broadHabitat.join(','),
        region: searchFilters.region.join(','),
        search_name,
        isRequestedUnit,
        search_query_string: window.location.search
      };

      return httpClient.post('savedsearch', body)
        .then(response => {
          return response.data
        })
    } catch (err) {
      console.log(err.message);
    }
  }

  const getSavedSearches = async () => {
    httpClient.get('savedsearch')
      .then(response => {
        setSavedSearches(response.data)
      })
  }

  const handleExpandSavedSearches = () => {
    getSavedSearches();
    setSavedSearchesOpen(!savedSearchesOpen);
  };

  const handleSavedSearchSelect = (id) => {
    const savedSearch = savedSearches.filter((savedSearch) => savedSearch.ss_id === id);

    setFiltersFromUrl(savedSearch[0]?.search_query_string)
    setSavedSearchesOpen(false);
  };

  // request units

  const showRequestUnitsDialog = () => {
    setRequestUnitsDialogActive(!requestUnitsDialogActive);
    setRequestSubmitted(false);
    setSaveSearchName('');
  };

  const handleSubmitRequestUnits = () => {
    postSavedSearch(true);
    setRequestSubmitted(true);
  };

  // filter display handlers

  const toggleFilter = () => {
    switch (activeTab) {
      case 0:
        setActiveTab(1);
        break;
      case 1:
        setActiveTab(0);
        break;
      default:
        setActiveTab(0);
        break;
    }
  };

  const handleExpandTypeFilter = () => {
    setTypeFilterOpen(!typeFilterOpen);
  };

  const handleExpandLocationFilter = () => {
    setLocationFilterOpen(!locationFilterOpen);
  };

  const handleExpandBroadHabitatFilter = () => {
    setBroadHabitatFilterOpen(!broadHabitatFilterOpen);
  };

  const handleSortUpdate = (sortValue) => {
    setRequestedPageNumbers([]);
    setNextPageNumber(0);
    setGlobalFilterChanged(true);

    setSort(sortValue);
  }

  const handleFilterUpdate = (filterName, filterValue, removeAll = false) => {
    setRequestedPageNumbers([]);
    setNextPageNumber(0);
    setGlobalFilterChanged(true);

    if (!removeAll) {
      if (typeof filterValue === 'string') {
        filterValue = [filterValue]
      }

      if (filterName === 'region' && (!filterValue || filterValue.length === 0)) {
        setSearchFilters({ ...searchFilters, [filterName]: filterValue, lpa: [], nca: [] });
      } else if (filterName === 'lpa' && (!filterValue || filterValue.length === 0)) {
        setSearchFilters({ ...searchFilters, [filterName]: filterValue, nca: [] });
      } else {
        setSearchFilters({ ...searchFilters, [filterName]: filterValue });
      }

    } else {
      setLocationFilterOpen(false);
      setBroadHabitatFilterOpen(false);
      setBroadHabitatFilterOpen(false);

      setSearchFilters({
        region: [],
        nca: [],
        lpa: [],
        unitType: [],
        broadHabitat: [],
        show_neighbour_lpas: true,
        show_neighbour_ncas: true
      })
    }
  }

  const handleRemoveSingleSearchParam = (searchParam) => {
    setRequestedPageNumbers([]);
    setNextPageNumber(0);
    setGlobalFilterChanged(true);
    setSearchFilters({
      region: searchFilters['region'].filter(value => value !== searchParam),
      nca: searchFilters['nca'].filter(value => value !== searchParam),
      lpa: searchFilters['lpa'].filter(value => value !== searchParam),
      unitType: searchFilters['unitType'].filter(value => value !== searchParam),
      broadHabitat: searchFilters['broadHabitat'].filter(value => value !== searchParam),
    })
  }

  // get function

  const getProjects = async () => {
    if (!requestedPageNumbers.includes(nextPageNumber)) {
      setRequestedPageNumbers([...requestedPageNumbers, nextPageNumber])
      setProjectsLoading(true);
      let params = new URLSearchParams();
      params.append('page', nextPageNumber);
      params.append('size', pageSize);
      params.append('sort', sort);
      Object.entries(searchFilters).map((value) => {
        // Handle if a custom region list hs been provided, and the search param needs to be updated to check the custom_region_tag.
        if (value[0] === 'region' && customRegionListRetreived) {
          return params.append('custom_region_tag', value[1].join(','))
        } else {
          if (value[0] === 'show_neighbour_lpas' || value[0] === 'show_neighbour_ncas') {
            return params.append(value[0], value[1])
          } else {
            return params.append(value[0], value[1].join(','))
          }
        }
      })

      // const route = hub?.hubDetails.main_hub ? 'buyer' : 'hub'
      const route = 'hub';
      projectService.getProjects(route, params.toString()).then(response => {
        if (globalFilterChanged) {
          setProjects(response.projects);
          amendUrlWithSearchParams();
          window.scrollTo(0, 250);
        } else {
          setProjects([...projects, ...response.projects]);
        }

        setNoResultsFound(response.noResults)

        setSearchResultsCount(response.count);
        setFirstSearchRetreived(true)
        setNextPageNumber(nextPageNumber + 1);
        if (nextPageNumber * pageSize > searchResultsCount) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setGlobalFilterChanged(false);
        setProjectsLoading(false);
      })
    }
  };

  const setFiltersFromUrl = (search) => {
    let params = new URLSearchParams(search ? search : window.location.search);
    let paramObject = {};

    for (const [key, value] of params.entries()) {
      if (key === 'unitType') {
        paramObject[key] = [value];
        setTypeFilterOpen(true)
      } else if (key === 'broadHabitat') {
        setBroadHabitatFilterOpen(true)
        paramObject[key] = value.split(',');
      } else if (key === 'show_neighbour_lpas' || key === 'show_neighbour_ncas') {
        paramObject[key] = value === 'true';
      } else {
        setLocationFilterOpen(true)
        paramObject[key] = value.split(',');
      }
    }

    if (Object.values(paramObject).length > 0) {
      setRequestedPageNumbers([]);
      setNextPageNumber(0);
      setGlobalFilterChanged(true)
      setActiveTab(1);
      setSearchFilters({ ...searchFilters, ...paramObject })
    }
  }

  const amendUrlWithSearchParams = () => {
    let params = new URLSearchParams();

    Object.entries(searchFilters).forEach((value) => {
      if (value[1].length) {
        params.append(value[0], value[1].join(','))
      } else if (value[0] === 'show_neighbour_lpas' || value[0] === 'show_neighbour_ncas') {
        params.append(value[0], value[1])
      }
    })
    navigate(`${window.location.pathname}?${params.toString()}`, { replace: true })
  }

  const handleMapRegionClick = (region) => {
    setProjectsLoading(true);
    setNextPageNumber(0);
    setRequestedPageNumbers([]);
    setProjects([]);
    handleFilterUpdate('region', region)
    setLocationFilterOpen(true);
    freeTier ? setActiveTab(0) : setActiveTab(1);
  }

  useEffect(() => {
    getProjects();
  }, [searchFilters, sort]);

  useEffect(() => {
    setFiltersFromUrl()
  }, [])

  const filterParamCount = () => {
    const tempSearchFilters = { ...searchFilters };
    delete tempSearchFilters.show_neighbour_lpas;
    delete tempSearchFilters.show_neighbour_ncas;
    return Object.values(tempSearchFilters).reduce((total, value) => total + value.length, 0)
  }

  useEffect(() => {
    hubCustomListService.getCustomRegionList(true)
      .then(response => {
        const newRegions = response.data.map(region => ({ label: region.value, group: 'Region', group_id: 5 }))
        setRegionsAutocomplete(newRegions);

        if (response.custom_list_retreived) {
          setCustomRegionListRetreived(true);
        }
      })
  }, [hub])

  const handleBuyerSearch = (filtername, value) => {
    if (filtername === 'unitType') {
      setActiveTab(1);
      setTypeFilterOpen(true);
      handleFilterUpdate(filtername, value);
    }

    if (!filtername) {
      handleFilterUpdate('', '', true)
    } else {
      setLocationFilterOpen(true)
      setActiveTab(1);
      handleFilterUpdate(filtername, value);
    }

    setSearchInputValue('');
  }

  const handleBngMetricNext = () => {
    //setBngMetricErrors([]);
    activeBngMetricStep === 1 && getBngMetricProjects();
    setActiveBngMetricStep(activeBngMetricStep + 1)
  }

  const handleBngMetricBack = () => {
    setActiveBngMetricStep(activeBngMetricStep - 1)
  }

  // get function

  const getBngMetricProjects = async () => {
    projectService.postBulkSearch(metricRequiredUnits).then(response => {
      setMetricRequiredUnits(response);
    })
  };

  const handleExpandedAlternativeClick = (index) => {
    index === expandedAlternative ? setExpandedAlternative(null) : setExpandedAlternative(index);
  };

  return (<>
    {true && <>
      <MetaTagProvider metaTitle={'BNG Marketplace'}></MetaTagProvider>
      <Toolbar />
      <Box
        sx={{
          minHeight: '45vh',
          pb: 12,
          backgroundImage: `url(${hub.hubDetails.hub_styles?.banner_image_url || '/background-land.png'})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Grid container sx={{ pl: 4, pr: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: hub?.main_hub ? 'center' : 'left', fontWeight: 600, color: '#fff', textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}
              >
                {titleCopy}
              </Typography>
              <Typography variant='h6' component='h2' sx={{ mb: 1.5, textAlign: hub?.main_hub ? 'center' : 'left', color: '#fff', textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}>
                {bodyCopy}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" width='100%'>
        {!freeTier || isHub ? (
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              border: '1px solid #a9a9a9',
              padding: 2,
              pb: 0,
              mt: '-80px',
              ml: "24px",
              mr: "24px"
            }}
          >
            {isMainHub &&
              <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 0, ml: 3, mr: 3, mt: 1 }}>
                <Tabs value={searchActiveTab} onChange={handleSearchTabChange} aria-label="search tabs">
                  <Tab
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    label="Search Marketplace"
                    className='removeUppercase'
                    {...a11yProps(0)}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    label={
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography>Upload BNG Metric</Typography>
                        <Chip label='New' />
                      </Stack>
                    }
                    className='removeUppercase'
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
            }
            <TabPanel value={searchActiveTab} index={0}>
              <BuyerSearch
                handleSetFilter={handleBuyerSearch}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={8} >
                  {(filterParamCount() === 0) && (
                    <List sx={{ pt: 2 }}>
                      {authenticated && <ListItemButton onClick={handleExpandSavedSearches} sx={{ width: '185px', borderRadius: '5px', pt: 1 }} >
                        <ListItemText primary="Use saved search" />
                        {savedSearchesOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>}
                      <Collapse in={savedSearchesOpen} timeout="auto" unmountOnExit>
                        <List component="nav" aria-label="saved-searches">
                          {savedSearches.length > 0 ? savedSearches.map((savedSearch, index) => (
                            <ListItemButton
                              onClick={() => handleSavedSearchSelect(savedSearch.ss_id)}
                              key={index}
                            >
                              <ListItem
                              >
                                <ListItemIcon>
                                  <PageviewIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={savedSearch.ss_search_name}
                                />
                              </ListItem>
                            </ListItemButton>
                          )
                          ) : (
                            <Typography
                              variant="body"
                              color="text.secondary"
                              sx={{ p: 2 }}
                            >
                              No searches have been saved yet.
                            </Typography>
                          )}
                        </List>
                      </Collapse>
                    </List>
                  )}
                  {/* Removed projects loading to stop it flickering - not sure if this will cause any issues */}
                  {/* {(searchResultsCount >= 0 && filterParamCount() > 0 && projectsLoading === false) && ( */}
                  {(searchResultsCount >= 0 && filterParamCount() > 0) && (
                    <Stack direction="row" spacing={1} useFlexGap sx={{ mt: 2, flexFlow: "wrap", rowGap: '10px' }}>
                      {Object.entries(searchFilters).map((filter, index) => (
                        filter[0] !== 'show_neighbour_lpas' && filter[0] !== 'show_neighbour_ncas' &&
                        filter[1].map((value, i) => {
                          return <Chip
                            label={value}
                            variant="outlined"
                            onDelete={() => handleRemoveSingleSearchParam(value)}
                            key={i}
                          />
                        })

                      )
                      )}
                      {filterParamCount() > 0 && (
                        <LinkButton
                          color="primary"
                          onClick={() => handleFilterUpdate('', '', true)}
                          sx={{
                            fontSize: '14px',
                            lineHeight: '32px',
                            cursor: 'pointer'
                          }}
                        >
                          Remove all filters
                        </LinkButton>
                      )}
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {/* {(searchResultsCount >= 0 && filterParamCount() > 0 && projectsLoading === false) && ( */}
                  {(searchResultsCount >= 0 && filterParamCount() > 0) && (
                    <Typography
                      variant="body"
                      color="text.secondary"
                      sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mt: 2 }}
                    >
                      {searchResultsCount} results found
                    </Typography>
                  )}
                  {(filterParamCount() === 0) && (
                    <Stack direction='row' spacing={1} justifyContent={'flex-end'} sx={{ pt: 2 }}>
                      {activeTab === 2 ? (
                        <Button
                          color="primary"
                          variant="link"
                          startIcon={<FormatListBulletedIcon />}
                          onClick={() => setActiveTab(1)}
                          className="removeUppercase"
                        // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                        >
                          List View
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          variant="link"
                          startIcon={<LocationOnIcon />}
                          onClick={() => setActiveTab(2)}
                          className="removeUppercase"
                        // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                        >
                          Map View
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="link"
                        startIcon={<TuneIcon />}
                        onClick={() => toggleFilter()}
                        className="removeUppercase"
                      // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '66px' : '40px' }}
                      >
                        Filter & sort
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            {isMainHub &&
              <TabPanel value={searchActiveTab} index={1}>
                <Alert severity="info" sx={{ mb: 4 }}>
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    sx={{ fontSize: '0.9rem' }}
                  >
                    <b>Disclaimer:</b> Gaia provides this tool as a resource to assist with the purchase of Biodiversity Net Gain (BNG) units. However, Gaia does not assume responsibility for verifying that the BNG units acquired using this tool meet the specific requirements or criteria of any developer. It is the responsibility of the buyer to ensure that the purchased BNG units comply with the necessary regulations.
                  </Typography>
                </Alert>
                {Object.keys(metricRequiredUnits).length === 0 && <><Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ mb: 3 }} >
                  Upload your Statutory Biodiversity Metric and Gaia will instantly find units that best meet your BNG requirements. Please note that this tool is not currently compatible with the small sites metric.
                </Typography>
                  {
                    uploadError && <Alert
                      severity="error"
                      sx={{ p: 2, mt: 2, mb: 2 }}
                    >
                      Please upload a valid Statutory Biodiversity Metric
                    </Alert>
                  }
                  <BNGMetricUpload setMetricRequiredUnits={setMetricRequiredUnits} setUploadError={setUploadError} />
                </>
                }
                {Object.keys(metricRequiredUnits).length > 0 && <Box>
                  {activeBngMetricStep === 0 && <>
                    <Alert
                      severity="success"
                      action={
                        <Button variant="outlined" color="inherit" size="small" startIcon={<Upload />} className='removeUppercase' onClick={() => setMetricRequiredUnits({})}>
                          Upload a different metric
                        </Button>
                      }
                      sx={{ p: 2, mt: 2, mb: 4 }}
                    >
                      Your BNG metric has been processed successfully
                    </Alert>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ mb: 3 }}
                    >
                      <b>Summary of units that need to be purchased</b>
                    </Typography>
                    {/* <Divider sx={{ mt: 1, mb: 3 }} /> */}
                    <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                      <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                        <SpaOutlined color="primary" />
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                        >
                          <b>Area Units: </b><NumericFormat value={metricRequiredUnits?.area?.totalUnitDeficit} decimalScale={2} thousandSeparator={true} displayType={'text'} /> units
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                        <GrassOutlined color="primary" />
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                        >
                          <b>Hedgerow Units: </b><NumericFormat value={metricRequiredUnits?.hedgerow?.totalUnitDeficit} decimalScale={2} thousandSeparator={true} displayType={'text'} /> units
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                        <Water color="primary" />
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                        >
                          <b>Watercourse Units: </b><NumericFormat value={metricRequiredUnits?.watercourse?.totalUnitDeficit} decimalScale={2} thousandSeparator={true} displayType={'text'} /> units
                        </Typography>
                      </Stack>
                    </Stack>
                    {(metricRequiredUnits?.area?.totalUnitDeficit > 0 || metricRequiredUnits?.hedgerow?.totalUnitDeficit > 0 || metricRequiredUnits?.watercourse?.totalUnitDeficit > 0) && <>
                      <Typography
                        variant="h6"
                        color="text.primary"
                        sx={{ mt: 6, mb: 2 }}
                      >
                        <b>Detailed breakdown of units that need to be purchased</b>
                      </Typography>
                      {metricRequiredUnits &&
                        <BngMetricTable
                          habitats={[
                            ...metricRequiredUnits.area?.habitats.map(habitat => ({
                              ...habitat,
                              unitType: "Area"
                            })) || [],
                            ...metricRequiredUnits.hedgerow?.habitats.map(habitat => ({
                              ...habitat,
                              unitType: "Hedgerow"
                            })) || [],
                            ...metricRequiredUnits.watercourse?.habitats.map(habitat => ({
                              ...habitat,
                              unitType: "Watercourse"
                            })) || []
                          ]}
                        />
                      }
                    </>
                    }
                    {(metricRequiredUnits?.area?.totalUnitDeficit === 0 && metricRequiredUnits?.hedgerow?.totalUnitDeficit === 0 && metricRequiredUnits?.watercourse?.totalUnitDeficit === 0) && <>
                      <Divider sx={{ mt: 4, mb: 1 }} />
                      <Alert
                        severity="success"
                        sx={{ p: 2, mt: 2, mb: 2, mt: 4 }}
                      >
                        Your BNG requirements have been satisfied and you don't require any additional units
                      </Alert>
                    </>
                    }
                    {/* {metricRequiredUnits?.hedgerow?.habitats.length > 0 &&
                    <BngMetricTable habitats={metricRequiredUnits.hedgerow.habitats} sx={{ mt: 4 }} />
                  }
                  {metricRequiredUnits?.watercourse?.habitats.length > 0 &&
                    <BngMetricTable habitats={metricRequiredUnits.watercourse.habitats} sx={{ mt: 4 }} />
                  } */}
                    {/* {(metricRequiredUnits?.area?.netGainUnitsRequired > 0 ||
                    metricRequiredUnits?.hedgerow?.netGainUnitsRequired > 0 ||
                    metricRequiredUnits?.watercourse?.netGainUnitsRequired > 0
                  ) && <>
                      <Typography
                        variant="h6"
                        color="text.primary"
                        sx={{ mt: 6, mb: 2 }}
                      >
                        <b>Additional units required for 10% net gain</b>
                      </Typography>
                      <BngMetricNetGainTable metricRequiredUnits={metricRequiredUnits} />
                    </>
                  } */}
                  </>}

                  {activeBngMetricStep === 1 && <>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ mb: 1 }}
                    >
                      Select the local planning authority for your development and/or national character area, in order to receive the most accurate pricing suggestions.
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ mb: 4 }}
                    >
                      If you're unsure about your national character area, use the Natural England map to find out - <a target="_blank" href='https://nationalcharacterareas.co.uk'>https://nationalcharacterareas.co.uk</a>
                    </Typography>
                    <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                      <Autocomplete
                        disablePortal
                        id="lpa"
                        options={lpasAutocomplete.map(lpa => lpa.label)}
                        renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
                        value={metricRequiredUnits.location.lpa}
                        getOptionLabel={(option) => (option ? option : "")}
                        sx={{ flex: 1 }}
                        onChange={(e, option, reason) => {
                          if (reason === "removeOption") {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, lpa: '' } })
                          }
                          if (reason === "clear") {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, lpa: '' } })
                          }
                          if (reason === 'selectOption') {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, lpa: option } })
                          }
                        }}
                      />
                      <Autocomplete
                        disablePortal
                        id="national-character-area"
                        options={nationalCharacterAreasAutocomplete.map(nca => nca.label)}
                        renderInput={(params) => <TextField {...params} label="National Character Area" />}
                        value={metricRequiredUnits.location.nca}
                        getOptionLabel={(option) => (option ? option : "")}
                        sx={{ flex: 1 }}
                        onChange={(e, option, reason) => {
                          if (reason === "removeOption") {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, nca: '' } })
                          }
                          if (reason === "clear") {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, nca: '' } })
                          }
                          if (reason === 'selectOption') {
                            setMetricRequiredUnits({ ...metricRequiredUnits, location: { ...metricRequiredUnits.location, nca: option } })
                          }
                        }}
                      />
                    </Stack>
                  </>}

                  {activeBngMetricStep === 2 && <>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ mb: 1 }}
                    >
                      Recommended Projects
                    </Typography>
                    {/* <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ mb: 3 }}
                  >
                    The recommendations below take into account the spatial multiplier and all trading rules.
                  </Typography>
                  {metricRequiredUnits?.area &&
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1, mt: 1, mb: 1 }}>
                      <SpaOutlined color="primary" />
                      <Typography
                        variant="h6"
                        color="text.primary"
                      >
                        <b>Area Units Required</b>
                      </Typography>
                    </Stack>
                  } */}
                    {metricRequiredUnits?.area?.habitats.length > 0 && metricRequiredUnits?.area?.habitats?.map((row, outerIdx) => {
                      return (
                        <div key={outerIdx}>
                          <Accordion defaultExpanded elevation={0} sx={{ pb: 2 }} >
                            <AccordionSummary
                              expandIcon={<ExpandMoreOutlined />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{ pl: 0 }}
                            >
                              <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1, pr: 2 }}>
                                {/* <IconComponent color="primary" /> */}
                                <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                                  <SpaOutlined color="primary" />
                                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 300 }}>
                                    Area - {row.broadHabitat} {row.broadHabitat === 'Any' && 'habitat'}
                                  </Typography>
                                </Stack>
                                <Chip label={`${row.unitsRequired.toFixed(2)} units`} />
                                <Tooltip title={row.tradingRules.tooltip} placement="bottom"><Chip label={`${row.distinctiveness} distinctiveness`} /></Tooltip>
                              </Stack>
                            </AccordionSummary>
                            {/* <Divider sx={{ mb: 4 }} /> */}
                            <AccordionDetails sx={{ pl: 0, pt: 2 }}>
                              {row?.results &&
                                <div style={{ margin: '0px 0px 16px 0px', position: 'relative' }}>
                                  {row?.results?.length > 0 && <MetricProjectCard result={row.results[0]} />}
                                  {row?.results?.length === 0 && <Alert
                                    severity="info"
                                    action={
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<OpenInNew />}
                                        className='removeUppercase'
                                        component={Link}
                                        to={`https://marketplace.gaiacompany.io/hub/site-enquiry/bng`}
                                        sx={{ width: '210px' }}
                                        target='_blank'
                                      >
                                        Submit a unit request
                                      </Button>
                                    }
                                    sx={{ p: 2, mt: 2, mb: 4 }}
                                  >
                                    We haven't been able to find any units on the marketplace that meet these requirements. However, if you submit a unit request, we will contact our sellers and find you a provider that will be able to deliver the units you require.
                                  </Alert>}
                                  {/* {row?.results.length > 1 &&
                                  <Button
                                    variant='text'
                                    sx={{ mt: 4 }}
                                    endIcon={expandedAlternative === outerIdx ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                                    className='removeUppercase'
                                    onClick={() => handleExpandedAlternativeClick(outerIdx)}
                                  >
                                    Alternative Options
                                  </Button>
                                } */}
                                </div>
                              }
                              {/* {expandedAlternative === outerIdx && row.results?.slice(1).map((result, innerIdx) => {
                              return <Collapse in={expandedAlternative === outerIdx ? true : false} timeout="auto">
                                <div key={innerIdx} style={{ position: 'relative' }}>
                                  <MetricProjectCard result={result} />
                                </div>
                              </Collapse>
                            })} */}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                    {metricRequiredUnits?.hedgerow?.habitats.length > 0 && metricRequiredUnits?.hedgerow?.habitats?.map((row, outerIdx) => {
                      return (
                        <div key={outerIdx}>
                          <Accordion defaultExpanded elevation={0} sx={{ pb: 2 }} >
                            <AccordionSummary
                              expandIcon={<ExpandMoreOutlined />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{ pl: 0 }}
                            >
                              <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1, pr: 2 }}>
                                {/* <IconComponent color="primary" /> */}
                                <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                                  <GrassOutlined color="primary" />
                                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 300 }}>
                                    Hedgerow - {row.broadHabitat} {row.broadHabitat === 'Any' && 'habitat'}
                                  </Typography>
                                </Stack>
                                <Chip label={`${row.unitsRequired.toFixed(2)} units`} />
                                <Tooltip title={row.tradingRules.tooltip} placement="bottom"><Chip label={`${row.distinctiveness} distinctiveness`} /></Tooltip>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pl: 0, pt: 2 }}>
                              {row?.results &&
                                <div style={{ margin: '0px 0px 16px 0px', position: 'relative' }}>
                                  {row?.results?.length > 0 && <MetricProjectCard result={row?.results[0]} />}
                                  {row?.results?.length === 0 && <Alert
                                    severity="info"
                                    action={
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<OpenInNew />}
                                        className='removeUppercase'
                                        component={Link}
                                        to={`https://marketplace.gaiacompany.io/hub/site-enquiry/bng`}
                                        sx={{ width: '210px' }}
                                        target='_blank'
                                      >
                                        Submit a unit request
                                      </Button>
                                    }
                                    sx={{ p: 2, mt: 2, mb: 4 }}
                                  >
                                    We haven't been able to find any units on the marketplace that meet these requirements. However, if you submit a unit request, we will contact our sellers and find you a provider that will be able to deliver the units you require.
                                  </Alert>}
                                </div>
                              }
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                    {metricRequiredUnits?.watercourse?.habitats?.length > 0 && metricRequiredUnits?.watercourse?.habitats?.map((row, outerIdx) => {
                      return (
                        <div key={outerIdx}>
                          <Accordion defaultExpanded elevation={0} sx={{ pb: 2 }} >
                            <AccordionSummary
                              expandIcon={<ExpandMoreOutlined />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{ pl: 0 }}
                            >
                              <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1, pr: 2 }}>
                                {/* <IconComponent color="primary" /> */}
                                <Stack direction='row' spacing={1} alignItems='center' sx={{ flex: 1 }}>
                                  <Water color="primary" />
                                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 300 }}>
                                    Watercourse - {row.broadHabitat} {row.broadHabitat === 'Any' && 'habitat'}
                                  </Typography>
                                </Stack>
                                <Chip label={`${row.unitsRequired.toFixed(2)} units`} />
                                <Tooltip title={row.tradingRules.tooltip} placement="bottom"><Chip label={`${row.distinctiveness} distinctiveness`} /></Tooltip>
                              </Stack>
                            </AccordionSummary>
                            {/* <Divider sx={{ mb: 4 }} /> */}
                            <AccordionDetails sx={{ pl: 0, pt: 2 }}>
                              {row?.results &&
                                <div style={{ margin: '0px 0px 16px 0px', position: 'relative' }}>
                                  {row.results.length > 0 && <MetricProjectCard result={row.results[0]} />}
                                  {row?.results?.length === 0 && <Alert
                                    severity="info"
                                    action={
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<OpenInNew />}
                                        className='removeUppercase'
                                        component={Link}
                                        to={`https://marketplace.gaiacompany.io/hub/site-enquiry/bng`}
                                        sx={{ width: '210px' }}
                                        target='_blank'
                                      >
                                        Submit a unit request
                                      </Button>
                                    }
                                    sx={{ p: 2, mt: 2, mb: 4 }}
                                  >
                                    We haven't been able to find any units on the marketplace that meet these requirements. However, if you submit a unit request, we will contact our sellers and find you a provider that will be able to deliver the units you require.
                                  </Alert>}
                                </div>
                              }
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                  </>
                  }
                  <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeBngMetricStep}
                    sx={{ width: '100%', flexGrow: 1, p: 0, pt: 6 }}
                    nextButton={
                      <Button
                        disableElevation
                        variant='contained'
                        endIcon={<East />}
                        className='removeUppercase'
                        onClick={() => handleBngMetricNext()}
                        sx={{ visibility: activeBngMetricStep === 2 && 'hidden' }}
                        disabled={
                          (metricRequiredUnits?.area?.totalUnitDeficit === 0 &&
                            metricRequiredUnits?.hedgerow?.totalUnitDeficit === 0 &&
                            metricRequiredUnits?.watercourse?.totalUnitDeficit === 0) ||
                          (activeBngMetricStep === 1 &&
                            metricRequiredUnits.location.lpa.length === 0 &&
                            metricRequiredUnits.location.nca.length === 0)}
                      > Continue</Button>
                    }
                    backButton={
                      <Button disableElevation variant='outlined' startIcon={<West />} className='removeUppercase' onClick={() => handleBngMetricBack()} disabled={activeBngMetricStep === 0}>Back</Button>
                    }
                  />
                </Box>
                }
              </TabPanel>
            }
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              border: '1px solid #a9a9a9',
              padding: '15px',
              mt: '-80px',
              ml: "24px",
              mr: "24px",
              pb: 6,
              p: 8
            }}
          >
            <Alert severity="info" sx={{ mb: 4 }}>The free tier restricts access to very basic filtering and information - <a href='/payment'>sign up</a> to a paid plan for the full suite of features.</Alert>
            <Autocomplete
              disablePortal
              id="region"
              options={regionsAutocomplete}
              renderInput={(params) => <TextField {...params} label="Region" />}
              multiple
              value={searchFilters['region']}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(e, option, reason) => {
                if (reason === "removeOption") {
                  handleFilterUpdate('region', option)
                }
                if (reason === "clear") {
                  handleFilterUpdate('region', option)
                }
                if (reason === 'selectOption') {
                  handleFilterUpdate('region', option)

                }
              }}
            />
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={8} lg={8}>

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack direction='row'>
                  {activeTab === 2 ? (
                    <Button
                      color="primary"
                      variant="link"
                      startIcon={<FormatListBulletedIcon />}
                      onClick={() => setActiveTab(0)}
                      className="removeUppercase"
                    // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                    >
                      List View
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="link"
                      startIcon={<LocationOnIcon />}
                      onClick={() => setActiveTab(2)}
                      className="removeUppercase"
                    // sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mr: isMobileMode ? '10px' : '10px' }}
                    >
                      Map View
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
        {searchActiveTab !== 1 && <>
          <TabPanel value={activeTab} index={0}>
            {noResultsFound && firstSearchRetreived && (
              <Alert severity="info" sx={{ p: 2, mb: 4 }}>
                <Stack direction={'column'}>
                  <Typography
                    variant="p"
                    color="text.primary"
                    sx={{ mb: 2 }} >
                    There are currently no listings that meet your search criteria.
                  </Typography>

                  <Typography
                    variant="p"
                    color="text.primary"
                    sx={{ mb: 3 }} >
                    You can make an enquiry by clicking the button below.
                  </Typography>

                  <Button
                    variant='contained'
                    component={Link}
                    to='/hub/site-enquiry/bng'
                    size="medium"
                    disableElevation
                    className="removeUppercase"
                    color='info'
                    sx={{ maxWidth: '240px' }}
                  >
                    Make a request
                  </Button>
                </Stack>
              </Alert>
            )}

            {noResultsFound && <>
              <Divider />
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ mb: 3, mt: 2 }} >
                National results below
              </Typography>
            </>}

            <InfiniteScroll
              pageStart={0}
              loadMore={() => getProjects()}
              hasMore={hasMore}
              loader={
                <div key="loader" className="loader" style={{ textAlign: 'center' }}>
                  <img src="/loading.gif" alt='loading' width='80px' />
                </div>
              }
            >
              <Grid container spacing={3}>
                {projects && projects.map((project, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    {project && (
                      <ProjectCard
                        cardType="standard"
                        id={project.p_id}
                        summary={project.p_summary}
                        description={project.p_description}
                        primaryImage={project.project_images[0].key}
                        primaryImageURL={project.project_images[0].location}
                        location={project.p_location}
                        LPA={project.p_local_planning_authority}
                        NCA={project.p_national_character_area}
                        totalBiodiversityUnits={project.totalBiodiversityUnitsRemaining}
                        totalAreaHabitats={project.totalAreaHabitats}
                        totalHedgerowHabitats={project.totalHedgerowHabitats}
                        totalWatercourseHabitats={project.totalWatercourseHabitats}
                        totalAreaPrice={project.totalAreaPrice}
                        totalHedgerowPrice={project.totalHedgerowPrice}
                        totalWatercoursePrice={project.totalWatercoursePrice}
                        totalPrice={project.totalPrice}
                        hidePrice={project.hidePrice}
                        freeTier={project.freeTier}
                        shallow_status={project.shallow_status}
                        isMobileMode={isMobileMode}
                        bngRegistered={project.bng_gov_registered}
                        project={project}
                      />
                    )}
                  </Grid>
                )
                )}
              </Grid>
            </InfiniteScroll>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} md={4} lg={4}>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    position: 'sticky',
                    top: '65px'
                  }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Card variant="outlined" sx={{ height: isTablet ? 'auto' : 'calc(100vh - 110px)', borderColor: '#a9a9a9', borderRadius: '10px' }}>
                      <CardContent sx={{ height: isTablet ? 'auto' : 'calc(100vh - 110px)', overflow: 'auto' }} >
                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, padding: '8px 16px' }}>
                          Filters
                        </Typography>
                        <List>
                          <ListItemButton onClick={handleExpandLocationFilter}>
                            <ListItemText primary="Location" />
                            {locationFilterOpen ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={locationFilterOpen} timeout="auto" unmountOnExit>
                            <List component="nav" aria-label="secondary mailbox folder">
                              <Grid container spacing={2} p={2} sx={{ pt: 1 }}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Autocomplete
                                    disablePortal
                                    id="region"
                                    options={regionsAutocomplete.map(region => region.label)}
                                    renderInput={(params) => <TextField {...params} label="Region" />}
                                    multiple
                                    value={searchFilters['region']}
                                    getOptionLabel={(option) => (option ? option : "")}
                                    onChange={(e, option, reason) => {
                                      if (reason === "removeOption") {
                                        handleFilterUpdate('region', option)
                                      }
                                      if (reason === "clear") {
                                        handleFilterUpdate('region', option)
                                      }
                                      if (reason === 'selectOption') {
                                        handleFilterUpdate('region', option)

                                      }
                                    }}
                                  />
                                </Grid>
                                {!customRegionListRetreived && (
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Autocomplete
                                      disablePortal
                                      id="lpa"
                                      options={lpasAutocomplete.map(lpa => lpa.label)}
                                      renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
                                      multiple
                                      value={searchFilters['lpa']}
                                      getOptionLabel={(option) => (option ? option : "")}
                                      onChange={(e, option, reason) => {
                                        if (reason === "removeOption") {
                                          handleFilterUpdate('lpa', option)
                                        }
                                        if (reason === "clear") {
                                          handleFilterUpdate('lpa', option)

                                        }
                                        if (reason === 'selectOption') {
                                          handleFilterUpdate('lpa', option)
                                        }
                                      }}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={!!searchFilters['show_neighbour_lpas'] || false}
                                          onChange={(e) => handleFilterUpdate('show_neighbour_lpas', e.target.checked)}
                                        />}
                                      label="Include neighbouring LPAs"
                                      sx={{ width: '100%', mb: '16px' }}
                                    />

                                  </Grid>
                                )}
                                {!customRegionListRetreived && (
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Autocomplete
                                      disablePortal
                                      id="national-character-area"
                                      options={nationalCharacterAreasAutocomplete.map(nca => nca.label)}
                                      renderInput={(params) => <TextField {...params} label="National Character Area" />}
                                      multiple
                                      value={searchFilters['nca']}
                                      getOptionLabel={(option) => (option ? option : "")}
                                      onChange={(e, option, reason) => {
                                        if (reason === "removeOption") {
                                          handleFilterUpdate('nca', option)
                                        }
                                        if (reason === "clear") {
                                          handleFilterUpdate('nca', option)
                                        }
                                        if (reason === 'selectOption') {
                                          handleFilterUpdate('nca', option)
                                        }
                                      }}
                                    />

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={!!searchFilters['show_neighbour_ncas'] || false}
                                          onChange={(e) => handleFilterUpdate('show_neighbour_ncas', e.target.checked)}
                                        />}
                                      label="Include neighbouring NCAs"
                                      sx={{ width: '100%', mb: '16px' }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </List>
                          </Collapse>
                        </List>
                        <Divider />
                        <List>
                          <ListItemButton onClick={handleExpandTypeFilter} >
                            <ListItemText primary="Unit Type" />
                            {typeFilterOpen ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={typeFilterOpen} timeout="auto" unmountOnExit>
                            <List component="nav" aria-label="Unit Type Filter">
                              <Stack spacing={1} sx={{ pr: 2, pt: 1, pl: 2, pb: 2 }}>
                                <Chip
                                  label="Area (Habitat)"
                                  variant={searchFilters['unitType'].find(filter => filter === 'Area') ? 'filled' : 'outlined'}
                                  color={searchFilters['unitType'].find(filter => filter === 'Area') ? 'primary' : 'default'}
                                  icon={<SpaOutlinedIcon />}
                                  onClick={() => handleFilterUpdate('unitType', searchFilters['unitType'].find(filter => filter === 'Area') ? '' : 'Area')}
                                />
                                <Chip
                                  label="Hedgerow"
                                  variant={searchFilters['unitType'].find(filter => filter === 'Hedgerow') ? 'filled' : 'outlined'}
                                  color={searchFilters['unitType'].find(filter => filter === 'Hedgerow') ? 'primary' : 'default'}
                                  icon={<GrassOutlinedIcon />}
                                  onClick={() => handleFilterUpdate('unitType', searchFilters['unitType'].find(filter => filter === 'Hedgerow') ? '' : 'Hedgerow')}
                                />
                                <Chip
                                  label="Watercourse"
                                  variant={searchFilters['unitType'].find(filter => filter === 'Watercourse') ? 'filled' : 'outlined'}
                                  color={searchFilters['unitType'].find(filter => filter === 'Watercourse') ? 'primary' : 'default'}
                                  icon={<WaterIcon />}
                                  onClick={() => handleFilterUpdate('unitType', searchFilters['unitType'].find(filter => filter === 'Watercourse') ? '' : 'Watercourse')}
                                />
                              </Stack>
                            </List>
                          </Collapse>
                        </List>
                        <Divider />
                        <List>
                          <ListItemButton onClick={handleExpandBroadHabitatFilter}>
                            <ListItemText primary="Broad Habitat" />
                            {broadHabitatFilterOpen ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={broadHabitatFilterOpen} timeout="auto" unmountOnExit>
                            <List component="nav" aria-label="broad habitat filter">
                              <Grid container spacing={2} p={2} sx={{ pt: 1 }}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Autocomplete
                                    disablePortal
                                    id="broad-habitat"
                                    options={Habitats.broadHabitatsAutocomplete.map(broadHabitat => broadHabitat.label)}
                                    renderInput={(params) => <TextField {...params} label="Broad Habitat" />}
                                    multiple
                                    value={searchFilters['broadHabitat']}
                                    getOptionLabel={(option) => (option ? option : "")}
                                    onChange={(e, option, reason) => {
                                      if (reason === "removeOption") {
                                        handleFilterUpdate('broadHabitat', option)
                                      }
                                      if (reason === "clear") {
                                        handleFilterUpdate('broadHabitat', option)
                                      }
                                      if (reason === 'selectOption') {
                                        handleFilterUpdate('broadHabitat', option)
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </List>
                          </Collapse>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
                  <Grid item xs={12} md={6} lg={9}>
                    {filterParamCount() > 0 && authenticated && (
                      <Stack direction='row' spacing={1}>
                        {!saveSearchActive && !activeSavedSearch && (
                          <Button
                            color="primary"
                            variant="link"
                            startIcon={searchSaved ? <StarIcon /> : <StarBorderIcon />}
                            onClick={() => setSaveSearchActive(true)}
                            className="removeUppercase"
                          >
                            {searchSaved ? 'Saved' : 'Save search'}
                          </Button>
                        )}
                        {activeSavedSearch && (
                          <Typography
                            variant="body"
                            color="text.secondary"
                          >
                            <b>Saved search:</b> {savedSearches.filter((savedSearch) => savedSearch.ss_id === activeSavedSearch)[0].ss_search_name}
                          </Typography>
                        )}
                        {saveSearchActive && (
                          <>
                            <TextField
                              id="saved-search-name"
                              label="Save as"
                              variant="outlined"
                              size="small"
                              onChange={e => setSaveSearchName(e.target.value)}
                            />
                            <Tooltip title="Save" placement="right" arrow>
                              <IconButton
                                aria-label="save"
                                onClick={() => handleSaveSearch()}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" placement="right" arrow>
                              <IconButton
                                aria-label="cancel"
                                onClick={() => setSaveSearchActive(false)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 180, float: 'right', mr: 0 }} size="small">
                      <InputLabel id="sort-label">Sort</InputLabel>
                      <Select
                        labelId="sort-label"
                        id="sort"
                        value={sort}
                        label="Sort"
                        onChange={(e) => handleSortUpdate(e.target.value)}
                      >
                        <MenuItem value={'relevance'}>Relevance</MenuItem>
                        <MenuItem value={'pricelowtohigh'}>Price: Low to High</MenuItem>
                        <MenuItem value={'pricehightolow'}>Price: High to Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {noResultsFound && firstSearchRetreived && authenticated && (
                  <Alert
                    severity="info"
                    sx={{ p: 2, mb: 4 }}
                  >
                    {/* <AlertTitle>There are currently no active listings that meet your search criteria.</AlertTitle>
                  <br />
                  Would you like to:
                  <ul>
                    <li>
                      Raise a request for new units? This will send out a notification to land managers and you will be alerted if any units meeting your criteria become available.<br /><br />
                      <Button
                        variant='outlined'
                        className='removeUppercase'
                        color='inherit'
                        startIcon={<CampaignIcon />}
                        disableElevation
                        onClick={() => showRequestUnitsDialog()}
                      >
                        Request units
                      </Button>
                      <br /><br />
                    </li>
                    {/* <li>
                        Print a Gaia certificate confirming that there are currently no units on the Gaia marketplace that meet your requirements. <br /><br />
                        <Button
                          variant='outlined'
                          className='removeUppercase'
                          color='inherit'
                          startIcon={<PictureAsPdfIcon />}
                          disableElevation
                        >
                          Print certificate
                        </Button>
                      </li> */}
                    {/* </ul> */}
                    <Stack direction={'column'}>
                      <Typography
                        variant="p"
                        color="text.primary"
                        sx={{ mb: 2 }} >
                        There are currently no listings that meet your search criteria.
                      </Typography>

                      <Typography
                        variant="p"
                        color="text.primary"
                        sx={{ mb: 3 }} >
                        You can make an enquiry by clicking the button below.
                      </Typography>

                      <Button
                        variant='contained'
                        component={Link}
                        to='/hub/site-enquiry/bng'
                        size="medium"
                        disableElevation
                        className="removeUppercase"
                        color='info'
                        sx={{ maxWidth: '240px' }}
                      >
                        Make a request
                      </Button>
                    </Stack>
                  </Alert>
                )}
                {noResultsFound && firstSearchRetreived && !authenticated && (
                  <Alert
                    severity="info"
                    sx={{ p: 2, mb: 4 }}
                  >
                    {/* <AlertTitle>There are currently no active listings that meet your search criteria.</AlertTitle> */}
                    <Stack direction={'column'}>
                      <Typography
                        variant="p"
                        color="text.primary"
                        sx={{ mb: 2 }} >
                        There are currently no active listings that meet your search criteria.
                      </Typography>

                      <Typography
                        variant="p"
                        color="text.primary"
                        sx={{ mb: 3 }} >
                        You can make an enquiry by clicking the button below.
                      </Typography>

                      <Button
                        variant='contained'
                        component={Link}
                        to='/hub/site-enquiry/bng'
                        size="medium"
                        disableElevation
                        className="removeUppercase"
                        color='info'
                        sx={{ maxWidth: '240px' }}
                      >
                        Make a request
                      </Button>
                    </Stack>
                  </Alert>
                )}
                <RequestUnitsDialog
                  requestUnitsDialogActive={requestUnitsDialogActive}
                  showRequestUnitsDialog={showRequestUnitsDialog}
                  saveSearchName={saveSearchName}
                  setSaveSearchName={setSaveSearchName}
                  handleSubmitRequestUnits={handleSubmitRequestUnits}
                  requestSubmitted={requestSubmitted}
                >
                </RequestUnitsDialog>

                {noResultsFound && <>
                  <Divider />
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ mb: 3, mt: 2 }} >
                    Showing all national results
                  </Typography>
                </>}


                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => getProjects()}
                  hasMore={hasMore}
                  loader={
                    <div key="loader" className="loader" style={{ textAlign: 'center' }}>
                      <img src="/loading.gif" width='80px' />
                    </div>
                  }
                >
                  <Grid container spacing={3}>
                    {projects.map((project, index) => (
                      <Grid item xs={12} sm={6} md={12} lg={12} key={index}>
                        {project && (
                          <ProjectCard
                            key={project.p_id}
                            cardType="wide"
                            id={project.p_id}
                            summary={project.p_summary}
                            description={project.p_description}
                            primaryImage={project.project_images[0].key}
                            primaryImageURL={project.project_images[0].location}
                            imageUrl={project.p_img_url}
                            location={project.p_location}
                            LPA={project.p_local_planning_authority}
                            NCA={project.p_national_character_area}
                            totalBiodiversityUnits={project.totalBiodiversityUnitsRemaining}
                            totalAreaHabitats={project.totalAreaHabitats}
                            totalHedgerowHabitats={project.totalHedgerowHabitats}
                            totalWatercourseHabitats={project.totalWatercourseHabitats}
                            totalPrice={project.totalPrice}
                            hidePrice={project.hidePrice}
                            shallow_status={project.shallow_status}
                            isMobileMode={isMobileMode}
                            bngRegistered={project.bng_gov_registered}
                            project={project}

                          />
                        )}
                      </Grid>
                    )
                    )}
                  </Grid>
                </InfiniteScroll>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Grid container spacing={3} sx={{ pt: 8 }}>
              <Paper
                sx={{
                  ml: '24px',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  borderColor: '#a9a9a9',
                  p: 2,
                  mt: -4
                }}
                variant="outlined"
              >
                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, p: 1, pl: 2 }}>
                  Interactive Map
                </Typography>

                <Typography variant="subtitle1" color="text.secondary" sx={{ pl: 2 }}>
                  Use the map to find units in your region.
                </Typography>
                {isMainHub &&
                  <ProjectMap handleMapRegionClick={handleMapRegionClick} />
                }
                {!isMainHub &&
                  <ProjectMapCornwall handleMapRegionClick={handleMapRegionClick} />
                }
              </Paper>
            </Grid>
          </TabPanel>
        </>
        }
      </Container >
    </>}
  </>
  );
};

export default BuyerHomePage;