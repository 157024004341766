import { Alert, Box, Button, Container, Divider, FormControl, Grid, Paper, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetaTagProvider } from '../../shared/components/mata-tag-provider'
import { GaiaTextField } from '../../shared/components/form-elements/text-field'
import SiteEnquiryFormPageService from '../services/site-enquiry-form-page-service'
import QuestionForm from '../../shared/components/question-form'
import { GlobalBackButton } from '../../shared/components/buttons/global-back-button'
import { SignUpDialog } from '../../shared/components/dialogs/sign-up'
import { UserDetailsContext } from '../../shared/context/user-details'
import { ChevronRight } from '@mui/icons-material'

export const SiteEnquiryFormPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const userDetailsContext = useContext(UserDetailsContext);
  const siteEnquiryFormService = new SiteEnquiryFormPageService();

  const [formableObjectCode, setFormableObjectCode] = useState();
  const [forwardUrl, setForwardUrl] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [questions, setQuestions] = useState();
  const [errors, setErrors] = useState([]);
  const [requiredDetails, setRequiredDetails] = useState({
    first_name: '',
    surname: '',
    organisation: '',
    email: ''
  })
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate('/')
  };

  useEffect(() => {
    if (userDetailsContext?.userDetails) {
      setIsLoggedIn(true);
      setRequiredDetails({
        first_name: userDetailsContext.userDetails.name,
        surname: userDetailsContext.userDetails.name,
        organisation: userDetailsContext.userDetails.organisation,
        email: userDetailsContext.userDetails.email,
      })
    }

    if (params.pageType === 'bng') {
      setFormableObjectCode('NO_BNG_PROJECTS_FOUND');
      setForwardUrl('/hub/marketplace')
    } else if (params.pageType === 'invest') {
      setFormableObjectCode('NO_INVESTMENT_PROJECTS_FOUND');
      setForwardUrl('/hub/invest')
    }
  }, [userDetailsContext]);

  useEffect(() => {
    if (formableObjectCode) {
      siteEnquiryFormService.getQuestions(formableObjectCode)
        .then(response => {
          if (response.questions) {
            setQuestions(response.questions)
          }
        })
    }
  }, [formableObjectCode])

  const handlePageInformationUpdate = (formControl, value) => {
    setRequiredDetails({ ...requiredDetails, [formControl]: value })
  }

  const handleAnswers = (answers) => {
    if (validateRequiredInformation()) {
      const data = { ...requiredDetails, answers, formable_object_code: formableObjectCode, searched_url: window.location.url }
      console.log(data)
      siteEnquiryFormService.saveEnquiryForm(data)
        .then(response => {
          setDialogOpen(true)
        })
    }
  }

  const validateRequiredInformation = () => {
    setErrors([])
    const valid = requiredDetails.email && requiredDetails.first_name && requiredDetails.surname;
    if (valid) {
      return true;
    } else {
      setErrors(['Please enter all the required information below.'])
      return false
    }
  }

  return (<Box>
    <Toolbar />
    {<MetaTagProvider
      metaTitle={'Make an enquiry'}
      metaDescription={`Use this form to contact us and let us know what you are looking for.`}>
    </MetaTagProvider>}
    <Container sx={{ mt: 4, mb: 4 }} maxWidth={'md'} margin={'auto'}>
      <Grid container alignItems="center" sx={{ mb: 4 }}>
        <GlobalBackButton buttonCopy={'Back to search'} buttonLink={'/hub/marketplace'}></GlobalBackButton>
      </Grid>
      <Grid container spacing={4} maxWidth={'md'} margin={'auto'}>
        <Grid item xs={12} md={12} lg={12} >
          <Paper
            elevation={0}
            sx={{
              p: 0,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto'
            }}>

            <Typography variant="h5" display="block" sx={{ mb: 2 }}>
              Please use the form below to let us know a little more about what you are looking for.
            </Typography>

            {errors.length > 0 && <Alert severity="error" sx={{ p: 2, mb: 4 }}>{errors[0]}</Alert>}

            {!isLoggedIn && <>
              <FormControl sx={{ mb: 3, width: '100%' }}>
                <GaiaTextField
                  label="Email address*"
                  error={errors.length > 0 && !requiredDetails.email}
                  value={requiredDetails.email}
                  minRows={1}
                  placeholder="Email address*"
                  formControlName='email'
                  onChange={handlePageInformationUpdate}>
                </GaiaTextField>
              </FormControl>

              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
              >
                <FormControl sx={{ mr: 1, width: '100%' }}>
                  <GaiaTextField
                    label="First name*"
                    error={errors.length > 0 && !requiredDetails.first_name}
                    value={requiredDetails.first_name}
                    minRows={1}
                    placeholder="First name*"
                    formControlName='first_name'
                    onChange={handlePageInformationUpdate}>
                  </GaiaTextField>
                </FormControl>
                <FormControl sx={{ ml: 1, width: '100%' }}>
                  <GaiaTextField
                    label="Surname*"
                    value={requiredDetails.surname}
                    error={errors.length > 0 && !requiredDetails.surname}
                    minRows={1}
                    placeholder="Surname*"
                    formControlName='surname'
                    onChange={handlePageInformationUpdate}>
                  </GaiaTextField>
                </FormControl>
              </Box>

              <FormControl sx={{ mb: 3, width: '100%' }}>
                <GaiaTextField
                  label="Organisation*"
                  value={requiredDetails.organisation}
                  minRows={1}
                  placeholder="Organisation*"
                  formControlName='organisation'
                  onChange={handlePageInformationUpdate}>
                </GaiaTextField>
              </FormControl>

            </>}
            <Divider style={{ marginTop: '32px', marginBottom: '32px' }} />
            {questions && <QuestionForm questions={questions} formattedAnswers={handleAnswers} buttonCopy={'Submit form'} />}

            {!questions && <FormControl sx={{ mb: 3, width: '100%' }}>
              <GaiaTextField
                label="Comment"
                value={requiredDetails.comment}
                minRows={5}
                placeholder="Please enter your request..."
                formControlName='comment'
                onChange={handlePageInformationUpdate}>
              </GaiaTextField>
            </FormControl>}

            {!questions && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => handleAnswers()}
                className='removeUppercase'
                endIcon={<ChevronRight />}
                sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
              >
                Submit
              </Button>
            </Grid>}

          </Paper>
        </Grid>
      </Grid>
    </Container>

    <SignUpDialog
      role='BUYER'
      inputUserDetails={{ ...requiredDetails, role: 'buyer' }}
      open={dialogOpen}
      closeDialog={handleDialogClose}
      forwardUrl={forwardUrl}>
    </SignUpDialog>


  </Box>)


}