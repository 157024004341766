import React, { useContext, useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import httpClient from '../../shared/http/http-client';
import { UserDetailsContext } from '../../shared/context/user-details';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Payment = (props) => {

  const [userId, setUserId] = useState();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpgradePage, setIsUpgradePage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useContext(UserDetailsContext);
  const location = useLocation();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    const isUpgradePage = searchParams.get("upgrade") || location.pathname.indexOf('upgrade') !== -1;

    if (isUpgradePage) {
      setIsUpgradePage(true);
    }

    if (user.userDetails) {
      setUserId(user.userDetails.id)
      setEmail(user.userDetails.email)
      if (user.userDetails.hasSubscription) {
        goToSubscriptionSettings();
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  function goToSubscriptionSettings() {
    httpClient.get('buyer/payment/customer-portal-session')
      .then(response => {
        window.open(response.data.data.url, '_self')
      })
      .catch(() => {
        setIsLoading(false);
      })
  }

  return (
    <>
      <Toolbar />
      <LoadingSpinner isLoading={isLoading} loadingMessage={'Loading...'}>
        {/* <TabPanel value={activeTab} index={0}> */}
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>

          {!isUpgradePage && <Box sx={{ margin: 'auto', maxWidth: '300px', width: '100%' }}>
            <Button
              component={Link}
              to={'/hub/marketplace'}
              variant="contained"
              color="primary"
              disableElevation
              className='removeUppercase'
              sx={{ width: '100%', justifyContent: "center", mb: "24px", mt: "15px", float: "right" }}
            >
              Continue on free tier
            </Button>

            <Typography variant="h6" color={'text.primary'} display="block" textAlign={'center'} width={'100%'} className='removeUppercase' margin={'24px auto'}>
              or
            </Typography>
          </Box>}

          <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TBALE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
            client-reference-id={userId}
            customer-email={email}>
          </stripe-pricing-table>

        </Container>
      </LoadingSpinner>
    </>
  );
};

export default Payment