import React, { useContext, useEffect } from "react";
import { CognitoUserPool, CognitoUser, CookieStorage } from "amazon-cognito-identity-js";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { HubDetailsContext } from '../../context/hub-details';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const LoginPage = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');

  let cognitoUser;
  const navigate = useNavigate();
  const hubDetailsContext = useContext(HubDetailsContext);

  //let from = location.state?.from?.pathname || '/login';

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : `.${hubDetailsContext.hubDetails?.cookie_domain}` })
  }

  const userPool = new CognitoUserPool(poolData);

  const handleNext = () => {
    setActiveTab((prevActiveTab) => prevActiveTab + 1);
  };

  const handleNewPassword = () => {
    var userData = {
      Username: email,
      Pool: userPool,
      Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : `.${hubDetailsContext.hubDetails?.cookie_domain}` })

    };
    cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        navigate('/login');
      }
    });
  }

  const resetPassword = (email) => {
    var userData = {
      Username: email,
      Pool: userPool,
      Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : `.${hubDetailsContext.hubDetails?.cookie_domain}` })

    };
    cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onFailure: function (err) {
        alert(err);
      },
      inputVerificationCode() {
        setActiveTab(1);
      }
    });
  }

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <Box style={{ backgroundColor: '#F0F1F3', minHeight: '100vh' }}>
      <Toolbar />
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <img
              src="/gaia_logo.png"
              alt="GAIA logo"
              //width="122"
              height="100"
              style={{ verticalAlign: 'middle', paddingLeft: '45px', paddingBottom: '15px' }}
            />
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
              }}
            >
              <Box sx={{ width: '100%', p: '24px' }}>
                <TabPanel value={activeTab} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5">
                        Forgotten password
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <TextField
                        fullWidth
                        className="formField"
                        label="Email address"
                        id="email"
                        placeholder="Email address"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        onClick={() => resetPassword(email)}
                        className='removeUppercase'
                        fullWidth
                        sx={{ textAlign: 'center' }}
                      >
                        Reset password
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <Link to="/login">
                        <Button
                          color="primary"
                          variant="outlined"
                          size="large"
                          disableElevation
                          className='removeUppercase'
                          fullWidth
                          sx={{ textAlign: 'center' }}
                        >
                          Back to login
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5">
                        Verification
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="subtitle2" color="text.secondary" >
                        Please enter the 6 digit verification code that has been sent to <b>{email}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <TextField
                        fullWidth
                        className="formField"
                        label="Verification Code"
                        id="varificationCode"
                        placeholder="Verification Code"
                        onChange={e => setVerificationCode(e.target.value)}
                        value={verificationCode}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleNext}
                        className='removeUppercase'
                        endIcon={<ChevronRightIcon />}
                        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5">
                        Set a new password
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <TextField
                        fullWidth
                        className="formField"
                        label="Password"
                        id="newPassword"
                        placeholder="Password"
                        onChange={e => setNewPassword(e.target.value)}
                        value={newPassword}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleNewPassword}
                        className='removeUppercase'
                        endIcon={<ChevronRightIcon />}
                        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginPage