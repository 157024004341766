// RequireAuth.js
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../context/user-details';
import { HubDetailsContext } from '../context/hub-details';
import CognitoUserAuthenticationService from '../services/cognito-user-authentication-service';

const RequireAuth = ({ children, isBuyer, isSeller, noRoleRequired }) => {
  const location = useLocation();
  const user = useContext(UserDetailsContext);
  const hubDetailsContext = useContext(HubDetailsContext);
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);

  const [isLoading, setIsLoading] = useState(true);
  const [canAccess, setCanAccess] = useState(false);
  const [navigateTo, setNavigateTo] = useState(<Navigate to="/login" state={{ from: location }} replace />)

  useEffect(() => {
    setIsLoading(true);
    setCanAccess(false);
    setNavigateTo(<Navigate to="/login" state={{ from: location }} replace />);

    cognitoUserAuthenticationService.getCookie()
      .then(async response => {
        if (response) {

          let userDetails = user.userDetails;

          if (!userDetails) {
            userDetails = await user.forceUpdateUserDetails()
          }

          if (userDetails.userInformationRequired && window.location.pathname.indexOf('sign-up-questions') === -1) {
            setNavigateTo(<Navigate to="/user-sign-up-questions" state={{ from: location }} replace />);
            setIsLoading(false)
          } else {
            if (userDetails?.roles.includes('BUYER') && isBuyer === true) {
              setCanAccess(true);
              setIsLoading(false);
            } else if ((userDetails?.roles.includes('SELLER') || userDetails?.roles.includes('ECOLOGIST')) && isSeller === true) {
              setCanAccess(true);
              setIsLoading(false);
            } else if (noRoleRequired === true) {
              setCanAccess(true);
              setIsLoading(false);
            } else if (userDetails) {
              setIsLoading(false);
            }
          }

        } else {
          let sendToRegisterAsSeller = false;
          let sendToRegisterAsBuyer = false;
          if (true) {
            sessionStorage.setItem('forward_link', location.pathname);
            const formattedSearchParams = location.search.split('&').filter(value =>
              value.indexOf('redirect_on_login') === -1 &&
              value.indexOf('through_register_seller') === -1 &&
              value.indexOf('through_register_buyer') === -1).join('&');
            sessionStorage.setItem('search', formattedSearchParams);
            sendToRegisterAsSeller = !!location.search.split('&').find(value => value.indexOf('through_register_seller') !== -1);
            sendToRegisterAsBuyer = !!location.search.split('&').find(value => value.indexOf('through_register_buyer') !== -1);
          }

          if (sendToRegisterAsSeller) {
            setNavigateTo(<Navigate to='/signup?role=seller' state={{ from: location }} replace />);
          } else if (sendToRegisterAsBuyer) {
            setNavigateTo(<Navigate to='/signup?role=buyer' state={{ from: location }} replace />);
          } else {
            setNavigateTo(<Navigate to="/login" state={{ from: location }} replace />);
          }
          setIsLoading(false);
        }

      })
  }, [window.location.pathname])

  return isLoading ? null : canAccess ? <Outlet /> : navigateTo;
}

export default RequireAuth;
