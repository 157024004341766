import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Stack, TextField } from '@mui/material'
import { lpasAutocomplete } from '../../../shared/data/lpas'
import { nationalCharacterAreasAutocomplete } from '../../../shared/data/national-character-areas'

export const EditProjectDialog = (
  props
) => {
  return (
    <Dialog open={props.editFormActive} onClose={props.handleSoldClick} maxWidth='md' fullWidth>
      <DialogTitle sx={{ p: 4, pb: 2, }}>Edit Project Information</DialogTitle>
      <DialogContent sx={{ pt: 0, p: 4 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Title"
              id="summary"
              value={props.updatedProjectDetails.p_summary}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_summary: e.target.value
              })}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Description"
              id="additionalInfo"
              multiline
              rows={6}
              value={props.updatedProjectDetails.p_description}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_description: e.target.value
              })}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              id="lpa"
              options={lpasAutocomplete}
              inputValue={props.updatedProjectDetails.p_local_planning_authority}
              value={props.updatedProjectDetails.p_local_planning_authority}
              renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
              onInputChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_local_planning_authority: value
              })}
              onChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_local_planning_authority: value
              })}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              id="nca"
              options={nationalCharacterAreasAutocomplete}
              inputValue={props.updatedProjectDetails.p_national_character_area}
              value={props.updatedProjectDetails.p_national_character_area}
              renderInput={(params) => <TextField {...params} label="National Character Area" />}
              onInputChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_national_character_area: value
              })}
              onChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_national_character_area: value
              })}
            />
          </FormControl>


          <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
            <TextField
              fullWidth
              className="formField"
              label="Biodiversity Gain Sites Register Number"
              id="defraRef"
              placeholder="Biodiversity Gain Sites Register Number"
              // onChange={e => setProjectData('bng_registration_number', e.target.value)}
              value={props.updatedProjectDetails.bng_registration_number}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                bng_registration_number: e.target.value
              })}
            />
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                value={props.updatedProjectDetails.group_at_project_level}
                checked={props.updatedProjectDetails.group_at_project_level}
                onChange={(e, value) => props.setUpdatedProjectDetails({
                  ...props.updatedProjectDetails,
                  group_at_project_level: value
                })}
              />}
            label='Group at project level'
            sx={{ width: '100%' }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          <Button onClick={props.handleEditClick} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => props.handleUpdateProjects(
              props.updatedProjectDetails.p_summary,
              props.updatedProjectDetails.p_description,
              props.updatedProjectDetails.p_location,
              props.projectDetails.status_id,
              props.updatedProjectDetails.p_local_planning_authority,
              props.updatedProjectDetails.p_national_character_area,
              props.updatedProjectDetails.group_at_project_level,
              props.updatedProjectDetails.bng_registration_number
            )}
            className="removeUppercase"
          >
            Update
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}