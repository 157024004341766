import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Button, Grid, Paper } from '@mui/material';
import Title from '../../../shared/components/title';
import AdminEnquiriesService from '../../services/admin-enquiries-service';
import AdminUserFilteredSearchResultsService from '../../services/admin-user-filtered-search-results';

const MarketplaceSearches = () => {

  const adminUserFilteredSearchResultsService = new AdminUserFilteredSearchResultsService();

  const [searches, setSearches] = useState();

  const getUserSearches = () => {
    adminUserFilteredSearchResultsService.getAllUserFilteredSearchResults()
      .then(response => {
        setSearches(response)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserSearches();
  }, [])

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToCsv = e => {
    e.preventDefault();
    let headers = ['id,region,lpa,nca,unit_type,broad_habitat,count_returned,created_at'];
    let searchesCsv = searches.reduce((acc, search) => {
      const { id, region, lpa, nca, unit_type, broad_habitat, count_returned, created_at } = search
      acc.push([id, `"${region}"`, `"${lpa}"`, `"${nca}"`, `"${unit_type}"`, `"${broad_habitat}"`, count_returned, created_at].join(','))
      return acc
    }, [])
    downloadFile({
      data: [...headers, ...searchesCsv].join('\n'),
      fileName: 'searches.csv',
      fileType: 'text/csv',
    })
  }

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 400,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Title>Marketplace Searches</Title>
              <Button variant='contained' onClick={exportToCsv} disableElevation className='removeUppercase' sx={{ width: '200px', mt: 2 }}>
                Export to CSV
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MarketplaceSearches