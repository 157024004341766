import httpClient from '../../shared/http/http-client';

export default class HubSuperAdminService {
  http;

  constructor() {
    this.http = httpClient
  }

  /**
   * Toggle a tile active/inactive for a given hub.
   * @param {*} tileId - The ID of the tile to toggle on and off.
   * @returns - A success or error message.
   */
  async createHub(hub) {
    return this.http.post('admin/site-admin/create-hub', hub)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err)
      })
  }
}
