import httpClient from "../http/http-client";

export default class CustomFilterService {
  http;

  constructor() {
    this.http = httpClient
  }

  async getFilters(pageCode) {
    return this.http.get(`hub/filters?page_code=${pageCode}`)
      .then(response => {
        return response.data
      });
  }
}