import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Alert from '@mui/material/Alert';
import httpClient from '../http/http-client';

import moment from 'moment';
import UserService from '../services/user-service';


export default function SavedProjectsTable() {

  const userService = new UserService();

  const [savedProjects, setSavedProjects] = useState([]);
  const [savedInvestmentProjects, setSavedInvestmentProjects] = useState([]);

  const navigate = useNavigate();

  const setSelectedRow = (savedProject, isInvestmentProject = false) => {
    if (isInvestmentProject) {
      navigate(`/hub/invest/${savedProject.id}`)
    } else {
      navigate(`/hub/projects/${savedProject.p_id}`)
    }
  }

  // get saved projects

  const getSavedBngProjects = async () => {
    userService.getSavedBngProjects()
      .then(response => {
        setSavedProjects(response);
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  const getSavedInvestmentProjects = async () => {
    userService.getSavedInvestmentProjects()
      .then(response => {
        setSavedInvestmentProjects(response);
      })
      .catch(err => {
        console.error(err.message);
      })
  };


  useEffect(() => {
    getSavedBngProjects();
    getSavedInvestmentProjects();
  }, []);

  return (
    <>
      {!savedProjects.length && !savedInvestmentProjects.length && (
        <Alert severity="info" sx={{ p: 2 }}>No listings have been saved yet.</Alert>
      )}
      <Table>
        <TableBody>
          {savedProjects.map((savedProject, index) => (
            <TableRow key={index} onClick={() => setSelectedRow(savedProject)} hover={true}>
              {/* <TableCell>
                <img
                  key={index}
                  src={savedProject.headerImage?.location}
                  alt='Lead landscape'
                  loading="lazy"
                  style={{
                    height: "100px",
                    maxHeight: '100px',
                    maxWidth: '100px',
                    width: "100%",
                    objectFit: "contain"
                  }}></img>
              </TableCell> */}
              <TableCell>BNG project</TableCell>
              <TableCell>{moment(savedProject.created_at).format('DD/MM/yyyy')}</TableCell>
              <TableCell>{savedProject.p_summary}</TableCell>
              <TableCell>{savedProject.p_local_planning_authority}</TableCell>
              <TableCell align="right"><TaskAltIcon color='primary' /></TableCell>
            </TableRow>
          ))}

          {savedInvestmentProjects.map((savedProject, index) => (
            <TableRow key={index} onClick={() => setSelectedRow(savedProject, true)} hover={true}>
              <TableCell>Investment project</TableCell>
              <TableCell>{moment(savedProject.created_at).format('DD/MM/yyyy')}</TableCell>
              <TableCell>{savedProject.title_text}</TableCell>
              <TableCell></TableCell>
              <TableCell align="right"><TaskAltIcon color='primary' /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
