import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Stack from '@mui/material/Stack';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

const SuperAdminDashboard = () => {

  const [adminAreas, setAdminAreas] = useState([{
    id: 1,
    title: 'Create Hub',
    description: 'Create an environment hub',
    to: '/super-admin/create-hub'
  },
  {
    id: 2,
    title: 'Analytics',
    description: 'View analytics on the Gaia Marketplace',
    to: '/super-admin/analytics'
  },
  {
    id: 3,
    title: 'View all Gaia Plus projects',
    description: 'See all current projects',
    to: '/admin/gaia-plus/all-projects'
  },
  {
    id: 4,
    title: 'Create new Gaia Plus project',
    description: 'Create a new project and assign it to an Ecologist',
    to: '/admin/gaia-plus/create'
  },
  {
    id: 5,
    title: 'Manage Gaia Plus tasks',
    description: 'View, enable and add new tasks.',
    to: '/admin/gaia-plus/tasks'
  },
  {
    id: 6,
    title: 'Marketplace Searches',
    description: 'View and export a log of marketplace searches',
    to: '/super-admin/marketplace-searches'
  }
  ]);


  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {adminAreas?.map((area) => {
            return (
              <Grid item xs={12} md={4} lg={3} key={area.id}>
                <Card variant="outlined">
                  <CardActionArea
                    component={Link}
                    to={area.to}
                    sx={{
                      height: '100%',
                      minHeight: '350px',
                      p: 2
                    }}
                  >
                    <CardContent>
                      <Stack>
                        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 300, marginBottom: '16px' }}>
                          {area.title}
                        </Typography>
                        <Typography variant="p" color="text.primary" sx={{ fontWeight: 200, marginBottom: '16px' }}>
                          {area.description}
                        </Typography>
                        <TrendingFlatIcon color="primary" sx={{ fontSize: '28px' }} />
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          }
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SuperAdminDashboard 