import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import httpClient from '../http/http-client';

export const ProjectHistory = ({ projectHistoryRecord, projectId }) => {

  const [projectHistory, setProjectHistory] = useState([]);

  // get project history

  const getProjectHistory = async (projectId) => {
    httpClient.get(`projecthistory/${projectId}`)
      .then(projectHistory => {
        setProjectHistory(projectHistory.data);
      })
      .catch(err => {
        console.log(err)
      })
  };

  useEffect(() => {
    if (projectHistoryRecord) {
      setProjectHistory(projectHistoryRecord)
    }
  }, [projectHistoryRecord]);

  useEffect(() => {
    if (projectId) {
      getProjectHistory(projectId);
      // props.refreshHistory();
    }
  }, [projectId]);

  return (
    <Timeline>
      {projectHistory?.map((history, index) => {
        const created_at = new Date(history.created_at);
        const createdDate = created_at.toLocaleDateString('en-GB');
        return (
          <TimelineItem key={uuidv4()}>
            <TimelineOppositeContent color="text.secondary">
              {createdDate}
            </TimelineOppositeContent>
            <TimelineSeparator>
              {history.ph_event_type === 'sale' && (
                <TimelineDot color='success' />
              )}
              {history.ph_event_type !== 'sale' && (
                <TimelineDot />
              )}
              {index !== projectHistory.length - 1 && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            <TimelineContent>{history.ph_event}</TimelineContent>
          </TimelineItem>
        )
      }
      )}

    </Timeline>
  );
}
