import httpClient from "../http/http-client";

export default class ProjectResourceService {
  http;

  constructor() {
    this.http = httpClient
  }

  async createResource(file) {
    return this.http.post('admin/resources', file)
      .then(response => {
        return response.data;
      })
  }

  async deleteResource(id) {
    return this.http.post(`admin/resources/delete/${id}`, {})
      .then(response => {
        return response.data;
      })
  }
}