import './html-parser-display.css';
import parse from 'html-react-parser';

export const HtmlParserDisplay = ({ html }) => {


  return (<>
    <div className='parser-display'>
      {html ? parse(html) : ''}
    </div>

  </>
  )

}