import React, { Fragment, useContext, useEffect, useState } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Divider, Button, IconButton, Stack, Select, MenuItem, Typography, ToggleButtonGroup, ToggleButton, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import AdminHubButtonService from '../../../services/admin-hub-button-service';
import IconPicker from '../../../../shared/components/icon-picker';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImagePicker from '../../../../shared/components/image-picker';
import { ImageName, CornwallImageName } from '../../../../shared/objects/image';
import { HubDetailsContext } from '../../../../shared/context/hub-details';

const BrandingButtonForm = () => {
  const hubButtonService = new AdminHubButtonService();
  const hubContext = useContext(HubDetailsContext);

  const [hubButtons, setHubButtons] = useState([]);
  const [addButtonDialogueActive, setAddButtonDialogueActive] = useState(false);
  const [buttonDialogueFunction, setButtonDialogueFunction] = useState('new');
  const [genericFunctions, setGenericFunctions] = useState([]);
  const [buttonImages, setButtonImages] = useState([]);

  const [createdButton, setCreatedButton] = useState();

  const imageArray = [
    { label: 'Environment', path: ImageName.Environment },
    { label: 'Protect', path: ImageName.Protect },
    { label: 'World', path: ImageName.World },
    { label: 'Ecology', path: ImageName.Ecology },
    { label: 'Growing Plant', path: ImageName.Growing },
    { label: 'Biology', path: ImageName.Biology },
    { label: 'Recycle', path: ImageName.Recycle },
    { label: 'Nature', path: ImageName.Nature },
    { label: 'Leaves', path: ImageName.Leaves },
    { label: 'Trees', path: ImageName.Trees },
    { label: 'Windmills', path: ImageName.Windmills },
  ]

  const cornwallImageArray = [
    { label: 'Cornish Wall', path: CornwallImageName.Wall },
    { label: 'Grass', path: CornwallImageName.Grass },
    { label: 'Handshake', path: CornwallImageName.Handshake },
    { label: 'Money Tree', path: CornwallImageName.MoneyTree },
    { label: 'Money', path: CornwallImageName.Money },
    { label: 'Ocean', path: CornwallImageName.Ocean },
    { label: 'Pond', path: CornwallImageName.Pond },
    { label: 'Stream', path: CornwallImageName.Stream },
    { label: 'Tree', path: CornwallImageName.Tree },
    { label: 'Buyers', path: CornwallImageName.Buyer },
    { label: 'Investors', path: CornwallImageName.Investor },
    { label: 'Funder', path: CornwallImageName.Funder }
  ]

  // Create the initial button object and add arrays for the specific areas.
  function createButton(title_copy = '', body_copy = '', icon_code = '', route_is_internal = 'internal', url = '', is_navbar_button = false) {
    const button = {
      title_copy: title_copy,
      body_copy: body_copy,
      icon_code: icon_code,
      route_is_internal: route_is_internal,
      url: url,
      is_navbar_button: is_navbar_button
    };

    setCreatedButton(button);
  }

  // Set the required button data.
  function setButtonData(formControl, value) {
    const button = createdButton;
    button[formControl] = value;

    setCreatedButton({ ...button });
  }

  useEffect(() => {
    getHubButtons();
    createButton();
    getGenericFunctions();
    if (hubContext.hubDetails) {
      setButtonImages(hubContext.hubDetails.id === 4 ? cornwallImageArray : imageArray);
    }
  }, []);

  const getHubButtons = () => {
    hubButtonService.getAllHubButtons()
      .then(response => {
        setHubButtons(response.data);
      })
  }

  const getGenericFunctions = () => {
    hubButtonService.getGenericFunctions()
      .then(response => {
        setGenericFunctions(response.data);
      })
  }

  const showHubButtonDialog = (formFunction, buttonData) => {
    const route_is_internal = buttonData?.route_is_internal ? 'internal' : 'external';
    setButtonDialogueFunction(formFunction);
    if (formFunction === 'edit') {
      const url = buttonData.url.replace('https://', '');
      setCreatedButton({ ...buttonData, route_is_internal: route_is_internal, url: url });
    }
    setAddButtonDialogueActive(!addButtonDialogueActive);
  };

  const cancelHubButtonDialog = () => {
    setAddButtonDialogueActive(!addButtonDialogueActive);
    createButton();
  }

  const handleAddHubButton = () => {
    const postData = { ...createdButton, route_is_internal: createdButton.route_is_internal === 'internal' ? true : false }
    hubButtonService.createNewButton(postData)
      .then(() => {
        getHubButtons();
        setAddButtonDialogueActive(false);
        clearForm();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleUpdateHubButton = (buttonId) => {
    const postData = { ...createdButton, route_is_internal: createdButton.route_is_internal === 'internal' ? true : false }
    hubButtonService.updateButton(buttonId, postData)
      .then(() => {
        getHubButtons();
        setAddButtonDialogueActive(false);
        clearForm();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDeleteHubButton = (buttonId) => {
    hubButtonService.deleteButton(buttonId)
      .then(() => {
        getHubButtons();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const clearForm = () => {
    setButtonData('title_copy', '');
    setButtonData('body_copy', '');
    setButtonData('icon_code', '');
    setButtonData('url', '');
    setButtonData('route_is_internal', 'internal');
    setButtonData('is_navbar_button', false);
  }

  const handleButtonTypeToggle = (buttonType) => {
    buttonDialogueFunction === 'new' && clearForm();
    setButtonData('route_is_internal', buttonType);
  }

  const handleGenericFunctionSelection = (functionCode) => {
    const buttonData = genericFunctions.filter(genericFunction => genericFunction.action_code === functionCode);
    setButtonData('title_copy', buttonData[0].title_copy);
    setButtonData('body_copy', buttonData[0].body_copy);
    setButtonData('icon_code', buttonData[0].icon_code);
    setButtonData('url', buttonData[0].url);
  }

  return (
    <>
      <List
        sx={{
          width: '100%',
          alignItems: 'center'
        }}
      >
        {hubButtons && hubButtons.map((button, index) => {
          return (
            <Fragment key={index}>
              <ListItem className='hoverList' secondaryAction={<Stack direction='row'>
                <IconButton className='hoverListChild' edge="end" aria-label="delete" onClick={() => showHubButtonDialog('edit', button)} sx={{ mr: 0 }}>
                  <EditIcon />
                </IconButton>
                <IconButton className='hoverListChild' edge="end" aria-label="delete" onClick={() => handleDeleteHubButton(button.id)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
              }>
                <ListItemIcon >
                  <IconPicker icon={button?.is_navbar_button ? 'link' : 'button'} />
                </ListItemIcon>
                <ListItemText primary={button?.title_copy} />
              </ListItem>
              {hubButtons.length !== index + 1 && <Divider sx={{ p: 0 }} />}
            </Fragment >
          )
        })
        }
        <ListItem>
          <ListItemText
            primary={
              <Button
                variant="text"
                startIcon={<AddIcon />}
                className="removeUppercase"
                onClick={() => showHubButtonDialog('new')}
              >
                Add a new hub button
              </Button>
            }
          />
        </ListItem>
      </List >
      <Dialog open={addButtonDialogueActive} onClose={showHubButtonDialog} maxWidth='sm' fullWidth>
        {buttonDialogueFunction === 'new' && <DialogTitle sx={{ pb: '5px' }}>Add a new hub button</DialogTitle>}
        {buttonDialogueFunction === 'edit' && <DialogTitle sx={{ pb: '5px' }}>Edit hub button</DialogTitle>}
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <FormControl fullWidth margin='normal'>
              <ToggleButtonGroup
                color="primary"
                value={createdButton?.route_is_internal}
                exclusive
                onChange={e => handleButtonTypeToggle(e.target.value)}
                aria-label="Button function"
                fullWidth
              >
                <ToggleButton value={'internal'} className='removeUppercase'>Gaia Page</ToggleButton>
                <ToggleButton value={'external'} className='removeUppercase'>Custom URL</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            {createdButton?.route_is_internal === 'external' &&
              <FormControl fullWidth margin='normal'>
                <InputLabel htmlFor="url">URL</InputLabel>
                <OutlinedInput
                  id="url"
                  fullWidth
                  className="formField"
                  label="URL"
                  type="text"
                  onChange={e => setButtonData('url', e.target.value)}
                  value={createdButton?.url}
                  placeholder='www.example.com'
                  startAdornment={
                    <InputAdornment position="start">
                      https://
                    </InputAdornment>
                  }
                />
              </FormControl>
            }
            {createdButton?.route_is_internal === 'internal' && buttonDialogueFunction === 'new' &&
              <FormControl fullWidth margin='normal'>
                <InputLabel id="function-label">Function</InputLabel>
                <Select
                  labelId="function-label"
                  id="function-dropdown"
                  label="Function"
                  onChange={e => handleGenericFunctionSelection(e.target.value)}
                >
                  {genericFunctions?.map((genericFunction, index) => (
                    <MenuItem key={index} value={genericFunction.action_code}>
                      {genericFunction.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            <FormControl fullWidth margin='normal'>
              <InputLabel htmlFor="button-title">Button Heading</InputLabel>
              <OutlinedInput
                id="button-title"
                fullWidth
                className="formField"
                label="Button Heading"
                type="text"
                onChange={e => setButtonData('title_copy', e.target.value)}
                value={createdButton?.title_copy}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <InputLabel htmlFor="button-subtitle">Button Subheading</InputLabel>
              <OutlinedInput
                id="button-subtitle"
                fullWidth
                className="formField"
                label="Button Subheading"
                type="text"
                onChange={e => setButtonData('body_copy', e.target.value)}
                value={createdButton?.body_copy}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <InputLabel id="icon-code-label">Icon</InputLabel>
              <Select
                labelId="icon-code-label"
                id="icon-code-dropdown"
                value={createdButton?.icon_code}
                label="Icon"
                onChange={e => setButtonData('icon_code', e.target.value)}
              >
                {buttonImages.map((image, index) => (
                  <MenuItem key={index} value={image.path}>
                    <Stack direction='row' spacing={2} sx={{ height: '23px', width: '23px', alignItems: 'center' }}>
                      <ImagePicker image={image.path} sx={{ pr: 2 }} />
                      <Typography>{image.label}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="answer_required"
                  value={createdButton?.is_navbar_button}
                  onChange={e => setButtonData('is_navbar_button', e.target.checked)}
                />}
              label="Display as a link on the top right menu"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
          <Button
            onClick={cancelHubButtonDialog}
            className="removeUppercase"
            sx={{ color: "text.primary" }}
          >
            Cancel
          </Button>
          {buttonDialogueFunction === 'new' && <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleAddHubButton()}
            //startIcon={<BlockIcon />}
            className="removeUppercase"
          >
            Add button
          </Button>
          }
          {buttonDialogueFunction === 'edit' && <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleUpdateHubButton(createdButton?.id)}
            //startIcon={<BlockIcon />}
            className="removeUppercase"
          >
            Update button
          </Button>
          }
        </DialogActions>
      </Dialog >
    </>
  );
};

export default BrandingButtonForm 