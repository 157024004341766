import React, { useEffect } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InvestmentProjectsTable from "../components/investment-projects-table";

const AdminInvestmentProjectsPage = () => {

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: 'calc(100vh - 130px)',
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <InvestmentProjectsTable />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminInvestmentProjectsPage