import { Stack, Tooltip } from '@mui/material';
import { EmailIcon, EmailShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from 'react-share';

export const SocialMediaShareComponent = () => {



  return (<>
    <Stack direction={'row'}>
        <Tooltip title="Share on LinkedIn">
          <LinkedinShareButton url={window.location.href} style={{ display: 'flex', marginLeft: '8px' }}>
            <LinkedinIcon size={35} round />
          </LinkedinShareButton>
        </Tooltip>
        <Tooltip title="Share on X (formerly Twitter)">
          <TwitterShareButton url={window.location.href} style={{ display: 'flex', marginLeft: '8px' }}>
            <XIcon size={35} round />
          </TwitterShareButton>
        </Tooltip>
        <Tooltip title="Share via Email">
          <EmailShareButton url={window.location.href} style={{ display: 'flex', marginLeft: '8px' }}>
            <EmailIcon size={35} round />
          </EmailShareButton>
        </Tooltip>
      </Stack>
  </>

  )
}