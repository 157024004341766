import { useEffect, useState } from 'react';
import AdminFormFlowService from '../../services/admin-form-flow-service';
import AdminFormService from '../../services/admin-form-service';
import { Box, Button, Card, CardActionArea, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DeleteOutlined, SaveOutlined, EditRounded, Add } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const CreateFormFlow = () => {

  const adminFormService = AdminFormService.instance;
  const adminFormFlowService = new AdminFormFlowService();
  const navigate = useNavigate();
  const params = useParams();


  const [availableForms, setAvailableForms] = useState();
  const [selectedFormId, setSelectedFormId] = useState();
  const [addedForms, setAddedForms] = useState([]);
  const [formFlow, setFormFlow] = useState({ name: '', description: 'Description' });
  const [formableObjectCode, setFormableObjectCode] = useState();
  const [addingNewForm, setAddingNewForm] = useState(true);

  useEffect(() => {
    adminFormService.getAllQuestionForms()
      .then(response => {
        setAvailableForms(response)
      })

    adminFormService.getFormableObjectList()
      .then(response => {
        setFormableObjectCode(response.find(obj => obj.id.toString() === params.formableObjectId).code);
      })
  }, [])

  const addFormToFlow = (event) => {
    const tempArray = addedForms;
    tempArray.push(event.target.value);
    setAddedForms([...tempArray]);
    setSelectedFormId(undefined);
    setAddingNewForm(false);
  }

  const removeForm = (formId) => {
    setAddedForms(addedForms.filter(form => form !== formId));
  }

  const submitFormFlow = () => {
    // if (formFlow.name) {
    const flow = formFlow;
    flow.formable_object_code = formableObjectCode
    flow.form_ids = addedForms;

    adminFormFlowService.createFormFlow(flow)
      .then(response => {
        navigate(`/admin/form-flow/${response.id}`)
      })
    // }

  }

  // const updateFormFlowName = (event) => {
  //   setFormFlow({ ...formFlow, name: event.target.value })
  // }


  return (<>
    <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>Workflows</Typography>
    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
    <Stack direction='row' sx={{ mb: 4, mt: 4 }}>
      <Typography variant="h5" color="text.primary" sx={{ flex: 1 }}>
        <b>Create new workflow</b>
      </Typography>
      {addedForms.length > 0 && <Button
        variant="outlined"
        size="medium"
        disableElevation
        className="removeUppercase"
        onClick={submitFormFlow}
        sx={{
          justifyContent: "flex-end",
          ml: "auto",
          float: "right"
        }}
        startIcon={<SaveOutlined />}
      >
        Save Workflow
      </Button>}
    </Stack>

    {addedForms.map((formId, index) => {
      return <Card
        sx={{ alignItems: 'center', justifyContent: 'center', mb: 1 }}
        key={index}
      ><List sx={{ flex: 1 }} dense>
          <ListItem
            secondaryAction={
              <IconButton
                onClick={() => removeForm(formId)}
                color='error'
                edge="end"
                aria-label="delete"
              >
                <DeleteOutlined />
              </IconButton>
            }
          >
            <ListItemText
              primary={<Stack direction='row' spacing={2}>
                <Typography color="text.primary" variant="subtitle1">{index + 1}</Typography>
                <Typography color="text.primary" variant="subtitle1">{availableForms?.find(availableForm => availableForm.id === formId)?.form_name}</Typography>
              </Stack>
              }
            />
          </ListItem>
        </List>
      </Card>
    })}

    {!addingNewForm && <Button
      onClick={() => setAddingNewForm(true)}
      color='primary'
      variant="outlined"
      size="medium"
      disableElevation
      className="removeUppercase"
      startIcon={<Add />}
      sx={{ textTransform: 'capitalize', mt: 2 }}
    >
      Add form to workflow
    </Button>}

    {addingNewForm && <FormControl sx={{ mt: 2 }} fullWidth>
      <InputLabel id="demo-simple-select-label">Select form to add</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Select form to add"
        value={availableForms?.find(form => form.id === selectedFormId)?.id || ''}
        onChange={(e) => addFormToFlow(e)}
      >
        {availableForms && availableForms.filter(form => !addedForms.includes(form.id)).map((form, index) => {
          return <MenuItem key={index} value={form.id}>{form.form_name}</MenuItem>
        })}
      </Select>
    </FormControl>}

    {addedForms.length > 0 && <Button
      variant="outlined"
      size="medium"
      disableElevation
      className="removeUppercase"
      onClick={submitFormFlow}
      sx={{
        justifyContent: "flex-end",
        ml: "auto",
        float: "right",
        mt: 2,
      }}
      startIcon={<SaveOutlined />}
    >
      Save Workflow
    </Button>}

  </>)
}

export default CreateFormFlow;