import { useEffect, useState } from 'react'
import AdminUserService from '../services/admin-user-service';
import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { Edit, SaveOutlined } from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useContext } from 'react';
import { HubDetailsContext } from '../../shared/context/hub-details';

export const EditUserDetailsForm = ({ userId, updateSuccessful }) => {

  const hubDetailsContext = useContext(HubDetailsContext);

  const [user, setUser] = useState();
  const [isEditing, setIsEditing] = useState();
  const [locale, setLocale] = useState('en-GB');

  const adminUserService = new AdminUserService();

  const getUser = () => {
    adminUserService.getSeller(userId)
      .then(response => {
        setUser(response)
      })
  }

  useEffect(() => {
    getUser()
  }, [userId]);

  const toggleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      adminUserService.updateUserDetails(userId, user)
        .then(response => {
          setUser(response);
          setIsEditing(false);
          updateSuccessful()
        })
    }
  }

  const handleUserUpdate = (formControl, value) => {
    setUser({ ...user, [formControl]: value })
  }

  return (<Box sx={{ maxWidth: '100%' }}>
    {user && <>
      <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

        <Typography variant="h6" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          User details
        </Typography>
        <Button
          variant={isEditing ? 'contained' : 'outlined'}
          disableElevation
          className="removeUppercase"
          startIcon={isEditing ? <SaveOutlined /> : <Edit />}
          onClick={() => toggleEdit()}
          sx={{ minWidth: '90px' }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Stack>
      <Grid container sx={{ maxWidth: '100%', pl: 2, pr: 2 }}>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Email address:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Email'
          // disabled={!isEditing}
          disabled={true}
          onChange={e => handleUserUpdate('email', e.target.value)}
          value={user.email || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Name:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Name'
          disabled={!isEditing}
          onChange={e => handleUserUpdate('name', e.target.value)}
          value={user.name || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Phone number:
        </Typography>

       
          <TextField
            type="number"
            disabled={!isEditing}
            sx={{ width: '100%' }}
            name='phone'
            value={user.phone}
            onChange={e => handleUserUpdate('phone', e.target.value)}
              InputProps={{
              type: "number",
              sx: {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield'
                },
              }
            }}
          />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Organisation name:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Organisation'
          disabled={!isEditing}
          onChange={e => handleUserUpdate('organisation', e.target.value)}
          value={user.organisation || ''}
          sx={{ width: '100%' }}
        />

        {hubDetailsContext.hubDetails.main_hub && <>
          <Typography variant="subtitle1" color="text.primary" sx={{ mt: 2 }}>
            Commission contract signed on:
          </Typography>

          <FormControl fullWidth margin='normal'>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
              <DatePicker
                disabled={!isEditing}
                maxDate={moment(new Date())}
                label="Commission contract signed"
                emptyLabel="DD/MM/YYYY"
                value={moment(user.commission_contract)}
                onChange={value => handleUserUpdate('commission_contract', value)}
              />
            </LocalizationProvider>
          </FormControl>
        </>}

      </Grid>
    </>}

  </Box>)
}