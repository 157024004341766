import axios from "axios";
import errorInterceptor from "./interceptors/error";
import updateHeaderInterceptor from "./interceptors/update-header";

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/gaia-api/v1/`
});

errorInterceptor(httpClient);
await updateHeaderInterceptor(httpClient);

export default httpClient;
