import { useContext, useEffect, useState } from 'react'
import { HubDetailsContext } from '../../context/hub-details'
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const CustomerServiceTextEmailLink = ({ overrideUrl, isAlert }) => {

  const hubContext = useContext(HubDetailsContext);
  const [url, setUrl] = useState();

  useEffect(() => {
    if (hubContext) {
      setUrl(hubContext.hubDetails?.organisation_settings?.support_email_address);
    }
  }, [hubContext])

  return (<Typography
    sx={{ display: 'inline', cursor: 'pointer', fontSize: isAlert ? '14px' : undefined }}
    color="primary"
    component={Link}
    to={`mailto:${overrideUrl || url}`}
    rel="noopener noreferrer"
  >
    customer services
  </Typography>)
}