import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HubDetailsContext } from '../../../context/hub-details';
import { Box } from '@mui/material';

export const NavBarBrand = ({ useGaiaLogo = false }) => {
  const [pageReady, setPageReady] = useState(false);
  const [logoUrl, setLogoUrl] = useState("/gaia_logo.png");
  const [homeUrl, setHomeUrl] = useState("");
  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    setLogoUrl(hub.hubDetails.hub_styles.logo_url);
    setHomeUrl(hub.hubDetails.home_page_url)
    setPageReady(true);
  }, [hub])

  return (<>
    {pageReady && (
      <Box className="nav-bar__brand" sx={{ flexShrink: 0 }}>
        <NavLink to={`dashboard`}>
          <img
            className="nav-bar__logo"
            src={logoUrl && !useGaiaLogo ? logoUrl : "/gaia_logo.png"}
            alt="logo"
            //width="122"
            height="60"
            style={{ verticalAlign: 'middle' }}
          />
        </NavLink>
      </Box>
    )}
  </>
  )
};
