import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import GaiaPlusService from '../services/gaia-plus-service';
import { UserDetailsContext } from '../../shared/context/user-details';
import { GaiaPlusProjectDetail } from '../../shared/components/gaia-plus-project-detail';


const GaiaPlusProjectDetails = () => {

  const [project, setProject] = useState();
  const [user, setUser] = useState()

  const params = useParams();
  const userDetails = useContext(UserDetailsContext);
  const gaiaPlusService = new GaiaPlusService();

  const getProject = () => {
    if (params.id) {
      gaiaPlusService.getProject(params.id)
        .then(response => {
          console.log(response)
          setProject(response)
        })
    }
  }

  useEffect(() => {
    if (userDetails.userDetails) {
      setUser(userDetails.userDetails)
    }
  }, [userDetails])

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getProject();
  }, [])

  return (<>
    {project && <GaiaPlusProjectDetail project={project} user={project.ecologist} />}
  </>
  );
};

export default GaiaPlusProjectDetails