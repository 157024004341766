/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import FormFlowList from '../form-flow/form-flow-list';
import CreateFormFlow from '../form-flow/create-form-flow';
import { Link } from 'react-router-dom';

const CustomFormFlow = () => {

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Workflows
        </Typography>

        {/* <Button
          component={Link}
          to='/admin/configuration/form-flow/create'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          sx={{ textTransform: 'none' }}
        >
          Create new form flow
        </Button> */}

      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <FormFlowList />

    </Box>
  );
};

export default CustomFormFlow 