
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect, useState } from 'react';
import { Box, Fab, Stack } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import AddIcon from '@mui/icons-material/Add';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { UserInformationProviderContext } from '../context/user-information-provider';
import { ImageUploadDialog } from './dialogs/image-upload-dialog';

export const ImageFullSizeDisplay = ({
  images = [],
  primaryImageKey,
  handlePrimaryImageClick,
  handleAddImage,
  handleDeleteImage,
  height = 500,
  autoPlay = false,
  indicators = false,
  canEdit = false,
  fileData,
  setFileData,
  imageCountLimit,
  activateUpload,
  handleUploadImagesClick,
  videoUrl
}) => {

  const [carouselHovered, setCarouselHovered] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const [imageUploadDialogActive, setImageUploadDialogActive] = useState(false);
  const [displayArray, setDisplayArray] = useState([])

  const userInformationContext = useContext(UserInformationProviderContext);

  // If the image count increases, close the add image dialog.
  useEffect(() => {
    console.log(!!videoUrl)
    setImageUploadDialogActive(false);

    if (activeImage > images.length + (videoUrl ? 1 : 0) - 1) {
      setActiveImage(0);
    } else {
      setActiveImage(images.length + (videoUrl ? 1 : 0) - 1)
      setActiveImage(0);
    }

    const imagesArray = images.length > 0 && images.filter(image => !videoUrl || image.key !== 'd65a3a36-6eef-4603-a4ed-9e35ef3b38c2').map((projectImage, index) => (
      <img
        key={uuidv4()}
        src={projectImage.location}
        alt='Lead landscape'
        loading="lazy"
        style={{
          height: `${height}px`,
          width: "100%",
          objectFit: "cover"
        }}
      />
    ))

    if (!!videoUrl) {
      imagesArray.unshift(<div class="container">
        <div class="h_iframe">
          <iframe
            style={{ position: 'absolute', top: 0, left: '60px', width: 'calc(100% - 120px)', height: 'calc(100% - 24px)' }}
            src={videoUrl}
            title="Video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>

          </iframe>
        </div>
      </div>)
    }

   setDisplayArray(imagesArray)

  }, [images])

  useEffect(() => {
    setImageUploadDialogActive(activateUpload);
  }, [activateUpload])

  /**
   * If the primary image key changes, set the index to 0, the loaction of the updated primary image.
   */
  useEffect(() => {
    setActiveImage(0);
  }, [primaryImageKey])

  const handleDeleteClick = (imageKey) => {
    userInformationContext.openDialog({
      active: true,
      title: 'Delete image',
      message: 'Are you sure you want to delete this image?'
    })
      .then(response => {
        if (response) {
          handleDeleteImage(imageKey)
        }
      })
  }

  const addImageClick = () => {
    setImageUploadDialogActive(true)
  }

  const cancelImageUpload = () => {
    setImageUploadDialogActive(false);
    handleUploadImagesClick();

    // Ensure the dialog closes before removing the fileData, so the size does not jump.
    setTimeout(() => {
      setFileData([]);
    }, 400);
  }

  

  return (
    <Box
      height={height}
      sx={{ position: 'relative' }}
      onMouseOver={() => setCarouselHovered(true)}
      onMouseOut={() => setCarouselHovered(false)}
    >
      <Carousel
        height={height}
        autoPlay={autoPlay}
        indicators={indicators && displayArray.length > 1}
        navButtonsAlwaysInvisible={displayArray.length < 2}
        navButtonsAlwaysVisible={displayArray.length > 1}
        sx={{
          borderRadius: '10px 10px 0px 0px'
        }}
        index={activeImage}
        onChange={(index) => setActiveImage(index)}
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            position: 'absolute',
            bottom: '5px'
          }
        }}
      >
      
        {/* {images.length > 0 && !!videoUrl && <div class="container">
            <div class="h_iframe">
              <iframe
                style={{ position: 'absolute', top: 0, left: '60px', width: 'calc(100% - 120px)', height: 'calc(100% - 24px)' }}
                src={videoUrl}
                title="Video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen>

              </iframe>
            </div>
          </div>} */}
          {/* {images.length > 0 && images.map((projectImage, index) => (
            <img
              key={uuidv4()}
              src={projectImage.location}
              alt='Lead landscape'
              loading="lazy"
              style={{
                height: `${height}px`,
                width: "100%",
                objectFit: "cover"
              }}
            />
          ))} */}
          {displayArray}
      
       
      </Carousel>
      {carouselHovered && canEdit && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10
          }}
        >

          {(!videoUrl || activeImage !== 0) && handlePrimaryImageClick && (images[activeImage - videoUrl ? 1:0].key) !== 'd65a3a36-6eef-4603-a4ed-9e35ef3b38c2' && (images[activeImage- videoUrl ? 1:0].key) !== primaryImageKey && < Fab
            color="default"
            aria-label="add"
            size="small"
            onClick={() => handlePrimaryImageClick(images[activeImage - videoUrl ? 1:0].key)}
          >
            {(images[activeImage - videoUrl ? 1:0].key === primaryImageKey) ? <StarIcon /> : <StarBorderIcon />}
          </Fab>}

          {fileData && <Fab
            color="default"
            aria-label="add"
            size="small"
            onClick={() => addImageClick()}
          >
            <AddIcon />
          </Fab>}

          {(!videoUrl || activeImage !== 0) && handleDeleteImage && images[0]?.key !== 'd65a3a36-6eef-4603-a4ed-9e35ef3b38c2' && <Fab
            color="default"
            aria-label="delete"
            size="small"
            onClick={() => handleDeleteClick(images[activeImage - videoUrl ? 1:0].key)}
          >
            <DeleteIcon />
          </Fab>}
        </Stack>
      )
      }

      <ImageUploadDialog
        fileData={fileData}
        setFileData={setFileData}
        imageUploadDialogActive={imageUploadDialogActive}
        cancelImageUpload={cancelImageUpload}
        completeImageUpload={handleAddImage}
        imageCountLimit={imageCountLimit ? imageCountLimit - images.length : undefined}
      />
    </Box >
  )
}
