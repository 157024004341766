
import { Alert, Box, Card, CardActionArea, Chip, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import IconPicker from '../../../shared/components/icon-picker';
import moment from 'moment';
import AdminProjectService from '../../services/admin-project-service';
import gaiaTheme from '../../../theme';

const ApprovalsBngProjectsList = () => {
  const adminBngProjectService = new AdminProjectService();
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const [bngProjects, setBngProjects] = useState([]);

  const getBngProjects = () => {
    adminBngProjectService.getAllBngProjectsPendingReview()
      .then((response) => {
        setBngProjects(response);
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    getBngProjects();
  }, [])

  return (
    <Box>
      {bngProjects && bngProjects.map((project, index) => {
        return <Card
          sx={{ alignItems: 'center', justifyContent: 'center', mb: 1, borderRadius: '10px' }}
          key={index}
          variant='outlined'
        >
          <CardActionArea
            sx={{ p: 2, }}
            component={Link}
            to={`/admin/bng-project/${project.p_id}`}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0, pt: isTablet ? 4 : 0 }}>
                <ListItemIcon>
                  <IconPicker icon="pause" color='info' size='medium' />
                </ListItemIcon>
                <ListItemText
                  primary={project.p_summary}
                  secondary={<>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Created at:&nbsp;
                    </Typography>
                    {moment(project.created_at).format('DD/MM/yyyy')}
                    <Typography
                      sx={{ display: 'inline', ml: 2 }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Approval requested:&nbsp;
                    </Typography>
                    {moment(project?.date_submitted_for_approval).format('DD/MM/yyyy')}
                  </>
                  }
                />
                <Box sx={isTablet ? { position: 'absolute', top: 1, right: 1 } : { minWidth: '217px' }}>
                  <Chip
                    edge="end"
                    label={project?.owner?.name}
                    variant='outlined'
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    edge="end"
                    label={project.admin_status.status_name}
                    variant='outlined'
                    color={project.admin_status.status_code === 'DRAFT' ? 'info' : 'primary'}
                  />

                </Box>

              </ListItem>
            </List>
          </CardActionArea>
        </Card>
      })}
      {bngProjects.length === 0 &&
        <Alert severity="info" sx={{ mb: 4, p: 2 }}>
          There are currently no investment projects awaiting approval.
        </Alert>
      }
    </Box>
  );
};

export default ApprovalsBngProjectsList
