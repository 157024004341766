import { Box, Chip, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CircularProgressBar } from './presentation/circular-progress-bar';

export const GaiaPlusProjectList = ({ projects, isAdmin, isEcologist }) => {

  const navigate = useNavigate();

  const goToProject = (project) => {
    if (isAdmin) {
      navigate(`/admin/gaia-plus/project/${project.id}`)
    } else if (isEcologist) {
      navigate(`/ecologist/gaia-plus/project/${project.id}`)
    } else {
      navigate(`/gaia-plus/${project.id}`)
    }
  }

  const getFriendlyStatusName = (statusCode) => {
    let formatted = statusCode.split('_').join(' ').toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  return (
    <>
      {/* <Box sx={{ margin: 2 }}>
        <Paper sx={{ padding: 4 }}> */}

      <Table>
        <TableHead>
          <TableRow sx={{ fontWeight: 600 }}>
            <TableCell sx={{ fontWeight: 600 }}>Project name</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Ecologist</TableCell>
            {isAdmin && <TableCell sx={{ fontWeight: 600 }}>User</TableCell>}
            <TableCell sx={{ fontWeight: 600 }}>Date created</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Completion percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.map((project, index) => (
            project ? (
              <TableRow key={project.id} onClick={() => goToProject(project)} hover={true}>
                <TableCell>{project.name}</TableCell>
                <TableCell>{project.ecologist.name}</TableCell>
                {isAdmin && <TableCell>{project.user.name}</TableCell>}
                <TableCell>{project?.created_at ? moment(project?.created_at).format('DD/MM/yyyy') : 'Unknown'}</TableCell>
                <TableCell>
                  <Chip
                    label={getFriendlyStatusName(project.status)}
                    variant="outlined"
                    sx={{
                      justifyContent: "center",
                      color: "primary",
                      borderColor: "primary",
                      textTransform: 'capitalize'
                    }}
                  />
                </TableCell>
                <TableCell>
                  <CircularProgressBar value={project.percentageComplete} />
                </TableCell>

              </TableRow>
            ) : (
              <TableRow key={index}>
                <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              </TableRow>
            )))
          }
        </TableBody>
      </Table>
      {/* </Paper>
      </Box> */}
    </>
  )
}