import { Box, Button, Divider, Stack, Switch, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DisplayForm from '../../../components/config/modules/display-form';
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import HubAdminService from '../../../services/hub-admin-service';
import httpClient from '../../../../shared/http/http-client';
import { HubDetailsContext } from '../../../../shared/context/hub-details';

const ConfigMarketplaceModule = () => {

  const hubAdminService = new HubAdminService();

  const location = useLocation();
  const state = location.state;
  const hubDetailsContext = useContext(HubDetailsContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [tile, setTile] = useState(state ? state.tile : null);
  const [moduleBrandingConfig, setModuleBrandingConfig] = useState({});

  const handleSaveClick = () => {
    hubAdminService.updateHubModuleStyle(tile.module_style[0]?.id, moduleBrandingConfig)
      .then(() => {
        setIsEditMode(false)
      })
  }

  // Set the required branding config data.
  const setBrandingConfigData = (formControl, value) => {

    const branding = moduleBrandingConfig;
    branding[formControl] = value;
    setModuleBrandingConfig({ ...branding });
  }

  // Toggle the active tile for the hub user.
  const toggleActiveTile = (tileId) => {
    hubAdminService.toggleTileActive(tileId)
      .then((response) => {
        getAvailableTiles();
        hubDetailsContext.setChange(hubDetailsContext.change + 1)

      })
  }

  function getAvailableTiles() {
    httpClient.get('admin/hub/available-tiles')
      .then(response => {
        setTile(response.data.filter((a) => a.tile_code === tile.tile_code)[0]);
      })
  }

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const styleConfig = {
      title_copy: tile.module_style[0]?.title_copy,
      body_copy: tile.module_style[0]?.body_copy,
      banner_image_url: tile.module_style[0]?.banner_image_url
    }
    setModuleBrandingConfig(styleConfig);
  }, [tile])

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          <Link
            underline="hover"
            color="text.primary"
            to="/admin/configuration/modules"
            style={{ color: 'black' }}
          >
            Modules
          </Link> &nbsp;/&nbsp;  Marketplace
        </Typography>
        <Switch
          onClick={() => toggleActiveTile(tile.id)}
          checked={tile.is_activated_by_hub}
          sx={{ mr: 1 }}
        />
        {isEditMode ?
          <Button
            variant='contained'
            disableElevation
            className="removeUppercase"
            startIcon={<SaveOutlinedIcon />}
            onClick={() => handleSaveClick()}
            sx={{ minWidth: '90px' }}
          >
            Save
          </Button> :
          <Button
            variant='outlined'
            disableElevation
            className="removeUppercase"
            startIcon={<EditIcon />}
            onClick={() => setIsEditMode(true)}
            sx={{ minWidth: '90px' }}
          >
            Edit
          </Button>
        }
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <DisplayForm
        tile={tile}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        handleSaveClick={handleSaveClick}
        setBrandingConfigData={setBrandingConfigData}
        moduleBrandingConfig={moduleBrandingConfig}
        setModuleBrandingConfig={setModuleBrandingConfig}
      />
    </Box>
  );
};

export default ConfigMarketplaceModule