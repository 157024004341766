/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Card, Chip, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import AdminFormService from '../../services/admin-form-service';
import { EditRounded } from '@mui/icons-material';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import AdminFormFlowService from '../../services/admin-form-flow-service';


const ViewCustomFormFlow = (props) => {

  const params = useParams();
  const adminFormService = AdminFormService.instance;
  const adminFormFlowService = new AdminFormFlowService();

  const [formFlow, setFormFlow] = useState();
  const [formableObjects, setFormableObjects] = useState();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getFlow();
    getFormableObjectList();
  }, []);

  const getFormableObjectList = () => {
    adminFormService.getFormableObjectList()
      .then(response => {
        setFormableObjects(response);
      })
  }

  const publishForm = () => {
    adminFormFlowService.publishFormFlow(formFlow.id, formFlow.formable_object_code)
      .then(response => {
        setFormFlow(response);
      })
  }

  const unPublishForm = () => {
    adminFormFlowService.unPublishFormFlow(formFlow.id)
      .then(response => {
        setFormFlow(response);
      })
  }

  /**
   * Submit the completed form the the BE.
   */
  const getFlow = () => {
    adminFormFlowService.getFormFlow(params.formFlowId)
      .then(response => {
        setFormFlow(response);
      })
  }

  return (<Box sx={{ p: 2 }}>

    {formFlow && <Stack direction='row' sx={{ alignItems: 'center', marginBottom: 3 }}>
      <Stack direction='row' spacing={2}>
        <Typography color="text.primary" variant="h5" sx={{ fontWeight: 300 }}>Workflows / {formableObjects.find(obj => obj.code === formFlow.formable_object_code)?.name}</Typography>
        <Chip
          edge="end"
          label={formFlow.status.toLowerCase()}
          variant='outlined'
          color={formFlow.status === 'DRAFT' ? 'info' : 'primary'}
          sx={{ textTransform: 'capitalize' }}
        />
      </Stack>

      <Stack direction='row' spacing={2} sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
        <Button
          component={Link}
          to={`/admin/configuration/form-flow/edit/${formFlow.id}`}
          color='primary'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<EditRounded />}
          sx={{ textTransform: 'capitalize' }}
        >
          Edit
        </Button>
        {/* {formFlow && formFlow.status === 'DRAFT' && <FormControl sx={{ width: '300px', mr: 2 }} size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Publish form to...</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedFormableObject || ''}
            label="Publish form to..."
            onChange={handleFormableObjectSelection}
          >
            {formableObjects && formableObjects.map((formableObject, index) => {
              return <MenuItem key={index} value={formableObject.code}>{formableObject.name}</MenuItem>
            })}
          </Select>
        </FormControl>} */}

        {formFlow && formFlow.status === 'DRAFT' && <Button
          onClick={() => publishForm()}
          color='primary'
          variant="contained"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<PublishOutlinedIcon />}
          sx={{ flex: 1 }}
        >
          Publish
        </Button>}

        {formFlow && formFlow.status === 'PUBLISHED' && <Button
          onClick={() => unPublishForm()}
          color='error'
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          startIcon={<UnpublishedOutlinedIcon />}
        >
          Unpublish
        </Button>}
      </Stack>
    </Stack>
    }
    <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />

    {formFlow && <>
      <Box sx={{ mb: 3 }}>

        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end', mb: 2 }}>
          <Chip
            edge="end"
            label={`${formFlow.forms.length} forms`}
            variant='outlined'
            color='info'
            sx={{ textTransform: 'capitalize', mr: 1 }}
          />

          <Chip
            edge="end"
            label={`${formFlow.forms.reduce((total, form) => total + form.informationQuestions.length, 0)} questions`}
            variant='outlined'
            color='info'
            sx={{ textTransform: 'capitalize' }}
          />
        </Stack>

        {formFlow.forms.map((form, index) => {
          return <Box key={index} sx={{ mb: 1 }}>
            <Card
              sx={{ alignItems: 'center', justifyContent: 'center' }}
              key={index}
              variant='outlined'
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'primary.light' }}>
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={form.form_name}
                  // secondary={<>
                  //   <Typography
                  //     sx={{ display: 'inline', mr: 1 }}
                  //     component="span"
                  //     variant="body2"
                  //     color="text.primary"
                  //   >
                  //     Created at:
                  //   </Typography>
                  //   {moment(form.created_at).format('DD/MM/yyyy')}
                  //   <Typography
                  //     sx={{ display: 'inline', mr: 1, ml: 2 }}
                  //     component="span"
                  //     variant="body2"
                  //     color="text.primary"
                  //   >
                  //     Updated at:
                  //   </Typography>
                  //   {moment(form.updated_at).format('DD/MM/yyyy')}
                  // </>
                  // }
                  />
                </ListItem>
              </List>
            </Card>
          </Box>
        })}
      </Box>
    </>}
  </Box >
  );
};

export default ViewCustomFormFlow 