import React, { useEffect, useState } from "react";
import { Alert, Typography, Box, Paper, Grid, Toolbar, Container, Divider } from "@mui/material";
import CreateInvestmentProjectForm from '../components/investment-projects/create-investment-project-form';
import QuestionForm from '../../shared/components/question-form';
import { useNavigate, useParams } from 'react-router-dom';
import SellerInvestmentProjectService from '../services/seller-investment-project-service';
import { CustomTabPanel } from '../../shared/components/presentation/custom-tab-panel';
import { ContactButton } from '../../shared/components/buttons/contact-button';

const CreateInvestmentProject = () => {
  const sellerInvestmentProjectService = new SellerInvestmentProjectService();

  const [answerFormId, setAnswerFormId] = useState();
  const [errors, setErrors] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [investmentProjectId, setInvestmentProjectId] = useState();
  const [investmentProject, setInvestmentProject] = useState();
  const [formDescription, setFormDescription] = useState();
  const [forceForwardRoute, setForceForwardRoute] = useState();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.investmentProjectId) {
      setInvestmentProjectId(params.investmentProjectId);
      sellerInvestmentProjectService.getInvestmentProject(params.investmentProjectId)
        .then(response => {
          if (response.answer_forms.length > 0) {

            if (params.answerFormId) {
              setAnswerFormId(params.answerFormId);
              setForceForwardRoute(`/investment-project/${params.investmentProjectId}`)
            } else {
              setAnswerFormId(response.answer_forms.find(form => !form.previous_answer_form_id).id);
            }
            setInvestmentProject(response)
          }
          setActiveStep(1);
        })
    }
  }, [params.investmentProjectId])


  const initQuestions = (returnedAnswerFormId, investmentProjectId) => {
    if (investmentProjectId) {
      setInvestmentProjectId(investmentProjectId);
      navigate(`/investment-project/questions/${investmentProjectId}`, { shallow: true });
      setAnswerFormId(returnedAnswerFormId);
    }
  }

  const handleFormCreated = (returnedAnswerFormId, investmentProjectId) => {
    initQuestions(returnedAnswerFormId, investmentProjectId);
    setActiveStep(1);
  }

  useEffect(() => {
    window.analytics.page();
  }, [])

  return (
    <>
      <Toolbar />
      <Container maxWidth="mdxl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '100%',
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Box sx={{ width: '100%', p: 2 }}>
                <Grid container spacing={2} sx={{ pl: 2, pr: 2, mb: 0 }}>
                  {errors.length > 0 && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px", mb: "10px" }}>
                    <Alert severity="error">{errors[0]}</Alert>
                  </Grid>}
                </Grid>
                <CustomTabPanel value={activeStep} index={0}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" sx={{ pt: 1 }}>
                      New Project for Funding
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Create a new project to raise funding for a nature project
                    </Typography>
                  </Grid>
                  {!answerFormId && <CreateInvestmentProjectForm formCreated={handleFormCreated} setInvestmentProject={setInvestmentProject} />}
                </CustomTabPanel>
                <CustomTabPanel value={activeStep} index={1}>
                  {answerFormId && investmentProjectId && <QuestionForm
                    answerFormId={answerFormId}
                    forwardRoute={`/investment-project/${investmentProjectId}`}
                    forceForwardRoute={forceForwardRoute}
                    haveProgressBar={true}
                    setFormDescription={setFormDescription} />}
                </CustomTabPanel>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 6,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '100%',
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Guidance
                </Typography>
                {!answerFormId && <Typography variant="p" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                  To list a project for investment, we require you to provide us with some information about the project.<br /><br /> If your project meets our eligibility criteria then it will be approved to be listed on the platform.
                </Typography>}
                <Typography variant="p" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                  {formDescription}
                </Typography>
              </>
              <Divider sx={{ mt: 4, mb: 4 }} />
              <ContactButton />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateInvestmentProject