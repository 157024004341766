import httpClient from "../http/http-client";

export default class ContactUsService {
  http;

  constructor() {
    this.http = httpClient
  }

  async submitForm(details) {
    return this.http.post('hub/contact', { details: details })
      .then(response => {
        return response.data
      });
  }
}