import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Toolbar, Typography, Box, Container, Grid, Card, CardActionArea, Stack, Button, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ProjectCarousel from '../components/project-carousel';
import { Add, East } from '@mui/icons-material';
import { HubDetailsContext } from '../../shared/context/hub-details';
import gaiaTheme from '../../theme';
import YoutubeEmbed from '../../shared/components/youtube-embed';
import InvestmentProjectService from '../services/investment-project-service';

const HubHomePage = () => {

  const hub = useContext(HubDetailsContext);

  const [buttons, setButtons] = useState([
    { title_copy: 'I am a Seller', body_copy: 'I have a natural capital project in Cornwall that I would like to market', icon_path: 'investors_icon.png', url: '/hub/seller', color: 'rgb(66, 139, 56)' },
    { title_copy: 'I am a Buyer', body_copy: 'I would like to invest or fund natural capital projects or purchase BNG Units', icon_path: 'buyers_icon.png', url: '/hub/buyer', color: 'rgb(51, 111, 159)' }
  ]);
  const [images, setImages] = useState(['/cornwall/estuaries_simon_jay_photography.jpg', '/cornwall/minack_theatre_simon_jay_photography.jpg', '/cornwall/polperro_martyn_selley.jpg', '/cornwall/cornish_flag.jpg']);
  const [activeImage, setActiveImage] = useState(0);
  const [investmentProjects, setInvestmentProjects] = useState([]);

  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const investmentProjectService = new InvestmentProjectService()

  const navigate = useNavigate();

  const handleClick = (button) => {
    navigate(button.url);
  }

  const getInvestmentProjects = async () => {
    let params = new URLSearchParams();
    params.append('page', 0);
    params.append('size', 18);



    investmentProjectService.getProjects(params.toString())
      .then(response => {
        setInvestmentProjects(response.investmentProjects);
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  useEffect(() => {
    getInvestmentProjects();
  }, []);

  return (<>
    <Toolbar />
    <Carousel
      height='45vh'
      autoPlay={true}
      animation='flash'
      duration='1000'
      indicators={false}
      index={activeImage}
      onChange={(index) => setActiveImage(index)}
      swipe
      interval={5000}

    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt='Cornish landscape'
          //loading="lazy"
          style={{
            height: '45vh',
            width: "100%",
            objectFit: "cover",
            backgroundPosition: "center center",
            // backgroundRepeat: "no-repeat"
          }}
        />
      ))}
    </Carousel>
    <Box sx={{ position: 'absolute', zIndex: 2, height: '45vh', width: '100%', top: isMobileMode ? '56px' : '64px', backgroundColor: 'rgba(0,0,0,0.15)' }}>
      <Container maxWidth="lg" >
        <Grid container style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{
              padding: isMobileMode ? 0 : 6,
              //pl: isMobileMode ? 6 : 0
              pl: 0
            }}>
              <Typography
                variant="h3"
                component="h1"
                textAlign={isMobileMode ? 'center' : 'left'}
                sx={{ fontWeight: 700, color: '#fff', mb: 2, textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}
              >
                {hub.hubDetails.hub_styles.title_copy}
              </Typography>
              <Typography
                variant="h6"
                textAlign={isMobileMode ? 'center' : 'left'}
                sx={{ color: '#fff', textShadow: '1px 1px 3px rgb(0,0,0,58%)' }}
              >
                {hub.hubDetails.hub_styles.body_copy}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Grid container spacing={4}>
          {buttons && buttons.map((button, index) => {
            return <Grid key={index} item xs={12} md={6} lg={6} >
              <Card elevation={0} sx={{ borderRadius: '10px', backgroundColor: button.color, color: '#fff' }}>
                <CardActionArea
                  onClick={() => handleClick(button)}
                  sx={{ p: 6, minHeight: '325px' }}
                >
                  <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ minHeight: '75px' }}>
                      <img src={`/cornwall/icons/${button.icon_path}`} style={{ width: "60px", textAlign: 'left' }} alt='button icon' />
                    </Box>

                    <Grid item xs={12} md={12} lg={12}>
                      <Typography
                        variant="h4"
                        sx={{ textAlign: 'center', pb: 3, pt: 3, fontWeight: 700, fontFamily: 'Fira sans bold' }}
                      >
                        <b>{button.title_copy}</b>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: 'center', fontFamily: 'Fira sans' }}
                      >
                        {button.body_copy}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          })}
        </Grid>
      </Container>
    </Box>
    <Box sx={{ pt: 6, pb: 2 }}>
      <Container maxWidth="lg">
        <YoutubeEmbed embedId="HrdR9g7XzMs" />
      </Container>
    </Box>
    <Box sx={{ justifyContent: 'center', pt: 8, pb: 2 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 700 }}>
          Browse natural capital projects seeking funding in Cornwall
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 2 }} color="text.secondary">
          Find a project to fund, natural capital asset to purchase or list a project or your own
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} sx={{ justifyContent: 'center', mt: 4, mb: 8 }} spacing={2}>
          <Button
            disableElevation
            startIcon={<Add />}
            size='large'
            variant='outlined'
            className='removeUppercase'
            sx={{ fontSize: '18px', borderRadius: '30px' }}
            component={Link}
            to='/hub/seller'
          >
            List a project
          </Button>
          <Button
            disableElevation
            endIcon={<East />}
            size='large'
            variant='outlined'
            className='removeUppercase'
            sx={{
              fontSize: '18px',
              borderColor: 'rgb(51, 111, 159)',
              color: 'rgb(51, 111, 159)',
              borderRadius: '30px',
              "&:hover": {
                backgroundColor: `rgba(51, 111, 159,0.1)`,
                borderColor: 'rgb(51, 111, 159)',
              }
            }}
            component={Link}
            to='/hub/buyer'
          >
            Browse projects
          </Button>
        </Stack>
        {investmentProjects?.length > 0 &&
          <ProjectCarousel investmentProjects={investmentProjects} />
        }
      </Container>
    </Box>
  </>
  );
};

export default HubHomePage