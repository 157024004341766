import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Stack, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemIcon, Divider, Button } from '@mui/material';
import IconPicker from "../../../../shared/components/icon-picker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DisplayForm = (props) => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Accordion
        key={1}
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
        elevation={0}
        square
        sx={{
          border: `1px solid rgba(0, 0, 0, 0.12)`,
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&::before': {
            display: 'none',
          },
          borderRadius: '5px'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <List sx={{ p: 0 }}>
            <ListItem sx={{ p: 0 }}>
              <ListItemIcon>
                <IconPicker icon="palette" />
              </ListItemIcon>
              <ListItemText primary="Display" secondary="Configure the appearance of the module." />
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px', pb: '30px' }}>
            <Grid item xs={12} md={3} lg={4}>
              <Typography variant="subtitle1" color="text.primary">
                Title:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              {props.isEditMode ?
                <TextField
                  id="title"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('title_copy', e.target.value)}
                  value={props.moduleBrandingConfig?.title_copy}
                  sx={{ width: '100%' }}
                />
                :
                <Typography variant="subtitle1" color="text.secondary">
                  {props.moduleBrandingConfig?.title_copy}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} md={3} lg={4}>
              <Typography variant="subtitle1" color="text.primary">
                Subtitle:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              {props.isEditMode ?
                <TextField
                  id="subtitle"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('body_copy', e.target.value)}
                  value={props.moduleBrandingConfig?.body_copy}
                  sx={{ width: '100%' }}
                />
                :
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    wordBreak: 'break-all',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {props.moduleBrandingConfig?.body_copy}
                </Typography>
              }
            </Grid>
            <Grid item xs={12} md={3} lg={4}>
              <Typography variant="subtitle1" color="text.primary">
                Background image URL:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              {props.isEditMode ?
                <TextField
                  id="background-image-url"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('banner_image_url', e.target.value)}
                  value={props.moduleBrandingConfig?.banner_image_url}
                  sx={{ width: '100%' }}
                />
                :
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    wordBreak: 'break-all',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {props.moduleBrandingConfig?.banner_image_url}
                </Typography>
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default DisplayForm 