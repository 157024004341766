import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Edit, Save } from '@mui/icons-material';
import { HtmlParserDisplay } from './html-parser-display/html-parser-display';
import ReactQuill from 'react-quill';
import AdminProjectService from '../../admin/services/admin-project-service';

export const BngProjectDisplayNotes = ({ project, isAdmin, isSeller }) => {

  const adminProjectService = new AdminProjectService();

  const [isEditing, setIsEditing] = useState(false);
  const [adminDisplayNotes, setAdminDisplayNotes] = useState();

  const toggleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      adminProjectService.editProject(project.p_id, { admin_display_notes: adminDisplayNotes })
        .then(() => {
          setIsEditing(false)
        })
    }
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  const handleUpdateNotes = (notes) => {
    if (notes === '<p><br></p>') {
      setAdminDisplayNotes('')
    } else {
      setAdminDisplayNotes(notes);

    }
  }

  useEffect(() => {
    if (project?.admin_display_notes) {
      setAdminDisplayNotes(project.admin_display_notes);
    }

  }, [project])

  return (<>
    {project && <>
      {isAdmin && <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
        <Typography variant="h6" color="text.primary">
          <b>Public Listing:</b>
        </Typography>
        <Button
          variant={isEditing ? 'contained' : 'outlined'}
          color={isEditing ? 'primary' : 'info'}
          size="large"
          disableElevation
          className='removeUppercase'
          startIcon={isEditing ? <Save /> : <Edit />}
          onClick={() => toggleEdit()}
        //disabled={!canSendEnquiry}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Stack>}

      {/* {isSeller && <Typography variant="h6" color="text.secondary">
        <b>Admin notes added:</b>
      </Typography>} */}

      {isEditing && <Box sx={{ mt: 3, mb: 10 }}>
        <ReactQuill
          formats={formats}
          modules={modules}
          theme="snow"
          style={{ height: '400px', marginBottom: '24px' }}
          placeholder='Please enter the article content'
          value={adminDisplayNotes || ''}
          onChange={e => handleUpdateNotes(e)}
        />
      </Box>}

      {!adminDisplayNotes && (isAdmin || isSeller) && !isEditing && <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        <b><i>No public information added</i></b>
      </Typography>}
      {adminDisplayNotes && !isEditing && <Box sx={{ mt: 4 }}>
        <HtmlParserDisplay html={adminDisplayNotes}></HtmlParserDisplay>
      </Box>
      }
    </>
    }
  </>)
}