import * as React from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DropDownMenu from '../../shared/components/presentation/dropdown-menu';
import { AddCircleOutlineRounded, Autorenew, ChangeCircleOutlined, EditNote } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { HubDetailsContext } from '../../shared/context/hub-details';

const ActionsMenu = (props) => {
  //const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(props.anchorEl);
  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const navigate = useNavigate();
  const hub = React.useContext(HubDetailsContext);

  const goToQuestionPage = () => {
    navigate(`/project/questions/${props.projectId}`)
  }

  return (
    <div>
      <Button
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={e => props.setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        className="removeUppercase"
        sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
      >
        Actions
      </Button>
      <DropDownMenu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={props.anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => props.handleEditClick()}
          disableRipple
        >
          <EditIcon />
          {props.isPublished ? 'Set to edit' : props.isInReview ? 'Withdraw from review' : 'Edit project overview'}
        </MenuItem>
        {/* {!props.isInReview && !props.isPublished && <MenuItem
          onClick={() => goToQuestionPage()}
          disableRipple
        >
          <EditNote />
          Edit application answers
        </MenuItem>} */}
        {props.shallowStatus?.status_code !== 'PAUSED' && props.isPublished && (
          <MenuItem
            onClick={() => props.handlePauseClick()}
            disableRipple
          >
            <PauseIcon />
            Pause listing
          </MenuItem>
        )}
        {props.shallowStatus?.status_code === 'PAUSED' && props.isPublished && (
          <MenuItem
            onClick={() => props.handleResumeClick()}
            disableRipple
          >
            <PlayArrowIcon />
            Resume listing
          </MenuItem>
        )}

        {!props.isPublished && !props.isInReview && <MenuItem
          onClick={() => props.handleAddNewHabitat()}
          disableRipple
        >
          <AddCircleOutlineRounded />
          Add a new habitat
        </MenuItem>}
        {hub.hubDetails.main_hub && <MenuItem
          onClick={() => props.handleChangeShallowStatus()}
          disableRipple
        >
          <Autorenew />
          Change status
        </MenuItem>}
        <Divider />
        <MenuItem
          onClick={() => props.handleCloseProject()}
          disableRipple
        >
          <DeleteIcon />
          Close listing
        </MenuItem>
        {/* <MenuItem
          onClick={() => props.handleDeleteClick()}
          disableRipple
        >
          <DeleteIcon />
          Delete listing
        </MenuItem> */}
      </DropDownMenu>
    </div>
  );
}

export default ActionsMenu