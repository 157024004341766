import React, { useContext, useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import QuestionForm from '../components/question-form';
import { UserDetailsContext } from '../context/user-details';
import { useNavigate } from 'react-router-dom';

const UserSignUpQuestions = (props) => {

  const [answerFormId, setAnswerFormId] = useState();

  const userContext = useContext(UserDetailsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.userDetails) {
      setAnswerFormId(userContext.userDetails.userInformationRequired)
    }
  }, [userContext]);

  useEffect(() => {
    window.analytics.page();
  }, []);

  const questionsComplete = () => {
    userContext.forceUpdateUserDetails()
      .then(() => {
        navigate('/dashboard')
      })
  }

  return (
    <>
      <Toolbar />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: 'calc(100vh - 130px)'
              }}
            >
              {answerFormId && <QuestionForm answerFormId={answerFormId} formattedAnswers={questionsComplete} haveProgressBar={true}></QuestionForm>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UserSignUpQuestions