import httpClient from '../http/http-client';

export default class QuestionAnswerService {
  http;

  constructor() {
    this.http = httpClient
  }

  async saveAnswers(answerFormId, answerDetails) {
    return this.http.post(`site-user/question/save-answers/${answerFormId}`, { answers: answerDetails })
      .then(response => {
        return response.data;
      })
  }
}