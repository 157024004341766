import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';

const RequestUnitsDialog = (
    props
  ) => {
    return (
      <Dialog open={props.requestUnitsDialogActive} onClose={props.showRequestUnitsDialog} maxWidth='md' fullWidth>
        <DialogTitle sx={{pb:'5px'}}>Request Units</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{pb:'10px'}}>
            Submit a request to the land managers for units not currently listed on Gaia.
          </DialogContentText>
          {props.requestSubmitted && (
            <Alert p={2} severity="success" sx={{mt:2}}>Your request has be submitted to land managers and you will be alerted if any units become available.</Alert>
          )}
          {!props.requestSubmitted && (
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth margin='normal'>
                <InputLabel htmlFor="requested-units-title">Requested units title</InputLabel>
                <OutlinedInput
                  id="requested-units-title"
                  fullWidth
                  className="formField"
                  label="Requested units title"
                  type="text"
                  value = {props.saveSearchName}
                  onChange={e => props.setSaveSearchName(e.target.value)}
                />
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'flex-end', mr: 2, mb: 2}}>
          <Button 
            onClick={props.showRequestUnitsDialog}
            className="removeUppercase"
          >
            {props.requestSubmitted ? "Close" : "Cancel"}
          </Button>
          {!props.requestSubmitted && (
            <Button 
              variant="contained"
              color="primary"
              disableElevation 
              onClick={props.handleSubmitRequestUnits}
              startIcon={<SendIcon />}
              className="removeUppercase"
            >
              Request units
            </Button>
          )}
        </DialogActions>
      </Dialog>
  )}

  export default RequestUnitsDialog