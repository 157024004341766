/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Card, CardActionArea, Chip, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import moment from 'moment';
import AdminFormFlowService from '../../services/admin-form-flow-service';
import IconPicker from '../../../shared/components/icon-picker';
import AdminFormService from '../../services/admin-form-service';

const FormFlowList = () => {
  const adminFormFlowService = new AdminFormFlowService();
  const adminFormService = AdminFormService.instance;

  const [formFlows, setFormFlows] = useState();
  const [formableObjects, setFormableObjects] = useState();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getFormFlows();
    adminFormService.getFormableObjectList()
      .then(response => {
        setFormableObjects(response);
      })

  }, []);

  /**
   * Get all formFlows WITHOUT questions.
   */
  const getFormFlows = () => {
    adminFormFlowService.getAllFormFlows()
      .then(response => {
        setFormFlows(response)
      })
  }

  return (
    formFlows && <>
      {formableObjects && formFlows && formableObjects.map((formableObject, index) => {
        return <Box key={index} sx={{ mb: 1 }}>
          <Card
            sx={{ alignItems: 'center', justifyContent: 'center' }}
            key={index}
            variant='outlined'
          >
            <CardActionArea
              sx={{ p: 2 }}
              component={Link}
              // to={`/admin/form-flow/${formFlows.find(flow => flow.formable_object_code === formableObject.code)?.id}` || `/admin/configuration/form-flow/create/${formableObject.id}`}
              to={formFlows.find(flow => flow.formable_object_code === formableObject.code)
                ? `/admin/form-flow/${formFlows.find(flow => flow.formable_object_code === formableObject.code).id}`
                : `/admin/configuration/form-flow/create/${formableObject.id}`}
            >
              <List sx={{ p: 0 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'primary.light' }}>
                      <IconPicker icon="flow" />
                    </Avatar>
                  </ListItemAvatar>
                  {/* <ListItemIcon>
                      <IconPicker icon="flow" />
                    </ListItemIcon> */}
                  <ListItemText
                    primary={formableObject.name}
                    secondary={`Configure the ${formableObject.name} workflow`}
                  />
                </ListItem>
              </List>
            </CardActionArea>
          </Card>
        </Box>

      })}
    </>
  );
};

export default FormFlowList 