/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Card, CardActionArea, Chip, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import AdminFormService from '../../../services/admin-form-service';
import moment from 'moment';
import IconPicker from '../../../../shared/components/icon-picker';

const CustomFormList = () => {

  const adminFormService = new AdminFormService();

  const [forms, setForms] = useState();

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
    getForm();
  }, []);

  /**
   * Get all forms WITHOUT questions.
   */
  const getForm = () => {
    adminFormService.getAllQuestionForms()
      .then(response => {
        setForms(response)
      })
  }

  return (
    forms?.length > 0 && <>
      {forms && forms.map((form, index) => {
        return <div key={index}>
          {/* <Typography variant="h5" color="text.primary" sx={{ fontWeight: 400, mb: 2 }}>
            {form.name}
          </Typography> */}

          <Box sx={{ mb: 1 }}>

            <Card
              sx={{ alignItems: 'center', justifyContent: 'center' }}
              key={index}
              variant='outlined'
            >
              <CardActionArea
                sx={{ p: 2 }}
                component={Link}
                to={`/admin/custom-forms/${form.id}`}
              >
                <List sx={{ p: 0 }}>
                  <ListItem sx={{ p: 0 }}>
                    {/* <ListItemIcon>
                      <IconPicker icon="form" />
                    </ListItemIcon> */}
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: 'primary.light' }}>
                        <IconPicker icon="form" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<>
                        {form.form_name}
                        <Typography
                          sx={{ display: 'inline', ml: 2, fontStyle: 'italic' }}
                          component="span"
                          variant="body2"
                        >
                          (Version {form.version})
                        </Typography>
                      </>}
                      secondary={<>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Created at:&nbsp;
                        </Typography>
                        {moment(form.created_at).format('DD/MM/yyyy')}
                        <Typography
                          sx={{ display: 'inline', ml: 2 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Updated at:&nbsp;
                        </Typography>
                        {moment(form.updated_at).format('DD/MM/yyyy')}
                      </>
                      }
                    />
                    {/* <Chip
                        edge="end"
                        label={form.status.toLowerCase()}
                        variant='outlined'
                        color={version.status === 'DRAFT' ? 'info' : 'primary'}
                        sx={{ textTransform: 'capitalize' }}
                      /> */}
                  </ListItem>
                </List>
              </CardActionArea>
            </Card>
          </Box>
        </div>
      })}
    </>
  );
};

export default CustomFormList 