import { useEffect, useState } from 'react';

export default function UnGoalsImagePicker({ id }) {

  const [imageName, setImageName] = useState();
  const [images, setImages] = useState([]);

  const UNImages = [
    { id: 1, path: `/un-goals/E-WEB-Goal-01.png` },
    { id: 2, path: `/un-goals/E-WEB-Goal-02.png` },
    { id: 3, path: `/un-goals/E-WEB-Goal-03.png` },
    { id: 4, path: `/un-goals/E-WEB-Goal-04.png` },
    { id: 5, path: `/un-goals/E-WEB-Goal-05.png` },
    { id: 6, path: `/un-goals/E-WEB-Goal-06.png` },
    { id: 7, path: `/un-goals/E-WEB-Goal-07.png` },
    { id: 8, path: `/un-goals/E-WEB-Goal-08.png` },
    { id: 9, path: `/un-goals/E-WEB-Goal-09.png` },
    { id: 10, path: `/un-goals/E-WEB-Goal-10.png` },
    { id: 11, path: `/un-goals/E-WEB-Goal-11.png` },
    { id: 12, path: `/un-goals/E-WEB-Goal-12.png` },
    { id: 13, path: `/un-goals/E-WEB-Goal-13.png` },
    { id: 14, path: `/un-goals/E-WEB-Goal-14.png` },
    { id: 15, path: `/un-goals/E-WEB-Goal-15.png` },
    { id: 16, path: `/un-goals/E-WEB-Goal-16.png` },
    { id: 17, path: `/un-goals/E-WEB-Goal-17.png` },
  ]

  useEffect(() => {
    setImages(UNImages);
  }, [id])

  return (
    <img key={id} alt='UN Goal' src={images.find(image => image.id === parseInt(id))?.path} style={{ width: '100%' }} />
  )
}