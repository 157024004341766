import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, CardActionArea, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip';
import IconPicker from "../icon-picker";

const ArticleCard = ({
  article,
  routeToArticle,
  isAdmin = false
}) => {

  const navigate = useNavigate();

  const [chip, setChip] = useState({ label: "Article", color: "primary", icon: "article" });

  const setupChip = () => {
    let chip = {};
    switch (article.format) {
      case 'pdf':
        chip.label = "PDF"
        chip.color = "info"
        chip.icon = "pdf"
        break;
      case 'article':
        chip.label = "Article"
        chip.color = "primary"
        chip.icon = "article"
        break;
      case 'external_website':
        chip.label = "Website"
        chip.color = "info"
        chip.icon = "link"
        break;
      default:
        chip.label = "Article"
        chip.color = "primary"
        chip.icon = "article"
    }
    setChip(chip);
  }

  const goToArticle = () => {
    if (article.external_url) {
      window.open(article.external_url, '_blank');
    } else {
      navigate(routeToArticle);
    }
  }

  useEffect(() => {
    setupChip();
  }, [])

  return (
    <Card
      sx={{ borderRadius: '10px', height: isAdmin ? 'auto' : '100%', display: 'flex', alignItems: 'stretch' }}
      key={article.id}
      variant='outlined'
    >
      <CardActionArea
        onClick={() => goToArticle()}
        sx={{ alignItems: 'stretch' }}
      >
        <CardMedia
          component="img"
          height="200"
          image={article.header_image?.location || article.og_meta_image_url}
          alt="project"
          key={1}
        //sx={!article.header_image?.location ? { objectFit: 'contain' } : { maxHeight: '305px' }}
        />
        <Stack direction='row' sx={{
          display: 'block',
          position: "absolute",
          top: 10,
          left: 10,
        }}>
          <Chip
            label={chip.label}
            variant="outlined"
            //icon={<ArticleOutlinedIcon fontSize='small' />}
            icon={<IconPicker icon={chip.icon} size='small' />}
            color={chip.color}
            sx={{

              backgroundColor: '#fff',
              p: 1
            }}
          />

          {article.external_url && <Chip
            label='External resource'
            variant="outlined"
            icon={<IconPicker icon='openInNew' size='small' />}
            color='primary'
            sx={{
              backgroundColor: '#fff',
              p: 1,
              ml: 1
            }}
          />}

        </Stack>

        <CardContent sx={{ pt: 3, pb: 3 }}>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {article.title_copy}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'initial',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              minHeight: '50px'
            }}
          >
            {article.subtitle_copy}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
};

export default ArticleCard