import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../shared/components/title';
import { Alert, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import ProjectService from '../services/project-service';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import moment from 'moment';

export default function ProjectsTable() {

  const projectService = new ProjectService();

  const [projectsLoading, setProjectsLoading] = useState(0);
  const [projects, setProjects] = useState([]);
  const [noProjects, setNoProjects] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState('ALL');

  const navigate = useNavigate();

  // const setSelectedRow = (project) => {
  //   navigate(`/project/${project.p_id}`, {
  //     state: { project }
  //   })
  // }

  // get projects

  const getProjects = async () => {
    setProjectsLoading(true);
    projectService.getAllProjects()
      .then(response => {
        if (response.projects.length === 0) {
          setNoProjects(true);
        } else {
          setProjects(response.projects);
        }

        setProjectsLoading(false);
      })
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      {noProjects && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No listings have been created yet.</Alert>
      )}
      {!noProjects && (<>
        <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center', pt: 2 }}>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: '0px !important' }}>
            <Title>Marketplace Listings</Title>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: '0px !important' }}>
            <Stack direction='row' spacing={3} sx={{ justifyContent: "flex-end", ml: "auto", mt: 0, float: "right", alignItems: 'center' }}>
              <Button
                size="medium"
                disableElevation
                startIcon={<AddCircleOutlineIcon />}
                className="removeUppercase"
                sx={{ mb: 1, float: 'right', mr: 0, textTransform: 'none' }}
                onClick={() => navigate('/projects/new')}
              >
                New Listing
              </Button>
              <FormControl size="small" >
                <InputLabel id="project-status-label">Project Status</InputLabel>
                <Select
                  labelId="project-status-label"
                  id="project-status"
                  value={projectStatusFilter}
                  label="Project Status"
                  onChange={(e) => setProjectStatusFilter(e.target.value)}
                  sx={{ minWidth: '200px' }}
                >
                  <MenuItem value={'ALL'}>All Projects</MenuItem>
                  <MenuItem value={'DRAFT'}>Draft</MenuItem>
                  <MenuItem value={'PUBLISHED'}>Published</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow sx={{ fontWeight: 600 }}>
              <TableCell sx={{ fontWeight: 600, pl: 0, width: '40px' }}></TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Local Planning Authority</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Project</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Date created</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Approval Status</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Project Stage</TableCell>
              <TableCell align='right' sx={{ fontWeight: 600 }}>Units</TableCell>
              <TableCell align='right' sx={{ fontWeight: 600 }}>Average Unit Price</TableCell>
              <TableCell align='right' sx={{ fontWeight: 600 }}>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(projectsLoading ? Array.from(new Array(10)) : projects?.filter(proj => (projectStatusFilter === 'ALL') ? proj : proj?.admin_status?.status_code === projectStatusFilter))?.map((project, index) => (
              project ? (
                <TableRow
                  key={project.p_id}
                  //onClick={() => setSelectedRow(project)}
                  component={Link}
                  to={`/project/${project.p_id}`}
                  hover={true}
                >
                  <TableCell>
                    {project?.admin_status?.status_code === 'PUBLISHED' && <TaskAltIcon color='success' sx={{ verticalAlign: 'middle' }} />}
                    {project?.admin_status?.status_code === 'DRAFT' && <PauseCircleOutlineIcon color='info' sx={{ verticalAlign: 'middle' }} />}
                    {project?.admin_status?.status_code !== 'DRAFT' && project?.admin_status?.status_code !== 'PUBLISHED' && <TaskAltIcon sx={{ verticalAlign: 'middle', color: 'text.secondary' }} />}
                  </TableCell>
                  <TableCell>{project.p_local_planning_authority}</TableCell>
                  <TableCell>{project.p_summary}</TableCell>
                  <TableCell>{project?.created_at ? moment(project?.created_at).format('DD/MM/yyyy') : 'Unknown'}</TableCell>

                  <TableCell>
                    <Chip
                      label={project?.admin_status?.status_name}
                      variant="outlined"
                      sx={{
                        justifyContent: "center",
                        color: project?.admin_status?.color || "primary",
                        borderColor: project?.admin_status?.color || "primary",
                        minWidth: '100px',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={project?.shallow_status?.status_name}
                      variant="outlined"
                      sx={{
                        justifyContent: "center",
                        color: project?.shallow_status?.color || "primary",
                        borderColor: project?.shallow_status?.color || "primary",
                        minWidth: '100px',
                      }}
                    />
                  </TableCell>
                  <TableCell align='right'><NumericFormat value={project.totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></TableCell>
                  <TableCell align='right'><NumericFormat value={project.totalPrice / project.totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>
                  <TableCell align='right'><NumericFormat value={project.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} /></TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                </TableRow>
              )))
            }
          </TableBody>
        </Table>
        {/* <Link color="primary" to="/projects" sx={{ mt: 3 }} component={RouterLink} >
          See more listings
        </Link> */}
      </>
      )}
    </>
  );
}
