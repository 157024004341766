export const nationalCharacterAreas = [
    "Arden",
    "Avon Vales",
    "Bedfordshire and Cambridgeshire Claylands",
    "Bedfordshire Greensand Ridge",
    "Berkshire and Marlborough Downs",
    "Black Mountains and Golden Valley",
    "Blackdowns",
    "Blackmore Vale and Vale of Wardour",
    "Bodmin Moor",
    "Border Moors and Forests",
    "Bowland Fells",
    "Bowland Fringe and Pendle Hill",
    "Bristol, Avon Valleys and Ridges",
    "Cannock Chase and Cank Wood",
    "Carnmenellis",
    "Central Lincolnshire Vale",
    "Central North Norfolk",
    "Charnwood",
    "Cheshire Sandstone Ridge",
    "Cheviot Fringe",
    "Cheviots",
    "Chilterns",
    "Clun and North West Herefordshire Hills",
    "Cornish Killas",
    "Cotswolds",
    "Cumbria High Fells",
    "Dark Peak",
    "Dartmoor",
    "Derbyshire Peak Fringe and Lower Derwent",
    "Devon Redlands",
    "Dorset Downs and Cranborne Chase",
    "Dorset Heaths",
    "Dunsmore and Feldon",
    "Durham Coalfield Pennine Fringe",
    "Durham Magnesian Limestone Plateau",
    "East Anglian Chalk",
    "Eden Valley",
    "Exmoor",
    "Forest of Dean and Lower Wye",
    "Greater Thames Estuary",
    "Hampshire Downs",
    "Hensbarrow",
    "Herefordshire Lowlands",
    "Herefordshire Plateau",
    "High Leicestershire",
    "High Weald",
    "Holderness",
    "Howardian Hills",
    "Howgill Fells",
    "Humber Estuary",
    "Humberhead Levels",
    "Inner London",
    "Isle of Porland",
    "Isle of Wight",
    "Isles of Scilly",
    "Kesteven Uplands",
    "Lancashire and Amounderness Plain",
    "Lancashire Coal Measures",
    "Lancashire Valleys",
    "Leicestershire and Nottinghamshire Wolds",
    "Leicestershire and South Derbyshire Coalfield",
    "Leicestershire Vales",
    "Lincolnshire Coast and Marshes",
    "Lincolnshire Wolds",
    "Low Weald",
    "Lundy",
    "Malvern Hills",
    "Manchester Conurbation",
    "Manchester Pennine Fringe",
    "Marshwood and Powerstock Vales",
    "Mease/Sence Lowlands",
    "Melbourne Parklands",
    "Mendip Hills",
    "Mersey Valley",
    "Merseyside Conurbation",
    "Mid Norfolk",
    "Mid Northumberland",
    "Mid Severn Sandstone Plateau",
    "Mid Somerset Hills",
    "Midvale Ridge",
    "Morecambe Bay Limestones",
    "Morecambe Coast and Lune Estuary",
    "Needwood and South Derbyshire Claylands",
    "New Forest",
    "North Downs",
    "North East Norfolk and Flegg",
    "North Kent Plain",
    "North Norfolk Coast",
    "North Northumberland Coastal Plain",
    "North Pennines",
    "North West Norfolk",
    "North York Moors and Cleveland Hills",
    "Northamptonshire Uplands",
    "Northamptonshire Vales",
    "Northern Lincolnshire Edge with Coversands",
    "Northern Thames Basin",
    "Northumberland Sandstone Hills",
    "Nottinghamshire, Derbyshire and Yorkshire Coalfield",
    "Orton Fells",
    "Oswestry Uplands",
    "Pennine Dales Fringe",
    "Pevensey Levels",
    "Potteries and Churnet Valley",
    "Quantock Hills",
    "Rockingham Forest",
    "Romney Marshes",
    "Salisbury Plain and West Wiltshire Downs",
    "Sefton Coast",
    "Severn and Avon Vales",
    "Sherwood",
    "Shropshire Hills",
    "Shropshire, Cheshire and Staffordshire Plain",
    "Solway Basin",
    "Somerset Levels and Moors",
    "South Coast Plain",
    "South Cumbria Low Fells",
    "South Devon",
    "South Downs",
    "South East Northumberland Coastal Plain",
    "South Hampshire Lowlands",
    "South Herefordshire and Over Severn",
    "South Norfolk and High Suffolk Claylands",
    "South Purbeck",
    "South Suffolk and North Essex Clayland",
    "South West Peak",
    "Southern Lincolnshire Edge",
    "Southern Magnesian Limestone",
    "Southern Pennines",
    "Suffolk Coast and Heaths",
    "Tees Lowlands",
    "Teme Valley",
    "Thames Basin Heaths",
    "Thames Basin Lowlands",
    "Thames Valley",
    "The Brecks",
    "The Broads",
    "The Culm",
    "The Fens",
    "The Lizard",
    "Trent and Belvoir Vales",
    "Trent Valley Washlands",
    "Tyne and Wear Lowlands",
    "Tyne Gap and Hadrian's Wall",
    "Upper Thames Clay Vales",
    "Vale of Mowbray",
    "Vale of Pickering",
    "Vale of Taunton and Quantock Fringes",
    "Vale of York",
    "Wealden Greensand",
    "West Cumbria Coastal Plain",
    "West Penwith",
    "Weymouth Lowlands",
    "White Peak",
    "Wirral",
    "Yardley-Whittlewood Ridge",
    "Yeovil Scarplands",
    "Yorkshire Dales",
    "Yorkshire Southern Pennine Fringe",
    "Yorkshire Wolds"
]

export const nationalCharacterAreasAutocomplete = [
    {label: "Arden"},
    {label: "Avon Vales"},
    {label: "Bedfordshire and Cambridgeshire Claylands"},
    {label: "Bedfordshire Greensand Ridge"},
    {label: "Berkshire and Marlborough Downs"},
    {label: "Black Mountains and Golden Valley"},
    {label: "Blackdowns"},
    {label: "Blackmore Vale and Vale of Wardour"},
    {label: "Bodmin Moor"},
    {label: "Border Moors and Forests"},
    {label: "Bowland Fells"},
    {label: "Bowland Fringe and Pendle Hill"},
    {label: "Bristol, Avon Valleys and Ridges"},
    {label: "Cannock Chase and Cank Wood"},
    {label: "Carnmenellis"},
    {label: "Central Lincolnshire Vale"},
    {label: "Central North Norfolk"},
    {label: "Charnwood"},
    {label: "Cheshire Sandstone Ridge"},
    {label: "Cheviot Fringe"},
    {label: "Cheviots"},
    {label: "Chilterns"},
    {label: "Clun and North West Herefordshire Hills"},
    {label: "Cornish Killas"},
    {label: "Cotswolds"},
    {label: "Cumbria High Fells"},
    {label: "Dark Peak"},
    {label: "Dartmoor"},
    {label: "Derbyshire Peak Fringe and Lower Derwent"},
    {label: "Devon Redlands"},
    {label: "Dorset Downs and Cranborne Chase"},
    {label: "Dorset Heaths"},
    {label: "Dunsmore and Feldon"},
    {label: "Durham Coalfield Pennine Fringe"},
    {label: "Durham Magnesian Limestone Plateau"},
    {label: "East Anglian Chalk"},
    {label: "Eden Valley"},
    {label: "Exmoor"},
    {label: "Forest of Dean and Lower Wye"},
    {label: "Greater Thames Estuary"},
    {label: "Hampshire Downs"},
    {label: "Hensbarrow"},
    {label: "Herefordshire Lowlands"},
    {label: "Herefordshire Plateau"},
    {label: "High Leicestershire"},
    {label: "High Weald"},
    {label: "Holderness"},
    {label: "Howardian Hills"},
    {label: "Howgill Fells"},
    {label: "Humber Estuary"},
    {label: "Humberhead Levels"},
    {label: "Inner London"},
    {label: "Isle of Porland"},
    {label: "Isle of Wight"},
    {label: "Isles of Scilly"},
    {label: "Kesteven Uplands"},
    {label: "Lancashire and Amounderness Plain"},
    {label: "Lancashire Coal Measures"},
    {label: "Lancashire Valleys"},
    {label: "Leicestershire and Nottinghamshire Wolds"},
    {label: "Leicestershire and South Derbyshire Coalfield"},
    {label: "Leicestershire Vales"},
    {label: "Lincolnshire Coast and Marshes"},
    {label: "Lincolnshire Wolds"},
    {label: "Low Weald"},
    {label: "Lundy"},
    {label: "Malvern Hills"},
    {label: "Manchester Conurbation"},
    {label: "Manchester Pennine Fringe"},
    {label: "Marshwood and Powerstock Vales"},
    {label: "Mease/Sence Lowlands"},
    {label: "Melbourne Parklands"},
    {label: "Mendip Hills"},
    {label: "Mersey Valley"},
    {label: "Merseyside Conurbation"},
    {label: "Mid Norfolk"},
    {label: "Mid Northumberland"},
    {label: "Mid Severn Sandstone Plateau"},
    {label: "Mid Somerset Hills"},
    {label: "Midvale Ridge"},
    {label: "Morecambe Bay Limestones"},
    {label: "Morecambe Coast and Lune Estuary"},
    {label: "Needwood and South Derbyshire Claylands"},
    {label: "New Forest"},
    {label: "North Downs"},
    {label: "North East Norfolk and Flegg"},
    {label: "North Kent Plain"},
    {label: "North Norfolk Coast"},
    {label: "North Northumberland Coastal Plain"},
    {label: "North Pennines"},
    {label: "North West Norfolk"},
    {label: "North York Moors and Cleveland Hills"},
    {label: "Northamptonshire Uplands"},
    {label: "Northamptonshire Vales"},
    {label: "Northern Lincolnshire Edge with Coversands"},
    {label: "Northern Thames Basin"},
    {label: "Northumberland Sandstone Hills"},
    {label: "Nottinghamshire, Derbyshire and Yorkshire Coalfield"},
    {label: "Orton Fells"},
    {label: "Oswestry Uplands"},
    {label: "Pennine Dales Fringe"},
    {label: "Pevensey Levels"},
    {label: "Potteries and Churnet Valley"},
    {label: "Quantock Hills"},
    {label: "Rockingham Forest"},
    {label: "Romney Marshes"},
    {label: "Salisbury Plain and West Wiltshire Downs"},
    {label: "Sefton Coast"},
    {label: "Severn and Avon Vales"},
    {label: "Sherwood"},
    {label: "Shropshire Hills"},
    {label: "Shropshire, Cheshire and Staffordshire Plain"},
    {label: "Solway Basin"},
    {label: "Somerset Levels and Moors"},
    {label: "South Coast Plain"},
    {label: "South Cumbria Low Fells"},
    {label: "South Devon"},
    {label: "South Downs"},
    {label: "South East Northumberland Coastal Plain"},
    {label: "South Hampshire Lowlands"},
    {label: "South Herefordshire and Over Severn"},
    {label: "South Norfolk and High Suffolk Claylands"},
    {label: "South Purbeck"},
    {label: "South Suffolk and North Essex Clayland"},
    {label: "South West Peak"},
    {label: "Southern Lincolnshire Edge"},
    {label: "Southern Magnesian Limestone"},
    {label: "Southern Pennines"},
    {label: "Suffolk Coast and Heaths"},
    {label: "Tees Lowlands"},
    {label: "Teme Valley"},
    {label: "Thames Basin Heaths"},
    {label: "Thames Basin Lowlands"},
    {label: "Thames Valley"},
    {label: "The Brecks"},
    {label: "The Broads"},
    {label: "The Culm"},
    {label: "The Fens"},
    {label: "The Lizard"},
    {label: "Trent and Belvoir Vales"},
    {label: "Trent Valley Washlands"},
    {label: "Tyne and Wear Lowlands"},
    {label: "Tyne Gap and Hadrian's Wall"},
    {label: "Upper Thames Clay Vales"},
    {label: "Vale of Mowbray"},
    {label: "Vale of Pickering"},
    {label: "Vale of Taunton and Quantock Fringes"},
    {label: "Vale of York"},
    {label: "Wealden Greensand"},
    {label: "West Cumbria Coastal Plain"},
    {label: "West Penwith"},
    {label: "Weymouth Lowlands"},
    {label: "White Peak"},
    {label: "Wirral"},
    {label: "Yardley-Whittlewood Ridge"},
    {label: "Yeovil Scarplands"},
    {label: "Yorkshire Dales"},
    {label: "Yorkshire Southern Pennine Fringe"},
    {label: "Yorkshire Wolds"}
]